import { hasHardwareAsync, isEnrolledAsync, authenticateAsync } from 'expo-local-authentication'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

const USER_HAS_BIOMETRICS_SET_UP = "UserHasBiometricsSetUp"

export const checkBiometricCompatability = async () => isEnrolledAsync()

export const authenticateUser = async () => {
  const isCompatible = await hasHardwareAsync()
  if (!isCompatible) {
    throw new Error(`Your device isn't compatible`)
  }
  const isEnrolled = await isEnrolledAsync()
  if (!isEnrolled) {
    throw new Error(`No faces or fingers found`)
  }
  const { success } = await authenticateAsync()
  return success
}

export const biometricsSetupFlagExists = async (): Promise<boolean> => {
  const isNative = ['ios', 'android'].includes(Platform.OS)
  if(!isNative){
    return false
  }

  const biometricsSetUpFlag = await AsyncStorage.getItem(USER_HAS_BIOMETRICS_SET_UP)
  if (biometricsSetUpFlag === null){
    return false
  }
  return true
}

export const setBiometricsSetupFlagValue = async (value: boolean) => {
  const isNative = ['ios', 'android'].includes(Platform.OS)
  if(!isNative){
    return false
  }

  const hasUsedBiometrics = await biometricsSetupFlagExists()
  if (!hasUsedBiometrics){
    const flagValue = value === true ? 'true' : 'false'
    AsyncStorage.setItem(USER_HAS_BIOMETRICS_SET_UP, flagValue)
  }
}