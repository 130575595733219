import { ReactNode } from 'react'
import Box from '../../atoms/Box'
import PlantImage from './PlantImage'
import BackArrow from '../../molucules/BackArrow'
import Heading from '../../atoms/Typography/Heading'

const AccountCreationWrapper = ({
  children,
  paddingTop,
  title,
  hasPlant = false,
}: {
  children: ReactNode
  paddingTop?: number[] | number
  hasPlant?: boolean
  title?: string
}) => (
  <Box style={{ flex: 1 }} backgroundDark="transparent" backgroundLight="white">
    {hasPlant && <PlantImage />}
    <Box paddingTop={paddingTop} marginHorizontal={[16, 16, 0]} marginBottom={[56, 56, 0]}>
      <BackArrow paddingTop={0} />
      {title ? (
        <Heading variant="--heading-2" style={{ marginBottom: 24 }}>
          {title}
        </Heading>
      ) : null}
      {children}
    </Box>
  </Box>
)

export default AccountCreationWrapper
