import { useEffect, useReducer, useRef, useContext } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { AuthContext } from '../providers/AuthProvider'
import { CustomerContext } from '../providers/CustomerProvider'
import axios, { AxiosRequestConfig } from 'axios'
import { AccessLevel, GENERIC_ERROR, INJECTABLE_TYPES, StatusDescription } from '../types'
import { getToken, refreshToken, patchBiometricKey, postBiometricLogin } from '../endpoints/index'
import { ILoggingService } from '../services/ILoggingService'
import { useInjection } from 'inversify-react'

export enum RequestStatus {
  INIT = 'init',
  FETCHING = 'fetching',
  FETCHED = 'fetched',
  ERROR = 'error',
}

interface FetchErrors<U> {
  statusCode: number
  statusDescription: StatusDescription
  status: number
  data: U
  error: U
}
interface UserAccess {
  accessLevel: AccessLevel
  responseToken: string
}

interface AuthData {
  accessLevel: AccessLevel
  token: string
  expiration?: string
}
interface State<T> {
  status: RequestStatus
  data?: T
  error?: string
}

type Action<T> =
  | { type: 'reset' }
  | { type: 'request' }
  | { type: 'success'; payload: T }
  | { type: 'failure'; payload: T }

interface UseFetch {
  url?: string
  method: 'post' | 'get' | 'patch'
  options?: AxiosRequestConfig
  body?: any
}

axios.defaults.headers = {
  'Cache-Control': 'no-store',
  Expires: '0',
}

export const useFetch = <T = unknown>({ url, options, method, body = {} }: UseFetch): State<T> => {
  const cancelRequest = useRef<boolean>(false)
  const { setToken, setAccessLevel, token, resetAuth } = useContext(AuthContext)
  const { resetCustomer } = useContext(CustomerContext)
  const navigation = useNavigation()

  const isIos = Platform.OS === 'ios'
  const initialState: State<T> = {
    status: RequestStatus.INIT,
    error: undefined,
    data: undefined,
  }

  const _loggingService = useInjection<ILoggingService>(INJECTABLE_TYPES.ILoggingService)

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'reset':
        return initialState
      case 'request':
        return { ...initialState, status: RequestStatus.FETCHING }
      case 'success':
        return { ...initialState, status: RequestStatus.FETCHED, data: action.payload }
      case 'failure':
        return { ...initialState, status: RequestStatus.ERROR, error: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json',
  }

  const updateUsersAccess = ({ accessLevel, responseToken }: UserAccess) => {
    if (accessLevel) {
      setAccessLevel(accessLevel)
    }
    if (responseToken) {
      setToken(responseToken)
    }
  }
  const updateAuth = (data: AuthData) => {
    if (!data) return
    const { accessLevel, token: responseToken } = data
    updateUsersAccess({
      responseToken,
      accessLevel,
    })
  }

  const revokeAccess = () => {
    resetAuth()
    resetCustomer()
  }

  useEffect(() => {
    if (url && state.status === RequestStatus.INIT) {
      cancelRequest.current = false
    }
    if (!url) {
      cancelRequest.current = state.status === RequestStatus.FETCHING
      if (state !== initialState) dispatch({ type: 'reset' })
      return
    }
    const defaultResponse = {
      statusDescription: StatusDescription.SERVER_ERROR,
      statusCode: 500,
    }
    const handleFetchErrors = ({
      statusCode,
      statusDescription,
      status,
      data,
      error,
    }: FetchErrors<T>) => {
      const resCode = statusCode || status

      if (statusDescription === StatusDescription.EXPIRED_TOKEN) {
        // TODO: show message indicating user has been logged out
        if (url === refreshToken) {
          dispatch({ type: 'failure', payload: data })
        } else {
          navigation.navigate(GENERIC_ERROR, {})
          revokeAccess()
        }

        return
      }
      if (resCode >= 400 && resCode < 500) {
        dispatch({ type: 'failure', payload: data })

        return
      }
      // Temporary hack for now. Should be addressed in the future.
      if (url === postBiometricLogin) {
        let data = { statusCode: 200, statusDescription: 'success' }
        dispatch({ type: 'success', payload: data })
      }
      if ([getToken, patchBiometricKey, postBiometricLogin].includes(url) && resCode === 500) {
        dispatch({ type: 'failure', payload: data })
        return
      }
      navigation.navigate(GENERIC_ERROR, {})
      revokeAccess()

      if (cancelRequest.current) return
      dispatch({ type: 'failure', payload: error.message })
    }

    const fetchData = async () => {
      dispatch({ type: 'request' })
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
      const maybeOptions = method === 'patch' && isIos ? {} : options
      const axiosOptions = { ...maybeOptions, headers }
      const axiosArguments = { method, url, ...axiosOptions, data: body }
      if (method === 'get' && isIos) {
        delete axiosArguments.data
      }
      try {
        const response = await axios(axiosArguments)

        if (cancelRequest.current) return

        updateAuth(response?.data)
        dispatch({ type: 'success', payload: response.data })
      } catch (error) {
        console.warn('Fetch error: ', error)
        const data = error?.response?.data || defaultResponse
        const { statusCode, statusDescription, status } = data
        handleFetchErrors({ statusCode, statusDescription, status, data, error })
      }
    }

    fetchData()

    return () => {
      cancelRequest.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return state
}

export default useFetch
