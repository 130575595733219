import { useState, createContext, FunctionComponent, useCallback } from 'react'

export interface IDeepLink {
  id: string
  type: 'AUTH' | 'NOAUTH'
  action: () => void | Promise<void>
}

export type DeepLinkContextType = {
  deepLinksState: IDeepLink | null
  addDeepLink: (link: IDeepLink) => void
  removeDeepLink: () => void
}

export const DeepLinkContext = createContext<DeepLinkContextType>({
  deepLinksState: null,
  addDeepLink: () => {},
  removeDeepLink: () => {},
})

export const DeepLinkProvider: FunctionComponent<unknown> = ({ children }) => {
  const [deepLinksState, setDeepLinksState] = useState<IDeepLink | null>(null)

  const addDeepLink = useCallback((link: IDeepLink) => {
    setDeepLinksState(link)
  }, [])

  const removeDeepLink = useCallback(() => {
    setDeepLinksState(null)
  }, [])

  return (
    <DeepLinkContext.Provider value={{ deepLinksState, addDeepLink, removeDeepLink }}>
      {children}
    </DeepLinkContext.Provider>
  )
}
