import { useContext } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { ContractContext, ModalVariant } from '../../../providers/ContractProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'
import Card from '../../atoms/Card'
import { ink50, darkest } from '../../tokens/colors/colors'
import Box from '../../atoms/Box'
import MakeAPaymentBase from '../MakeAPaymentBase'
import { useSharedLogic } from './useSharedLogic'

const MakeAPaymentEntry = () => {
  const { contract } = useContext(ContractContext)
  const { buttonAction, buttonActionSecondary, getMakeAPaymentChildren, isLoading } =
    useSharedLogic()
  const { viewPort } = useContext(DimensionContext)
  const hasBackArrow = Platform.OS === 'web' && contract.modalVariant === ModalVariant.MAKE_PAYMENT
  const { Component: FormChildComponent, props } = getMakeAPaymentChildren()
  const hasChildren = Boolean(FormChildComponent)

  return (
    <Box style={styles.modalContainer} backgroundLight={ink50} backgroundDark={darkest}>
      <Card style={[styles.card, styles[`card-${viewPort}`]]}>
        <MakeAPaymentBase
          variant={contract.modalVariant}
          buttonAction={buttonAction()}
          isLoading={isLoading}
          hasBackArrow={hasBackArrow}
          {...(buttonActionSecondary() && { buttonActionSecondary: buttonActionSecondary() })}>
          {hasChildren ? <FormChildComponent {...props} /> : null}
        </MakeAPaymentBase>
      </Card>
    </Box>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    height: 'auto',
  },
  card: {
    paddingHorizontal: 72,
    paddingVertical: 56,
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 552,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  'card-xs': {
    paddingHorizontal: 16,
    paddingVertical: 32,
    marginHorizontal: 16,
  },
  'card-sm': {
    paddingHorizontal: 24,
    paddingVertical: 32,
    marginHorizontal: 16,
  },
  'card-md': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'card-lg': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'card-xl': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
})

export default MakeAPaymentEntry
