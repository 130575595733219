export const validateCCExpiryDate = (expiryDate: string) => {
  const expiryMonth = parseInt(expiryDate.split('/')[0], 10)
  const expiryYear = 2000 + parseInt(expiryDate.split('/')[1], 10)
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  const monthIsGreaterThan12 = expiryMonth > 12
  const yearIsLessThanCurrentYear = expiryYear < currentYear
  const notEnoughDigits = expiryDate.length < 5
  const expiredThisYear = expiryYear === currentYear && expiryMonth < currentMonth
  return !(yearIsLessThanCurrentYear || expiredThisYear || monthIsGreaterThan12 || notEnoughDigits)
}
