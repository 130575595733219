import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const CssReset = createGlobalStyle`

@media only screen and (max-height: 500px) and (max-width: 768px) {
    body {
        min-height: 800px;
        overflow: hidden;
    }
  }
 ${normalize}

 @supports (-webkit-touch-callout: none) and (not (-ms-ime-align:auto))
 and (not (-moz-appearance:none))
 {
   html {
    height:100vh;
    max-width:100vw;
    position:relative;
    height: -webkit-fill-available;
    }

    body {
      min-height: 100vh;
      position:absolute;
      left:0;
      right:0
      max-width:100vw;
      min-height: -webkit-fill-available;
    }
  }
`

export default CssReset
