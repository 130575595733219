import { useEffect, useContext } from 'react'
import { ContentContext } from '../providers'

export const useSmartBanner = () => {
  const { $ } = useContext(ContentContext)
  const getITL = (key: string) => $(`smartBanner.${key}`)

  const excludeUserAgentRegex = new RegExp(
    `(\\s|^)AppleWebKit/[\\d\\.]+\\s+\\(.+\\)\\s+Version/(1[0-9]|[2-9][0-9]|\\d{3,})(\\.|$|\\s)`,
    'i'
  ).source
  const dynamicContent = [
    'title',
    'priceSuffixApple',
    'priceSuffixGoogle',
    'iconApple',
    'iconGoogle',
    'buttonUrlApple',
    'buttonUrlGoogle',
    'closeLabel',
    'button',
    'price',
  ]

  const widgetOptions = {
    ...Object.fromEntries(dynamicContent.map(k => [k, getITL(k)])),
    excludeUserAgentRegex,
    disablePositioning: 'true',
    enabledPlatforms: 'android,ios',
    author: '',
  }

  const init = () => {
    globalThis.smartbanner.options = { ...globalThis.smartbanner.options, ...widgetOptions }
    globalThis.smartbanner.publish()
  }

  useEffect(() => {
    setTimeout(init, 1000)
  }, [])
}
