import { FC, useContext, useEffect } from 'react'
import { Modal, Portal } from 'react-native-paper'
import { StyleSheet, View } from 'react-native'
import Button from '../../atoms/Button'
import Card from '../../atoms/Card'
import Box from '../../atoms/Box'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Heading from '../../atoms/Typography/Heading'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { ContentContext } from '../../../providers/ContentProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'

import { useScrollBlock } from '../../../hooks/useScrollBlock'

const SharedBody = ({ variant, children }: { variant: BodyVariants; children: string }) => (
  <Body
    style={styles.body}
    variant={variant}
    lightColor={colors['--text-dark']}
    darkColor={whiteOpacity9}>
    {children}
  </Body>
)

interface Props {
  header: string
  visible: boolean
  closeModal: () => void
  message: string
  additionalMessage?: string
  buttonText: string
  headerIcon?: () => JSX.Element
  onButtonPress?: () => void
  secondaryButtonText?: string
  onSecondaryButtonPress?: () => void
}

const GenericModal: FC<Props> = ({
  header,
  children,
  buttonText,
  visible,
  message,
  additionalMessage,
  closeModal,
  headerIcon,
  onButtonPress,
  secondaryButtonText,
  onSecondaryButtonPress,
}) => {
  const { localized } = useContext(ContentContext)
  const { viewPort } = useContext(DimensionContext)

  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (visible) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [visible])

  return (
    <Portal>
      <Modal
        contentContainerStyle={styles.modalContainer}
        style={{ alignItems: 'center' }}
        visible={visible}
        dismissable={true}
        onDismiss={closeModal}>
        <Card style={[styles.card, styles[`card-${viewPort}`]]}>
          <Box style={styles.titleWrapper}>
            <Heading style={styles.heading} variant="--heading-2">
              {localized(header)}
              {headerIcon ? ' ' : ''}
            </Heading>
            {headerIcon ? headerIcon() : null}
          </Box>
          <SharedBody variant="--body-01-regular">{localized(message)}</SharedBody>
          {additionalMessage ? (
            <SharedBody variant="--body-01-regular">{localized(additionalMessage)}</SharedBody>
          ) : null}
          <View style={styles.buttonContainer}>
            <Button
              isFullWidthOnMobile
              testID="PrimaryBtn"
              onPress={onButtonPress ? onButtonPress : closeModal}>
              {localized(buttonText)}
            </Button>
            {secondaryButtonText ? (
              <Box style={styles.buttonWrapper}>
                <Button
                  isFullWidthOnMobile
                  secondary={true}
                  testID="SecondaryBtn"
                  onPress={onSecondaryButtonPress ? onSecondaryButtonPress : closeModal}>
                  {localized(secondaryButtonText)}
                </Button>
              </Box>
            ) : null}
          </View>
        </Card>
      </Modal>
    </Portal>
  )
}

export default GenericModal

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    paddingHorizontal: 8,
  },
  card: {
    paddingHorizontal: 72,
    paddingVertical: 64,
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 552,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
    width: '100%',
  },
  'card-xs': {
    paddingHorizontal: 16,
    paddingVertical: 32,
  },
  'card-sm': {
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  'card-md': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'card-lg': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'card-xl': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  heading: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  body: {
    textAlign: 'center',
    marginBottom: 40,
  },
  buttonContainer: {
    width: '100%',
  },
  buttonWrapper: {
    marginTop: 24,
  },
})
