import { useContext, useState, useRef, useEffect } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { CustomerContext, ContentContext, DimensionContext } from '../../../providers'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import SharedInput from '../../molucules/SharedInput'
import Button from '../../atoms/Button'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import { validateEmail } from '../../../helpers'
import { useNavigation } from '@react-navigation/native'
import { initiateEmailUpdate } from '../../../endpoints'
import { EMAIL_UPDATE_EMAIL_VERIFICATION, isApiResponse, StatusDescription } from '../../../types'
import GenericModal from '../../molucules/GenericModal'

const SharedBody = ({ children, variant }: { children: string; variant?: BodyVariants }) => (
  <Body
    variant={variant}
    style={styles.marginBottomMd}
    darkColor={whiteOpacity9}
    lightColor={colors['--text-dark']}>
    {children}
  </Body>
)

const EmailUpdate = () => {
  const { customer } = useContext(CustomerContext)
  const [email, setEmail] = useState('')
  const [url, setUrl] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)
  const isAndroid = Platform.OS === 'android'

  const initialErrorVisibility = useRef(true)

  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs' || viewPort === 'sm'
  const navigation = useNavigation()
  const [loading, setLoading] = useState(false)

  type EmailValidType = 'valid' | 'invalid' | 'duplicate'

  const { $, getLocaleCodeShort } = useContext(ContentContext)

  const { status, data } = useFetch({
    url,
    method: 'post',
    body: {
      newCredential: email,
      culture: getLocaleCodeShort(),
    },
  })

  const handleError = () => {
    setUrl('')
    setLoading(false)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setEmail('')
  }

  const respondToAPIResponse = () => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if (status === RequestStatus.FETCHED) {
      setUrl('')
      setLoading(false)
      if (isApiResponse(data)) {
        const { statusCode, statusDescription } = data
        if (statusCode === 200 && statusDescription === StatusDescription.SUCCESS) {
          navigation.navigate(EMAIL_UPDATE_EMAIL_VERIFICATION)
          return
        }
      }
      handleError()
    }
    if (status === RequestStatus.ERROR) {
      handleError()
    }
  }

  useEffect(respondToAPIResponse, [status])

  const emailValid: EmailValidType =
    email === customer.email ? 'duplicate' : validateEmail(email) ? 'valid' : 'invalid'
  const isEmailInvalid = emailValid !== 'valid'
  const getEmailErrorText = (key: EmailValidType) =>
    ({
      valid: '',
      invalid: $(`global.errorEmail`),
      duplicate: $('updateEmailAddress.errorDuplicateEmail'),
    }[key] || '')

  const buttonDisabled =
    initialErrorVisibility.current && emailValid === 'duplicate' ? false : emailValid !== 'valid'

  const handleSubmit = () => {
    if (initialErrorVisibility.current) {
      initialErrorVisibility.current = false
    }
    if (isEmailInvalid) return
    setUrl(initiateEmailUpdate)
  }

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' && !buttonDisabled) {
      handleSubmit()
    }
  }

  return (
    <>
      <GenericModal
        header={$`updateEmailAddress.errorModalHeader`}
        visible={isModalOpen}
        closeModal={closeModal}
        message={$`updateEmailAddress.errorModalBody`}
        buttonText={'global.ok'}
      />
      <PlanDetailWrapper
        title={$`updateEmailAddress.heading`}
        headingAlignment="left"
        {...Platform.select({
          native: {
            scrollView: true,
          },
          web: { isModal: true },
          default: {},
        })}>
        <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
          <SharedBody>{$`updateEmailAddress.subHeading`}</SharedBody>
          <SharedInput
            textContentType="emailAddress"
            keyboardType={isAndroid ? 'visible-password' : 'email-address'}
            value={email}
            testID="Email"
            maskChar=""
            onChangeText={(text: string) => setEmail(text.toLowerCase())}
            style={styles.marginBottomMd}
            label={$`global.emailAddress`}
            error={isEmailInvalid}
            errorMessage={getEmailErrorText(emailValid)}
            disableMask={true}
            onKeyPress={handleKeyPress}
            onSubmitEditing={handleSubmit}
            autoCapitalize="none"
            {...(isAndroid && { secureTextEntry: true })}
          />
          <SharedBody variant="--body-02-regular">{$`updateEmailAddress.body`}</SharedBody>
          <Box style={styles.marginBottomLg}>
            <Button
              testID="ContinueBtn"
              isLoading={loading}
              disabled={buttonDisabled}
              onPress={handleSubmit}>
              {$`global.continue`}
            </Button>
          </Box>
        </PlanDetailWrapper.InnerWrapper>
      </PlanDetailWrapper>
    </>
  )
}

export default EmailUpdate

const styles = StyleSheet.create({
  marginBottomMd: {
    marginBottom: 24,
  },
  marginBottomLg: {
    marginBottom: 32,
  },
})
