import { createContext, ReactNode, useState, Dispatch, SetStateAction } from 'react'
import { CC, EFT, ContractDetailItem } from '../types'

export enum ModalVariant {
  PAYMENT_ALREADY_SCHEDULED = 'paymentAlreadyScheduled',
  PAYMENT_AMOUNT_EXCEEDED = 'paymentAmountExceeded',
  PAYMENT_BELOW_MINIMUM = 'paymentBelowMinimum',
  CUTOFF_DATE_EXCEEDED = 'cutoffDateExceeded',
  BALANCE_REMAINING_LESS_THAN_MINIMUM = 'balanceRemainingLessThanMinimum',
  NOT_ALLOWED_IF_HAS_UNCONFIRMED_SENT_PAYMENTS = 'notAllowedIfHasUnconfirmedSentPayments',
  CONTRACT_NOT_CAPTURED = 'contractNotCaptured',
  CONTRACT_NOT_FULLY_CAPTURED = 'contractNotFullyCaptured',
  CONTRACT_PENDING_SCHEDULE = 'contractPendingSchedule',
  NOT_ALLOWED_UNTIL_FIRST_PAYMENT = 'notAllowedUntilFirstPayment',
  GENERIC_ERROR = 'genericError',
  MAKE_PAYMENT = 'makePayment',
  CONFIRM = 'confirm',
  CONFIRM_FORCE = 'confirmForce',
  SUCCESS = 'success',
  PAYMENT_METHOD_UPDATED = 'paymentMethodUpdated',
  EFT_ACCEPTED = 'eftAccepted',
  EFT_FAILED = 'eftFailed',
  EFT_UNSUPPORTED_EFT_TYPE = 'eftUnsupportedEftType',
  EFT_UPDATE_NOT_ALLOWED = 'eftUpdateNotAllowed',
}

export type Contract = Partial<ContractDetailItem> & {
  isCaptured?: boolean
  modalVariant?: ModalVariant | null
  paymentProblem?: boolean
}

export interface EFTPayload {
  flinksLoginID?: string
  accountId?: string
  isAppliedToAllOfContractGroup?: boolean
}

type EFTState = EFTPayload | null

interface ContextState {
  contract: Contract
  setContract: Dispatch<SetStateAction<Contract>>
  isRefreshing: boolean
  setRefreshing: Dispatch<SetStateAction<boolean>>
  eftPayload?: EFTPayload | null
  setEFTPayload: Dispatch<SetStateAction<EFTState>>
  hasPaymentProblem?: boolean
  setHasPaymentProblem: Dispatch<SetStateAction<boolean>>
}

export const ContractContext = createContext<ContextState>({
  contract: {
    modalVariant: null,
  },
  setContract: (): void => {},
  isRefreshing: false,
  setRefreshing: (): void => {},
  eftPayload: null,
  setEFTPayload: (): void => {},
  hasPaymentProblem: false,
  setHasPaymentProblem: (): void => {},
})

interface Props {
  children: ReactNode
}

export const ContractProvider = ({ children }: Props) => {
  const [contract, setContract] = useState<Contract>({})
  const [eftPayload, setEFTPayload] = useState<EFTState>(null)
  const [isRefreshing, setRefreshing] = useState<boolean>(false)
  const [hasPaymentProblem, setHasPaymentProblem] = useState<boolean>(false)
  return (
    <ContractContext.Provider
      value={{
        contract,
        setContract,
        isRefreshing,
        setRefreshing,
        eftPayload,
        setEFTPayload,
        hasPaymentProblem,
        setHasPaymentProblem,
      }}>
      {children}
    </ContractContext.Provider>
  )
}
