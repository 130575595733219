import { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { useToolTip } from '../../../hooks/useToolTip'
import { ContentContext } from '../../../providers/ContentProvider'
import { darker4, mintSprig75, whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import Heading from '../../atoms/Typography/Heading'
import elevation from '../../tokens/elevation'

import { AuthContext } from '../../../providers/AuthProvider'
import { AccessLevel } from '../../../types'

const PlansTopSection = ({ totalBalance }: { totalBalance: number }) => {
  const { localized, getCurrency } = useContext(ContentContext)
  const { accessLevel } = useContext(AuthContext)
  const totalBalanceValue =
    accessLevel === AccessLevel.AUTHORIZED_USER && typeof totalBalance === 'number'
      ? totalBalance
      : 0

  const { ToolTip } = useToolTip({
    headerText: localized('plans.toolTipHeader'),
    bodyText: localized('plans.toolTipBody'),
    buttonText: localized('global.ok'),
  })

  return (
    <Box
      style={styles.headingWrapper}
      paddingTop={[50, 50, 60, 60, 60]}
      paddingHorizontal={[16, 16, 80, 80, 80]}
      paddingBottom={32}
      backgroundLight={colors['--ui-background-hover']}
      backgroundDark={darker4}>
      <Heading style={styles.heading} variant="--heading-2">
        {localized('plans.header')}
      </Heading>
      <Box style={styles.balanceWrapper}>
        <Body
          style={styles.topBody}
          variant="--body-01-semi-emphasized"
          lightColor={colors['--text-dark']}
          darkColor={whiteOpacity9}>
          {`${localized('plans.totalBalance')} `}
        </Body>
        <Body
          style={{ color: mintSprig75 }}
          variant="--body-01-semi-emphasized"
          lightColor={colors['--text-01']}
          darkColor={mintSprig75}>
          {getCurrency(totalBalanceValue)}
        </Body>
        <ToolTip />
      </Box>
      <ToolTip.Modal />
    </Box>
  )
}

export default PlansTopSection

const styles = StyleSheet.create({
  headingWrapper: {
    ...elevation['--elevation-emphasized-2'],
    zIndex: 1,
  },
  heading: {
    marginBottom: 16,
  },
  topBody: {
    marginRight: 5,
  },
  balanceWrapper: {
    flexDirection: 'row',
  },
})
