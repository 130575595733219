import { useContext, useState, Fragment } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'
import Body from '../../components/atoms/Typography/Body'
import AccountInformationSettingsWrapper from '../../components/wrappers/AccountInformationSettingsWrapper'
import AccountInformationWrapper from '../../components/wrappers/AccountInformationWrapper'
import {
  DARK,
  LIGHT,
  MANUAL,
  SYSTEM,
  Theme,
  ThemeContext,
  ThemeSettingsType,
} from '../../providers/ThemeProvider'
import { ContentContext } from '../../providers/ContentProvider'
import { Localized } from '../../localization/types'
import colors from '../../components/tokens/colors'
import { ink25, whiteOpacity9 } from '../../components/tokens/colors/colors'
import Hairline from '../../components/atoms/Hairline'
import { Check } from '../../components/atoms/Icons'
import Caption from '../../components/atoms/Typography/Caption'
import { localized } from '../../localization/Localized'

type MenuItem = {
  label: string
  value: Theme | typeof SYSTEM
  web: boolean
}

const menuItems = (localized: Localized): MenuItem[] => [
  {
    label: localized('darkMode.on'),
    value: DARK,
    web: true,
  },
  {
    label: localized('darkMode.off'),
    value: LIGHT,
    web: true,
  },
  {
    label: 'System',
    value: SYSTEM,
    web: false,
  },
]

const getCurrentIndex = (items: MenuItem[], themeSettingsType: ThemeSettingsType, theme: Theme) => {
  if (themeSettingsType === SYSTEM) {
    return items.findIndex(item => item.value === SYSTEM)
  }
  return items.findIndex(item => item.value === theme)
}

const getItems = (items: MenuItem[]) => {
  if (Platform.OS === 'web') {
    return items.filter(item => item.web)
  }
  return items
}

const DarkModeScreen = () => {
  const { theme, themeSettingsType, updateThemeSettingsType } = useContext(ThemeContext)
  const currentThemeIndex = getCurrentIndex(menuItems(localized), themeSettingsType, theme)
  const [selectedIndex, setIndex] = useState(currentThemeIndex)

  const updateSettings = (item: MenuItem, i: number) => {
    setIndex(i)
    const settingsType = item.value === SYSTEM ? SYSTEM : MANUAL
    updateThemeSettingsType(settingsType, item.value)
  }

  return (
    <AccountInformationSettingsWrapper>
      <AccountInformationWrapper title={localized('darkMode.settings')}>
        <Hairline />
        {getItems(menuItems(localized)).map((item, i) => (
          <Fragment key={item.label}>
            <TouchableOpacity
              style={{ paddingVertical: 24, flexDirection: 'row', justifyContent: 'space-between' }}
              onPress={() => updateSettings(item, i)}>
              <Body lightColor={colors['--text-dark']} darkColor={whiteOpacity9}>
                {item.label}
              </Body>
              {i === selectedIndex && <Check size="24" />}
            </TouchableOpacity>
            <Hairline />
          </Fragment>
        ))}
        {Platform.OS !== 'web' && (
          <Caption style={styles.caption} lightColor={colors['--text-dark']} darkColor={ink25}>
            {localized('darkMode.system')}
          </Caption>
        )}
      </AccountInformationWrapper>
    </AccountInformationSettingsWrapper>
  )
}
export default DarkModeScreen

const styles = StyleSheet.create({
  caption: {
    marginVertical: 24,
  },
})
