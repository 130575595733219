import { ReactNode } from 'react'
import { TextStyle } from 'react-native'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'

export interface SharedBodyProps {
  variant: BodyVariants
  children: string | ReactNode
  style?: TextStyle
}

const SharedBody = ({ children, variant, style }: SharedBodyProps) => (
  <Body
    variant={variant}
    style={style}
    lightColor={colors['--text-dark']}
    darkColor={whiteOpacity9}>
    {children}
  </Body>
)

export default SharedBody
