import { useContext } from 'react'
import { AuthContext } from '../../providers/AuthProvider'
import HomeEntry from '../../components/organisms/HomeEntry'

const HomeEntryScreen = () => {
  const { token, accessLevel } = useContext(AuthContext)
  const parsingToken = token && !accessLevel
  return parsingToken ? null : <HomeEntry />
}

export default HomeEntryScreen
