import Svg, { Rect, Path } from 'react-native-svg'
import Props from './shared'

const CheckBoxCheckedDisabled = ({
  color = 'black',
  colorSecondary = 'white',
  size = '40',
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 18 18" fill="none">
    <Rect width="18" height="18" rx="2" fill={colorSecondary} />
    <Rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke={color} strokeOpacity=".2" />
    <Path d="M7 14.42L2 9.42L3.41 8.01L7 11.59L14.59 4L16 5.42L7 14.42Z" fill={color} />
  </Svg>
)

export default CheckBoxCheckedDisabled
