import { useState, useCallback } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { QuestionMark } from '../components/atoms/Icons'
import GenericModal from '../components/molucules/GenericModal'
import ErrorModal from '../components/molucules/ErrorModal/index.native'

export interface ToolTipOptions {
  headerText?: string
  bodyText?: string
  buttonText?: string
}
const defaults = {
  headerText: '',
  bodyText: '',
  buttonText: 'OK',
}

export const useToolTip = (opts: ToolTipOptions = {}) => {
  const { headerText, bodyText, buttonText } = { ...defaults, ...opts }
  const [isOpen, setIsOpen] = useState(false)
  const openToolTipModal = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const closeToolTipModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const ToolTip = ({ children }: { children?: JSX.Element }) => (
    <TouchableOpacity
      accessible={true}
      accessibilityLabel={'ttoltip'}
      accessibilityRole="button"
      importantForAccessibility="yes"
      onPress={openToolTipModal}>
      <View style={{ marginTop: 2, marginLeft: 4 }}>{children || <QuestionMark />}</View>
    </TouchableOpacity>
  )

  ToolTip.Modal = () => (
    <GenericModal
      header={headerText}
      visible={isOpen}
      closeModal={closeToolTipModal}
      message={bodyText}
      buttonText={buttonText}
    />
  )

  return { ToolTip }
}
