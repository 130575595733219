import Svg, { Path, Circle } from 'react-native-svg'
import Props from './shared'

const SuccessAlt = ({ color = 'black', size = 40 }: Props) => (
  <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 42 42" fill="none">
    <Circle cx="21" cy="21" r="21" fill={color} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5418 11.6466C30.823 11.917 30.8319 12.3642 30.5615 12.6454L16.7559 27.0085C16.276 27.6127 15.5369 27.9547 14.7639 27.9318C13.9796 27.9086 13.2524 27.5123 12.8113 26.864L8.75424 21.1531C8.52829 20.8351 8.60296 20.394 8.92102 20.1681C9.23908 19.9421 9.6801 20.0168 9.90605 20.3349L13.9676 26.052L13.9772 26.066C14.1617 26.3394 14.4698 26.5096 14.8057 26.5196C15.1417 26.5295 15.4597 26.3778 15.6606 26.1155C15.6767 26.0945 15.6939 26.0745 15.7122 26.0555L29.5429 11.6664C29.8133 11.3851 30.2605 11.3762 30.5418 11.6466Z"
      fill="white"
    />
  </Svg>
)

export default SuccessAlt
