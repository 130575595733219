import { purpleJellyDark } from '../colors/colors'

const elevation = {
  '--elevation-regular-1': {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowColor: purpleJellyDark,
    shadowRadius: 4,
    elevation: 2,
  },
  '--elevation-regular-2': {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowColor: purpleJellyDark,
    shadowRadius: 6,
    elevation: 4,
  },
  '--elevation-regular-3': {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowColor: purpleJellyDark,
    shadowRadius: 6,
    elevation: 6,
  },
  '--elevation-regular-4': {
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowColor: purpleJellyDark,
    shadowRadius: 10,
    elevation: 14,
  },
  '--elevation-emphasized-1': {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowColor: purpleJellyDark,
    shadowRadius: 3,
    elevation: 2,
  },
  '--elevation-emphasized-2': {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowColor: purpleJellyDark,
    shadowRadius: 8,
    elevation: 4,
  },
  '--elevation-emphasized-3': {
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowColor: purpleJellyDark,
    shadowRadius: 10,
    elevation: 6,
  },
  '--elevation-emphasized-4': {
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.3,
    shadowColor: purpleJellyDark,
    shadowRadius: 4,
    elevation: 14,
  },
}

export default elevation
