import { FC, useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { ContentContext } from '../../../providers/ContentProvider'
import Button from '../../atoms/Button/index'
import Body from '../../atoms/Typography/Body'
import Heading from '../../atoms/Typography/Heading'
import colors from '../../tokens/colors'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import BackArrow from '../../molucules/BackArrow'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import { ModalVariant } from '../../../providers/ContractProvider'
import ThumbsUp from '../../molucules/ThumbsUp'

interface MakeAPaymentBaseProps {
  variant: string
  buttonAction?(): void
  buttonActionSecondary?(): void
  hasBackArrow?: boolean
  isLoading: boolean
}

const MakeAPaymentBase: FC<MakeAPaymentBaseProps> = ({
  variant,
  buttonAction,
  buttonActionSecondary,
  isLoading,
  hasBackArrow,
  children,
}) => {
  const { localized } = useContext(ContentContext)
  const bodyText = localized(`makeAPayment.modal.${variant}.body`)

  const hasTumbsUp = variant === ModalVariant.PAYMENT_METHOD_UPDATED
  const headingText = localized(`makeAPayment.modal.${variant}.heading`) + (hasTumbsUp ? ' ' : '')

  return (
    <>
      {hasBackArrow ? (
        <Box style={styles.arrowWrapper}>
          <BackArrow paddingBottom={24} />
        </Box>
      ) : null}
      <Box style={styles.titleWrapper}>
        <Heading style={styles.heading} variant="--heading-2">
          {headingText}
        </Heading>
        {hasTumbsUp ? <ThumbsUp /> : null}
      </Box>
      {bodyText ? (
        <Body style={styles.body} lightColor={colors['--text-dark']} darkColor={whiteOpacity9}>
          {bodyText}
        </Body>
      ) : null}
      {children ? <Box style={styles.childrenContainer}>{children}</Box> : null}
      {buttonAction ? (
        <View style={styles.buttonContainer}>
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            onPress={buttonAction}
            testID="ContinueBtn">
            {localized(`makeAPayment.modal.${variant}.button`)}
          </Button>
        </View>
      ) : null}
      {buttonActionSecondary ? (
        <View style={styles.buttonContainer}>
          <Box style={styles.buttonSecondaryContainer}>
            <Link onPress={buttonActionSecondary}>
              {localized(`makeAPayment.modal.${variant}.buttonSecondary`)}
            </Link>
          </Box>
        </View>
      ) : null}
    </>
  )
}

export default MakeAPaymentBase

export const styles = StyleSheet.create({
  arrowWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  childrenContainer: {
    width: '100%',
  },
  heading: {
    marginRight: 5,
    textAlign: 'center',
  },
  body: {
    textAlign: 'center',
    marginBottom: 40,
  },
  buttonContainer: {
    width: '100%',
  },
  buttonSecondaryContainer: {
    marginTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
