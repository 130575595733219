import { VFC, useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../../../providers/ThemeProvider'
import AnimatedProgressBar from 'react-native-animated-progress'
import { ink5, whiteOpacity1 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'

const calculateProgress = (principalBalance: number, financedAmount: number) => {
  const showZeroProgress =
    typeof principalBalance !== 'number' &&
    typeof financedAmount !== 'number' &&
    principalBalance >= financedAmount
  const tempProgress = showZeroProgress ? 0 : (1 - principalBalance / financedAmount) * 100
  return tempProgress > 0 ? tempProgress : 0
}

interface ProgressBarProps {
  principalBalance: number
  financedAmount: number
}

export const ProgressBar: VFC<ProgressBarProps> = ({ principalBalance, financedAmount }) => {
  const [progress, setProgress] = useState(0)
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  const backgroundColor = colors['--ui-alt-01']
  const trackColor = isDark ? whiteOpacity1 : ink5

  useEffect(() => {
    const progressValue = calculateProgress(principalBalance, financedAmount)
    if (progressValue) {
      setTimeout(() => {
        setProgress(progressValue)
      }, 500)
    }
  }, [calculateProgress, principalBalance, financedAmount])

  return (
    <AnimatedProgressBar
      progress={progress}
      height={8}
      backgroundColor={backgroundColor}
      trackColor={trackColor}
      animated={true}
    />
  )
}
