import { createContext, ReactNode, useState, Dispatch } from 'react'

interface ContextState {
  isModalOpen: boolean
  setModalOpen: Dispatch<boolean>
}

export const AccessibilityContext = createContext<ContextState>({
  isModalOpen: false,
  setModalOpen: (): void => {},
})

interface Props {
  children: ReactNode
}

export const AccessibilityProvider = ({ children }: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  return (
    <AccessibilityContext.Provider value={{ isModalOpen, setModalOpen }}>
      {children}
    </AccessibilityContext.Provider>
  )
}
