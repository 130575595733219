import { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { ink10, whiteOpacity1 } from '../../tokens/colors/colors'

const Hairline = () => {
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'

  const backgroundColor = isDark ? whiteOpacity1 : ink10
  return <View style={{ backgroundColor, height: 1, width: '100%' }} />
}

export default Hairline
