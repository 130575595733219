import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const ChevronRight = ({ color = 'black', size = '40' }: Props) => (
  <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4878 8.45213C15.7904 8.16926 16.265 8.18523 16.5479 8.48781L26.3466 18.9692C26.3468 18.9693 26.3469 18.9695 26.347 18.9696C26.6102 19.2509 26.75 19.6224 26.75 20C26.75 20.3776 26.6102 20.7491 26.347 21.0304C26.3469 21.0305 26.3468 21.0307 26.3466 21.0308L16.5479 31.5122C16.265 31.8148 15.7904 31.8307 15.4878 31.5479C15.1852 31.265 15.1693 30.7904 15.4521 30.4878L25.2487 20.0087C25.2493 20.0069 25.25 20.0039 25.25 20C25.25 19.9961 25.2493 19.9931 25.2487 19.9913L15.4521 9.51219C15.1693 9.20961 15.1852 8.73501 15.4878 8.45213ZM25.2516 19.9944C25.2515 19.9942 25.2514 19.9941 25.2513 19.994Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </Svg>
)

export default ChevronRight
