import {
  ContractDetailItem,
  PI4,
  AURORA_INSTALLMENT,
  AURORA_ONE_TIME,
  AURORA_REVISED_TRADE_IN,
  MONTHLY,
  PaymentMethodType,
} from '../../../../types'

export const getPlanConditionals = (plan: ContractDetailItem) => {
  const {
    contractType,
    nextPaymentDate,
    nextPaymentAmount,
    tradeInCredit,
    paidInFull,
    contractStatus,
    updateEFTStatus,
  } = plan

  const isPending = updateEFTStatus === 'pending'
  const isValidPayMethod = plan.paymentMethod in PaymentMethodType
  const isInCollections = contractStatus?.toLocaleUpperCase() === 'COLEXT'
  const isPerMonth = contractType && [MONTHLY, AURORA_INSTALLMENT].includes(contractType)
  const isBiWeekly = contractType && contractType === PI4
  const isOneTime =
    contractType && [AURORA_REVISED_TRADE_IN, AURORA_ONE_TIME].includes(contractType)
  const showBalanceRemaining =
    contractType &&
    [PI4, MONTHLY, AURORA_INSTALLMENT].includes(plan.contractType) &&
    !isInCollections
  const showNextPayment =
    nextPaymentDate &&
    typeof nextPaymentAmount === 'number' &&
    nextPaymentAmount > 0 &&
    showBalanceRemaining
  const tradeInCreditIsValid = typeof tradeInCredit === 'number' && tradeInCredit > 0
  const showDetailsCard = tradeInCreditIsValid || isOneTime
  const isRevisedTradeIn = contractType === AURORA_REVISED_TRADE_IN
  const showMakeAPaymentButton = !(isOneTime || paidInFull)
  const showUpdatePaymentMethodLink =
    (plan.paymentProblem && !isPending) ||
    (plan.isCaptured &&
    !paidInFull &&
    isValidPayMethod &&
    !isOneTime &&
    !isInCollections &&
    !isPending)

  return {
    isPerMonth,
    isBiWeekly,
    isOneTime,
    showBalanceRemaining,
    showNextPayment,
    showDetailsCard,
    isRevisedTradeIn,
    showMakeAPaymentButton,
    showUpdatePaymentMethodLink,
    isInCollections,
    isPending,
  }
}
