import { useContext } from 'react'
import { Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Props from './shared'
import { ContentContext } from '../../../../providers/ContentProvider'

const PayBrightByAffirm = ({ width = '138.66', height = '52' }: Props) => {
  const isWeb = Platform.OS === 'web'
  const { localized } = useContext(ContentContext)
  const titleText = localized('entry.SVGTitle')
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 120 45"
      fill="none"
      aria-labelledby="web--svg_paybright_by_affirm_title">
      {isWeb ? <title id="web--svg_paybright_by_affirm_title">{titleText}</title> : null}
      <Path
        d="M22.5756 6.09821C24.5133 6.09821 26.1348 6.98453 26.8147 8.23017V7.1043C26.8118 7.02399 26.8254 6.94394 26.8547 6.86915C26.8839 6.79437 26.9281 6.72644 26.9846 6.66961C27.041 6.61278 27.1085 6.56827 27.1828 6.53885C27.2571 6.50942 27.3366 6.49572 27.4164 6.49859H29.9558C30.035 6.49719 30.1137 6.51192 30.1872 6.5419C30.2606 6.57189 30.3273 6.61651 30.3831 6.67309C30.439 6.72966 30.483 6.79702 30.5123 6.87113C30.5417 6.94523 30.5559 7.02454 30.5541 7.1043V19.9303C30.5559 20.0101 30.5417 20.0894 30.5123 20.1635C30.483 20.2376 30.439 20.305 30.3831 20.3615C30.3273 20.4181 30.2606 20.4627 30.1872 20.4927C30.1137 20.5227 30.035 20.5374 29.9558 20.536H27.4164C27.337 20.5374 27.2581 20.5227 27.1845 20.4928C27.1109 20.4628 27.044 20.4183 26.9878 20.3617C26.9317 20.3052 26.8874 20.2379 26.8577 20.1638C26.8279 20.0896 26.8133 20.0103 26.8147 19.9303V18.8216C26.0736 20.0912 24.5133 20.957 22.5348 20.957C18.9144 20.957 15.5728 18.0584 15.5728 13.5276C15.5728 8.99672 18.9144 6.09821 22.5756 6.09821ZM23.0957 17.4527C25.2543 17.4527 26.8147 15.7006 26.8147 13.4865C26.8147 11.2724 25.2543 9.56137 23.0957 9.56137C22.5915 9.56665 22.0934 9.6728 21.6303 9.87363C21.1672 10.0745 20.7484 10.366 20.3982 10.7312C20.048 11.0965 19.7735 11.5281 19.5905 12.0012C19.4076 12.4742 19.3199 12.9791 19.3325 13.4865C19.3325 15.7006 20.9745 17.4527 23.0957 17.4527Z"
        fill="white"
      />
      <Path
        d="M8.19377 6.09821C6.2119 6.09821 4.61417 6.98453 3.8119 8.23017V7.1043C3.81476 7.02444 3.80123 6.94483 3.77218 6.87046C3.74312 6.79609 3.69915 6.72854 3.64301 6.67203C3.58688 6.61552 3.51978 6.57126 3.44589 6.54201C3.37201 6.51276 3.29294 6.49914 3.2136 6.50201H0.711619C0.632377 6.50061 0.553671 6.51534 0.480227 6.54533C0.406783 6.57531 0.340119 6.61994 0.284237 6.67651C0.228354 6.73308 0.184407 6.80045 0.155036 6.87455C0.125665 6.94865 0.111476 7.02796 0.113321 7.10772V25.8711C0.11146 25.9507 0.125661 26.0299 0.15507 26.1038C0.184478 26.1777 0.228481 26.2448 0.284413 26.3012C0.340346 26.3575 0.407045 26.4017 0.480476 26.4314C0.553907 26.461 0.632541 26.4753 0.711619 26.4734H3.2544C3.33348 26.4753 3.41211 26.461 3.48554 26.4314C3.55897 26.4017 3.62567 26.3575 3.6816 26.3012C3.73754 26.2448 3.78154 26.1777 3.81095 26.1038C3.84036 26.0299 3.85456 25.9507 3.8527 25.8711V25.6897L3.89349 18.9653C4.73315 20.2315 6.47366 20.9159 8.17338 20.9159C11.855 20.9159 15.0946 18.0174 15.0946 13.4865C15.0946 8.95566 11.8754 6.09821 8.19377 6.09821ZM7.59207 17.4527C5.47423 17.4527 3.8323 15.7006 3.8323 13.4865C3.8323 11.2724 5.43343 9.56137 7.59207 9.56137C9.75071 9.56137 11.3315 11.3135 11.3315 13.4865C11.3315 15.6596 9.75411 17.4527 7.59207 17.4527Z"
        fill="white"
      />
      <Path
        d="M44.8726 6.51909H42.153C41.9788 6.51609 41.8091 6.57526 41.6741 6.68613C41.5391 6.797 41.4474 6.9524 41.4153 7.1248L40.2323 11.3306L38.4782 16.7683L35.5547 7.1248C35.5372 6.96735 35.4659 6.82091 35.3531 6.71045C35.2402 6.59999 35.0928 6.53236 34.936 6.51909H31.9751C31.3156 6.51909 31.234 6.94343 31.3768 7.42594L35.5955 19.9098C35.6347 20.0792 35.7288 20.2307 35.863 20.3404C35.9972 20.4501 36.1638 20.5117 36.3366 20.5155H37.336L35.3949 25.5699C35.1978 26.1311 35.3575 26.4733 35.915 26.4733H38.6346C38.8085 26.4772 38.9781 26.4187 39.1132 26.3084C39.2483 26.1981 39.3401 26.0431 39.3723 25.8711L39.5524 25.2072L41.2522 20.5531L45.4335 7.48754C45.5762 6.8613 45.3961 6.51909 44.8726 6.51909Z"
        fill="white"
      />
      <Path
        d="M70.161 6.27956C70.4805 6.27956 70.8205 6.41987 70.8205 7.02216V9.52029C70.8292 9.61697 70.8174 9.71441 70.7859 9.80614C70.7543 9.89788 70.7038 9.98181 70.6375 10.0524C70.5713 10.1229 70.4909 10.1785 70.4017 10.2154C70.3125 10.2523 70.2165 10.2696 70.1202 10.2663H69.4199C67.5808 10.2663 66.4998 11.2108 66.4998 13.6268V19.9303C66.4945 20.0849 66.4285 20.2311 66.3164 20.337C66.2043 20.4428 66.0551 20.4996 65.9015 20.495H63.3587C63.2795 20.4964 63.2008 20.4816 63.1273 20.4517C63.0539 20.4217 62.9872 20.377 62.9313 20.3205C62.8754 20.2639 62.8315 20.1965 62.8021 20.1224C62.7727 20.0483 62.7586 19.969 62.7604 19.8893V7.04269C62.757 6.9627 62.7702 6.88286 62.7991 6.80826C62.828 6.73365 62.8719 6.66589 62.9282 6.60928C62.9844 6.55266 63.0517 6.50841 63.1258 6.47934C63.1999 6.45028 63.2792 6.43702 63.3587 6.4404H65.7587C65.8349 6.4372 65.9109 6.4492 65.9825 6.47572C66.0541 6.50224 66.1197 6.54275 66.1756 6.59492C66.2315 6.64709 66.2767 6.70988 66.3084 6.77968C66.3401 6.84948 66.3578 6.92492 66.3604 7.00163V8.33282C66.6927 7.70971 67.1857 7.18827 67.7875 6.82333C68.3893 6.4584 69.0777 6.26347 69.7802 6.25903C69.9064 6.27612 70.0337 6.28298 70.161 6.27956Z"
        fill="white"
      />
      <Path
        d="M71.201 2.25176C71.2044 1.79097 71.343 1.34145 71.5994 0.959717C71.8558 0.577984 72.2186 0.281065 72.6422 0.106286C73.0657 -0.0684931 73.5311 -0.113336 73.9799 -0.0226065C74.4287 0.0681232 74.8409 0.290378 75.1646 0.61622C75.4883 0.942062 75.709 1.35695 75.7992 1.80874C75.8893 2.26053 75.8448 2.72906 75.6711 3.15544C75.4975 3.58182 75.2026 3.94702 74.8233 4.20514C74.4441 4.46326 73.9976 4.60278 73.5399 4.60617C72.9198 4.60526 72.3255 4.35692 71.8871 3.91558C71.4486 3.47424 71.2019 2.87591 71.201 2.25176ZM72.2821 20.4744C72.2031 20.4744 72.1248 20.4588 72.0518 20.4283C71.9788 20.3979 71.9125 20.3533 71.8566 20.297C71.8007 20.2408 71.7564 20.174 71.7262 20.1005C71.6959 20.027 71.6804 19.9483 71.6804 19.8687V7.0427C71.6775 6.96254 71.6911 6.88264 71.7204 6.80802C71.7496 6.73341 71.7939 6.66569 71.8504 6.60913C71.9069 6.55257 71.9744 6.50838 72.0487 6.47935C72.123 6.45031 72.2025 6.43706 72.2821 6.44041H74.7875C74.8671 6.43706 74.9465 6.45031 75.0208 6.47935C75.0951 6.50838 75.1626 6.55257 75.2191 6.60913C75.2756 6.66569 75.3199 6.73341 75.3492 6.80802C75.3784 6.88264 75.392 6.96254 75.3891 7.0427V19.8482C75.3848 20.0071 75.3199 20.1583 75.2079 20.2705C75.0959 20.3826 74.9454 20.447 74.7875 20.4505H72.2889L72.2821 20.4744Z"
        fill="white"
      />
      <Path
        d="M120 18.4006L119.12 16.9702C118.882 16.5869 118.419 16.5869 118.001 16.8709C117.611 17.1362 117.153 17.2824 116.682 17.2918C116.019 17.2918 115.081 16.8709 115.081 15.5021V9.56133H119.12C119.278 9.55784 119.429 9.4929 119.541 9.38006C119.653 9.26721 119.718 9.11516 119.721 8.95561V7.02213C119.721 6.94275 119.706 6.86415 119.675 6.79085C119.645 6.71755 119.601 6.651 119.545 6.59503C119.489 6.53906 119.423 6.49477 119.35 6.4647C119.277 6.43463 119.198 6.41939 119.12 6.41984H115.067V3.5179C115.07 3.43773 115.057 3.35783 115.027 3.28322C114.998 3.20861 114.954 3.14089 114.897 3.08433C114.841 3.02777 114.773 2.98358 114.699 2.95455C114.625 2.92551 114.545 2.91225 114.466 2.91561H111.926C111.847 2.91225 111.767 2.92551 111.693 2.95455C111.619 2.98358 111.551 3.02777 111.495 3.08433C111.438 3.14089 111.394 3.20861 111.365 3.28322C111.335 3.35783 111.322 3.43773 111.325 3.5179V6.4609H109.407C109.328 6.45905 109.249 6.47333 109.176 6.5029C109.102 6.53247 109.035 6.5767 108.979 6.63296C108.923 6.68921 108.878 6.75633 108.849 6.83026C108.819 6.90419 108.804 6.98342 108.806 7.06319V8.99668C108.804 9.07645 108.819 9.15568 108.849 9.22962C108.878 9.30355 108.923 9.37066 108.979 9.42692C109.035 9.48317 109.102 9.52741 109.176 9.55698C109.249 9.58654 109.328 9.60083 109.407 9.59897H111.325V16.3679C111.325 19.5265 113.667 20.7755 115.948 20.7755C117.546 20.7755 118.967 20.293 119.888 19.3656C120.024 19.2486 120.111 19.0841 120.132 18.9052C120.152 18.7263 120.105 18.5461 120 18.4006Z"
        fill="white"
      />
      <Path
        d="M88.1847 20.4334V18.582C87.4844 19.7079 85.9241 20.5737 83.9626 20.5737C80.1416 20.5737 76.9224 17.836 76.9224 13.3257C76.9224 8.81537 80.2028 6.03662 83.8232 6.03662C85.8017 6.03662 87.4844 6.94348 88.1847 8.17201V7.04272C88.1828 6.96311 88.197 6.88395 88.2264 6.81003C88.2558 6.73611 88.2999 6.66897 88.3558 6.61266C88.4117 6.55636 88.4784 6.51206 88.5518 6.48246C88.6253 6.45285 88.7039 6.43855 88.783 6.44043H91.285C91.3644 6.43704 91.4437 6.4503 91.5179 6.47937C91.592 6.50844 91.6593 6.55268 91.7155 6.6093C91.7718 6.66592 91.8157 6.73368 91.8446 6.80828C91.8735 6.88289 91.8866 6.96272 91.8833 7.04272V20.5326C91.8833 24.4167 89.0244 27.1167 84.4045 27.1167C80.6855 27.1167 77.966 24.7384 77.3065 22.0623C77.2818 22.0044 77.2695 21.942 77.2703 21.879C77.2712 21.816 77.2853 21.754 77.3116 21.6968C77.3379 21.6397 77.3758 21.5887 77.423 21.5473C77.4701 21.506 77.5254 21.475 77.5853 21.4566H80.3252C80.6651 21.4566 80.8861 21.4977 80.9847 21.6996C81.3042 22.7467 82.3853 23.835 84.4657 23.835C86.9813 23.8966 88.1847 22.5277 88.1847 20.4334ZM84.4453 17.2508C86.6074 17.2508 88.1847 15.6014 88.1847 13.4078C88.1847 11.2143 86.6244 9.56138 84.4453 9.56138C83.947 9.5569 83.453 9.6541 82.993 9.84713C82.5331 10.0402 82.1168 10.325 81.7692 10.6845C81.4216 11.044 81.15 11.4706 80.9708 11.9387C80.7916 12.4067 80.7084 12.9065 80.7263 13.4078C80.7229 15.6014 82.3037 17.2508 84.4419 17.2508H84.4453Z"
        fill="white"
      />
      <Path
        d="M54.7988 5.9955C52.817 5.9955 51.1785 6.90236 50.4782 8.0693V5.10918L50.4374 1.14297C50.4339 0.984325 50.3698 0.83315 50.2583 0.720946C50.1468 0.608742 49.9967 0.544167 49.8391 0.540679H47.3371C47.2576 0.537291 47.1783 0.550551 47.1042 0.57962C47.0301 0.608689 46.9628 0.652934 46.9065 0.709552C46.8503 0.76617 46.8064 0.833926 46.7775 0.908533C46.7486 0.983139 46.7354 1.06297 46.7388 1.14297L46.7762 5.10918V19.8242C46.7748 19.904 46.7894 19.9832 46.8192 20.0571C46.849 20.1311 46.8933 20.1982 46.9495 20.2544C47.0057 20.3107 47.0726 20.3549 47.1463 20.3845C47.2199 20.4141 47.2987 20.4283 47.3779 20.4265H49.8765C49.9557 20.4283 50.0345 20.4141 50.1081 20.3845C50.1817 20.3549 50.2486 20.3107 50.3048 20.2544C50.361 20.1982 50.4054 20.1311 50.4351 20.0571C50.4649 19.9832 50.4796 19.904 50.4782 19.8242V18.6949C51.277 19.9645 52.7796 20.8303 54.7581 20.8303C58.4192 20.8303 61.7201 17.9284 61.7201 13.4009C61.7201 8.87349 58.3988 5.9955 54.7988 5.9955ZM54.2175 17.3945C52.0997 17.3945 50.4986 15.6424 50.4986 13.4249C50.4986 11.2074 52.0997 9.49973 54.2175 9.49973C54.7218 9.50502 55.2199 9.61116 55.683 9.81199C56.1461 10.0128 56.5649 10.3043 56.9151 10.6696C57.2652 11.0348 57.5398 11.4665 57.7227 11.9395C57.9057 12.4125 57.9934 12.9174 57.9807 13.4249C57.9399 15.6219 56.3388 17.3945 54.2175 17.3945Z"
        fill="white"
      />
      <Path
        d="M102.405 5.99552C100.185 5.99552 98.5836 6.94345 97.7847 8.06932V1.12245C97.7821 1.04559 97.7644 0.970007 97.7328 0.900019C97.7011 0.830032 97.656 0.767017 97.6001 0.714575C97.5442 0.662134 97.4786 0.621295 97.4071 0.594395C97.3355 0.567496 97.2594 0.555063 97.183 0.557807H94.6436C94.5644 0.556402 94.4857 0.571135 94.4122 0.601121C94.3388 0.631107 94.2721 0.675728 94.2162 0.732303C94.1604 0.788878 94.1164 0.856239 94.087 0.930342C94.0577 1.00444 94.0435 1.08376 94.0453 1.16352V19.8482C94.0435 19.9278 94.0577 20.0069 94.0871 20.0809C94.1165 20.1548 94.1605 20.2219 94.2164 20.2782C94.2724 20.3345 94.3391 20.3788 94.4125 20.4084C94.4859 20.438 94.5646 20.4523 94.6436 20.4505H97.183C97.2594 20.4532 97.3355 20.4408 97.4071 20.4139C97.4786 20.387 97.5442 20.3461 97.6001 20.2937C97.656 20.2413 97.7011 20.1782 97.7328 20.1083C97.7644 20.0383 97.7821 19.9627 97.7847 19.8858V13.1032C97.7847 10.9302 99.1853 9.45869 101.266 9.45869C103.424 9.45869 104.645 10.8891 104.645 13.1032V19.8687C104.647 19.9456 104.665 20.0212 104.697 20.0911C104.728 20.1611 104.773 20.2241 104.829 20.2766C104.885 20.329 104.951 20.3699 105.022 20.3968C105.094 20.4237 105.17 20.4361 105.246 20.4334H107.725C107.801 20.4361 107.877 20.4237 107.949 20.3968C108.02 20.3699 108.086 20.329 108.142 20.2766C108.198 20.2241 108.243 20.1611 108.274 20.0911C108.306 20.0212 108.324 19.9456 108.326 19.8687V12.0971C108.384 8.61343 106.086 5.99552 102.405 5.99552Z"
        fill="white"
      />
      <Path
        d="M72.068 34.3237H72.9315V37.1127H73.0062C73.1506 36.8063 73.3821 36.5501 73.6715 36.3764C73.9608 36.2028 74.2948 36.1196 74.6312 36.1374C76.0555 36.1374 77.038 37.2735 77.038 39.0598C77.038 40.8462 76.0555 41.9994 74.6584 41.9994C74.3187 42.0136 73.9823 41.9276 73.6906 41.7519C73.3989 41.5762 73.1646 41.3184 73.0164 41.0104H72.9111V41.8797H72.068V34.3237ZM74.5292 41.2158C75.6136 41.2158 76.1711 40.2576 76.1711 39.0462C76.1711 37.8347 75.6306 36.921 74.5292 36.921C73.4278 36.921 72.9179 37.7766 72.9179 39.0462C72.9179 40.3158 73.4924 41.2158 74.5326 41.2158H74.5292Z"
        fill="white"
      />
      <Path
        d="M78.085 43.8884L78.306 43.1184C78.9349 43.2827 79.3258 43.2006 79.6386 42.3519L79.8017 41.9104L77.7179 36.2127H78.6561L80.2267 40.723H80.2879L81.8414 36.2058H82.7864L80.3899 42.6873C80.0669 43.5531 79.5128 43.9705 78.7309 43.9705C78.5124 43.9853 78.2931 43.9574 78.085 43.8884Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1609 40.8736C87.7394 40.8736 87.5286 40.6649 87.5286 40.3192C87.5286 39.6793 88.2391 39.4603 89.5377 39.3234C89.5515 39.5169 89.5266 39.7112 89.4643 39.8947C89.4021 40.0783 89.3039 40.2474 89.1754 40.392C89.047 40.5366 88.891 40.6538 88.7168 40.7365C88.5425 40.8192 88.3534 40.8659 88.1609 40.8736ZM88.7252 36.0553C87.7959 36.0877 86.8984 36.404 86.1519 36.9622L86.6788 38.0812C87.2018 37.6056 87.8718 37.3264 88.5757 37.2907C89.2181 37.2907 89.5751 37.5063 89.5751 37.9409C89.5751 38.2352 89.3371 38.3857 88.8952 38.4439C87.2329 38.6595 85.9275 39.1283 85.9275 40.4151C85.9275 41.4417 86.6516 42.0577 87.7802 42.0577C88.1548 42.0587 88.5234 41.963 88.8508 41.7797C89.1781 41.5963 89.4533 41.3315 89.6499 41.0105V41.89H91.1524V38.201C91.1524 36.6747 90.102 36.0553 88.7286 36.0553"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.832 36.223V41.8899H105.44V39.1523C105.44 37.8553 106.222 37.4754 106.766 37.4754C107.007 37.4753 107.243 37.5455 107.446 37.6773L107.738 36.1785C107.509 36.0873 107.264 36.0431 107.018 36.0484C106.652 36.0259 106.29 36.1227 105.984 36.3243C105.677 36.5259 105.444 36.8216 105.318 37.1675V36.2161L103.832 36.223Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.214 36.0553C114.853 36.0609 114.499 36.1544 114.183 36.3279C113.866 36.5013 113.596 36.7495 113.395 37.0511C113.219 36.7459 112.965 36.4931 112.661 36.3183C112.356 36.1434 112.011 36.0528 111.66 36.0556C111.309 36.0584 110.966 36.1545 110.664 36.3341C110.362 36.5137 110.113 36.7706 109.941 37.0785V36.223H108.388V41.89H109.999V38.9743C109.999 37.9238 110.546 37.4241 111.053 37.4241C111.559 37.4241 111.937 37.7253 111.937 38.4953V41.89H113.541V38.9743C113.541 37.9135 114.071 37.4241 114.605 37.4241C115.03 37.4241 115.479 37.7356 115.479 38.485V41.89H117.087V37.9854C117.106 37.7309 117.07 37.4753 116.983 37.2357C116.895 36.9962 116.758 36.7783 116.58 36.5967C116.402 36.4151 116.187 36.2741 115.95 36.183C115.713 36.092 115.46 36.0531 115.207 36.069"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9093 36.2229H98.451V35.648C98.451 34.8951 98.8759 34.683 99.243 34.683C99.4941 34.6859 99.7409 34.748 99.9637 34.8643L100.46 33.7214C100.027 33.481 99.537 33.3628 99.0425 33.3792C98.0227 33.3792 96.843 33.9643 96.843 35.7952V36.2127H94.3989V35.6377C94.3989 34.8849 94.8272 34.6727 95.1909 34.6727C95.4424 34.6716 95.6902 34.734 95.9116 34.8541L96.4113 33.7111C95.9758 33.4731 95.4857 33.3551 94.9904 33.3689C93.9705 33.3689 92.7909 33.9541 92.7909 35.7849V36.2024H91.8561V37.4515H92.8045V41.8694H94.4125V37.472H96.8566V41.8899H98.4646V37.472H99.9229L99.9093 36.2229Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.871 41.89H102.479V36.223H100.871V41.89Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.25 28.3179C105.903 28.3179 102.031 31.3533 100.929 35.2613H102.507C103.424 32.3525 106.542 29.786 110.25 29.786C114.758 29.786 118.654 33.2389 118.654 38.6184C118.667 39.7208 118.516 40.819 118.205 41.8762H119.735V41.8249C119.992 40.776 120.119 39.6989 120.112 38.6184C120.112 32.6195 115.771 28.3042 110.237 28.3042"
        fill="white"
      />
    </Svg>
  )
}
export default PayBrightByAffirm
