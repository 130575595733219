import { ReactNode, useContext } from 'react'
import { vw } from 'react-native-expo-viewport-units'
import { StyleSheet, View } from 'react-native'
import { StyledModal } from './styled'
import styledComponents from 'styled-components'
import { DimensionContext, breakpoints } from '../../../providers/DimensionProvider'
import { ThemeContext } from '../../../providers/ThemeProvider'
import Loading from '../../../components/molucules/Loading'
import Box from '../../atoms/Box'
import Body from '../../atoms/Typography/Body'
import Heading from '../../atoms/Typography/Heading'
import BackArrow from '../../molucules/BackArrow'
import colors from '../../tokens/colors'
import { ink50, ink0, darkest, darker7, whiteOpacity9 } from '../../tokens/colors/colors'

const StyledDiv = styledComponents('div')(
  () => `
   position: absolute;
   width:100%;
   flex:1;
   display:flex;
   min-height:100%;
   flex-grow:1;
   & > div {
    min-height: calc(100vh - 72px);
    min-height:100%;
    flex:1 0 100%;
    z-index:1000;
    @media only screen and (min-width: ${breakpoints[3]}px) {
      min-height: 100vh;
    }
  }`
)

const StyledDivMobile = styledComponents('div')(
  () => `
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
    max-height:100%;
    & > div {
      width:100%;
    }`
)

interface PlanDetailWrapperProps {
  title?: string
  subHeading?: string
  isRefreshing?(): void
  onRefresh?(): void
  scrollView: boolean
  disableBackButton?: boolean
  isModal?: boolean
  isLoading?: boolean
  hasIframe?: boolean
  darkModeOverride?: boolean
  headingAlignment?: 'center' | 'left'
  children: ReactNode
}

const PlanDetailWrapper = ({
  title,
  subHeading,
  children,
  disableBackButton = false,
  isModal = false,
  isLoading = false,
  hasIframe = false,
  darkModeOverride = false,
  headingAlignment = 'center',
}: PlanDetailWrapperProps) => {
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'
  const InnerDynamicWrapper = isMobile ? StyledDivMobile : StyledDiv
  const { theme } = useContext(ThemeContext)
  const modalBackground = darkModeOverride ? ink0 : theme === 'dark' ? darker7 : ink0
  const arrowLeftPadding = headingAlignment === 'center' ? 16 : 8
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
      }}>
      <InnerDynamicWrapper>
        <Box
          contentContainerStyle={styles.modalContainer}
          scrollView={!isMobile}
          backgroundLight={isModal && !isMobile ? ink50 : modalBackground}
          backgroundDark={
            darkModeOverride ? ink50 : isModal && !isMobile ? darkest : modalBackground
          }>
          {isLoading ? (
            <Loading variant="in-screen" isTransparent={true} />
          ) : (
            <StyledModal isMobile={isMobile} isModal={isModal} modalBackground={modalBackground}>
              <Box
                style={styles.modalOuter}
                contentContainerStyle={styles.modalInner}
                backgroundLight={ink0}
                backgroundDark={darkModeOverride ? ink0 : 'transparent'}
                scrollView={!isMobile}>
                {!disableBackButton && (
                  <BackArrow
                    paddingBottom={24}
                    paddingLeft={arrowLeftPadding}
                    darkModeOverride={darkModeOverride}
                  />
                )}
                <Box
                  style={styles.container}
                  paddingHorizontal={[0, 0, 0, 0, 0]}
                  backgroundLight={ink0}
                  paddingBottom={[isMobile ? 0 : 32, 0, 0, 16, 16]}>
                  {title && (
                    <Heading
                      lightColor={colors['--title-dark']}
                      darkColor={colors['--text-light']}
                      style={[styles.heading, { textAlign: headingAlignment }]}
                      variant="--heading-2">
                      {title}
                    </Heading>
                  )}
                  {subHeading ? (
                    <Box
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 24,
                      }}>
                      <Body
                        variant="--body-02-semi-emphasized"
                        darkColor={whiteOpacity9}
                        lightColor={colors['--text-de-emphasized']}>
                        {subHeading}
                      </Body>
                    </Box>
                  ) : null}

                  {!isMobile ? (
                    <Box
                      style={{
                        paddingHorizontal: 8,
                      }}
                      scrollView={isMobile}>
                      {children}
                    </Box>
                  ) : null}
                </Box>
              </Box>
              {isMobile ? (
                <Box
                  style={{ paddingLeft: hasIframe ? 0 : 8, flex: 1 }}
                  contentContainerStyle={{ flexGrow: 1 }}
                  scrollView={isMobile}>
                  {children}
                </Box>
              ) : null}
            </StyledModal>
          )}
        </Box>
      </InnerDynamicWrapper>
    </View>
  )
}

export default PlanDetailWrapper

const styles = StyleSheet.create({
  modalContainer: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    height: 'auto',
  },
  modal: {
    overflow: 'hidden',
    borderRadius: 8,
    borderWidth: 1,
    paddingTop: 23,
    paddingBottom: 23,
    minWidth: '320px',
    maxWidth: '552px',
    width: 'clamp(320px, 100vw, 552px)',
    marginVertical: 80,
  },
  modalInner: {
    paddingHorizontal: 56,
  },
  modalOuter: {
    paddingHorizontal: '8',
  },
  container: {
    flex: 1,
  },
  heading: {
    marginBottom: 16,
    lineHeight: '1',
    paddingHorizontal: 8,
  },
})

interface InnerWrapperProps {
  children: ReactNode
  isMobile?: boolean
}
const InnerWrapper = ({ isMobile, children }: InnerWrapperProps) => (
  <Box
    scrollView={true}
    style={{
      width: isMobile ? vw(100) - 16 : 'auto',
      maxWidth: '100%',
    }}
    contentContainerStyle={{
      ...(isMobile && {
        paddingRight: 16,
        paddingBottom: 16,
      }),
    }}>
    {children}
  </Box>
)

PlanDetailWrapper.InnerWrapper = InnerWrapper
