import { useContext } from 'react'
import { StyleSheet, Image } from 'react-native'
import { DimensionContext } from '../../../providers/DimensionProvider'
// tslint:disable-next-line: no-var-requires
const { version } = require('../../../package.json')

const PlantImage = () => {
  const { viewPort } = useContext(DimensionContext)

  return viewPort === 'xs' ? (
    <Image
      testID={version}
      accessibilityLabel={version}
      source={require('../../../assets/images/plant.png')}
      style={styles.image}
    />
  ) : null
}
export default PlantImage

const styles = StyleSheet.create({
  image: {
    alignSelf: 'flex-end',
    position: 'absolute',
    marginTop: -30,
    width: 100,
    height: 200,
    resizeMode: 'contain',
  },
})
