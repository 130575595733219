import { createElement } from 'react'
import { GetTitle, GetIcon, Description, Status } from './types'
import { SuccessAlt, Circle, CircleFilled, Failed } from '../../atoms/Icons'

export const getTitle = (key: Description) => {
  const values: GetTitle = {
    Scheduled: 'paymentSchedule.scheduled',
    Reattempt: 'paymentSchedule.reAttempt',
    ['Full repayment']: 'paymentSchedule.fullRepayment',
    ['Extra payment']: 'paymentSchedule.extraPayment',
    Defaulted: 'paymentSchedule.defaulted',
    Refund: 'paymentSchedule.refund',
    Processing: 'paymentSchedule.processing',
  }
  return values[key]
}

export const getIcon = ({
  key,
  iconSize,
  color,
}: {
  key: Status
  iconSize: string
  color: string
}) => {
  const defaultFn = () => null
  const values: GetIcon = {
    Active: () => createElement(Circle, { size: iconSize }, null),
    Confirmed: () => createElement(SuccessAlt, { color, size: iconSize }, null),
    Failed: () => createElement(Failed, { size: iconSize }, null),
    Processing: () => createElement(CircleFilled, { size: iconSize }, null),
  }
  return values[key] || defaultFn
}
