import { useContext, useState, useEffect } from 'react'
import { Platform } from 'react-native'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useNavigation } from '@react-navigation/native'
import { ContractContext, ModalVariant } from '../../../providers/ContractProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { MakeAPaymentConfirm } from '../MakeAPaymentConfirm'
import { MakeAPaymentForm } from '../MakeAPaymentForm'
import { postPayment } from '../../../endpoints'

export const useSharedLogic = () => {
  const { contract, setContract, setRefreshing } = useContext(ContractContext)
  const { localized, getCurrency } = useContext(ContentContext)
  const navigation = useNavigation()
  const [requestedPayment, setRequestedPayment] = useState<number | null>(null)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const { minExtraPaymentAmount = 10, maxExtraPaymentAmount, payOffBalance, allowPayoff } = contract
  const minimumAsCurrency = getCurrency(minExtraPaymentAmount)
  const maximumAsCurrency = getCurrency(maxExtraPaymentAmount)

  const getErrorMessage = (
    inputValue: number,
    hasBeenFocused: boolean
  ): { errorMsg: string; isError: boolean } => {
    const isPayOffBalanceAndPayOffIsAllowed = inputValue === payOffBalance && allowPayoff
    const isGreaterOrEqualToMinimum = inputValue >= minExtraPaymentAmount
    const isLessThanOrEqualToMaxium = inputValue <= maxExtraPaymentAmount
    const isBetweenMinAndMax = isGreaterOrEqualToMinimum && isLessThanOrEqualToMaxium
    if (isBetweenMinAndMax || isPayOffBalanceAndPayOffIsAllowed) {
      return { errorMsg: '', isError: false }
    }

    if (!isLessThanOrEqualToMaxium) {
      return {
        errorMsg: `${localized('makeAPayment.errorMaximum')} ${maximumAsCurrency} ${localized(
          'makeAPayment.orRepay'
        )}`,
        isError: true,
      }
    }


    return {
      errorMsg: hasBeenFocused
        ? `${localized('makeAPayment.errorMinimum')} ${minimumAsCurrency}`
        : '',
      isError: true,
    }
  }

  const { status, data } = useFetch({
    url,
    method: 'post',
    body: {
      contractKey: contract.contractKey,
      amount: requestedPayment,
    },
  })
  const dispatchRequestedPayment = (formInputValue: number) => setRequestedPayment(formInputValue)
  const handlePostPayment = () => {
    setUrl(postPayment)
  }

  const handleConfirmForcePayment = () => {
    setRequestedPayment(contract.payOffBalance)
    setContract(prevContract => ({
      ...prevContract,
      modalVariant: ModalVariant.CONFIRM_FORCE,
    }))
  }
  const returnToStart = () => {
    if (contract.modalVariant) setContract(prev => ({ ...prev, modalVariant: null }))
    if (isModalVisible) setModalVisible(false)
    if (requestedPayment) setRequestedPayment(null)
    if (Platform.OS === 'web') navigation.goBack()
  }
  useEffect(() => (contract.modalVariant ? setModalVisible(true) : returnToStart()), [contract])
  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if (status === RequestStatus.FETCHED) {
      setUrl('')
      setLoading(false)
      const { statusCode, statusDescription } = data
      if (statusCode === 200) {
        const variantKey = statusDescription ? statusDescription.toUpperCase() : ''
        const isIncludedInModals = Object.keys(ModalVariant).includes(variantKey)
        const modalVariant = isIncludedInModals
          ? ModalVariant[variantKey]
          : ModalVariant.GENERIC_ERROR

        if (modalVariant === ModalVariant.SUCCESS) {
        }
        setRequestedPayment(null)
        setContract(prevContract => ({
          ...prevContract,
          modalVariant,
        }))
      }
    }
    if (status === RequestStatus.ERROR) {
      setUrl('')
      setLoading(false)
      setRequestedPayment(null)
      setContract(prevContract => ({
        ...prevContract,
        modalVariant: ModalVariant.GENERIC_ERROR,
      }))
    }
  }, [status])

  const refreshAndCloseModal = () => {
    setRefreshing(true)
    returnToStart()
  }

  const handleDismiss = () =>
    [ModalVariant.SUCCESS, ModalVariant.PAYMENT_METHOD_UPDATED, ModalVariant.EFT_ACCEPTED].includes(
      contract.modalVariant
    )
      ? refreshAndCloseModal()
      : returnToStart()

  const buttonAction = () => {
    switch (contract.modalVariant) {
      case ModalVariant.NOT_ALLOWED_UNTIL_FIRST_PAYMENT:
        return handleConfirmForcePayment
      case ModalVariant.CONFIRM_FORCE:
      case ModalVariant.CONFIRM:
        return handlePostPayment
      case ModalVariant.EFT_ACCEPTED:
      case ModalVariant.PAYMENT_METHOD_UPDATED:
      case ModalVariant.SUCCESS:
        return refreshAndCloseModal
      case ModalVariant.MAKE_PAYMENT:
        return null
      default:
        return returnToStart
    }
  }

  const buttonActionSecondary = () => {
    switch (contract.modalVariant) {
      case ModalVariant.NOT_ALLOWED_UNTIL_FIRST_PAYMENT:
      case ModalVariant.CONFIRM:
      case ModalVariant.CONFIRM_FORCE:
        return returnToStart
      case ModalVariant.MAKE_PAYMENT:
      default:
        return null
    }
  }

  const getMakeAPaymentChildren = () => {
    const sharedProps = {
      paymentAccountNumber: contract.paymentAccountNumber,
      paymentIcon: contract.paymentIcon,
    }
    const modals = {
      [ModalVariant.MAKE_PAYMENT]: {
        Component: MakeAPaymentForm,
        props: { getErrorMessage, dispatchRequestedPayment },
      },
      [ModalVariant.CONFIRM]: {
        Component: MakeAPaymentConfirm,
        props: { ...sharedProps, amount: requestedPayment, variant: contract.modalVariant },
      },
      [ModalVariant.CONFIRM_FORCE]: {
        Component: MakeAPaymentConfirm,
        props: { ...sharedProps, amount: contract.payOffBalance, variant: contract.modalVariant },
      },
    }
    return modals[contract.modalVariant] || {}
  }

  return {
    buttonAction,
    buttonActionSecondary,
    getMakeAPaymentChildren,
    isLoading,
    isModalVisible,
    handleDismiss,
  }
}
