import { ReactNode } from 'react'
import { Provider as IocProvider } from 'inversify-react'
import { Platform, LogBox } from 'react-native'
LogBox?.ignoreLogs(['Setting a timer'])
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Provider as PaperProvider } from 'react-native-paper'
import { FlagsProvider, FeatureFlags } from 'flagged'
import { getEnvVars } from './config/getEnvVars'
import useCachedResources from './hooks/useCachedResources'
import Navigation from './nav/Navigation'

import {
  AccessibilityProvider,
  AuthProvider,
  ContentProvider,
  ContractProvider,
  CustomerProvider,
  DimensionProvider,
  LoadingProvider,
  QueryParamsProvider,
  ThemeProvider,
  DeepLinkProvider,
} from './providers'
import CssReset from './components/CssReset'
import { iocContainer } from './inversify.config'
import { combineProviders, Provider, ProviderProps } from './helpers/combineProviders'

const App = () => {
  const isLoadingComplete = useCachedResources()
  const { FEATURES } = getEnvVars()
  const QueryParamsWebProvider = ({ children }: { children: ReactNode }) => {
    if (Platform.OS === 'web') {
      return <QueryParamsProvider>{children}</QueryParamsProvider>
    }
    return <>{children}</>
  }

  if (!isLoadingComplete) {
    return null
  }

  interface FeatureFlagProps extends ProviderProps {
    features?: FeatureFlags
  }

  const FeatureFlagsProvider: Provider<FeatureFlagProps> = ({ children }) => (
    <FlagsProvider features={FEATURES}>{children}</FlagsProvider>
  )

  const CombinedProvider = combineProviders([
    FeatureFlagsProvider,
    QueryParamsWebProvider,
    ContentProvider,
    ContractProvider,
    ThemeProvider,
    DimensionProvider,
    PaperProvider,
    AccessibilityProvider,
    AuthProvider,
    CustomerProvider,
    LoadingProvider,
    SafeAreaProvider,
  ])

  return (
    <>
      <DeepLinkProvider>
        <IocProvider container={iocContainer}>
          <CssReset />
          <CombinedProvider>
            <Navigation />
          </CombinedProvider>
        </IocProvider>
      </DeepLinkProvider>
    </>
  )
}

export default App
