import { ScreenParentWrapper } from '../../components/wrappers/ScreenParentWrapper'
import PaymentMethodSelector from '../../components/organisms/PaymentMethodSelector'

const PaymentMethodSelectorScreen = () => (
  <ScreenParentWrapper>
    <PaymentMethodSelector />
  </ScreenParentWrapper>
)

export default PaymentMethodSelectorScreen
