import { useContext, useState, useEffect } from 'react'
import GenericModal from '../../molucules/GenericModal'
import Button from '../../atoms/Button'
import AccountCreationWrapper from '../../wrappers/AccountCreationWrapper'
import MorePersonalInformation from '../../molucules/MorePersonalInformation'
import { accountVerification } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { useNavigation } from '@react-navigation/native'
import { CustomerContext } from '../../../providers/CustomerProvider'

import {
  StatusDescription,
  EMAIL_VERIFICATION_CODE_ENTRY,
  PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION,
  AccessLevel,
  ApiResponse,
} from '../../../types'

const UpdatePhoneNumber = () => {
  const navigation = useNavigation()
  const { accessLevel } = useContext(AuthContext)
  const { customer, setCustomer } = useContext(CustomerContext)
  const { localized, getLocaleCodeShort } = useContext(ContentContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isValidForm, setIsValidForm] = useState<boolean>(false)
  const [oldPhoneNumber, setOldPhoneNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [lastDigitsOfPaymentMethod, setLastDigitsOfPaymentMethod] = useState('')
  const { firstName, lastName, email } = customer
  const [url, setUrl] = useState('')
  const [responseEmail, setResponseEmail] = useState('')

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: {
      firstName,
      lastName,
      oldPhoneNumber,
      postalCode,
      email,
      lastDigitsOfPaymentMethod,
      culture: getLocaleCodeShort(),
    },
  })

  useEffect(() => {
    if ([RequestStatus.ERROR, RequestStatus.FETCHED].includes(status)) {
      const { statusDescription } = (error || data) as ApiResponse
      setUrl('')
      if (statusDescription === StatusDescription.FAILED) {
        setModalOpen(true)
      }
    }
  }, [status])

  const send = async () => {
    setUrl(accountVerification)
  }

  useEffect(() => {
    if (responseEmail) {
      navigation.replace(EMAIL_VERIFICATION_CODE_ENTRY, {
        params: PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION,
      })
    }
  }, [responseEmail])

  const triggerNavigationIfAuthStep3 = () => {
    if (accessLevel === AccessLevel.AUTH_STEP_3) {
      const maskedEmail = data?.emailAddress
      setCustomer({ ...customer, maskedEmail })
      setResponseEmail(maskedEmail)
    }
  }

  useDidUpdateEffect(() => {
    if (data) {
      triggerNavigationIfAuthStep3()
    }
  }, [data])

  const validFormHandler = (isValid: boolean) => setIsValidForm(isValid)

  return (
    <>
      <GenericModal
        header={localized('updatePhoneNumber.errorHeader')}
        visible={isModalOpen}
        closeModal={() => setModalOpen(false)}
        message={localized('updatePhoneNumber.errorParagraph')}
        buttonText={localized('global.ok')}
      />
      <AccountCreationWrapper
        hasPlant={true}
        paddingTop={[32, 32, 0, 0, 0]}
        title={localized('pageTitle.updatePhoneNumber')}>
        <MorePersonalInformation
          validFormHandler={validFormHandler}
          send={send}
          isValidForm={isValidForm}
          oldPhoneNumber={oldPhoneNumber}
          setOldPhoneNumber={setOldPhoneNumber}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          lastDigitsOfPaymentMethod={lastDigitsOfPaymentMethod}
          setLastDigitsOfPaymentMethod={setLastDigitsOfPaymentMethod}
        />
        <Button disabled={!isValidForm} testID="updatePhoneNumberContinueBtn" onPress={send}>
          {localized('global.continue')}
        </Button>
      </AccountCreationWrapper>
    </>
  )
}

export default UpdatePhoneNumber
