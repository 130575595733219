const space = {
  '--spacing-01': 4,
  '--spacing-02': 8,
  '--spacing-03': 16,
  '--spacing-04': 24,
  '--spacing-05': 32,
  '--spacing-06': 40,
  '--spacing-07': 48,
  '--spacing-08': 72,
  '--spacing-09': 108,
  '--spacing-10': 160,
  '--spacing-11': 250,
}

export default space
