import { FC, useContext, useEffect, useState } from 'react'
import { FlatList, Platform } from 'react-native'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext } from '../../../providers/ContractProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'
import Box from '../../atoms/Box'
import Hairline from '../../atoms/Hairline'
import Loading from '../../molucules/Loading'
import { ScheduleItem } from '../../molucules/ScheduleItem'
import { getPaymentSchedule } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import { Description, Status, ItemsEntity } from '../../../types'

const Schedule: FC = () => {
  const { contract } = useContext(ContractContext)
  const { viewPort } = useContext(DimensionContext)
  const [scheduleItems, setScheduleItems] = useState([])
  const [pageState, setPageState] = useState({})
  const [isRefreshing, setRefreshing] = useState(false)

  const [isLoading, setLoading] = useState(true)
  const isMobile = viewPort === 'xs'
  const [url, setUrl] = useState('')

  const { status, data } = useFetch({
    url,
    method: 'get',
  })

  useEffect(() => {
    if (status === RequestStatus.FETCHING && !isRefreshing) {
      setLoading(true)
      return
    }
    setLoading(false)
    if (status === RequestStatus.FETCHED && data) {
      const { itemTotalCount, pageNumber, pageSize, currentPageNumber, items, statusCode } = data
      setPageState({ itemTotalCount, pageNumber, pageSize, currentPageNumber })
      const transformedItems = items
        .map((item: ItemsEntity) =>
          item.status === Status.Processing
            ? { ...item, description: Description.Processing }
            : item
        )
        .filter((item: ItemsEntity) => Object.values(Status).includes(item.status))
      setScheduleItems(transformedItems)
      setUrl('')
    }
  }, [data, status, isRefreshing])

  useEffect(() => {
    setUrl(`${getPaymentSchedule}/${contract.contractKey}`)
  }, [])

  useEffect(() => {
    if (status === RequestStatus.FETCHED) {
      setRefreshing(false)
    }
  }, [status])

  const handleRefresh = () => {
    if (!isRefreshing) {
      setRefreshing(true)
      setUrl(`${getPaymentSchedule}/${contract.contractKey}`)
    }
  }

  const { localized } = useContext(ContentContext)
  const renderItem = ({ item }) => <ScheduleItem item={item} />

  return (
    <Box style={{ flex: 1 }}>
      <PlanDetailWrapper
        title={localized('paymentSchedule.header')}
        subHeading={`#${contract.contractNumber}`}
        {...Platform.select({
          native: { scrollView: false },
          web: { isModal: !isMobile },
          default: {},
        })}>
        {isLoading ? (
          <Loading variant="in-screen" isTransparent={true} darkModeOverride={true} />
        ) : (
          <Box style={{ flex: 1, flexGrow: 1 }}>
            <Box style={{ flexBasis: '100%', flexGrow: 1 }}>
              <FlatList
                contentContainerStyle={{ paddingHorizontal: 8 }}
                data={scheduleItems}
                renderItem={renderItem}
                refreshing={isRefreshing}
                onRefresh={handleRefresh}
                keyExtractor={(item: ItemsEntity) => `${item.paymentScheduleKey}`}
                ListHeaderComponent={Hairline}
                ListFooterComponent={Hairline}
                ItemSeparatorComponent={() => <Hairline />}
              />
            </Box>
          </Box>
        )}
      </PlanDetailWrapper>
    </Box>
  )
}

export default Schedule
