import { FC, useContext } from 'react'
import { StyleSheet, Image, View } from 'react-native'
import { StyledCard, StyledInnerCardMD, StyledInnerCard, Sizes } from './styled'
import Box from '../../atoms/Box'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { beigeBread50, darker7, ink0 } from '../../tokens/colors/colors'
import Div100vh from 'react-div-100vh'

interface LoginProps {
  center?: boolean
}

const Login: FC<LoginProps> = ({ center, children }) => {
  const { viewPort } = useContext(DimensionContext)
  const { theme } = useContext(ThemeContext)
  const blobsSource = require(theme === 'light'
    ? '../../../assets/images/login-light-blobs.png'
    : '../../../assets/images/login-dark-blobs.png')

  if (viewPort === 'xs') {
    return <View style={styles.container}>{children}</View>
  }

  if (viewPort === 'sm' || viewPort === 'md') {
    return (
      <Box backgroundLight={ink0} backgroundDark={darker7} style={styles.mediumScreenContainer}>
        <Image source={require('../../../assets/images/plant.png')} style={styles.image} />

        <StyledInnerCardMD center={center}>{children}</StyledInnerCardMD>
      </Box>
    )
  }
  return (
    <Box style={styles.container}>
      <StyledCard center={center} backgroundLight={ink0} backgroundDark={darker7}>
        <StyledInnerCard center={center} viewPort={viewPort as Sizes}>
          {children}
        </StyledInnerCard>
      </StyledCard>
      <Box style={styles.shapeContainer} backgroundLight={beigeBread50}>
        <Image
          source={blobsSource}
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'cover',
            position: 'absolute',
          }}
        />
        <Image
          style={{ width: 510, height: 600 }}
          source={require('../../../assets/images/login-shape.png')}
        />
      </Box>
    </Box>
  )
}

export default Login

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  mediumScreenContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    alignSelf: 'flex-end',
    position: 'absolute',
    top: 10,
    width: 140,
    height: 280,
    resizeMode: 'contain',
  },
  'margin-lg': {
    marginRight: '20%',
    width: 330,
  },
  'margin-xl': {
    marginRight: '20%',
  },
  shapeContainer: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '50%',
  },
})
