import { useContext, FC } from 'react'
import { View } from 'react-native'
import colors from '../../tokens/colors'
import Body from '../../atoms/Typography/Body'
import { SharedBody } from './styled'
import { ContentContext } from '../../../providers/ContentProvider'
import { whiteOpacity9 } from '../../tokens/colors/colors'

export const AprBlockRow = ({ apr }: { apr: number }) => {
  const { localized, getApr, getLocale } = useContext(ContentContext)
  const isFrench = getLocale().slice(0, 2).toLowerCase() === 'fr'

  const AprText: FC = ({ children }) => (
    <Body
      lightColor={colors['--text-dark']}
      darkColor={whiteOpacity9}
      variant={'--body-02-regular'}>
      {children}
    </Body>
  )

  return (
    <View style={{ alignItems: 'center', flexDirection: 'row' }}>
      {isFrench ? <AprText>{`${localized('plans.apr')} `}</AprText> : null}
      <SharedBody>{getApr(apr)}</SharedBody>
      {isFrench ? null : <AprText>{` ${localized('plans.apr')}`}</AprText>}
    </View>
  )
}
