import { FC, Dispatch, SetStateAction, useContext, useRef } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { MASK_PHONE_NUMBER, MASK_PHONE_NUMBER_ALT, MASK_PIN } from '../../../helpers/maskTypes'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import SharedBody from '../../molucules/SharedBody'
import AccountCreationWrapper from '../../wrappers/AccountCreationWrapper'
import { ContentContext } from '../../../providers/ContentProvider'
import { AuthContext } from '../../../providers'

export type LoginFormType = {
  isPinPage?: boolean
  title: string
  instruction: string
  inputLabel: string
  send(): void
  isLoading: boolean
  setValue: Dispatch<SetStateAction<null>>
  value: string | null
  hasPlant: boolean
  paddingTop: number[] | number
}

const LoginForm: FC<LoginFormType> = ({
  isPinPage = false,
  paddingTop,
  title,
  instruction,
  inputLabel,
  setValue,
  isLoading,
  send,
  value,
  hasPlant = true,
  children,
}) => {
  const { localized } = useContext(ContentContext)
  const { setAuthFlowType, authFlowType } = useContext(AuthContext)
  const inputRef = useRef(null)

  const isTenDigits = (str: string) => {
    if (typeof str !== 'string' || str.length === 0) return false
    return str.split('').filter(letter => !Number.isNaN(parseInt(letter, 10))).length === 10
  }

  const disabled = isPinPage ? value?.length !== 6 : !isTenDigits(value || '')

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' && !disabled) {
      send()
    }
  }

  const handleSubmit = () => {
    if (!disabled) {
      send()
    }
  }

  const cleanedPhoneValue = value?.replace(/[^0-9]/g, '') || ''

  const loginInputProps: LoginInputProps = {
    value: value ?? '',
    testID: isPinPage ? 'PIN' : 'PhoneNumber',
    maskProps: isPinPage
      ? MASK_PIN
      : cleanedPhoneValue.length <= 2
      ? MASK_PHONE_NUMBER_ALT
      : MASK_PHONE_NUMBER,
    maskChar: '',
    autoFocus: true,
    style: styles.margin,
    label: inputLabel,
    onChangeText: val => setValue(val.replace(/[^0-9]/g, '')),
    keyboardType: 'number-pad',
    onKeyPress: handleKeyPress,
    onSubmitEditing: handleSubmit,
    ref: inputRef,
    ...(Platform.OS === 'web' && {
      inputProps: {
        autoComplete: isPinPage
          ? 'new-password'
          : cleanedPhoneValue.length === 3
          ? 'new-password'
          : 'tel-national',
      },
    }),
  }

  interface LoginInputProps {
    testID: string
    maskProps?: {
      type: string
      options: {
        mask: string
      }
    }
    maskChar: ''
    autoFocus: boolean
    style: any
    label: string
    value: any
    onChangeText: () => void
    enablesReturnKeyAutomatically: boolean
    keyboardType: string
    onKeyPress: (e: any) => void
    onSubmitEditing: () => void
    returnKeyType?: string
    ref?: any
  }

  if (!disabled) {
    loginInputProps.returnKeyType = 'done'
  }

  const handlePhoneFlowType = () => setAuthFlowType('phoneUpdate')

  return (
    <AccountCreationWrapper paddingTop={[32, 32, 0, 0]} hasPlant={hasPlant} title={title}>
      <Box paddingTop={paddingTop}>
        <SharedBody variant="--body-02-semi-emphasized" style={styles.margin}>
          {instruction}
        </SharedBody>
        <Input {...loginInputProps} />
        {children}
        <Button
          testID={isPinPage ? 'enterPINContinueBtn' : 'enterPhoneNumberContinueBtn'}
          disabled={disabled}
          style={styles.button}
          isLoading={isLoading}
          onPress={send}>
          {localized('global.continue')}
        </Button>
        <Box style={styles.linkWrapper}>
          {authFlowType === 'auth' && !isPinPage ? (
            <Box style={styles.link}>
              <Link onPress={handlePhoneFlowType}>{localized('entry.updatePhoneLink')}</Link>
            </Box>
          ) : null}
        </Box>
      </Box>
    </AccountCreationWrapper>
  )
}

export default LoginForm

const styles = StyleSheet.create({
  backWrapper: {
    position: 'absolute',
  },
  margin: {
    marginBottom: 24,
  },
  button: {
    marginBottom: 40,
  },
  linkWrapper: {
    position: 'relative',
    paddingVertical: 16,
  },
  link: {
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
  },
})
