import { useContext, useEffect, useState } from 'react'
import {
  StyleSheet,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  Platform,
} from 'react-native'
import SharedInput from '../SharedInput'
import {
  personalInformationPropsLastName,
  personalInformationPropsFirstName,
  personalInformationOldPhoneNumber,
  personalInformationPropsEmail,
  personalInformationPropsStreetNumber,
  personalInformationPostalCode,
  personalInformationLastDigitsOfPaymentMethod,
} from '../MorePersonalInformation/helpers'
import { validateEmail, isTenDigits, validatePostalCode } from '../../../helpers'
import { CustomerContext } from '../../../providers/CustomerProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'

const MorePersonalInformation = ({
  validFormHandler,
  send,
  isValidForm,
  oldPhoneNumber,
  setOldPhoneNumber,
  postalCode,
  setPostalCode,
  lastDigitsOfPaymentMethod,
  setLastDigitsOfPaymentMethod,
}: {
  validFormHandler(isFormValid: boolean): void
  send(): void
  isValidForm: boolean
  oldPhoneNumber: string
  setOldPhoneNumber(text: string): void
  postalCode: string
  setPostalCode(text: string): void
  lastDigitsOfPaymentMethod: string
  setLastDigitsOfPaymentMethod(text: string): void
}) => {
  const { customer, setCustomer } = useContext(CustomerContext)
  const { localized } = useContext(ContentContext)
  const { phoneNumber, firstName, lastName, email } = customer
  const [isEmailValid, setEmailIsValid] = useState(true)
  const [isLastNameValid, setLastNameIsValid] = useState(true)
  const [isFirstNameValid, setFirstNameIsValid] = useState(true)
  const [oldPhoneNumberValid, setOldPhoneNumberIsValid] = useState<'valid' | 'short' | 'duplicate'>(
    'valid'
  )
  const [isPostalCodeValid, setPostalCodeIsValid] = useState(true)
  const [islastDigitsValid, setLastDigitsValid] = useState(true)
  const isAndroid = Platform.OS === 'android'

  useEffect(() => {
    setEmailIsValid(!email ? false : validateEmail(email))
  }, [email])

  useEffect(() => {
    setLastNameIsValid(Boolean(lastName?.length))
  }, [lastName])

  useEffect(() => {
    setFirstNameIsValid(Boolean(firstName?.length))
  }, [firstName])

  useEffect(() => {
    if (!isTenDigits(oldPhoneNumber || '')) {
      setOldPhoneNumberIsValid('short')
    } else if (oldPhoneNumber?.replace(/\D+/g, '') === phoneNumber?.replace(/\D+/g, '')) {
      setOldPhoneNumberIsValid('duplicate')
    } else {
      setOldPhoneNumberIsValid('valid')
    }
  }, [oldPhoneNumber, phoneNumber])

  useEffect(() => {
    setPostalCodeIsValid(Boolean(validatePostalCode(postalCode || '')))
  }, [postalCode])

  useEffect(() => {
    setLastDigitsValid(Boolean(lastDigitsOfPaymentMethod?.length))
  }, [lastDigitsOfPaymentMethod])

  useEffect(() => {
    validFormHandler(
      oldPhoneNumberValid === 'valid' &&
        isEmailValid &&
        isLastNameValid &&
        isFirstNameValid &&
        isPostalCodeValid &&
        islastDigitsValid
    )
  }, [
    isEmailValid,
    isLastNameValid,
    isFirstNameValid,
    oldPhoneNumberValid,
    isPostalCodeValid,
    islastDigitsValid,
  ])

  const handleKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Enter' && isValidForm) {
      sanitizeCustomerContext()
    }
  }

  const sanitizeCustomerContext = () => {
    setCustomer(prevState => ({
      ...prevState,
      email: prevState?.email?.trim(),
      lastName: prevState?.lastName?.trim(),
      firstName: prevState?.firstName?.trim(),
      isSanitized: true,
    }))
  }

  useEffect(() => {
    const { isSanitized } = customer || {}
    if (isSanitized) {
      send()
    }
  }, [customer])

  const handleSubmit = () => {
    if (isValidForm) {
      sanitizeCustomerContext()
    }
  }

  if (isValidForm) {
    ;[
      personalInformationPropsEmail,
      personalInformationPropsLastName,
      personalInformationOldPhoneNumber,
      personalInformationPropsEmail,
      personalInformationPostalCode,
      personalInformationLastDigitsOfPaymentMethod,
    ].forEach(key => key.returnKeyType === 'done')
  }

  const getPhoneErrorText = (key: string) =>
    ({
      valid: '',
      short: localized(`global.errorPhone`),
      duplicate: localized('updatePhoneNumber.errorDuplicatePhone'),
    }[key] || '')

  return (
    <>
      <Body
        variant="--body-02-regular"
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        style={styles.paragraph}>
        {localized('updatePhoneNumber.body1')}
        <Body
          variant="--body-02-semi-emphasized"
          darkColor={whiteOpacity9}
          lightColor={colors['--text-dark']}
          style={styles.marginLg}>
          {phoneNumber}
        </Body>
        {localized('updatePhoneNumber.body2')}
      </Body>
      <SharedInput
        label={localized('global.firstName')}
        errorMessage={localized('global.errorName')}
        error={!isFirstNameValid}
        onChangeText={(text: string) => {
          setCustomer({ ...customer, firstName: text })
        }}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={firstName}
        {...personalInformationPropsFirstName}
      />
      <SharedInput
        placeholder={lastName}
        label={localized('global.lastName')}
        errorMessage={localized('global.errorName')}
        error={!isLastNameValid}
        onChangeText={(text: string) => {
          setCustomer({ ...customer, lastName: text })
        }}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={lastName}
        {...personalInformationPropsLastName}
      />
      <SharedInput
        label={localized('updatePhoneNumber.oldPhoneNumber')}
        errorMessage={getPhoneErrorText(oldPhoneNumberValid)}
        error={oldPhoneNumberValid !== 'valid'}
        onChangeText={(text: string) => {
          setOldPhoneNumber(text.replace(/[^0-9]/g, ''))
        }}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={oldPhoneNumber}
        {...personalInformationOldPhoneNumber}
      />
      <SharedInput
        onChangeText={(text: string) => {
          setPostalCode(text.replace(/ /g, '').toLocaleUpperCase())
        }}
        label={localized('address.labelPostalCode')}
        errorMessage={localized('address.errorPostalCode')}
        error={!isPostalCodeValid}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={postalCode}
        {...personalInformationPostalCode}
      />
      <SharedInput
        placeholder={email}
        label={localized('global.emailAddress')}
        errorMessage={localized('global.errorEmail')}
        error={!isEmailValid}
        onChangeText={(text: string) => {
          setCustomer({ ...customer, email: text.toLowerCase() })
        }}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={email}
        keyboardType={isAndroid ? 'visible-password' : 'email-address'}
        {...(isAndroid && { secureTextEntry: true })}
        {...personalInformationPropsEmail}
      />
      <SharedInput
        label={localized('updatePhoneNumber.labelLastDigitsOfPaymentMethod')}
        errorMessage={localized('updatePhoneNumber.errorlastDigitsOfPaymentMethod')}
        error={!islastDigitsValid}
        onChangeText={(text: string) => {
          setLastDigitsOfPaymentMethod(text)
        }}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleSubmit}
        value={lastDigitsOfPaymentMethod}
        {...personalInformationLastDigitsOfPaymentMethod}
      />
      <Body
        variant="--body-02-regular"
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        style={styles.marginLg}>
        {localized('updatePhoneNumber.descriptionLastDigitsOfPaymentMethod')}
      </Body>
      <Body
        variant="--body-02-regular"
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        style={styles.marginLg}>
        {localized('linkYourAccount.paragraph')}
      </Body>
    </>
  )
}

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: 32,
    lineHeight: 25,
  },
  marginLg: {
    marginBottom: 36,
  },
  box: {
    flexDirection: 'row',
    marginBottom: 16,
  },
})

export default MorePersonalInformation
