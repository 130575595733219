export const validateEmail = (email: string) => {
  if (typeof email !== 'string') return false
  const emailRegex = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  )
  return emailRegex.test(email)
}

export const isTenDigits = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) return false
  return str.split('').filter(letter => !Number.isNaN(parseInt(letter, 10))).length === 10
}

export const luhnCheck = (value: string) => {
  let checksum = 0 // running checksum total
  let j = 1 // takes value of 1 or 2

  // Process each digit one by one starting from the last
  for (let i = value.length - 1; i >= 0; i--) {
    let calc = 0
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(value.charAt(i)) * j

    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1
      calc = calc - 10
    }

    // Add the units element to the checksum total
    checksum = checksum + calc

    // Switch the value of j
    if (j === 1) {
      j = 2
    } else {
      j = 1
    }
  }

  //Check if it is divisible by 10 or not.
  return checksum % 10 === 0
}

export const validateCreditCardNumber = (ccNumber: string): boolean => {
  const normalizeNumber = ccNumber.replace(/ /g, '')
  return normalizeNumber.length === 16 && luhnCheck(normalizeNumber)
}

export const objectToUrlParams = (params: { [k: string]: string | number }) =>
  Object.entries(params).reduce((acc, next, i) => `${acc}${i > 0 ? '&' : ''}${next.join('=')}`, '')
