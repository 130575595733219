import { ReactNode, FC } from 'react'
import styled from 'styled-components/native'
import { Platform, TextStyle, View } from 'react-native'
import Link from '../../atoms/Link'

import { DARK, Theme } from '../../../providers/ThemeProvider'
import colors from '../../tokens/colors'
import { whiteOpacity9, mintSprig75, coralJam } from '../../tokens/colors/colors'
import { SuccessAlt, Failed } from '../../atoms/Icons'
import Body, { BodyVariants } from '../../../components/atoms/Typography/Body'
import Numerical from '../../atoms/Typography/Numerical'
import Button from '../../../components/atoms/Button'
import Card from '../../../components/atoms/Card'
import Box from '../../../components/atoms/Box'
import Loading from '../../../components/molucules/Loading'
import PlanCardRowGridItem from '../../molucules/PlanCardRowGridItem'
import Hairline from '../../atoms/Hairline'

const backgroundStyle = (theme: Theme, backgroundLight: string, backgroundDark: string) =>
  theme === DARK ? backgroundDark : backgroundLight

const outerBackgroundDark = Platform.OS === 'web' ? '#2c2c2c' : '#202020'
const innerBackgroundDark = Platform.OS === 'web' ? '#202020' : '#2C2C2C'

const borderError = `0.5px solid ${coralJam}`

export const OuterCard = styled(Card)`
  flex: 0 0 auto;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 0 18px 0;
  padding: 8px;
  border: ${({ isError }) => (isError ? borderError : 'none')};
  background-color: ${({ theme }) =>
    backgroundStyle(theme, colors['--background-light'], outerBackgroundDark)};
`

export const InnerCard = styled(Box)`
  flex: 0 0 auto;
  align-items: center;
  padding: 20px 16px 14px 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) =>
    backgroundStyle(theme, colors['--background-02'], innerBackgroundDark)};
`
const StyledCornerIconBox = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
`

const StyledButtonBox = styled(Box)`
  margin: 22px 0 18px 0;
`

export const StyledButton: FC<{ shouldRender: boolean; onPress: any }> = ({
  shouldRender,
  onPress,
  children,
}) => (
  <>
    {shouldRender ? (
      <Box style={{ marginHorizontal: 8 }}>
        <StyledButtonBox>
          <Button onPress={onPress}>{children}</Button>
        </StyledButtonBox>
      </Box>
    ) : null}
  </>
)

export type CornerIconType = 'success' | 'failure'

export const CornerIcon = ({
  type,
  shouldRender,
}: {
  type: CornerIconType
  shouldRender: boolean
}) => (
  <>
    {shouldRender ? (
      <StyledCornerIconBox>
        {type === 'success' ? (
          <SuccessAlt color={mintSprig75} size="24" />
        ) : type === 'failure' ? (
          <Failed size="24" />
        ) : null}
      </StyledCornerIconBox>
    ) : null}
  </>
)

export const SharedBody = ({
  children,
  style,
  lightColor = colors['--text-dark'],
  variant = '--body-02-semi-emphasized',
}: {
  children: string | ReactNode
  style?: TextStyle
  lightColor?: string
  variant?: BodyVariants
}) => (
  <Body style={style} lightColor={lightColor} darkColor={whiteOpacity9} variant={variant}>
    {children}
  </Body>
)

export const HeaderBlock = ({ date, contract }: { date: string; contract: string }) => (
  <Box style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}>
    <SharedBody>{date}</SharedBody>
    <SharedBody lightColor={colors['--text-de-emphasized']}>{`#${contract}`}</SharedBody>
  </Box>
)

export const StyledLoader = () => (
  <View
    {...Platform.select({
      web: { style: { height: '100%' } },
      default: { style: { flex: 1 } },
    })}>
    <Loading variant="in-screen" />
  </View>
)

export const InnerCardBlock = ({
  isPIF,
  totalRepayment,
}: {
  isPIF: boolean
  totalRepayment: string
}) => (
  <Box
    style={{
      paddingHorizontal: isPIF ? 40 : 0,
      paddingBottom: 6,
      paddingTop: 3,
      flexDirection: 'row',
      alignItems: 'flex-end',
    }}>
    <Numerical style={{ fontSize: 20 }}>{totalRepayment}</Numerical>
    <SharedBody
      style={{ marginBottom: Platform.OS === 'android' ? 2 : 1 }}
      variant="--body-02-regular">
      {' '}
      total
    </SharedBody>
  </Box>
)

export const OtherItemsBlock = ({
  otherItems,
  title,
  getCurrency,
}: {
  otherItems: any[]
  title: string
  getCurrency: (num: number) => string
}) => (
  <>
    {otherItems.length ? (
      <Box style={{ marginBottom: 12, marginTop: 4 }}>
        <SharedBody style={{ marginBottom: 8 }}>{title}</SharedBody>
        {otherItems.map(({ itemDescription, itemAmount }) => (
          <PlanCardRowGridItem
            key={itemDescription}
            label={itemDescription}
            dollarAmount={getCurrency(itemAmount)}
          />
        ))}
      </Box>
    ) : null}
  </>
)

export const IsInCollectionsBlock = ({
  heading,
  shouldRender,
}: {
  heading: string
  shouldRender: boolean
}) => (
  <>
    {shouldRender ? (
      <Box
        style={{
          marginTop: 16,
          marginBottom: 8,
        }}>
        <SharedBody style={{ textAlign: 'center' }} variant="--body-01-semi-emphasized">
          {heading}
        </SharedBody>
      </Box>
    ) : null}
  </>
)

export const StyledHairline = () => (
  <Box style={{ marginVertical: 8 }}>
    <Hairline />
  </Box>
)

export const StyledLink: FC<{ onPress: any }> = ({ onPress, children }) => (
  <Box style={{ marginVertical: 6, alignItems: 'center', justifyContent: 'center' }}>
    <Link onPress={onPress}>{children}</Link>
  </Box>
)
