import { AddressType } from '../providers/CustomerProvider'

export const formatAddress = (address: AddressType) => {
  const addressEntries = Object.entries(address).map(([key, value]) => [
    key,
    value === null ? '' : value,
  ])
  const { aptSuite, houseNo, streetName, city, province, postalCode }: AddressType =
    Object.fromEntries(addressEntries)

  const upperCase = (str: string) =>
    typeof str === 'string' && str.length > 1 ? `${str[0].toUpperCase()}${str.slice(1)}` : ''
  const formatPostalCode = (str: string = ''): string => {
    const stringWithNoSpaces = str.replace(/ /g, '')
    const tempString =
      stringWithNoSpaces.length === 6
        ? `${postalCode?.slice(0, 3)} ${postalCode?.slice(3)}`
        : stringWithNoSpaces
    return tempString.toLocaleUpperCase()
  }

  const upProvince = province.toLocaleUpperCase()
  const upCity = city
    .split(' ')
    .map(str => upperCase(str))
    .join(' ')

  const upStreetName = streetName
    .split(' ')
    .map(str => upperCase(str))
    .join(' ')
  const formattedPostalCode = formatPostalCode(postalCode)
  const firstLine = [aptSuite ? `${aptSuite}-${houseNo}` : `${houseNo}`, upStreetName]
  const secondLine = [city ? `${upCity},` : '', upProvince, formattedPostalCode]
  return [firstLine, secondLine].filter(str => str).map(arr => arr.join(' '))
}
