import { FC } from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import styled from 'styled-components'
import colors from '../../tokens/colors'
import { DARK } from '../../../providers/ThemeProvider'
import Error from '../../atoms/Icons/svgs/Error'
import Caption from '../../atoms/Typography/Caption'
import Box from '../../atoms/Box'

interface HelperTextProps {
  error: string
  theme: any
}
export const HelperText: FC<HelperTextProps> = ({ error, theme }) => {
  const red = colors['--text-error']
  const white = colors['--text-light']
  return (
    <>
      <Box>
        <Error size="30" color={theme === DARK ? white : red} />
        <Caption
          style={{ flexBasis: 50, flexGrow: 1 }}
          variant="--caption-regular"
          lightColor={red}
          darkColor={white}>
          {error}
        </Caption>
      </Box>
    </>
  )
}

export const StyledCurrencyTextField = styled(CurrencyTextField)(({ theme }) => {
  const isDark = theme === DARK
  const white = colors['--text-light']
  const isWhite = isDark ? `color: ${white}` : ''
  const isWhiteBorder = isDark ? `border-color:${white}` : ''

  return `
  ${isWhite}
  & label.Mui-error,
  & label.Mui-focused,
  & .MuiTypography-colorTextSecondary {
    ${isWhite}
  }
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: ${isDark ? 'white' : colors['--border-focus-dark']}
  }
  & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-notchedOutline,
  & .Mui-focused .PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline{
    border-width:1px;
    ${isWhiteBorder}
  }
  & input {
    ${isWhite}
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 2px;
    .MuiTypography-colorTextSecondary{
      ${isWhite}
    }
  }
  .MuiFormLabel-root {
    color: ${isDark ? white : colors['--border-focus-dark']}
  }
  & .MuiFormHelperText-root.Mui-error {
    position: relative;
    margin-top:0;
  }
  & .MuiFormHelperText-root.Mui-error > div {
    position:absolute;
    left:0;
    top:0;
    flex-direction: row;
    align-items: center;
    left: -14px;
}
  `
})
