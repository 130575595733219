import { useContext } from 'react'
import { Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Props from './shared'
import { ContentContext } from '../../../../providers/ContentProvider'

const WithPayBrightByAffirm = ({ color, width = '203', height = '52' }: Props) => {
  const isWeb = Platform.OS === 'web'
  const { localized } = useContext(ContentContext)
  const titleText = localized('entry.SVGTitle')
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 203 52"
      fill="none"
      aria-labelledby="web--svg_with_paybright_logo_title">
      {isWeb ? <title id="web--svg_with_paybright_logo_title">{titleText}</title> : null}
      <Path
        d="M10.864 14.32L13.02 22.412C13.104 22.748 13.356 23 13.776 23H16.828C17.248 23 17.472 22.748 17.584 22.412L21.476 10.316C21.672 9.784 21.392 9.504 20.944 9.504H18.592C18.172 9.504 17.976 9.756 17.892 10.064L15.456 18.688L13.132 10.064C13.02 9.7 12.768 9.504 12.404 9.504H9.492C9.128 9.504 8.904 9.7 8.792 10.064L6.468 18.688L4.088 10.064C4.004 9.756 3.808 9.504 3.388 9.504H0.756C0.308 9.504 0.028 9.784 0.224 10.316L4.116 22.412C4.228 22.748 4.48 23 4.844 23H7.98C8.344 23 8.624 22.748 8.708 22.412L10.864 14.32ZM24.8749 7.74C26.1069 7.74 27.1149 6.704 27.1149 5.472C27.1149 4.24 26.1069 3.204 24.8749 3.204C23.6149 3.204 22.6069 4.24 22.6069 5.472C22.6069 6.704 23.6149 7.74 24.8749 7.74ZM26.0789 23C26.4149 23 26.6669 22.72 26.6669 22.412V10.092C26.6669 9.756 26.4149 9.504 26.0789 9.504H23.6429C23.3069 9.504 23.0549 9.756 23.0549 10.092V22.412C23.0549 22.72 23.3069 23 23.6429 23H26.0789ZM30.576 19.024C30.576 22.048 32.844 23.28 35.056 23.28C36.624 23.28 37.996 22.804 38.864 21.936C39.144 21.656 39.172 21.348 38.948 21.012L38.108 19.64C37.856 19.276 37.436 19.276 37.016 19.528C36.54 19.836 36.064 19.948 35.728 19.948C35.084 19.948 34.188 19.556 34.188 18.212V12.528H38.108C38.416 12.528 38.696 12.248 38.696 11.94V10.092C38.696 9.756 38.416 9.504 38.108 9.504H34.188V5.752C34.188 5.416 33.936 5.164 33.628 5.164H31.164C30.828 5.164 30.576 5.416 30.576 5.752V9.504H28.728C28.42 9.504 28.168 9.756 28.168 10.092V11.94C28.168 12.248 28.42 12.528 28.728 12.528H30.576V19.024ZM50.8036 22.468C50.8036 22.776 51.0836 23 51.3916 23H53.8276C54.1356 23 54.4156 22.776 54.4156 22.468V14.964C54.4156 11.632 52.2036 9.112 48.6196 9.112C46.4636 9.112 44.9236 10.008 44.1396 11.1V3.932C44.1396 3.624 43.8596 3.4 43.5516 3.4H41.0876C40.7796 3.4 40.5276 3.652 40.5276 3.96V22.44C40.5276 22.748 40.7796 23 41.0876 23H43.5516C43.8596 23 44.1396 22.776 44.1396 22.468V15.944C44.1396 13.844 45.4836 12.444 47.5276 12.444C49.6276 12.444 50.8036 13.816 50.8036 15.944V22.468Z"
        fill={color || '#212189'}
      />
      <Path
        d="M90.1502 7.06372C92.3947 7.06372 94.2729 8.09038 95.0605 9.53325V8.22912C95.0572 8.13608 95.0729 8.04336 95.1068 7.95674C95.1407 7.87011 95.1919 7.79143 95.2573 7.7256C95.3227 7.65977 95.4008 7.60821 95.4869 7.57413C95.5729 7.54005 95.665 7.52417 95.7574 7.5275H98.6989C98.7907 7.52587 98.8818 7.54294 98.9669 7.57767C99.052 7.61241 99.1292 7.66409 99.1939 7.72962C99.2587 7.79516 99.3096 7.87318 99.3436 7.95902C99.3776 8.04485 99.3941 8.13673 99.3919 8.22912V23.0859C99.3941 23.1783 99.3776 23.2702 99.3436 23.356C99.3096 23.4419 99.2587 23.5199 99.1939 23.5854C99.1292 23.651 99.052 23.7026 98.9669 23.7374C98.8818 23.7721 98.7907 23.7892 98.6989 23.7875H95.7574C95.6655 23.7892 95.5741 23.7721 95.4888 23.7374C95.4036 23.7027 95.3261 23.6511 95.2611 23.5856C95.196 23.5202 95.1447 23.4422 95.1103 23.3563C95.0758 23.2705 95.0589 23.1785 95.0605 23.0859V21.8016C94.2021 23.2722 92.3947 24.2751 90.1029 24.2751C85.9093 24.2751 82.0386 20.9177 82.0386 15.6694C82.0386 10.4212 85.9093 7.06372 90.1502 7.06372ZM90.7527 20.216C93.2531 20.216 95.0605 18.1865 95.0605 15.6219C95.0605 13.0572 93.2531 11.0752 90.7527 11.0752C90.1686 11.0813 89.5916 11.2043 89.0552 11.4369C88.5188 11.6696 88.0336 12.0072 87.628 12.4303C87.2224 12.8534 86.9044 13.3534 86.6925 13.9013C86.4806 14.4492 86.379 15.0341 86.3936 15.6219C86.3936 18.1865 88.2955 20.216 90.7527 20.216Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M73.4911 7.06372C71.1955 7.06372 69.3448 8.09038 68.4155 9.53325V8.22912C68.4188 8.13661 68.4031 8.0444 68.3695 7.95825C68.3358 7.8721 68.2849 7.79386 68.2199 7.7284C68.1548 7.66294 68.0771 7.61167 67.9915 7.57779C67.9059 7.54391 67.8144 7.52814 67.7225 7.53146H64.8243C64.7325 7.52984 64.6414 7.5469 64.5563 7.58164C64.4712 7.61637 64.394 7.66806 64.3293 7.73359C64.2645 7.79912 64.2136 7.87715 64.1796 7.96298C64.1456 8.04882 64.1292 8.14069 64.1313 8.23308V29.9673C64.1291 30.0595 64.1456 30.1512 64.1796 30.2368C64.2137 30.3225 64.2647 30.4002 64.3295 30.4655C64.3943 30.5307 64.4715 30.582 64.5566 30.6163C64.6416 30.6506 64.7327 30.6671 64.8243 30.665H67.7697C67.8613 30.6671 67.9524 30.6506 68.0374 30.6163C68.1225 30.582 68.1998 30.5307 68.2646 30.4655C68.3293 30.4002 68.3803 30.3225 68.4144 30.2368C68.4484 30.1512 68.4649 30.0595 68.4627 29.9673V29.7572L68.51 21.9681C69.4826 23.4348 71.4987 24.2275 73.4675 24.2275C77.732 24.2275 81.4846 20.8701 81.4846 15.6218C81.4846 10.3736 77.7557 7.06372 73.4911 7.06372ZM72.7942 20.216C70.341 20.216 68.4391 18.1865 68.4391 15.6218C68.4391 13.0572 70.2938 11.0752 72.7942 11.0752C75.2946 11.0752 77.1256 13.1048 77.1256 15.6218C77.1256 18.1389 75.2985 20.216 72.7942 20.216Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M115.977 7.55129H112.827C112.625 7.54781 112.429 7.61636 112.272 7.74478C112.116 7.8732 112.01 8.0532 111.973 8.2529L110.602 13.1246L108.571 19.4233L105.184 8.2529C105.164 8.07053 105.081 7.9009 104.951 7.77295C104.82 7.645 104.649 7.56666 104.467 7.55129H101.038C100.274 7.55129 100.179 8.04281 100.345 8.60173L105.231 23.0621C105.277 23.2584 105.386 23.4339 105.541 23.561C105.697 23.688 105.89 23.7593 106.09 23.7638H107.247L104.999 29.6185C104.771 30.2686 104.956 30.665 105.602 30.665H108.752C108.953 30.6694 109.15 30.6017 109.306 30.4739C109.463 30.3462 109.569 30.1666 109.606 29.9673L109.815 29.1983L111.784 23.8074L116.627 8.67308C116.792 7.94768 116.584 7.55129 115.977 7.55129Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M145.27 7.2738C145.64 7.2738 146.034 7.43632 146.034 8.13397V11.0276C146.044 11.1396 146.03 11.2525 145.994 11.3587C145.957 11.465 145.898 11.5622 145.822 11.644C145.745 11.7257 145.652 11.79 145.549 11.8328C145.445 11.8755 145.334 11.8956 145.222 11.8918H144.411C142.281 11.8918 141.029 12.9858 141.029 15.7844V23.0859C141.023 23.265 140.946 23.4344 140.816 23.5569C140.687 23.6795 140.514 23.7453 140.336 23.74H137.39C137.299 23.7416 137.207 23.7245 137.122 23.6898C137.037 23.6551 136.96 23.6034 136.895 23.5378C136.831 23.4723 136.78 23.3943 136.746 23.3085C136.712 23.2226 136.695 23.1307 136.697 23.0384V8.15776C136.693 8.06509 136.709 7.97262 136.742 7.8862C136.776 7.79978 136.827 7.7213 136.892 7.65571C136.957 7.59013 137.035 7.53888 137.121 7.50521C137.207 7.47154 137.298 7.45618 137.39 7.4601H140.17C140.259 7.45639 140.347 7.47029 140.43 7.50101C140.513 7.53173 140.589 7.57865 140.653 7.63908C140.718 7.69951 140.77 7.77225 140.807 7.8531C140.844 7.93396 140.864 8.02133 140.867 8.11019V9.65216C141.252 8.93038 141.823 8.32638 142.52 7.90366C143.218 7.48095 144.015 7.25515 144.829 7.25002C144.975 7.26981 145.122 7.27776 145.27 7.2738Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M146.475 2.60827C146.479 2.07451 146.639 1.55382 146.936 1.11165C147.233 0.669472 147.653 0.325542 148.144 0.123089C148.635 -0.0793633 149.174 -0.131307 149.693 -0.0262113C150.213 0.0788839 150.691 0.336329 151.066 0.713763C151.441 1.0912 151.696 1.57178 151.801 2.0951C151.905 2.61843 151.854 3.16114 151.652 3.65503C151.451 4.14891 151.11 4.57194 150.67 4.87093C150.231 5.16992 149.714 5.33153 149.184 5.33545C148.466 5.3344 147.777 5.04674 147.269 4.53552C146.761 4.0243 146.476 3.33124 146.475 2.60827ZM147.727 23.7162C147.635 23.7162 147.545 23.6981 147.46 23.6628C147.376 23.6275 147.299 23.5759 147.234 23.5107C147.169 23.4456 147.118 23.3682 147.083 23.2831C147.048 23.198 147.03 23.1067 147.03 23.0146V8.15777C147.026 8.06491 147.042 7.97236 147.076 7.88593C147.11 7.79951 147.161 7.72107 147.227 7.65555C147.292 7.59003 147.37 7.53885 147.456 7.50522C147.543 7.47159 147.635 7.45623 147.727 7.46012H150.629C150.721 7.45623 150.813 7.47159 150.899 7.50522C150.985 7.53885 151.063 7.59003 151.129 7.65555C151.194 7.72107 151.246 7.79951 151.279 7.88593C151.313 7.97236 151.329 8.06491 151.326 8.15777V22.9908C151.321 23.1749 151.246 23.35 151.116 23.4799C150.986 23.6098 150.812 23.6844 150.629 23.6884H147.735L147.727 23.7162Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M203 21.3141L201.98 19.6571C201.705 19.2132 201.169 19.2132 200.685 19.5422C200.233 19.8495 199.702 20.0188 199.157 20.0297C198.389 20.0297 197.302 19.5422 197.302 17.9566V11.0752H201.98C202.164 11.0712 202.339 10.996 202.469 10.8653C202.598 10.7345 202.673 10.5584 202.677 10.3736V8.13399C202.677 8.04203 202.659 7.95099 202.624 7.86609C202.589 7.78119 202.538 7.7041 202.473 7.63927C202.408 7.57443 202.331 7.52312 202.247 7.4883C202.162 7.45347 202.072 7.43581 201.98 7.43634H197.287V4.07492C197.29 3.98206 197.274 3.88951 197.24 3.80308C197.206 3.71666 197.155 3.63822 197.09 3.5727C197.024 3.50719 196.946 3.456 196.86 3.42237C196.774 3.38874 196.682 3.37338 196.59 3.37727H193.648C193.556 3.37338 193.464 3.38874 193.378 3.42237C193.292 3.456 193.214 3.50719 193.148 3.5727C193.083 3.63822 193.031 3.71666 192.997 3.80308C192.964 3.88951 192.948 3.98206 192.951 4.07492V7.4839H190.73C190.638 7.48175 190.547 7.4983 190.462 7.53255C190.377 7.56679 190.299 7.61804 190.234 7.6832C190.169 7.74836 190.118 7.8261 190.083 7.91174C190.049 7.99738 190.032 8.08915 190.033 8.18155V10.4212C190.032 10.5136 190.049 10.6054 190.083 10.691C190.118 10.7766 190.169 10.8544 190.234 10.9195C190.299 10.9847 190.377 11.0359 190.462 11.0702C190.547 11.1044 190.638 11.121 190.73 11.1188H192.951V18.9595C192.951 22.6182 195.664 24.065 198.306 24.065C200.157 24.065 201.803 23.5061 202.87 22.4319C203.028 22.2963 203.129 22.1058 203.153 21.8986C203.177 21.6914 203.122 21.4826 203 21.3141Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M166.147 23.6686V21.5242C165.336 22.8283 163.529 23.8312 161.257 23.8312C156.831 23.8312 153.102 20.66 153.102 15.4356C153.102 10.2111 156.902 6.99239 161.095 6.99239C163.387 6.99239 165.336 8.04283 166.147 9.46588V8.15778C166.145 8.06557 166.162 7.97388 166.196 7.88826C166.23 7.80263 166.281 7.72486 166.346 7.65964C166.41 7.59442 166.488 7.54311 166.573 7.50881C166.658 7.47452 166.749 7.45796 166.84 7.46013H169.739C169.831 7.45621 169.922 7.47156 170.008 7.50524C170.094 7.53891 170.172 7.59016 170.237 7.65574C170.302 7.72132 170.353 7.79981 170.387 7.88623C170.42 7.97265 170.435 8.06512 170.432 8.15778V23.7836C170.432 28.2827 167.12 31.4102 161.769 31.4102C157.461 31.4102 154.311 28.6553 153.547 25.5555C153.518 25.4885 153.504 25.4161 153.505 25.3432C153.506 25.2702 153.522 25.1983 153.553 25.1321C153.583 25.0659 153.627 25.0069 153.682 24.959C153.736 24.911 153.8 24.8752 153.87 24.8539H157.043C157.437 24.8539 157.693 24.9014 157.807 25.1353C158.177 26.3483 159.43 27.6088 161.84 27.6088C164.753 27.6801 166.147 26.0946 166.147 23.6686ZM161.816 19.9822C164.32 19.9822 166.147 18.0716 166.147 15.5307C166.147 12.9898 164.34 11.0752 161.816 11.0752C161.239 11.07 160.666 11.1826 160.134 11.4062C159.601 11.6298 159.119 11.9598 158.716 12.3762C158.313 12.7926 157.999 13.2868 157.791 13.8289C157.584 14.3711 157.487 14.95 157.508 15.5307C157.504 18.0716 159.335 19.9822 161.812 19.9822H161.816Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M127.475 6.94481C125.18 6.94481 123.282 7.99525 122.471 9.34695V5.91815L122.423 1.32395C122.419 1.14019 122.345 0.96508 122.216 0.835111C122.087 0.705141 121.913 0.630342 121.73 0.626302H118.832C118.74 0.622377 118.648 0.637737 118.563 0.671409C118.477 0.70508 118.399 0.75633 118.334 0.821913C118.268 0.887495 118.218 0.96598 118.184 1.0524C118.151 1.13882 118.135 1.23129 118.139 1.32395L118.183 5.91815V22.963C118.181 23.0555 118.198 23.1472 118.232 23.2329C118.267 23.3185 118.318 23.3962 118.383 23.4614C118.448 23.5266 118.526 23.5778 118.611 23.6121C118.697 23.6463 118.788 23.6629 118.88 23.6607H121.774C121.866 23.6629 121.957 23.6463 122.042 23.6121C122.127 23.5778 122.205 23.5266 122.27 23.4614C122.335 23.3962 122.386 23.3185 122.421 23.2329C122.455 23.1472 122.472 23.0555 122.471 22.963V21.655C123.396 23.1256 125.137 24.1284 127.428 24.1284C131.669 24.1284 135.493 20.767 135.493 15.5228C135.493 10.2785 131.645 6.94481 127.475 6.94481ZM126.802 20.1487C124.349 20.1487 122.494 18.1191 122.494 15.5505C122.494 12.9819 124.349 11.0039 126.802 11.0039C127.386 11.01 127.963 11.1329 128.5 11.3656C129.036 11.5982 129.521 11.9359 129.927 12.3589C130.332 12.782 130.65 13.282 130.862 13.83C131.074 14.3779 131.176 14.9627 131.161 15.5505C131.114 18.0953 129.259 20.1487 126.802 20.1487Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M182.618 6.94481C180.047 6.94481 178.193 8.04282 177.267 9.34695V1.30017C177.264 1.21114 177.244 1.12359 177.207 1.04252C177.17 0.96145 177.118 0.888458 177.053 0.827713C176.989 0.766968 176.913 0.719663 176.83 0.688504C176.747 0.657346 176.659 0.642944 176.57 0.646123H173.629C173.537 0.644495 173.446 0.661561 173.361 0.696295C173.276 0.731029 173.198 0.782715 173.134 0.848248C173.069 0.91378 173.018 0.991807 172.984 1.07764C172.95 1.16348 172.934 1.25535 172.936 1.34774V22.9908C172.934 23.083 172.95 23.1747 172.984 23.2603C173.018 23.346 173.069 23.4237 173.134 23.489C173.199 23.5542 173.276 23.6055 173.361 23.6398C173.446 23.6741 173.537 23.6906 173.629 23.6885H176.57C176.659 23.6916 176.747 23.6772 176.83 23.6461C176.913 23.6149 176.989 23.5676 177.053 23.5069C177.118 23.4461 177.17 23.3731 177.207 23.2921C177.244 23.211 177.264 23.1234 177.267 23.0344V15.1779C177.267 12.6608 178.89 10.9563 181.299 10.9563C183.8 10.9563 185.213 12.6132 185.213 15.1779V23.0146C185.216 23.1036 185.237 23.1912 185.274 23.2722C185.31 23.3533 185.362 23.4263 185.427 23.487C185.492 23.5478 185.568 23.5951 185.651 23.6263C185.734 23.6574 185.822 23.6718 185.91 23.6686H188.781C188.869 23.6718 188.958 23.6574 189.04 23.6263C189.123 23.5951 189.199 23.5478 189.264 23.487C189.329 23.4263 189.381 23.3533 189.418 23.2722C189.454 23.1912 189.475 23.1036 189.478 23.0146V14.0125C189.545 9.97722 186.883 6.94481 182.618 6.94481Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M147.479 39.7582H148.479V42.9888H148.566C148.733 42.634 149.001 42.3371 149.336 42.136C149.671 41.9349 150.058 41.8385 150.448 41.8591C152.098 41.8591 153.236 43.1751 153.236 45.2443C153.236 47.3135 152.098 48.6493 150.479 48.6493C150.086 48.6658 149.696 48.5661 149.358 48.3626C149.02 48.159 148.749 47.8605 148.577 47.5038H148.455V48.5106H147.479V39.7582ZM150.33 47.7416C151.586 47.7416 152.232 46.6317 152.232 45.2285C152.232 43.8252 151.605 42.7669 150.33 42.7669C149.054 42.7669 148.463 43.7578 148.463 45.2285C148.463 46.6991 149.129 47.7416 150.334 47.7416H150.33Z"
        fill={color || 'black'}
      />
      <Path
        d="M154.448 50.8374L154.704 49.9455C155.433 50.1358 155.886 50.0407 156.248 49.0576L156.437 48.5463L154.023 41.9463H155.11L156.929 47.1708H157L158.8 41.9384H159.894L157.118 49.4461C156.744 50.4489 156.102 50.9325 155.197 50.9325C154.943 50.9497 154.689 50.9173 154.448 50.8374Z"
        fill={color || 'black'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.12 47.3452C165.631 47.3452 165.387 47.1034 165.387 46.7031C165.387 45.9618 166.21 45.7081 167.714 45.5496C167.73 45.7737 167.701 45.9987 167.629 46.2114C167.557 46.424 167.443 46.6199 167.295 46.7874C167.146 46.9549 166.965 47.0906 166.763 47.1864C166.561 47.2822 166.343 47.3362 166.12 47.3452ZM166.773 41.764C165.697 41.8016 164.657 42.168 163.792 42.8144L164.403 44.1107C165.008 43.5598 165.785 43.2363 166.6 43.195C167.344 43.195 167.758 43.4447 167.758 43.9481C167.758 44.289 167.482 44.4634 166.97 44.5308C165.045 44.7806 163.532 45.3236 163.532 46.8141C163.532 48.0032 164.371 48.7167 165.679 48.7167C166.112 48.718 166.539 48.6071 166.919 48.3947C167.298 48.1824 167.616 47.8756 167.844 47.5038V48.5225H169.585V44.2494C169.585 42.4815 168.368 41.764 166.777 41.764"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.272 41.9582V48.5225H186.135V45.3514C186.135 43.849 187.04 43.409 187.67 43.409C187.95 43.4089 188.223 43.4902 188.458 43.6429L188.797 41.9067C188.531 41.8011 188.247 41.7499 187.962 41.7561C187.539 41.73 187.119 41.8421 186.764 42.0756C186.409 42.3092 186.139 42.6517 185.993 43.0523V41.9503L184.272 41.9582Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M197.456 41.764C197.038 41.7705 196.628 41.8788 196.261 42.0797C195.894 42.2806 195.581 42.5681 195.349 42.9175C195.145 42.564 194.851 42.2711 194.498 42.0686C194.146 41.8661 193.746 41.7611 193.34 41.7644C192.933 41.7676 192.535 41.8789 192.186 42.087C191.836 42.295 191.547 42.5925 191.348 42.9492V41.9582H189.549V48.5225H191.415V45.1452C191.415 43.9283 192.049 43.3496 192.636 43.3496C193.223 43.3496 193.66 43.6984 193.66 44.5903V48.5225H195.518V45.1452C195.518 43.9164 196.133 43.3496 196.751 43.3496C197.243 43.3496 197.763 43.7103 197.763 44.5784V48.5225H199.625V43.9997C199.647 43.7049 199.606 43.4088 199.505 43.1313C199.404 42.8539 199.245 42.6015 199.038 42.3911C198.832 42.1808 198.583 42.0174 198.309 41.912C198.034 41.8065 197.741 41.7615 197.448 41.7799"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.728 41.9582H178.039V41.2923C178.039 40.4202 178.531 40.1744 178.956 40.1744C179.247 40.1778 179.533 40.2497 179.791 40.3845L180.366 39.0606C179.864 38.7822 179.297 38.6452 178.724 38.6642C177.543 38.6642 176.176 39.342 176.176 41.4627V41.9463H173.345V41.2804C173.345 40.4083 173.841 40.1626 174.263 40.1626C174.554 40.1613 174.841 40.2335 175.097 40.3726L175.676 39.0487C175.172 38.773 174.604 38.6363 174.03 38.6523C172.849 38.6523 171.483 39.3301 171.483 41.4508V41.9344H170.4V43.3813H171.498V48.4987H173.361V43.405H176.192V48.5225H178.055V43.405H179.744L179.728 41.9582Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.843 48.5225H182.705V41.9582H180.843V48.5225Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.707 32.8016C186.67 32.8016 182.185 36.3176 180.909 40.8444H182.737C183.8 37.475 187.411 34.5021 191.707 34.5021C196.928 34.5021 201.44 38.5017 201.44 44.733C201.456 46.01 201.281 47.2821 200.921 48.5066H202.693V48.4472C202.991 47.2322 203.138 45.9845 203.13 44.733C203.13 37.7842 198.101 32.7857 191.691 32.7857"
        fill={color || '#4A4AF4'}
      />
    </Svg>
  )
}
export default WithPayBrightByAffirm
