import { useContext, useEffect, useState, useCallback, useRef, ReactNode } from 'react'
import { StyleSheet, Platform, ScrollView, View } from 'react-native'
import { AbsoluteTouchable } from '../../atoms/AbsoluteTouchable'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import Button from '../../atoms/Button'
import Box from '../../atoms/Box'
import colors from '../../tokens/colors'
import ManualAddressInput, { defaultBillingAddress } from '../../molucules/ManualAddressInput'
import SharedInput from '../../molucules/SharedInput'
import GenericModal from '../../molucules/GenericModal'
import SharedBody from '../../molucules/SharedBody'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import { ContentContext, DimensionContext, ThemeContext } from '../../../providers'
import {
  canadaPost,
  isApiResponse,
  StatusDescription,
  BillingAddress,
  ACCOUNT,
} from '../../../types'
import { objectToUrlParams } from '../../../helpers/helpers'
import { initiateMailingAddressUpdate } from '../../../endpoints'
import { CommonActions, useNavigation } from '@react-navigation/native'

interface Item {
  Id: string
  Text: string
  Description: string
  Next: 'Retrieve' | 'Find'
}
interface Data {
  Items: Item[]
}

export const MailingAddressUpdate = () => {
  const formatBillingAddress = (billingAddress: BillingAddress) =>
    Object.fromEntries(
      Object.entries(billingAddress).map(([p, v]) => {
        const replacedAddr = p.replace('addr', '')
        const computedProp = replacedAddr[0].toLowerCase() + replacedAddr.slice(1)
        if (p === 'addrPostalCode') {
          v = v?.replace(/ /g, '')
        }
        return [[computedProp], v]
      })
    )

  const { getMatchingAddressesUrl, getAddressByIdUrl } = canadaPost
  const [mailingAddressInput, setMailingAddressInput] = useState('')
  const [billingAddress, setBillingAddress] = useState<BillingAddress | null>(null)
  const [url, setUrl] = useState('')
  const [formValid, setFormValid] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [addressOptions, setAddressOptions] = useState<Item[]>([])
  const navigation = useNavigation()
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  const allowUpdate = useRef(true)

  const { status, data } = useFetch({
    url,
    method: url === initiateMailingAddressUpdate ? 'patch' : 'get',
    ...(url === initiateMailingAddressUpdate &&
      billingAddress && {
        body: {
          culture: 'en',
          newMailingAddress: formatBillingAddress(billingAddress),
        },
      }),
  })

  const handleError = () => {
    setUrl('')
    setModalOpen(true)
  }

  const effectHandleAddressesResultsResponse = (dataRes: Data) => {
    const options = dataRes?.Items
    if (!Array.isArray(options)) return
    const filteredOptions = options.filter(({ Next }) => ['Retrieve', 'Find'].includes(Next))
    if (filteredOptions) {
      setAddressOptions(filteredOptions)
    }
    setUrl('')
  }
  const effectAddressSelectionResponse = data => {
    if (data?.Items?.[0]) {
      const { City, PostalCode, Street, BuildingNumber, POBOoxNumber, SubBuilding } = data.Items[0]

      const localBillingAddress: BillingAddress = {
        ...defaultBillingAddress,
        addrCity: City,
        addrPostalCode: PostalCode,
      }
      localBillingAddress.addrStreetName = Street || POBOoxNumber
      if (BuildingNumber) {
        localBillingAddress.addrHouseNo = BuildingNumber
      }
      if (Street) {
        localBillingAddress.addrStreetName = Street
      }
      if (SubBuilding) {
        localBillingAddress.addrAptSuite = SubBuilding
      }
      setBillingAddress(localBillingAddress)
    }
    setUrl('')
  }

  const navToBottomNavWithParam = (params: { [s: string]: string }) => {
    const routes: { name: string; params: {} }[] = navigation.getState().routes.slice(0, 2)
    routes[1].params = params
    navigation.dispatch(
      CommonActions.reset({
        routes,
        index: 1,
      })
    )
  }

  const effectHandleAddressUpdateResponse = data => {
    if (isApiResponse(data)) {
      const { statusCode, statusDescription } = data
      if (statusCode === 200 && statusDescription === StatusDescription.ADDRESS_UPDATED) {
        setBillingAddress(billingAddress)
        navToBottomNavWithParam({ paramType: ACCOUNT })
        return
      }
    }

    handleError()
    setUrl('')
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHED && url) {
      if (url.startsWith(getMatchingAddressesUrl)) {
        effectHandleAddressesResultsResponse(data)
      } else if (url.startsWith(getAddressByIdUrl)) {
        effectAddressSelectionResponse(data)
      } else if (url === initiateMailingAddressUpdate) {
        effectHandleAddressUpdateResponse(data)
      }
    }
  }, [status, url, data])

  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs' || viewPort === 'sm'

  const { $ } = useContext(ContentContext)

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleChangeProductName = debounce(value => {
    if (value === '') {
      setAddressOptions([])
      return
    }
    const urlParams = objectToUrlParams({ SearchTerm: encodeURIComponent(value) })
    const updatedUrl = `${getMatchingAddressesUrl}&${urlParams}`

    setUrl(updatedUrl)
  }, 600)

  const debounceRequest = useCallback(value => handleChangeProductName(value), [])

  const handleSelection = (clickedID: string) => {
    allowUpdate.current = false

    const address = addressOptions.find(({ Id }) => Id === clickedID)
    if (address) {
      const { Text, Description, Next } = address
      setAddressOptions([])

      setMailingAddressInput(`${Text}, ${Description}`)
      const { reqUrl, reqParam } =
        Next === 'Retrieve'
          ? { reqUrl: getAddressByIdUrl, reqParam: 'Id' }
          : { reqUrl: getMatchingAddressesUrl, reqParam: 'LastId' }
      const params = objectToUrlParams({ [reqParam]: clickedID })
      setUrl(`${reqUrl}&${params}`)
    }
  }

  const handleButtonPress = () => {
    setUrl(initiateMailingAddressUpdate)
  }

  const isWeb = Platform.OS === 'web'

  const StyledDiv = styled('div')`
    &:hover {
      background: ${colors[isDark ? '--background-dark' : '--border-inactive-01']};
    }
  `
  const ButtonWrapper = ({ children }: { children: ReactNode }) =>
    isWeb ? <StyledDiv>{children}</StyledDiv> : <View>{children}</View>

  return (
    <>
      <GenericModal
        header={$`updateMailingAddress.errorModalHeader`}
        visible={isModalOpen}
        closeModal={closeModal}
        message={$`updateMailingAddress.errorModalBody`}
        buttonText={'global.ok'}
      />

      <PlanDetailWrapper
        title={$`updateMailingAddress.heading`}
        headingAlignment="left"
        {...Platform.select({
          native: {
            scrollView: true,
          },
          web: { isModal: true },
          default: {},
        })}>
        <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
          <SharedBody variant={'--body-01-regular'} style={styles.bottomMargin}>
            {$`updateMailingAddress.subHeading`}
          </SharedBody>
          <SharedInput
            onChangeText={(text: string) => {
              if (allowUpdate.current) {
                setMailingAddressInput(text.trimStart())
                debounceRequest(text.trimStart())
              }
            }}
            onFocus={() => {
              allowUpdate.current = true
            }}
            value={mailingAddressInput}
            placeholder={$`updateMailingAddress.lookupPlaceholder`}
            disableMask={true}
          />
          <Box
            style={{
              ...styles.container,
              ...(addressOptions.length && {
                height: addressOptions.length * 36 + 32,
              }),
            }}>
            {addressOptions.length ? (
              <Box
                backgroundDark={colors['--background-footer']}
                backgroundLight={colors['--ui-disabled-background']}
                style={styles.dropdown}>
                <Box
                  backgroundDark={colors['--background-dark']}
                  backgroundLight={colors['--border-inactive-01']}>
                  <SharedBody variant={'--body-01-regular'} style={styles.lookupHeading}>
                    {$`updateMailingAddress.lookupHeading`}
                  </SharedBody>
                </Box>
                <ScrollView>
                  {addressOptions.map(({ Text, Description, Id }, index) => (
                    <ButtonWrapper key={index}>
                      <AbsoluteTouchable onPress={() => handleSelection(Id)}>
                        <SharedBody style={styles.selection} variant="--body-01-regular">
                          {`${Text} ${Description}`}
                        </SharedBody>
                      </AbsoluteTouchable>
                    </ButtonWrapper>
                  ))}
                </ScrollView>
              </Box>
            ) : null}
          </Box>
          <Box style={styles.bottomMargin}>
            <ManualAddressInput
              hasLookupAddress={false}
              setBillingAddress={setBillingAddress}
              billingAddress={billingAddress}
              setFormValid={setFormValid}
            />
          </Box>
          <Box style={styles.bottomMargin}>
            <Button
              testID="ContinueBtn"
              onPress={handleButtonPress}
              isLoading={url === initiateMailingAddressUpdate && status === RequestStatus.FETCHING}
              disabled={!formValid}>
              {$`global.continue`}
            </Button>
          </Box>
        </PlanDetailWrapper.InnerWrapper>
      </PlanDetailWrapper>
    </>
  )
}

const styles = StyleSheet.create({
  bottomMargin: {
    marginBottom: 24,
  },
  container: {
    position: 'relative',
    zIndex: 10,
    marginTop: -16,
    marginBottom: 16,
  },
  dropdown: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    left: 0,
    top: 0,
  },
  lookupHeading: {
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
  selection: {
    padding: 6,
  },
})
