import styled from 'styled-components/native'
import Heading from '../../atoms/Typography/Heading'
import { Image } from 'react-native'

interface Dimensions {
  screen: { width: number }
}

interface StyledProps {
  isMobile?: boolean
  isDark?: boolean
  background?: string
  dimensions?: Dimensions
}

const getBackgroundStyle = (color: string) => `background-color:${color};`

export const StyledButtonContainerWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`

export const StyledButtonContainer = styled.View`
  margin-bottom: 32px;
  width: 100%;
  display: flex;
  align-items: stretch;
`
export const StyledTextContainer = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 24px;
`
export const StyledHeading = styled(Heading)<StyledProps>`
  text-align: center;
  margin: ${({ isMobile }) => (isMobile ? '-20px 0 8px 0;' : '0 -10px 0 -10px;')};
  ${({ isMobile }) => (isMobile ? 'font-size: 48px;' : null)}
  ${({ isMobile }) => (isMobile ? 'line-height: 52px;' : null)}
    ${({ isMobile, dimensions }) =>
    isMobile && dimensions && dimensions?.screen?.width > 350 ? 'padding: 0 15px;' : null}
`
export const StyledTaglineContainer = styled.View<StyledProps>`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '57px')};
`

export const StyledRoot = styled.View<StyledProps>`
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  ${({ isMobile }) => (!isMobile ? 'width: 410px;' : 'height:100%;')}
  ${({ isDark, isMobile, background }) =>
    !isDark && isMobile ? getBackgroundStyle(background as string) : null}
`

export const StyledImg1 = styled(Image)`
  width: 181px;
  height: 178px;
`

export const StyledImg2 = styled(Image)`
  width: 67px;
  height: 67px;
`
export const StyledImg3 = styled(Image)`
  width: 147px;
  height: 115px;
`

export const TopImageBlock = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 178px;
`

export const BottomImageBlock = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  right: -50px;
  bottom: -25px;
  margin-top: -25px;
  height: 115px;
`
export const BottomLinkBlock = styled.View`
  padding-bottom: 16px;
`
