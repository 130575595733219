import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useState, useEffect } from 'react'
import { initLocalization } from '../localization/Localized'

const useCachedResources = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)
  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        SplashScreen.preventAutoHideAsync()
        const mangueiraBold =
          'https://assets.paybright.com/fonts/Mangueira/otf/MangueiraBoldLH.otf?ik-sdk-version=javascript-1.4.3&updatedAt=1674007390033'
        const calibreRegular =
          'https://assets.paybright.com/fonts/Calibre/otf/CalibreRegular.otf?ik-sdk-version=javascript-1.4.3&updatedAt=1669829514684'
        const calibreSemiBold =
          'https://assets.paybright.com/fonts/Calibre/otf/CalibreSemiBold.otf?ik-sdk-version=javascript-1.4.3&updatedAt=1669829398361'
        const calibreBold =
          'https://assets.paybright.com/fonts/Calibre/otf/CalibreBold.otf?ik-sdk-version=javascript-1.4.3&updatedAt=1669829179464'
        // Load fonts
        await Font.loadAsync({
          MangueiraBold: mangueiraBold,
          Calibre: calibreRegular,
          'Calibre-semi': calibreSemiBold,
          'Calibre-bold': calibreBold,
        })
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        // tslint:disable-next-line: no-console
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      }
    }

    loadResourcesAndDataAsync()
    initLocalization()
  }, [])

  return isLoadingComplete
}

export default useCachedResources
