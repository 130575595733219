import { useRoute } from '@react-navigation/native'
import * as workerTimers from 'worker-timers'
import { Platform } from 'react-native'
import { useContext, useEffect, useRef, useState } from 'react'
import {
  INACTIVE_SESSION_MINUTES,
  TOKEN_EXPIRES_MINUTES,
  BUFFER_ADDED_SECONDS,
} from '../config/api'
import { useUUID } from './useUUID'
import { refreshToken, logout } from './../endpoints'
import { useFetch, RequestStatus } from './useFetch'
import { AuthContext } from './../providers/AuthProvider'
import { CustomerContext } from './../providers/CustomerProvider'
import {
  AccessLevel,
  StatusDescription,
  HOME_ENTRY,
  ACCOUNT,
  PLANS,
  PURCHASES_TAB,
} from '../types'
import { navigate } from '../nav/Navigation'

const safeSetInterval = Platform.select({
  web: workerTimers.setInterval,
  default: setInterval,
})
const safeClearInterval = Platform.select({
  web: workerTimers.clearInterval,
  default: clearInterval,
})

export const useRefreshToken = async () => {
  const route = useRoute()
  const inactiveSessionMS = INACTIVE_SESSION_MINUTES * 60 * 1000
  const latencyBufferMS = BUFFER_ADDED_SECONDS * 1000
  const tokenExpiresMS = TOKEN_EXPIRES_MINUTES * 60 * 1000
  const intervalBetweenRefreshesMS = tokenExpiresMS - latencyBufferMS
  const maxRefreshes = Math.floor(inactiveSessionMS / intervalBetweenRefreshesMS)
  const {
    token,
    accessLevel,
    phoneNumber,
    resetAuth,
    setSessionExpired,
    sessionExpired,
    setAccessLevel,
  } = useContext(AuthContext)
  const { resetCustomer } = useContext(CustomerContext)

  const iterationRef = useRef(1)
  const tokenRef = useRef(token)
  const attemptedToValidateSessionToken = useRef(Platform.OS !== 'web')
  const accessLevelRef = useRef(accessLevel)
  const setIntervalIDRef = useRef<number | null>(null)
  const [url, setUrl] = useState('')
  const { deleteUUID } = useUUID()
  const logUserOut = () => setUrl(logout)

  const incrementIterator = () => {
    iterationRef.current = iterationRef.current + 1
  }

  useEffect(() => {
    tokenRef.current = token
  }, [token])

  useEffect(() => {
    accessLevelRef.current = accessLevel
  }, [accessLevel])

  const { status, data, error } = useFetch({
    url,
    method: url === logout ? 'post' : 'get',
  })

  const resetAndNavHome = () => {
    resetAuth()
    resetCustomer()
    navigate(HOME_ENTRY, {})
  }

  useEffect(() => {
    if ([RequestStatus.FETCHED, RequestStatus.ERROR].includes(status)) {
      const { statusDescription } = data || error
      const isRefreshEndpoint = url === refreshToken
      setUrl('')

      if (statusDescription === StatusDescription.PORTAL_ACCOUNT_DISABLED) {
        deleteUUID(phoneNumber)
        resetAndNavHome()
        return
      }
      if (statusDescription === StatusDescription.EXPIRED_TOKEN) {
        setSessionExpired(true)
        resetAndNavHome()
        return
      }
      if (statusDescription === StatusDescription.SUCCESS && isRefreshEndpoint) {
        const isURLReload = accessLevel !== AccessLevel.AUTHORIZED_USER
        setAccessLevel(AccessLevel.AUTHORIZED_USER)
        if (isURLReload) {
          navigate('BottomNavigator', {
            screen: PURCHASES_TAB,
            params: { screen: PLANS },
          })
        }
        return
      }
    }
  }, [status])

  useEffect(() => {
    const isLogout = url === logout
    if (isLogout) {
      resetAuth()
      resetCustomer()
    }
  }, [url])

  const refreshUsersToken = () => {
    const hasAccess = tokenRef.current && accessLevelRef.current === AccessLevel.AUTHORIZED_USER
    if (!hasAccess) return
    setUrl(refreshToken)
  }

  const doRefreshTokenInBackground = () => {
    const maxRefreshesReached = iterationRef.current >= maxRefreshes
    if (maxRefreshesReached) {
      logUserOut()
      setSessionExpired(true)
      navigate(HOME_ENTRY, {})
    } else {
      refreshUsersToken()
    }
    incrementIterator()
  }

  useEffect(() => {
    if (attemptedToValidateSessionToken.current) return
    if (!token) {
      attemptedToValidateSessionToken.current = true
    } else {
      setUrl(refreshToken)
      attemptedToValidateSessionToken.current = true
    }
  }, [token, attemptedToValidateSessionToken])

  useEffect(() => {
    if (accessLevel === AccessLevel.AUTHORIZED_USER) {
      setIntervalIDRef.current = safeSetInterval(() => {
        doRefreshTokenInBackground()
      }, intervalBetweenRefreshesMS)
    }
    return () => {
      if (setIntervalIDRef.current) {
        safeClearInterval(setIntervalIDRef.current)
        setIntervalIDRef.current = null
      }
    }
  }, [accessLevel])

  useEffect(() => {
    iterationRef.current = 1
  }, [route])
}
