import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'
import Props from './shared'

const HowItWorks = ({ color = 'black', size = '40' }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <G>
      <Path
        d="M21.9531 28.6459H18.0469C17.9581 28.6459 17.8707 28.6686 17.7932 28.7119C17.7156 28.7551 17.6504 28.8175 17.6037 28.8931C17.5571 28.9687 17.5305 29.0549 17.5266 29.1436C17.5226 29.2324 17.5415 29.3206 17.5813 29.4L17.9313 30.099C18.1031 30.4453 18.3684 30.7367 18.6972 30.9401C19.0259 31.1435 19.4051 31.2509 19.7917 31.25H20.2083C20.5946 31.2514 20.9735 31.1449 21.3024 30.9424C21.6313 30.74 21.8971 30.4496 22.0698 30.1042L22.4198 29.4052C22.4596 29.3258 22.4784 29.2374 22.4745 29.1486C22.4705 29.0598 22.4438 28.9735 22.3971 28.8979C22.3503 28.8223 22.2849 28.7599 22.2072 28.7167C22.1295 28.6735 22.042 28.6509 21.9531 28.6511V28.6459Z"
        fill={color}
      />
      <Path
        d="M20 10.9375C20.2762 10.9375 20.5412 10.8278 20.7365 10.6324C20.9319 10.4371 21.0416 10.1721 21.0416 9.89583V7.29167C21.0416 7.0154 20.9319 6.75045 20.7365 6.5551C20.5412 6.35975 20.2762 6.25 20 6.25C19.7237 6.25 19.4588 6.35975 19.2634 6.5551C19.0681 6.75045 18.9583 7.0154 18.9583 7.29167V9.89583C18.9583 10.1721 19.0681 10.4371 19.2634 10.6324C19.4588 10.8278 19.7237 10.9375 20 10.9375Z"
        fill={color}
      />
      <Path
        d="M29.575 10.2166C29.3797 10.0213 29.1148 9.91162 28.8386 9.91162C28.5624 9.91162 28.2975 10.0213 28.1021 10.2166L26.2615 12.0583C26.162 12.1544 26.0826 12.2693 26.0281 12.3964C25.9735 12.5235 25.9447 12.6602 25.9435 12.7985C25.9423 12.9368 25.9687 13.074 26.0211 13.202C26.0734 13.33 26.1508 13.4463 26.2486 13.5441C26.3464 13.6419 26.4627 13.7193 26.5907 13.7716C26.7187 13.824 26.8559 13.8504 26.9942 13.8492C27.1325 13.848 27.2692 13.8192 27.3963 13.7646C27.5234 13.71 27.6383 13.6307 27.7344 13.5312L29.575 11.6895C29.7703 11.4942 29.88 11.2293 29.88 10.9531C29.88 10.6769 29.7703 10.4119 29.575 10.2166V10.2166Z"
        fill={color}
      />
      <Path
        d="M31.4583 18.75H29.375C29.0987 18.75 28.8338 18.8597 28.6384 19.0551C28.4431 19.2504 28.3333 19.5154 28.3333 19.7917C28.3333 20.0679 28.4431 20.3329 28.6384 20.5282C28.8338 20.7236 29.0987 20.8333 29.375 20.8333H31.4583C31.7346 20.8333 31.9995 20.7236 32.1949 20.5282C32.3902 20.3329 32.5 20.0679 32.5 19.7917C32.5 19.5154 32.3902 19.2504 32.1949 19.0551C31.9995 18.8597 31.7346 18.75 31.4583 18.75Z"
        fill={color}
      />
      <Path
        d="M13.7396 12.0584L11.898 10.2167C11.7015 10.027 11.4384 9.92198 11.1652 9.92436C10.8921 9.92673 10.6309 10.0363 10.4377 10.2294C10.2446 10.4225 10.135 10.6838 10.1327 10.9569C10.1303 11.2301 10.2353 11.4932 10.425 11.6896L12.2667 13.5313C12.4632 13.7211 12.7263 13.826 12.9994 13.8237C13.2725 13.8213 13.5338 13.7118 13.7269 13.5186C13.9201 13.3255 14.0296 13.0642 14.032 12.7911C14.0344 12.518 13.9294 12.2549 13.7396 12.0584Z"
        fill={color}
      />
      <Path
        d="M10.625 18.75H8.54167C8.2654 18.75 8.00045 18.8597 7.8051 19.0551C7.60975 19.2504 7.5 19.5154 7.5 19.7917C7.5 20.0679 7.60975 20.3329 7.8051 20.5282C8.00045 20.7236 8.2654 20.8333 8.54167 20.8333H10.625C10.9013 20.8333 11.1662 20.7236 11.3616 20.5282C11.5569 20.3329 11.6667 20.0679 11.6667 19.7917C11.6667 19.5154 11.5569 19.2504 11.3616 19.0551C11.1662 18.8597 10.9013 18.75 10.625 18.75Z"
        fill={color}
      />
      <Path
        d="M19.9999 12.5C18.5604 12.5021 17.1591 12.963 15.9993 13.8157C14.8395 14.6683 13.9817 15.8684 13.5503 17.2418C13.1189 18.6151 13.1365 20.0902 13.6004 21.4529C14.0643 22.8156 14.9504 23.9949 16.1301 24.8198C16.1993 24.8678 16.2558 24.9318 16.2948 25.0063C16.3338 25.0809 16.3541 25.1638 16.3541 25.2479V26.0417C16.3541 26.3179 16.4638 26.5829 16.6592 26.7782C16.8545 26.9736 17.1195 27.0833 17.3958 27.0833H22.6041C22.8804 27.0833 23.1453 26.9736 23.3407 26.7782C23.536 26.5829 23.6458 26.3179 23.6458 26.0417V25.2469C23.6457 25.1627 23.6661 25.0798 23.7051 25.0053C23.7441 24.9307 23.8006 24.8667 23.8697 24.8188C25.0524 23.9956 25.9414 22.8164 26.4073 21.4529C26.8731 20.0893 26.8914 18.6127 26.4596 17.238C26.0277 15.8633 25.1683 14.6624 24.0064 13.8102C22.8445 12.9579 21.4408 12.4989 19.9999 12.5V12.5Z"
        fill={color}
      />
    </G>
  </Svg>
)

export default HowItWorks
