export type RootStackParamList = {
  Root: undefined
  NotFound: undefined
}

export type AccountStackParamList = {
  Account: undefined
  DarkMode: undefined
  AccountInformation: undefined
  PhoneNumberUpdate: undefined
  PhoneNumberUpdateVerifyEmail: undefined
  PhoneNumberUpdatePinEntry: undefined
  EmailUpdate: undefined
  EmailUpdateVerifyEmail: undefined
  EmailUpdatePinEntry: undefined
  AddressUpdate: undefined
  LanguageSettings: undefined
  Biometrics: undefined
  MailingAddressUpdate: undefined
  AuthenticatedPhoneNumberUpdate: undefined
  AuthenticatedPhoneNumberUpdatePIN: undefined
}

export type PlansStackParamList = {
  PurchasePlans: { id: string }
  DarkMode: undefined
  PlanDetails: undefined
  MakePayment: undefined
  Schedule: undefined
  UpdatePayment: undefined
  LinkCreditCard: undefined
  LinkBankAccount: undefined
  PaymentMethodSelector: undefined
}

export type BottomTabParamList = {
  Home: undefined
  Plans: undefined
  Account: undefined
  PurchasesTab: undefined
  ProfileTab: undefined
}

export type HomeParamList = {
  HomeEntry: undefined
  PhoneNumberEntry: undefined
  PinEntry: undefined
  EmailVerificationCodeEntry: undefined
  EmailUpdateVerifyEmail: undefined
  EmailVerification: undefined
  FaceIDSetUp: undefined
  BottomNavigator: undefined
  GenericError: undefined
  Approval: undefined
  PaymentMethodSetup: undefined
  PaymentMethodCreditCardEntry: undefined
  PaymentMethodCreditCardEntrySuccess: undefined
  LinkYourAccount: undefined
  PhoneNumberUpdate: undefined
}

export type ComponentsParamList = {
  components: undefined
}

export type LinkYourAccountParamList = {
  LinkYourAccount: undefined
}

export type EmailVerificationCodeEntryParamList = {
  EmailVerificationCodeEntry: undefined
}

export type LoginParamList = {
  Login: undefined
}

export const DEFAULT_PHONE_NUMBER = '0000000000'
export const HASH_SALT = 'BuyNowPL'
export const MONTHLY = 'Monthly'
export const PI4 = 'PI4'
export const AURORA_INSTALLMENT = 'AuroraInstallment'
export const AURORA_ONE_TIME = 'AuroraOneTime'
export const AURORA_REVISED_TRADE_IN = 'AuroraRevisedTradeIn'
export const SUPPRESS_REDIRECT = 'suppressRedirect'

export enum AuroraContract {
  AURORA_INSTALLMENT = 'AuroraInstallment',
  AURORA_ONE_TIME = 'AuroraOneTime',
  AURORA_REVISED_TRADE_IN = 'AuroraRevisedTradeIn',
}

export const RBC = 'RBC'
export const TD = 'TD'
export const SC = 'SC'
export const BMO = 'BMO'
export const CIBC = 'CIBC'
export const DES = 'DES'
export const TAN = 'TAN'
export const PC = 'PC'
export const NAT = 'NAT'
export const ATB = 'ATB'
export const VAN = 'VAN'
export const MER = 'MER'
export const OTHERBANK = 'OTHERBANK'
export const VISA = 'VISA'
export const MC = 'MC'
export const OTHERCC = 'OTHERCC'

export enum PaymentIcon {
  RBC = 'RBC',
  TD = 'TD',
  SC = 'SC',
  BMO = 'BMO',
  CIBC = 'CIBC',
  DES = 'DES',
  TAN = 'TAN',
  PC = 'PC',
  NAT = 'NAT',
  ATB = 'ATB',
  VAN = 'VAN',
  MER = 'MER',
  OTHERBANK = 'OTHERBANK',
  VISA = 'VISA',
  MC = 'MC',
  OTHERCC = 'OTHERCC',
  VISA_DEBIT = 'VISA_DEBIT',
}

export enum PaymentMethodType {
  CC = 'CC',
  EFT = 'EFT',
}

export enum ContractType {
  MONTHLY = 'Monthly',
  PI4 = 'PI4',
  AURORA_INSTALLMENT = 'AuroraInstallment',
  AURORA_ONE_TIME = 'AuroraOneTime',
  AURORA_REVISED_TRADE_IN = 'AuroraRevisedTradeIn',
}

type ContractOtherItems = {
  itemDescription: string
  itemAmount: number
}

type ContractApplicableTaxes = {
  itemDescription: string
  taxAmount: number
}

export interface ContractOverviewItem {
  contractKey: number
  contractTitle: string
  contractSubtitle: string
  contractType: ContractType
  financedAmount: number
  nextPaymentAmount?: number
  nextPaymentDate?: Date
  paidInFull: boolean
  principalBalance: number
  totalRepaymentAmount: number
  contractStatus: string
  paymentProblem: boolean
  updateEFTStatus: 'pending' | null
}
export interface ContractDetailItem extends ContractOverviewItem {
  allowExtraPayment: boolean
  allowExtraPaymentMessage: string
  allowPayoff: boolean
  allowPayoffMessage: string
  applicableTaxes?: ContractApplicableTaxes[]
  apr: number
  contractDate: Date
  contractNumber: string
  currentBalance: number
  extraPaymentCutOffDate?: Date
  incrementValue: number
  interestRate: number
  isCaptured?: boolean
  maxExtraPaymentAmount?: number
  minExtraPaymentAmount: number
  noPaymentScheduled: boolean
  originalTotal?: number
  otherItems?: ContractOtherItems[]
  payOffBalance: number
  paymentAccountNumber: string
  paymentExpiryDate: Date
  paymentIcon: string
  paymentMethod: string
  payoutCutOffDate?: Date
  remainingTermsToPay: number
  revisedTradeInValue?: number
  shippingCharges?: number
  totalMonths?: number
  tradeInCredit: number
  isApple?: boolean
  isAppliedToAllOfContractGroup?: boolean
}

export type ContractOverView = {
  items: ContractOverviewItem[]
  itemTotalCount: number
  pageNumber: number
  pageSize: number
  currentPageNumber: number
}

export const HOME_ENTRY = 'HomeEntry'
export const PHONE_NUMBER_ENTRY = 'PhoneNumberEntry'
export const PIN_ENTRY_LOGIN = 'PinEntry'
export const LINK_YOUR_ACCOUNT = 'LinkYourAccount'
export const EMAIL_VERIFICATION_CODE_ENTRY = 'EmailVerificationCodeEntry'
export const EMAIL_VERIFICATION = 'EmailVerification'
export const FACE_ID_SET_UP = 'FaceIDSetUp'
export const BOTTOM_NAVIGATOR = 'BottomNavigator'
export const AUTH_NAVIGATOR = 'AuthNavigator'
export const ROOT_NAVIGATOR = 'RootNavigator'
export const GENERIC_ERROR = 'GenericError'
export const ACCOUNT = 'Account'
export const DARK_MODE = 'DarkMode'
export const APPROVAL = 'Approval'
export const ACCOUNT_INFORMATION = 'AccountInformation'
export const BIOMETRICS = 'Biometrics'
export const LANGUAGE_SETTINGS = 'LanguageSettings'
export const PHONE_NUMBER_UPDATE = 'PhoneNumberUpdate'
export const AUTHENICATED_PHONE_NUMBER_UPDATE = 'AuthenticatedPhoneNumberUpdate'
export const AUTHENICATED_PHONE_NUMBER_UPDATE_PIN = 'AuthenticatedPhoneNumberUpdatePIN'
export const EMAIL_UPDATE = 'EmailUpdate'
export const EMAIL_UPDATE_EMAIL_VERIFICATION = 'EmailUpdateVerifyEmail'
export const ADDRESS_UPDATE = 'AddressUpdate'
export const PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION = 'PhoneNumberUpdateVerifyEmail'
export const PHONE_NUMBER_UPDATE_PIN_ENTRY = 'PhoneNumberUpdatePinEntry'
export const EMAIL_UPDATE_PIN_ENTRY = 'EmailUpdatePinEntry'
export const PAYMENT_METHOD_SETUP = 'PaymentMethodSetup'
export const PAYMENT_METHOD_CREDIT_CARD_ENTRY = 'PaymentMethodCreditCardEntry'
export const UPDATE_PHONE_NUMBER = 'UpdatePhoneNumber'
export const MAILING_ADDRESS_UPDATE = 'MailingAddressUpdate'

export const PLANS = 'PurchasePlans'
export const PLAN_DETAILS = 'PlanDetails'
export const MAKE_PAYMENT = 'MakePayment'
export const LINK_CREDIT_CARD = 'LinkCreditCard'
export const LINK_BANK_ACCOUNT = 'LinkBankAccount'
export const PAYMENT_METHOD_SELECTOR = 'PaymentMethodSelector'

export const PURCHASES_TAB = 'Purchases'
export const PROFILE_TAB = 'Profile'

export const PLANS_QUERY_PARAMS = {
  WELCOME: 'welcome',
  PHONE_UPDATED: 'phone-updated',
  CONTRACT_NOT_FOUND: 'contract-not-found',
}

export const SCHEDULE = 'Schedule'
export const mainScreens = {
  PHONE_NUMBER_ENTRY,
  PIN_ENTRY_LOGIN,
  LINK_YOUR_ACCOUNT,
  EMAIL_VERIFICATION_CODE_ENTRY,
  EMAIL_VERIFICATION,
  FACE_ID_SET_UP,
  BOTTOM_NAVIGATOR,
  GENERIC_ERROR,
  DARK_MODE,
  APPROVAL,
  ACCOUNT_INFORMATION,
  LANGUAGE_SETTINGS,
  PHONE_NUMBER_UPDATE,
  EMAIL_UPDATE,
  EMAIL_UPDATE_EMAIL_VERIFICATION,
  ADDRESS_UPDATE,
  PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION,
  PHONE_NUMBER_UPDATE_PIN_ENTRY,
  EMAIL_UPDATE_PIN_ENTRY,
  PAYMENT_METHOD_SETUP,
  PAYMENT_METHOD_CREDIT_CARD_ENTRY,
  UPDATE_PHONE_NUMBER,
}

export interface ApiResponse {
  statusDescription: StatusDescription
  statusCode: number
}

export const isApiResponse = (data: any): data is ApiResponse =>
  ['statusDescription', 'statusCode'].every(key => key in data)

export enum StatusDescription {
  SUCCESS = 'success',
  INVALID_PIN = 'invalid_pin',
  TOO_MANY_INCORRECT_ATTEMPTS = 'too_many_incorrect_attempts',
  UNAUTHORIZED = 'Unauthorized',
  NO_PORTAL_ACCOUNT = 'no_portal_account',
  NO_PORTAL_ACCOUNT_MATCH_FOUND = 'no_portal_account_match_found',
  DEVICE_LINKED_TO_DIFFERENT_ACCOUNT = 'device_linked_to_different_account',
  PORTAL_ACCOUNT_DISABLED = 'portal_account_disabled',
  EXPIRED_TOKEN = 'expired_token',
  SERVER_ERROR = 'server_error',
  VISA_CREDIT_NOT_SUPPORTED_FOR_INTEREST_BEARING = 'visa_credit_not_supported_for_interest_bearing',
  CC_EXPIRED = 'cc_expired',
  UNSUPPORTED_CC_TYPE = 'unsupported_cc_type',
  FAILED = 'failed',
  CC_NOT_CANADIAN = 'cc_not_canadian',
  INVALID_BILLING_ADDESS = 'invalid_billing_addess',
  INVALID_CARD_NUMBER = 'invalid_card_number',
  ACCEPTED = 'accepted',
  UNSUPPORTED_EFT_TYPE = 'unsupported_eft_type',
  UPDATE_NOT_ALLOWED = 'update_not_allowed',
  DELETED = 'deleted',
  PHONE_NUMBER_UPDATED = 'phone_number_updated',
  EMAIL_UPDATED = 'email_updated',
  ADDRESS_UPDATED = 'mailing_address_updated',
}

type excludedStatusesLinkYourAccount =
  | typeof StatusDescription.INVALID_PIN
  | typeof StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS
  | typeof StatusDescription.UNAUTHORIZED
  | typeof StatusDescription.NO_PORTAL_ACCOUNT

export type StatusDescriptionLinkYourAccount = Exclude<
  StatusDescription,
  excludedStatusesLinkYourAccount
>

export type LinkYourAccountMap = Record<StatusDescriptionLinkYourAccount, () => void>

export enum AccessLevel {
  AUTHORIZED_USER = 'AuthorizedUser',
  AUTH_STEP_1 = 'AuthStep1',
  AUTH_STEP_2 = 'AuthStep2',
  AUTH_STEP_3 = 'AuthStep3',
}

export enum Description {
  Scheduled = 'Scheduled',
  ReAttempt = 'Reattempt',
  FullRepayment = 'Full repayment',
  ExtraPayment = 'Extra payment',
  Defaulted = 'Defaulted',
  Refund = 'Refund',
  Processing = 'Processing',
}

export enum Status {
  Active = 'Active',
  Confirmed = 'Confirmed',
  Failed = 'Failed',
  Processing = 'Processing',
}
export interface Payments {
  items?: ItemsEntity[] | null
  itemTotalCount: number
  pageNumber: number
  pageSize: number
  currentPageNumber: number
  statusDescription: string
  statusCode: number
}
export interface ItemsEntity {
  paymentScheduleKey: number
  contractKey: number
  description: Description
  status: Status
  eventDate: Date
  totalPayment: number
  principal: number
  otherFees: number
  interest: number
  processingFees: number
  creditCardFees: number
}

export type GetTitle = Record<Description, string>
export type GetIcon = Record<Status, () => JSX.Element>

export interface ScheduleItemProps {
  item: ItemsEntity
}

export enum ListEnum {
  PRINCIPAL = 'principal',
  INTEREST = 'interest',
  PROCESSING_FEES = 'processingFees',
  CREDIT_CARD_FEES = 'creditCardFees',
  OTHER_FEES = 'otherFees',
}

export interface ListProperties {
  id: ListEnum
  value: number | null
  label: string
}

export type destructuredEntries = [ListEnum, number | null]

export type Next = [ListEnum, number | null]

type FeatureGroup = {
  [featureName: string]: boolean | FeatureGroup
}

export interface EnvironmentVariables {
  REACT_APP_SMS_PHONE_NUMBER: string
  REACT_APP_BASE_URL: string
  SENTRY_DSN: string
  SENTRY_ENABLE_IN_DEVELOPMENT: boolean
  SENTRY_ENABLE_DEBUG_CONSOLE_LOG: boolean
  SENTRY_ENVIRONMENT: string
  SENTRY_MAX_BREADCRUMBS: number
  SENTRY_ATTACH_STACKTRACE: boolean
  GA_ENABLE_DEBUG_MODE: boolean
  GA_ENVIRONMENT: string
  FLINKS_URL_EN: string
  FLINKS_URL_FR: string
  FLINKS_INSTANCE: string
  FEATURES: FeatureGroup
  INACTIVE_SESSION_MINUTES: number
}

export const Channel = {
  DEVELOPMENT: 'development',
  PREVIEW: 'preview',
  PRODUCTION: 'production',
}

export type CHANNEL = typeof Channel[keyof typeof Channel]

export type Env = Record<CHANNEL, EnvironmentVariables>

export const dockerDBPlaygroundURL = 'http://localhost:80'
export const dockerDBDevelopmentURL = 'http://localhost:8500'
export const playgroundURL = 'https://sandbox.portal.paybright.com'
export const sandboxURL = 'https://sandbox.portal.paybright.com'
export const prodURL = 'https://portal.paybright.com'

export const sentryDSN = 'https://ccb3b1d406fa4f63b489265fb6f352dd@o995361.ingest.sentry.io/5954235'

export const demoFlinksInstance = 'https://healthsmart-iframe.private.fin.ag'
export const prodFlinksInstance = 'https://healthsmart-iframe.private.fin.ag'

const prodFlinksParams =
  '/?innerRedirect=false&consentEnable=true&customerName=PayBright&headerEnable=true&institutionFilterEnable=true&accountSelectorEnable=true'

const demoFlinksParams = `${prodFlinksParams}&demo=true`

export const demoFlinksURLEn = `${demoFlinksInstance + demoFlinksParams}`
export const demoFlinksURLFr = `${demoFlinksInstance + demoFlinksParams}&language=fr`
export const prodFlinksURLEn = `${prodFlinksInstance + prodFlinksParams}`
export const prodFlinksURLFr = `${prodFlinksInstance + prodFlinksParams}&language=fr`

const INJECTABLE_TYPES = {
  ILoggingService: Symbol.for('ILoggingService'),
  ILoggingParameterService: Symbol.for('ILoggingParameterService'),
  IUserAuthInfoService: Symbol.for('IUserAuthInfoService'),
}

export { INJECTABLE_TYPES }

export interface LoggingParameters {
  PortalDeviceGuid: string
  PortalAccountId: string
  PortalSessionId: string
}

export const EFT = 'EFT'
export const CC = 'CC'

export interface Eft {
  financialInstitution?: string
  bankAccountNo?: string
  branchAddress?: string
  branchCity?: string
  branchProvince?: string
  branchPostalCode?: string
}
export interface CreditCard {
  addrAptSuite?: string
  addrHouseNo?: string
  addrStreetName?: string
  addrCity?: string
  addrProvince?: string
  addrPostalCode?: string
  cardNumber?: string
  cardExpiry?: string
}

export interface ContractPaymentMethod {
  currentPaymentMethod: typeof EFT | typeof CC
  eft: Eft
  creditCard: CreditCard
}

export type BillingAddress = Omit<CreditCard, 'cardNumber' | 'cardExpiry'>

export type CreditCardNumberDetails = Pick<CreditCard, 'cardNumber' | 'cardExpiry'>

export type UpdateCCPayload = CreditCardNumberDetails & {
  cvv: string
  billingAddress?: BillingAddress
  isAppliedToAllOfContractGroup?: boolean
}

export const canadaPost = {
  country: 'CAN',
  maxResults: 8,
  apiKey: 'TE78-RJ32-HR12-RK65',
  searchFor: 'Everything',
  get getMatchingAddressesUrl() {
    return `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${this.apiKey}&Country=${this.country}&MaxResults=${this.maxResults}&SearchFor=${this.searchFor}`
  },
  get getAddressByIdUrl() {
    return `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.00/json3.ws?Key=${this.apiKey}`
  },
}
