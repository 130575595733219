import { StatusDescription } from '../../../../types'
import { ModalVariant } from '../../../../providers/ContractProvider'

export const getEFTModalVariant = (statusDescription: StatusDescription) => {
  type ModalVariantsEFT = Partial<Record<StatusDescription, ModalVariant>>

  const modalVariantsEFT: ModalVariantsEFT = {
    [StatusDescription.SUCCESS]: ModalVariant.PAYMENT_METHOD_UPDATED,
    [StatusDescription.FAILED]: ModalVariant.EFT_FAILED,
    [StatusDescription.UNSUPPORTED_EFT_TYPE]: ModalVariant.EFT_UNSUPPORTED_EFT_TYPE,
    [StatusDescription.UPDATE_NOT_ALLOWED]: ModalVariant.EFT_UPDATE_NOT_ALLOWED,
    [StatusDescription.ACCEPTED]: ModalVariant.EFT_ACCEPTED,
  }
  const getVariant = (
    statusDesc: StatusDescription | null
  ): typeof modalVariantsEFT[keyof typeof modalVariantsEFT] | null =>
    statusDesc ? modalVariantsEFT[statusDesc] : null

  return getVariant(statusDescription)
}
