import PlanDetailInformation from '../../components/organisms/PlanDetailInformation'
import { ScreenParentWrapper } from '../../components/wrappers/ScreenParentWrapper'

const PlanDetailScreen = () => (
  <ScreenParentWrapper>
    <PlanDetailInformation />
  </ScreenParentWrapper>
)

export default PlanDetailScreen
