import { useEffect } from 'react'
import { Platform } from 'react-native'
import FlagSecure from 'react-native-flag-secure-android'

export const useFlagSecure = () => {
  const isAndroid = Platform.OS === 'android'
  useEffect(() => {
    if (!isAndroid) return
    FlagSecure.activate()
    return () => FlagSecure.deactivate()
  }, [isAndroid])
}
