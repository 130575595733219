import { FC, useContext } from 'react'
import { View } from 'react-native'
import Div100vh from 'react-div-100vh'
import Box from '../../atoms/Box'
import { DimensionContext } from '../../../providers'

export const ScreenParentWrapper: FC = ({ children }) => {
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'
  const Wrapper = isMobile ? Div100vh : Box

  return (
    <View style={{ flexGrow: 1, maxHeight: '100%' }}>
      <Wrapper
        {...(!isMobile
          ? {
              scrollView: true,
              contentContainerStyle: { flex: 1 },
            }
          : {
              style: {
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                maxHeight: '100%',
              },
            })}>
        {children}
      </Wrapper>
    </View>
  )
}
