import { useContext, useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'

import GenericModal from '../../molucules/GenericModal'
import Button from '../../atoms/Button'
import AccountCreationWrapper from '../../wrappers/AccountCreationWrapper'
import PersonalInformation from '../../molucules/PersonalInformation'
import { linkAccount } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { CustomerContext } from '../../../providers/CustomerProvider'

import {
  StatusDescription,
  EMAIL_VERIFICATION_CODE_ENTRY,
  PHONE_NUMBER_UPDATE,
  AccessLevel,
  ApiResponse,
} from '../../../types'

const LinkYourAccount = () => {
  const navigation = useNavigation()
  const { accessLevel, token, authFlowType } = useContext(AuthContext)
  const { customer } = useContext(CustomerContext)
  const { localized } = useContext(ContentContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isValidForm, setIsValidForm] = useState<boolean>(false)
  const { lastName, email } = customer
  const [url, setUrl] = useState('')
  const isUpdatePhoneFlow = authFlowType === 'phoneUpdate'

  const linkYourAccountBody = () => ({
    lastName,
    email,
  })
  const axiosBody = url === linkAccount ? linkYourAccountBody() : {}

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: axiosBody,
  })

  useEffect(() => {
    if ([RequestStatus.ERROR, RequestStatus.FETCHED].includes(status)) {
      const { statusDescription } = (error || data) as ApiResponse
      setUrl('')
      if (statusDescription === StatusDescription.NO_PORTAL_ACCOUNT_MATCH_FOUND) {
        if (isUpdatePhoneFlow) {
          navigation.navigate(PHONE_NUMBER_UPDATE)
        } else {
          setModalOpen(true)
        }
      }
    }
  }, [status])

  const send = async () => {
    setUrl(linkAccount)
  }

  const isFocused = useIsFocused()
  const triggerNavigationIfAuthStep3 = () => {
    if (accessLevel === AccessLevel.AUTH_STEP_3 && isFocused) {
      navigation.replace(EMAIL_VERIFICATION_CODE_ENTRY)
    }
  }

  useDidUpdateEffect(() => {
    triggerNavigationIfAuthStep3()
  }, [token])

  const validFormHandler = (isValid: boolean) => setIsValidForm(isValid)

  const modalProps = {
    message: localized('linkYourAccount.errorParagraphUpdateDisabled'),
    buttonText: localized('global.goBack'),
    onButtonPress: navigation.goBack,
  }

  return (
    <>
      <GenericModal
        header={localized('linkYourAccount.errorHeader')}
        visible={isModalOpen}
        {...modalProps}
      />
      <AccountCreationWrapper
        hasPlant={true}
        paddingTop={[32, 32, 0, 0, 0]}
        title={localized('linkYourAccount.header')}>
        <PersonalInformation
          validFormHandler={validFormHandler}
          send={send}
          isValidForm={isValidForm}
        />
        <Button disabled={!isValidForm} testID="linkYourAccountContinueBtn" onPress={send}>
          {localized('global.continue')}
        </Button>
      </AccountCreationWrapper>
    </>
  )
}

const styles = StyleSheet.create({
  heading: {
    marginBottom: 24,
  },
})

export default LinkYourAccount
