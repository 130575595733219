import { FC } from 'react'
import styled from 'styled-components/native'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import Body, { BodyVariants } from '../../atoms/Typography/Body'

interface PlanCardDescriptionProps {
  textAtFirstPostion: string
  textAtSecondPostion: string
  boldAtFirstPostion?: boolean
}

const StyledBody = styled(Body)`
  margin-bottom: 4px;
  text-align: center;
`

const PlanCardDescription: FC<PlanCardDescriptionProps> = ({
  textAtFirstPostion,
  textAtSecondPostion,
  boldAtFirstPostion = false,
}) => {
  const variants: BodyVariants[] = ['--body-02-semi-emphasized', '--body-02-regular']
  const firstBlockVariant = variants[boldAtFirstPostion ? 0 : 1]
  const secondBlockVariant = variants[boldAtFirstPostion ? 1 : 0]
  return (
    <StyledBody
      darkColor={whiteOpacity9}
      lightColor={colors['--text-dark']}
      variant={firstBlockVariant}>
      {textAtFirstPostion}
      <Body
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        variant={secondBlockVariant}>
        {textAtSecondPostion}
      </Body>
    </StyledBody>
  )
}

export default PlanCardDescription
