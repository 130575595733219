import Svg, { Rect } from 'react-native-svg'
import Props from './shared'

const CheckBoxOutline = ({ color = '#303030', size = '24' }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 18 18" fill="none">
    <Rect width="18" height="18" rx="2" fill={color} />
    <Rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#838383" />
  </Svg>
)
export default CheckBoxOutline
