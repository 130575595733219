import { useCallback, DependencyList } from 'react'
import { ParamListBase, useNavigation, useFocusEffect } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BackHandler } from 'react-native'

export const useGoBackHandler = <
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList & string
>(
  onGoBackCallback: () => boolean | null | undefined,
  deps?: DependencyList
) => {
  const navigation = useNavigation<StackNavigationProp<ParamList, RouteName>>()
  useFocusEffect(
    useCallback(() => {
      BackHandler.addEventListener('hardwareBackPress', onGoBackCallback)
      navigation.addListener('gestureEnd', onGoBackCallback)

      return () => {
        BackHandler.removeEventListener('hardwareBackPress', onGoBackCallback)
        navigation.removeListener('gestureEnd', onGoBackCallback)
      }
    }, [navigation, onGoBackCallback, deps])
  )
}
