import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { checkBiometricCompatability } from '../helpers/biometrics'

export const useCheckBiometricsCapable = () => {
    const [isBiometricsAvailable, setBiometricsAvailable] = useState(false)
    const isNative = ['ios', 'android'].includes(Platform.OS)
    
    const init = async () => {
        if(!isNative) return
        const isAvailable = await checkBiometricCompatability() 

        if (isAvailable) {
            setBiometricsAvailable(isAvailable)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return isBiometricsAvailable
}