import { useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ContentContext } from '../providers/ContentProvider'
import {
  ACCOUNT,
  AccountStackParamList,
  ACCOUNT_INFORMATION,
  LANGUAGE_SETTINGS,
  DARK_MODE,
  BIOMETRICS,
  EMAIL_UPDATE,
  EMAIL_UPDATE_EMAIL_VERIFICATION,
  MAILING_ADDRESS_UPDATE,
  AUTHENICATED_PHONE_NUMBER_UPDATE,
  AUTHENICATED_PHONE_NUMBER_UPDATE_PIN,
} from '../types'
import AccountScreen from '../screens/AccountScreen'
import AccountInformationScreen from '../screens/AccountInformation'
import DarkModeScreen from '../screens/DarkModeScreen'
import BiometricsScreen from '../screens/BiometricsScreen'
import LanguageSettingsScreen from '../screens/LangaugeSettings'
import EmailUpdateScreen from '../screens/EmailUpdateScreen'
import AuthenticatedPhoneNumberUpdateScreen from '../screens/AuthenticatedPhoneNumberUpdateScreen'
import AuthenticatedPhoneNumberUpdatePINScreen from '../screens/AuthenticatedPhoneNumberUpdatePINScreen'
import EmailUpdateVerifyEmailScreen from '../screens/EmailUpdateVerifyEmailScreen'
import MailingAddressUpdateScreen from '../screens/MailingAddressUpdateScreen'

const Stack = createStackNavigator<AccountStackParamList>()

const AccountNavigator = () => {
  const { localized } = useContext(ContentContext)
  return (
    <Stack.Navigator initialRouteName="Account" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ACCOUNT}
        component={AccountScreen}
        options={{
          title: localized('pageTitle.account'),
        }}
      />
      <Stack.Screen
        name={LANGUAGE_SETTINGS}
        component={LanguageSettingsScreen}
        options={{
          title: localized('pageTitle.languageSetting'),
        }}
      />
      <Stack.Screen
        name={DARK_MODE}
        component={DarkModeScreen}
        options={{
          title: localized('pageTitle.darkMode'),
        }}
      />
      <Stack.Screen
        name={ACCOUNT_INFORMATION}
        component={AccountInformationScreen}
        options={{
          title: localized('pageTitle.accountInformation'),
        }}
      />
      <Stack.Screen
        name={BIOMETRICS}
        component={BiometricsScreen}
        options={{
          title: localized('pageTitle.accountInformation'),
        }}
      />
      <Stack.Screen
        name={EMAIL_UPDATE}
        component={EmailUpdateScreen}
        options={{
          title: 'email update',
        }}
      />
      <Stack.Screen
        name={EMAIL_UPDATE_EMAIL_VERIFICATION}
        component={EmailUpdateVerifyEmailScreen}
        options={{ title: localized('pageTitle.emailVerificationCode') }}
      />
      <Stack.Screen
        name={AUTHENICATED_PHONE_NUMBER_UPDATE}
        component={AuthenticatedPhoneNumberUpdateScreen}
        options={{
          title: 'phone number update',
        }}
      />
      <Stack.Screen
        name={AUTHENICATED_PHONE_NUMBER_UPDATE_PIN}
        component={AuthenticatedPhoneNumberUpdatePINScreen}
        options={{
          title: 'phone number update',
        }}
      />
      <Stack.Screen
      name={MAILING_ADDRESS_UPDATE}
        component={MailingAddressUpdateScreen}
        options={{
          title: 'mailing address update'
        }}
      />
    </Stack.Navigator>
  )
}

export default AccountNavigator
