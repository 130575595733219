import { VFC } from 'react'
import { StyleSheet } from 'react-native'
import { whiteOpacity4, blackOpacity6 } from '../../tokens/colors/colors'
import Box from '../../atoms/Box'
import Spinner from '../../atoms/Spinner'
import NavBar from '../NavBar'
import SharedBody from '../SharedBody'
import { BodyVariants } from '../../atoms/Typography/Body'

type Variant = 'default' | 'in-screen'

interface LoadingProps {
  isTransparent?: boolean
  variant: Variant
  darkModeOverride?: boolean
  message?: string
  messageVariant?: 'separated'
}

const Loading: VFC<LoadingProps> = ({
  isTransparent,
  variant = 'default',
  darkModeOverride = false,
  message,
  messageVariant,
}) => {
  const backgroundLight = isTransparent ? whiteOpacity4 : 'white'
  const backgroundDark = darkModeOverride ? 'white' : isTransparent ? blackOpacity6 : 'black'

  const formattedloadingMessage = (msg: string) =>
    `${msg} `
      .split('. ')
      .filter(t => t)
      .map(t => `${t}.`)
  const messageArray = message
    ? messageVariant === 'separated'
      ? formattedloadingMessage(message)
      : [message]
    : []
  return (
    <>
      <Box backgroundLight={backgroundLight} backgroundDark={backgroundDark} style={styles.wrapper}>
        {variant === 'default' && (
          <Box>
            <NavBar />
          </Box>
        )}

        <Box style={styles.spinnerWrapper}>
          <Spinner darkModeOverride={darkModeOverride} size={64} />
          {messageArray?.length ? (
            <Box style={{ paddingHorizontal: 32, paddingVertical: 64, maxWidth: 360 }}>
              {messageArray.map(text => (
                <SharedBody
                  key={text}
                  style={{ textAlign: 'center', lineHeight: 24 }}
                  variant="--body-02-semi-emphasized">
                  {text}
                </SharedBody>
              ))}
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default Loading

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  spinnerWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
