import { Modal, Portal } from 'react-native-paper'
import { StyleSheet, Image } from 'react-native'
import Button from '../../atoms/Button'
import Card from '../../atoms/Card'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Heading from '../../atoms/Typography/Heading'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { CustomerType } from '../../../providers/CustomerProvider'
import Box from '../../atoms/Box'
import ThumbsUp from '../ThumbsUp'

const SharedBody = ({ variant, children }: { variant: BodyVariants; children: string }) => (
  <Body
    style={styles.body}
    variant={variant}
    lightColor={colors['--text-dark']}
    darkColor={whiteOpacity9}>
    {children}
  </Body>
)

const successMessage = {
  phone_number: 'You’ve successfully updated your phone number to',
  email: 'You’ve successfully updated your email address to',
  address: 'You’ve successfully updated your home address to',
}

export const PHONE_NUMBER_SUCCESS_TYPE = 'phone_number'
export const EMAIL_SUCCESS_TYPE = 'email'
export const ADDRESS_SUCCESS_TYPE = 'address'

type Type =
  | typeof PHONE_NUMBER_SUCCESS_TYPE
  | typeof EMAIL_SUCCESS_TYPE
  | typeof ADDRESS_SUCCESS_TYPE

const SuccessModal = ({
  customer,
  setGenericModalOpen,
  isGenericModalOpen,
  type,
}: {
  customer: CustomerType
  setGenericModalOpen: (arg0: boolean) => void
  isGenericModalOpen: boolean
  type: Type
}) => (
  <Portal>
    <Modal dismissable={true} contentContainerStyle={styles.modal} visible={isGenericModalOpen}>
      <Card style={styles.card}>
        <Box style={styles.titleWrapper}>
          <Heading style={styles.heading} variant="--heading-2">
            All done{' '}
          </Heading>
          <ThumbsUp />
        </Box>
        <SharedBody variant="--body-01-regular">
          {successMessage[type]}{' '}
          <SharedBody variant="--body-01-semi-emphasized">
            {customer[type]}
            {'.'}
          </SharedBody>
        </SharedBody>
        <Button testID="CloseBtn" onPress={() => setGenericModalOpen(false)}>
          Close
        </Button>
      </Card>
    </Modal>
  </Portal>
)

export default SuccessModal

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    padding: 16,
    alignItems: 'center',
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  card: {
    padding: 32,
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 500,
  },

  heading: {
    alignSelf: 'center',
  },
  body: {
    textAlign: 'center',
    marginBottom: 40,
  },
})
