import { createContext, ReactNode, useState, Dispatch, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import useColorScheme from '../hooks/useColorScheme'
import { ink0 } from '../components/tokens/colors/colors'

export const DARK = 'dark'
export const LIGHT = 'light'
export const MANUAL = 'manual'
export const SYSTEM = 'system'

export type Theme = typeof DARK | typeof LIGHT
export type ThemeSettingsType = typeof MANUAL | typeof SYSTEM
interface ContextState {
  theme: Theme
  updateTheme: Dispatch<Theme>
  themeSettingsType: ThemeSettingsType
  updateThemeSettingsType: (selectedSettingsType: ThemeSettingsType, selectedTheme: Theme) => void
  setRootBackgroundColor: Dispatch<string>
  rootBackgroundColor: string
}

export const ThemeContext = createContext<ContextState>({
  theme: DARK,
  updateTheme: (): void => {},
  themeSettingsType: 'system',
  updateThemeSettingsType: (): void => {},
  setRootBackgroundColor: (): void => {},
  rootBackgroundColor: 'green',
})

interface Props {
  children: ReactNode
}

const THEME_STORAGE_KEY = 'theme'
const THEME_SETTINGS_TYPE = 'themeSettingsType'

export const ThemeProvider = ({ children }: Props) => {
  const systemTheme = useColorScheme()
  const [theme, setTheme] = useState<Theme>(systemTheme)
  const [rootBackgroundColor, setRootBackgroundColor] = useState(ink0)
  const [themeSettingsType, setThemeSettingsType] = useState<ThemeSettingsType>(MANUAL)
  const updateTheme = (selectedTheme: Theme) => {
    AsyncStorage.setItem(THEME_STORAGE_KEY, selectedTheme)
    setTheme(selectedTheme)
  }

  const updateThemeSettingsType = (
    selectedSettingsType: ThemeSettingsType,
    selectedTheme: Theme
  ) => {
    AsyncStorage.setItem(THEME_SETTINGS_TYPE, selectedSettingsType)
    setThemeSettingsType(selectedSettingsType)
    if (selectedSettingsType === SYSTEM) {
      updateTheme(systemTheme)
    } else {
      updateTheme(selectedTheme)
    }
  }

  const getTheme = async () => {
    try {
      const settingsType = await AsyncStorage.getItem(THEME_SETTINGS_TYPE)
      if (!settingsType || settingsType === MANUAL) {
        setThemeSettingsType(MANUAL)
        try {
          const storedTheme: Theme | null = await AsyncStorage.getItem(THEME_STORAGE_KEY)

          if (storedTheme !== null) {
            updateTheme(storedTheme)
          }
        } catch (e) {
          // tslint:disable-next-line: no-console
          console.log(e)
        }
      } else {
        setThemeSettingsType(SYSTEM)
      }
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e)
    }
  }

  useEffect(() => {
    getTheme()
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme,
        themeSettingsType,
        updateThemeSettingsType,
        rootBackgroundColor,
        setRootBackgroundColor,
      }}>
      {children}
    </ThemeContext.Provider>
  )
}
