import { createContext, ReactNode, useState, Dispatch, SetStateAction } from 'react'

interface ContextState {
  customer: CustomerType
  setCustomer: Dispatch<SetStateAction<CustomerType>>
  resetCustomer: () => void
}
export interface AddressType {
  aptSuite: string
  houseNo: string
  streetName: string
  city: string
  province: string
  postalCode: string
}

export interface CustomerType {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: AddressType
}

export const defaultCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: {
    aptSuite: '',
    houseNo: '',
    streetName: '',
    city: '',
    province: '',
    postalCode: '',
  },
}

export const CustomerContext = createContext<ContextState>({
  customer: defaultCustomer,
  setCustomer: (): void => {},
  resetCustomer: (): void => {},
})

interface Props {
  children: ReactNode
}

export const CustomerProvider = ({ children }: Props) => {
  const [customer, setCustomer] = useState<CustomerType>(defaultCustomer)

  const resetCustomer = () => setCustomer(defaultCustomer)

  return (
    <CustomerContext.Provider value={{ customer, setCustomer, resetCustomer }}>
      {children}
    </CustomerContext.Provider>
  )
}
