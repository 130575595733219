import { FC, useContext, useMemo } from 'react'
import { ContentContext } from '../../../providers/ContentProvider'
import { Image } from 'react-native'
import { PaymentIcon } from '../../../types'
import { getImages } from './helpers'
import { DARK, ThemeContext } from '../../../providers/ThemeProvider'

interface BankCCLogoProps {
  paymentIcon: PaymentIcon
}

const BankCCLogo: FC<BankCCLogoProps> = ({ paymentIcon }) => {
  const { theme } = useContext(ThemeContext)
  const { localized } = useContext(ContentContext)
  const isDarkMode = theme === DARK
  const alt = localized(`bankCCNames.${paymentIcon}`)
  const source = useMemo(() => getImages(paymentIcon)(isDarkMode), [paymentIcon, isDarkMode])
  return (
    <Image
      style={{ width: 44, height: 44 }}
      accessible={true}
      accessibilityLabel={alt}
      resizeMode="contain"
      source={source}
    />
  )
}

export default BankCCLogo
