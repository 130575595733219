import { Platform } from 'react-native'

const isWeb = Platform.OS !== 'android' && Platform.OS !== 'ios'

export const MASK_PHONE_NUMBER = { type: 'custom', options: { mask: '(999) 999-9999' } }
export const MASK_PHONE_NUMBER_ALT = { type: 'custom', options: { mask: '999)  999-9999' } }
export const MASK_PIN = { type: 'custom', options: { mask: '999999' } }
const LAST_NAME_CHAR_LENGTH = 25
const lastNameMask = `A${'!'.repeat(LAST_NAME_CHAR_LENGTH - 2)}A`

export const MASK_NAME = {
  type: 'custom',
  options: isWeb
    ? {
        mask: lastNameMask.toLowerCase(),
        formatChars: {
          '9': '[A-Za-z]',
          a: '[A-Za-z]',
          '*': '[A-Za-z0-9]',
          '!': `[ a-zA-Z\-'’]`,
        },
      }
    : {
        mask: lastNameMask,
        translation: {
          '!': (value: string) => {
            const regex = /[ a-zA-Z\-'’]/
            return regex.test(value) ? value : ''
          },
        },
      },
}

export const MASK_ADDRESS_SHORT = {
  type: 'custom',
  options: isWeb
    ? {
        mask: '!!!!!!!!!!',
        formatChars: {
          '!': `[ a-zA-Z0-9\-'’]`,
        },
      }
    : {
        mask: '!!!!!!!!!!',
        translation: {
          '!': (value: string) => {
            const regex = /[ a-zA-Z0-9\-'’]/
            return regex.test(value) ? value : ''
          },
        },
      },
}

export const MASK_ADDRESS = {
  type: 'custom',
  options: isWeb
    ? {
        mask: '!!!!!!!!!!!!!!!!!!!!!!!!!!',
        formatChars: {
          '!': `[ a-zA-Z0-9\-'’]`,
        },
      }
    : {
        mask: '!!!!!!!!!!!!!!!!!!!!!!!!!!',
        translation: {
          '!': (value: string) => {
            const regex = /[ a-zA-Z0-9\-'’]/
            return regex.test(value) ? value : ''
          },
        },
      },
}

export const MASK_POSTAL_CODE = {
  type: 'custom',
  options: isWeb
    ? {
        mask: 'a9a 9a9',
        formatChars: {
          '9': '[0-9]',
          a: '[A-Za-z]',
        },
      }
    : { mask: 'A9A 9A9' },
}

export const MASK_PROVINCE = {
  type: 'custom',
  options: isWeb ? { mask: 'aa' } : { mask: 'AA' },
}

export const MASK_EMAIL = { type: 'custom', options: { mask: '****************************' } }
export const MASK_DATE_OF_BIRTH = { type: 'custom', options: { mask: '99/99/9999' } }
export const MASK_SIN = { type: 'custom', options: { mask: '999 999 999' } }
export const MASK_CREDIT_CARD = { type: 'custom', options: { mask: '9999 9999 9999 9999' } }
export const MASK_EXPIRY_DATE = { type: 'custom', options: { mask: '99/99' } }
export const MASK_CVV = { type: 'custom', options: { mask: '999' } }
export const MASK_LAST_DIGITS_OF_PAYMENT_ACCOUNT_NUMBER = {
  type: 'custom',
  options: { mask: '9999' },
}

export const MASK_CURRENCY = {
  type: 'money',
  options: {
    precision: 2,
    separator: '.',
    delimiter: ',',
    unit: '$',
    suffixUnit: '',
  },
}

export const normalizeMaskedValue = (num: number) =>
  num
    .toString()
    .replace('/', '')
    .replace('(', '')
    .replace(')', '')
    .replace(/ /g, '')
    .replace('-', '')
