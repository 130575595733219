import { PaymentIcon } from '../../../types'

export const getImages = (iconKey: PaymentIcon) =>
  ({
    RBC: () => require('../../../assets/logos/RBC.png'),
    TD: () => require('../../../assets/logos/TD.png'),
    SC: () => require('../../../assets/logos/Scotiabank.png'),
    BMO: () => require('../../../assets/logos/BMO.png'),
    CIBC: () => require('../../../assets/logos/CIBC.png'),
    DES: () => require('../../../assets/logos/Desjardins.png'),
    TAN: () => require('../../../assets/logos/Tangerine.png'),
    PC: () => require('../../../assets/logos/Simplii.png'),
    NAT: () => require('../../../assets/logos/National.png'),
    ATB: () => require('../../../assets/logos/ATB.png'),
    VAN: () => require('../../../assets/logos/Vancity.png'),
    MER: () => require('../../../assets/logos/Meridian.png'),
    VISA: () => require('../../../assets/logos/Visa.png'),
    MC: () => require('../../../assets/logos/Mastercard.png'),
    VISA_DEBIT: (isDark: boolean) =>
      isDark
        ? require('../../../assets/logos/VisaDebit_dark.png')
        : require('../../../assets/logos/VisaDebit.png'),
    OTHERBANK: (isDark: boolean) =>
      isDark
        ? require('../../../assets/logos/OTHERBANK_dark.png')
        : require('../../../assets/logos/OTHERBANK.png'),
    OTHERCC: (isDark: boolean) =>
      isDark
        ? require('../../../assets/logos/OTHERCC_dark.png')
        : require('../../../assets/logos/OTHERCC.png'),
  }[iconKey])
