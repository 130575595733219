import { useContext } from 'react'
import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import NavBar from '../components/molucules/NavBar'
import { ContentContext } from '../providers/ContentProvider'
import { AuthContext } from '../providers/AuthProvider'
import { useRefreshToken } from '../hooks/useRefreshToken'
import BottomNavigator from './BottomNavigator'
import { purpleJelly10 } from '../components/tokens/colors/colors'
import PhoneNumberEntryScreen from '../screens/PhoneNumberEntryScreen'
import HomeEntryScreen from '../screens/HomeEntryScreen'
import PinEntryLoginScreen from '../screens/PinEntryLoginScreen'
import LinkYourAccountScreen from '../screens/LinkYourAccountScreen'
import PhoneNumberUpdateScreen from '../screens/PhoneNumberUpdateScreen'
import EmailVerificationCodeEntryScreen from '../screens/EmailVerificationCodeEntryScreen'
import EmailVerificationScreen from '../screens/EmailVerificationScreen'
import GenericErrorScreen from '../screens/GenericErrorScreen'
import { useGoBackHandler } from '../hooks/useGoBackHandler'

import {
  HomeParamList,
  LINK_YOUR_ACCOUNT,
  EMAIL_VERIFICATION_CODE_ENTRY,
  EMAIL_VERIFICATION,
  HOME_ENTRY,
  PHONE_NUMBER_ENTRY,
  PIN_ENTRY_LOGIN,
  BOTTOM_NAVIGATOR,
  GENERIC_ERROR,
  PHONE_NUMBER_UPDATE,
} from '../types'
import { BackHandler } from 'react-native'

export const AuthNavigator = () => {
  const { localized } = useContext(ContentContext)
  const { isLoggedIn } = useContext(AuthContext)

  useRefreshToken()
  const route = useRoute()
  const ROOT = 'Root'
  const routeName = getFocusedRouteNameFromRoute(route) || ROOT
  const disabledBackRoutes = [isLoggedIn ? BOTTOM_NAVIGATOR : null, HOME_ENTRY, ROOT]
  const isBackButtonDisabledScreen = disabledBackRoutes.includes(routeName)

  useGoBackHandler(() => isBackButtonDisabledScreen, [isBackButtonDisabledScreen])

  return (
    <Home.Navigator>
      <Home.Screen
        name={HOME_ENTRY}
        component={HomeEntryScreen}
        options={{ ...tabBarOptionsEntry, title: localized('pageTitle.entry') }}
      />
      <Home.Screen
        name={PHONE_NUMBER_ENTRY}
        component={PhoneNumberEntryScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.phoneEntry') }}
      />
      <Home.Screen
        name={PIN_ENTRY_LOGIN}
        component={PinEntryLoginScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.pinEntry') }}
      />
      <Home.Screen
        name={LINK_YOUR_ACCOUNT}
        component={LinkYourAccountScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.linkYourAccount') }}
      />
      <Home.Screen
        name={EMAIL_VERIFICATION_CODE_ENTRY}
        component={EmailVerificationCodeEntryScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.emailVerificationCode') }}
      />
      <Home.Screen
        name={EMAIL_VERIFICATION}
        component={EmailVerificationScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.emailVerification') }}
      />
      <Home.Screen
        name={PHONE_NUMBER_UPDATE}
        component={PhoneNumberUpdateScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.updatePhoneNumber') }}
      />
      <Home.Screen
        name={GENERIC_ERROR}
        component={GenericErrorScreen}
        options={{ ...tabBarOptions, title: localized('pageTitle.genericError') }}
      />
      <Home.Screen
        name={BOTTOM_NAVIGATOR}
        component={BottomNavigator}
        options={{ header: () => null, title: localized('pageTitle.genericError') }}
      />
    </Home.Navigator>
  )
}

const tabBarOptions = {
  header: () => <NavBar />,
}

const tabBarOptionsEntry = {
  header: () => <NavBar hideOnMbile={true} lightColor={purpleJelly10} />,
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const Home = createStackNavigator<HomeParamList>()
