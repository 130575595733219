import { useContext } from 'react'
import { Image, StyleSheet, Text } from 'react-native'
import * as Linking from 'expo-linking'
import Box from '../../components/atoms/Box'
import Button from '../../components/atoms/Button'
import Heading from '../../components/atoms/Typography/Heading'
import Footer from '../../components/molucules/Footer'
import NavBar from '../../components/molucules/NavBar'
import colors from '../../components/tokens/colors'
import { darkest, whiteOpacity9 } from '../../components/tokens/colors/colors'
import { DimensionContext, ViewPortType } from '../../providers/DimensionProvider'
import { Theme, ThemeContext } from '../../providers/ThemeProvider'

const assignBlobsSource = (viewPort: ViewPortType, theme: Theme) => {
  const isDark = theme === 'dark'
  if (viewPort === 'xs' || viewPort === 'sm') {
    return isDark
      ? require('../../assets/images/404-xs-dark.png')
      : require('../../assets/images/404-xs-light.png')
  }
  if (viewPort === 'md' || viewPort === 'lg') {
    return isDark
      ? require('../../assets/images/404-md-dark.png')
      : require('../../assets/images/404-md-light.png')
  }
  return isDark
    ? require('../../assets/images/404-xl-dark.png')
    : require('../../assets/images/404-xl-light.png')
}

const background = colors['--ui-primary-active']

const NotFoundScreen = () => {
  const { viewPort } = useContext(DimensionContext)
  const { theme } = useContext(ThemeContext)
  const blobsSource = assignBlobsSource(viewPort, theme)
  return (
    <>
      <NavBar />
      <Box style={styles.container} backgroundLight={background} backgroundDark={darkest}>
        <Image
          source={blobsSource}
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'cover',
            position: 'absolute',
          }}
        />
        <Heading
          style={styles.margin16}
          lightColor={whiteOpacity9}
          darkColor={whiteOpacity9}
          variant="--heading-4">
          Error 404
        </Heading>
        <Heading style={styles.margin16} lightColor={whiteOpacity9} darkColor={whiteOpacity9}>
          Oops! This page has gone dark
        </Heading>
        <Box marginBottom={[24, 24, 32, 32, 32]}>
          <Text style={[styles.text, styles[`text-${viewPort}`]]}>Where do we go from here?</Text>
        </Box>
        <Box style={styles[`buttonsWrapper-${viewPort}`]}>
          <Box marginRight={[0, 0, 24, 24, 24]} marginBottom={[24, 24, 0, 0, 0]}>
            <Button onPress={() => Linking.openURL('https://paybright.com/en/')}>
              Return home
            </Button>
          </Box>
          <Button
            onPress={() => Linking.openURL('https://help.paybright.com/hc/en-us')}
            inverse={true}>
            Need help?
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default NotFoundScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 80,
  },
  margin16: {
    marginBottom: 16,
    textAlign: 'center',
  },
  margin24: {
    marginBottom: 24,
  },
  text: {
    fontFamily: 'Calibre',
    color: whiteOpacity9,
  },
  'text-xs': {
    fontSize: 18,
  },
  'text-sm': {
    fontSize: 18,
  },
  'text-md': {
    fontSize: 20,
  },
  'text-lg': {
    fontSize: 22,
  },
  'text-xl': {
    fontSize: 24,
  },
  'buttonsWrapper-xs': {
    flexDirection: 'column',
  },
  'buttonsWrapper-sm': {
    flexDirection: 'column',
  },
  'buttonsWrapper-md': {
    flexDirection: 'row',
  },
  'buttonsWrapper-lg': {
    flexDirection: 'row',
  },
  'buttonsWrapper-xl': {
    flexDirection: 'row',
  },
})
