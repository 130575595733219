import { useContext } from 'react'
import { Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Props from './shared'
import { ContentContext } from '../../../../providers/ContentProvider'

const HorizontalLogoEN = ({ color, width = '196', height = '28' }: Props) => {
  const isWeb = Platform.OS === 'web'
  const { localized } = useContext(ContentContext)
  const titleText = localized('entry.SVGTitle')
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 196 28"
      fill="none"
      aria-labelledby="web--svg_horizontal_logo_title">
      {isWeb ? <title id="web--svg_horizontal_logo_title">{titleText}</title> : null}
      <Path
        d="M23.2503 6.44714C25.1752 6.44714 26.7822 7.31952 27.4533 8.54861V7.4396C27.4503 7.36088 27.4636 7.2824 27.4924 7.20906C27.5212 7.13573 27.5648 7.06912 27.6205 7.01342C27.6762 6.95772 27.7428 6.91412 27.8161 6.88535C27.8894 6.85659 27.9679 6.84327 28.0466 6.84625H30.5543C30.6329 6.84433 30.7111 6.85834 30.7841 6.88743C30.8572 6.91652 30.9236 6.9601 30.9793 7.01554C31.0351 7.07097 31.0791 7.13712 31.1086 7.21C31.1381 7.28288 31.1526 7.36098 31.1512 7.4396V20.059C31.1526 20.1378 31.1381 20.216 31.1086 20.2891C31.0792 20.3622 31.0352 20.4285 30.9795 20.4843C30.9238 20.54 30.8574 20.5839 30.7844 20.6134C30.7113 20.6429 30.633 20.6573 30.5543 20.6559H28.0466C27.968 20.6573 27.8899 20.6429 27.817 20.6133C27.7442 20.5838 27.678 20.5398 27.6226 20.4841C27.5671 20.4283 27.5236 20.3619 27.4945 20.2888C27.4654 20.2158 27.4514 20.1376 27.4533 20.059V18.9712C26.7186 20.2179 25.1752 21.0691 23.215 21.0691C19.6302 21.0691 16.3208 18.2189 16.3208 13.7581C16.3208 9.29737 19.6266 6.44714 23.2503 6.44714ZM23.766 17.622C25.9063 17.622 27.4533 15.8984 27.4533 13.7369C27.4533 11.5754 25.9063 9.87307 23.766 9.87307C23.2682 9.87855 22.7765 9.98269 22.3193 10.1795C21.862 10.3762 21.4483 10.6617 21.1021 11.0194C20.7559 11.3771 20.4841 11.7999 20.3024 12.2633C20.1207 12.7267 20.0326 13.2216 20.0434 13.7193C20.0434 15.8843 21.6681 17.622 23.766 17.622Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M9.00991 6.44714C7.04972 6.44714 5.47803 7.31952 4.67276 8.54861V7.4396C4.67534 7.35978 4.66127 7.28029 4.63147 7.20619C4.60166 7.1321 4.55676 7.06501 4.49963 7.00921C4.44249 6.9534 4.37437 6.9101 4.29959 6.88204C4.22482 6.85398 4.14502 6.84179 4.06528 6.84625H1.59297C1.51465 6.84481 1.43685 6.85917 1.36421 6.88848C1.29157 6.91779 1.22559 6.96145 1.1702 7.01684C1.11481 7.07222 1.07116 7.13821 1.04185 7.21084C1.01254 7.28348 0.998172 7.36129 0.99961 7.4396V25.9043C0.998172 25.9826 1.01254 26.0604 1.04185 26.133C1.07116 26.2057 1.11481 26.2716 1.1702 26.327C1.22559 26.3824 1.29157 26.4261 1.36421 26.4554C1.43685 26.4847 1.51465 26.4991 1.59297 26.4976H4.11119C4.18951 26.4991 4.26731 26.4847 4.33995 26.4554C4.41259 26.4261 4.47857 26.3824 4.53396 26.327C4.58935 26.2716 4.633 26.2057 4.66231 26.133C4.69162 26.0604 4.70599 25.9826 4.70455 25.9043V25.7277L4.7434 19.1089C5.57692 20.3557 7.30048 21.0303 8.98165 21.0303C12.6266 21.0303 15.8335 18.1765 15.8335 13.7193C15.8335 9.26205 12.6513 6.44714 9.00991 6.44714ZM8.41302 17.622C6.2939 17.622 4.68689 15.8984 4.68689 13.7369C4.66792 13.2352 4.75017 12.7348 4.92874 12.2655C5.10731 11.7962 5.37854 11.3677 5.72626 11.0054C6.07397 10.6432 6.49106 10.3547 6.95266 10.1571C7.41426 9.95956 7.91092 9.85694 8.41302 9.85541C10.5533 9.85541 12.0968 11.579 12.0968 13.7193C12.0968 15.8596 10.5533 17.622 8.41302 17.622Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M45.3245 6.86396H42.6296C42.4567 6.86159 42.2883 6.92006 42.1541 7.02916C42.0198 7.13825 41.9281 7.29104 41.895 7.46085L40.726 11.6002L39.0024 16.9439L36.1098 7.45378C36.0942 7.29906 36.0243 7.15487 35.9124 7.04686C35.8005 6.93886 35.654 6.87405 35.4988 6.86396H32.5673C31.9139 6.86396 31.8327 7.28072 31.974 7.75753L36.1522 20.0414C36.1919 20.2075 36.2855 20.3559 36.4184 20.4632C36.5513 20.5705 36.716 20.6309 36.8868 20.6347H37.8757L35.9544 25.6076C35.7566 26.1621 35.9155 26.4977 36.4701 26.4977H39.1649C39.3375 26.5009 39.5058 26.4432 39.6402 26.3347C39.7745 26.2262 39.8663 26.0738 39.8995 25.9043L40.0761 25.2509L41.7608 20.6595L45.9002 7.80344C46.0379 7.20302 45.8578 6.86396 45.3245 6.86396Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M70.3869 6.62715C70.7048 6.62715 71.0403 6.76489 71.0403 7.36178V9.83409C71.0484 9.93021 71.036 10.027 71.0037 10.1179C70.9715 10.2088 70.9202 10.2918 70.8534 10.3613C70.7865 10.4308 70.7056 10.4853 70.616 10.5211C70.5264 10.5568 70.4303 10.5731 70.3339 10.5687H69.6275C67.8051 10.5687 66.7349 11.4976 66.7349 13.8746V20.0589C66.7326 20.1343 66.7155 20.2086 66.6844 20.2774C66.6532 20.3462 66.6088 20.4081 66.5537 20.4597C66.4985 20.5112 66.4337 20.5513 66.363 20.5777C66.2923 20.6041 66.217 20.6162 66.1416 20.6134H63.6269C63.5497 20.6129 63.4735 20.5972 63.4025 20.567C63.3315 20.5369 63.2672 20.4929 63.2133 20.4377C63.1594 20.3825 63.117 20.3172 63.0886 20.2455C63.0602 20.1738 63.0462 20.0971 63.0476 20.02V7.37944C63.0452 7.30055 63.0589 7.22199 63.0881 7.14865C63.1173 7.0753 63.1612 7.00874 63.2172 6.95309C63.2732 6.89745 63.34 6.85391 63.4135 6.82518C63.487 6.79644 63.5657 6.78314 63.6445 6.78608H66.0215C66.1736 6.78131 66.3214 6.83697 66.4326 6.94088C66.5438 7.04479 66.6093 7.1885 66.6148 7.34059V8.64738C66.9449 8.03503 67.4334 7.52255 68.0292 7.16346C68.625 6.80436 69.3063 6.61182 70.0019 6.60596C70.1294 6.62397 70.2582 6.63106 70.3869 6.62715Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M71.4185 2.66446C71.422 2.21123 71.5593 1.76915 71.8133 1.39376C72.0673 1.01837 72.4265 0.726412 72.8459 0.55458C73.2654 0.382749 73.7262 0.338704 74.1705 0.427984C74.6149 0.517263 75.023 0.735883 75.3434 1.05637C75.6639 1.37686 75.8826 1.78492 75.9718 2.22928C76.0611 2.67364 76.0171 3.13447 75.8452 3.55388C75.6734 3.97328 75.3815 4.33255 75.0061 4.58653C74.6307 4.84051 74.1886 4.97788 73.7354 4.98137C73.1209 4.98137 72.5316 4.73727 72.0971 4.30276C71.6626 3.86826 71.4185 3.27894 71.4185 2.66446ZM72.478 20.5958C72.3993 20.5963 72.3213 20.5812 72.2485 20.5514C72.1757 20.5216 72.1095 20.4777 72.0537 20.4222C71.9979 20.3668 71.9536 20.3008 71.9234 20.2281C71.8932 20.1555 71.8776 20.0776 71.8776 19.9989V7.37952C71.8746 7.3008 71.8879 7.22231 71.9167 7.14898C71.9455 7.07564 71.9891 7.00904 72.0448 6.95334C72.1005 6.89763 72.1671 6.85403 72.2404 6.82527C72.3138 6.7965 72.3922 6.78319 72.471 6.78616H74.9433C75.022 6.78319 75.1005 6.7965 75.1738 6.82527C75.2471 6.85403 75.3137 6.89763 75.3694 6.95334C75.4251 7.00904 75.4687 7.07564 75.4975 7.14898C75.5263 7.22231 75.5396 7.3008 75.5366 7.37952V19.9601C75.5331 20.1166 75.4695 20.2659 75.3591 20.377C75.2487 20.488 75.0998 20.5525 74.9433 20.5569H72.471L72.478 20.5958Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M119.741 18.5544L118.869 17.1417C118.629 16.7638 118.162 16.7638 117.76 17.0428C117.371 17.3028 116.917 17.4462 116.449 17.456C115.796 17.456 114.867 17.0428 114.867 15.6901V9.85542H118.869C119.025 9.85183 119.174 9.78816 119.284 9.67765C119.395 9.56714 119.459 9.4183 119.462 9.26206V7.36191C119.462 7.20422 119.4 7.05293 119.289 6.94109C119.177 6.82926 119.026 6.76596 118.869 6.76503H114.867V3.91127C114.87 3.83144 114.856 3.75195 114.826 3.67786C114.796 3.60376 114.751 3.53668 114.694 3.48087C114.637 3.42507 114.569 3.38176 114.494 3.35371C114.419 3.32565 114.339 3.31346 114.26 3.31791H111.745C111.666 3.3144 111.587 3.32735 111.514 3.35592C111.44 3.3845 111.373 3.42808 111.318 3.4839C111.262 3.53971 111.218 3.60653 111.19 3.68011C111.161 3.75369 111.148 3.83241 111.152 3.91127V6.80388H109.248C109.17 6.80382 109.092 6.81957 109.02 6.85017C108.948 6.88077 108.883 6.92561 108.828 6.98198C108.774 7.03836 108.731 7.10513 108.703 7.1783C108.675 7.25147 108.662 7.32953 108.665 7.40783V9.30798C108.663 9.38659 108.677 9.46477 108.706 9.53782C108.735 9.61088 108.779 9.67729 108.834 9.73305C108.89 9.78882 108.956 9.83279 109.029 9.86232C109.102 9.89184 109.18 9.90632 109.259 9.90486H111.162V16.5519C111.162 19.6634 113.479 20.8925 115.736 20.8925C117.322 20.8925 118.728 20.4157 119.639 19.5045C119.772 19.3878 119.856 19.2254 119.875 19.0494C119.894 18.8735 119.846 18.6968 119.741 18.5544Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M88.2369 20.557V18.731C87.5305 19.8435 85.9977 20.6947 84.0587 20.6947C80.2725 20.6947 77.0833 17.9999 77.0833 13.5603C77.0833 9.12077 80.3326 6.39062 83.9174 6.39062C85.8812 6.39062 87.5447 7.28066 88.2369 8.48856V7.37955C88.2355 7.30123 88.2499 7.22343 88.2792 7.15079C88.3085 7.07816 88.3521 7.01217 88.4075 6.95678C88.4629 6.9014 88.5289 6.85774 88.6015 6.82843C88.6741 6.79912 88.752 6.78476 88.8303 6.7862H91.3026C91.3813 6.78322 91.4598 6.79654 91.5331 6.8253C91.6065 6.85407 91.6731 6.89767 91.7288 6.95337C91.7845 7.00907 91.8281 7.07568 91.8568 7.14901C91.8856 7.22235 91.8989 7.30083 91.8959 7.37955V20.6559C91.8959 24.4774 89.0704 27.1333 84.479 27.1333C80.7952 27.1333 78.1004 24.7952 77.447 22.1605C77.4219 22.1038 77.4092 22.0425 77.4097 21.9805C77.4102 21.9186 77.424 21.8574 77.4501 21.8012C77.4761 21.745 77.514 21.6951 77.561 21.6547C77.6079 21.6143 77.663 21.5844 77.7225 21.5671H80.4385C80.7917 21.5671 80.993 21.606 81.0919 21.8037C81.4063 22.835 82.4764 23.9052 84.539 23.9052C87.0643 23.9617 88.2369 22.6161 88.2369 20.557ZM84.532 17.4242C86.6723 17.4242 88.2193 15.7995 88.2193 13.6416C88.2193 11.4836 86.6723 9.8554 84.532 9.8554C84.0382 9.85077 83.5487 9.94631 83.0929 10.1363C82.6371 10.3262 82.2246 10.6066 81.8802 10.9605C81.5359 11.3144 81.2669 11.7345 81.0895 12.1953C80.9121 12.6561 80.8301 13.1481 80.8482 13.6416C80.8482 15.7995 82.4128 17.4242 84.532 17.4242Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M55.1717 6.34823C53.2079 6.34823 51.5833 7.2418 50.891 8.38966V5.47939L50.8522 1.57313C50.8477 1.41658 50.7833 1.26772 50.6722 1.1573C50.5611 1.04688 50.4119 0.983335 50.2553 0.979778H47.783C47.7043 0.976804 47.6258 0.990118 47.5524 1.01888C47.4791 1.04765 47.4125 1.09125 47.3568 1.14695C47.3011 1.20266 47.2575 1.26926 47.2287 1.34259C47.2 1.41593 47.1866 1.49441 47.1896 1.57313L47.2285 5.47939V19.9601C47.2265 20.0387 47.2406 20.1169 47.2696 20.1899C47.2987 20.263 47.3423 20.3294 47.3978 20.3852C47.4532 20.4409 47.5193 20.4849 47.5922 20.5144C47.6651 20.544 47.7432 20.5584 47.8218 20.557H50.2941C50.3726 20.5579 50.4505 20.5431 50.5232 20.5134C50.5958 20.4837 50.6618 20.4397 50.7171 20.3841C50.7724 20.3284 50.816 20.2622 50.8453 20.1894C50.8746 20.1165 50.8889 20.0386 50.8875 19.9601V18.8511C51.6786 20.1014 53.1656 20.9525 55.1257 20.9525C58.753 20.9525 62.0235 18.0988 62.0235 13.6416C62.0235 9.18433 58.7353 6.34823 55.1717 6.34823ZM54.596 17.5619C52.4945 17.5619 50.9122 15.8384 50.9122 13.6769C50.9122 11.5154 52.4945 9.813 54.596 9.813C55.0939 9.8185 55.5858 9.92262 56.0432 10.1193C56.5007 10.3161 56.9146 10.6015 57.261 10.9591C57.6075 11.3168 57.8797 11.7395 58.0618 12.203C58.2439 12.6664 58.3324 13.1614 58.3221 13.6592C58.2797 15.8207 56.6904 17.5619 54.596 17.5619Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M102.326 6.34828C100.125 6.34828 98.5394 7.28069 97.7342 8.3897V1.55552C97.7286 1.40343 97.6631 1.25972 97.5519 1.15581C97.4407 1.0519 97.2929 0.996247 97.1408 1.00102H94.6226C94.5443 0.999578 94.4665 1.01394 94.3938 1.04325C94.3212 1.07256 94.2552 1.11622 94.1998 1.1716C94.1444 1.22699 94.1008 1.29297 94.0715 1.36561C94.0422 1.43825 94.0278 1.51606 94.0292 1.59437V19.9601C94.0273 20.0387 94.0413 20.1169 94.0704 20.19C94.0995 20.263 94.1431 20.3294 94.1985 20.3852C94.2539 20.441 94.3201 20.4849 94.393 20.5145C94.4659 20.544 94.544 20.5585 94.6226 20.557H97.1408C97.2933 20.5608 97.4411 20.5044 97.5521 20.3999C97.6632 20.2954 97.7286 20.1514 97.7342 19.999V13.3449C97.7342 11.2046 99.1187 9.75654 101.181 9.75654C103.322 9.75654 104.53 11.1693 104.53 13.3449V19.999C104.532 20.0746 104.549 20.149 104.58 20.218C104.611 20.287 104.655 20.3491 104.711 20.401C104.766 20.4528 104.83 20.4933 104.901 20.52C104.972 20.5468 105.047 20.5594 105.123 20.557H107.595C107.671 20.5594 107.746 20.5468 107.817 20.52C107.888 20.4933 107.952 20.4528 108.008 20.401C108.063 20.3491 108.107 20.287 108.138 20.218C108.169 20.149 108.186 20.0746 108.189 19.999V12.3525C108.256 8.92655 105.971 6.34828 102.326 6.34828Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M130.1 10.5476H131.255V14.2384H131.351C131.545 13.8341 131.855 13.4962 132.241 13.2669C132.626 13.0376 133.071 12.9271 133.519 12.9493C135.412 12.9493 136.719 14.4539 136.719 16.8132C136.719 19.1725 135.412 20.6982 133.54 20.6982C133.088 20.717 132.64 20.6029 132.251 20.3701C131.863 20.1373 131.551 19.7958 131.354 19.3879H131.213V20.5393H130.1V10.5476ZM133.381 19.6669C134.826 19.6669 135.568 18.3954 135.568 16.7955C135.568 15.1956 134.861 13.97 133.381 13.97C131.969 13.97 131.234 15.1037 131.234 16.7955C131.234 18.4873 131.997 19.6669 133.381 19.6669Z"
        fill={color || 'black'}
      />
      <Path
        d="M138.11 23.1987L138.404 22.1851C139.244 22.4005 139.77 22.291 140.17 21.1679L140.396 20.5816L137.623 13.0587H138.873L140.943 19.0311H141.021L143.09 13.0587H144.348L141.169 21.6306C140.738 22.7784 140 23.3294 138.962 23.3294C138.672 23.3416 138.383 23.2972 138.11 23.1987Z"
        fill={color || 'black'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.532 19.2113C150.967 19.2113 150.688 18.9358 150.688 18.4802C150.688 17.6325 151.634 17.3429 153.361 17.1592C153.361 18.293 152.599 19.2113 151.532 19.2113ZM152.277 12.8539C151.039 12.8972 149.844 13.3146 148.848 14.0512L149.554 15.5346C150.247 14.8999 151.138 14.5254 152.076 14.475C152.934 14.475 153.407 14.7611 153.407 15.3368C153.407 15.7288 153.093 15.9231 152.5 16.0008C150.282 16.2904 148.544 16.8979 148.544 18.6073C148.544 19.9635 149.508 20.7829 151.016 20.7829C151.513 20.7819 152 20.6538 152.433 20.4108C152.866 20.1678 153.229 19.8181 153.489 19.3949V20.5604H155.491V15.6758C155.491 13.6591 154.078 12.8503 152.263 12.8503"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.395 13.0587V20.5569H174.539V16.9438C174.539 15.2238 175.598 14.7223 176.305 14.7223C176.629 14.7193 176.948 14.8127 177.219 14.9907L177.611 13.0093C177.306 12.8883 176.979 12.8295 176.651 12.8362C176.166 12.8099 175.686 12.9393 175.281 13.2056C174.875 13.4719 174.566 13.8611 174.397 14.3161V13.0587H172.395Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.554 12.8398C187.073 12.8475 186.602 12.9713 186.18 13.2007C185.758 13.4301 185.398 13.7583 185.131 14.1572C184.896 13.7548 184.559 13.4215 184.154 13.1912C183.749 12.9609 183.29 12.8417 182.824 12.8456C182.358 12.8495 181.901 12.9765 181.5 13.2137C181.099 13.4508 180.767 13.7897 180.539 14.1961V13.0588H178.473V20.557H180.617V16.7002C180.617 15.3122 181.345 14.6482 182.03 14.6482C182.644 14.6482 183.209 15.0473 183.209 16.0609V20.557H185.35V16.7002C185.35 15.2874 186.056 14.6482 186.762 14.6482C187.328 14.6482 187.924 15.0614 187.924 16.0609V20.557H190.068V15.3757C190.068 13.691 188.935 12.8398 187.564 12.8398"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.175 13.0589H165.232V12.2995C165.232 11.307 165.801 11.021 166.292 11.021C166.625 11.0246 166.953 11.1069 167.249 11.2611L167.913 9.74949C167.334 9.437 166.681 9.28576 166.023 9.31154C164.653 9.31154 163.092 10.085 163.092 12.4902V13.0589H159.843V12.2995C159.843 11.307 160.408 11.021 160.902 11.021C161.237 11.022 161.567 11.1044 161.863 11.2611L162.523 9.74949C161.943 9.4402 161.291 9.28916 160.634 9.31154C159.263 9.31154 157.706 10.085 157.706 12.4902V13.0589H156.463V14.7153H157.702V20.557H159.846V14.7153H163.096V20.557H165.239V14.7153H167.182L167.175 13.0589Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.453 20.557H170.597V13.0588H168.453V20.557Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.946 2.60093C175.157 2.60093 169.997 6.6202 168.535 11.7838H170.633C171.855 7.93406 176.005 4.55759 180.946 4.55759C186.95 4.55759 192.135 9.12784 192.135 16.2446C192.152 17.7026 191.95 19.155 191.538 20.5535H193.572L193.593 20.4828C193.931 19.0933 194.094 17.6673 194.081 16.2375C194.081 8.30138 188.299 2.59033 180.932 2.59033"
        fill={color || '#4A4AF4'}
      />
    </Svg>
  )
}
export default HorizontalLogoEN
