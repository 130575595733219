import { forwardRef, useContext, useState, useEffect, useCallback } from 'react'
import { TextField, FormHelperText, InputAdornment } from '@material-ui/core'
import { ThemeContext, DARK } from '../../../providers/ThemeProvider'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import InputMask from 'react-input-mask'
import { TextInputProps } from 'react-native-paper/lib/typescript/src/components/TextInput/TextInput'
import colors from '../../tokens/colors'
import './input.css'
import StyledFormControl from './StyledFormControl'
import { Error } from '../Icons/index'

const Input = forwardRef(
  (
    {
      type,
      testID,
      label,
      value,
      defaultValue,
      autoFocus,
      format,
      placeholder,
      error,
      helperText,
      disabled = false,
      multiline = false,
      maskProps = { type: 'only-numbers', options: { mask: '' } },
      maskChar = ' ',
      disableMask = false,
      required,
      errorMessage,
      onChangeText,
      startIcon,
      endIcon,
      style,
      ...otherProps
    }: TextInputProps,
    ref
  ) => {
    const showHelperText = error ? errorMessage : helperText

    const [hasBeenFocused, setHasBeenFocused] = useState(false)
    const { theme } = useContext(ThemeContext)
    const navigation = useNavigation()
    const isDark = theme === DARK

    const resetInput = () => {
      value = ''
      onChangeText(value)
    }

    const inputFocusCallback = useCallback(() => {
      ref?.current?.focus()
    }, [ref])

    useEffect(() => {
      if (!autoFocus) return
      navigation.addListener('focus', inputFocusCallback)
      return () => {
        if (autoFocus) navigation.removeListener('focus', inputFocusCallback)
      }
    }, [navigation, inputFocusCallback, autoFocus])

    const inputIcons = {
      startAdornment: startIcon ? (
        <InputAdornment position="start">{startIcon}</InputAdornment>
      ) : null,
      endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
    }

    const formClassNames = `${isDark ? 'is-dark' : ''} ${error ? 'Mui-error' : ''}`

    const newPropsWithDeletedProperties = (props, obj) => {
      const objCopy = { ...obj }
      props.forEach(prop => (objCopy.hasOwnProperty(prop) ? delete objCopy[prop] : null))
      return objCopy
    }

    const modifiedProps = newPropsWithDeletedProperties(
      [
        'keyboardType',
        'returnKeyType',
        'enablesReturnKeyAutomatically',
        'onSubmitEditing',
        'textContentType',
      ],
      otherProps
    )

    return (
      <View style={style} className={isDark ? 'is-dark' : ''}>
        <StyledFormControl style={style} fullWidth className={formClassNames}>
          {disableMask ? (
            <TextField
              {...modifiedProps}
              label={label}
              autoFocus={autoFocus}
              id={testID}
              defaultValue={defaultValue}
              disabled={disabled}
              margin="normal"
              InputProps={inputIcons}
              placeholder={placeholder}
              value={value}
              variant="outlined"
              inputRef={ref}
              onChange={e => onChangeText(e.target.value)}
              error={hasBeenFocused && error}
              onBlur={() => {
                hasBeenFocused || setHasBeenFocused(true)
              }}
            />
          ) : (
            <InputMask
              mask={maskProps.options.mask}
              onChange={e => onChangeText(e.target.value)}
              onBlur={e => {
                hasBeenFocused || setHasBeenFocused(true)
                onChangeText(e.target.value.trim())
              }}
              value={value}
              disabled={disabled}
              maskChar={maskChar}
              {...(maskProps?.options?.formatChars
                ? { formatChars: maskProps.options.formatChars }
                : {})}>
              {() => (
                <TextField
                  {...modifiedProps}
                  label={label}
                  autoFocus={autoFocus}
                  id={testID}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  multiline={multiline}
                  margin="normal"
                  InputProps={inputIcons}
                  placeholder={placeholder}
                  value={value}
                  variant="outlined"
                  inputRef={ref}
                  error={hasBeenFocused && error}
                />
              )}
            </InputMask>
          )}
          {hasBeenFocused && (
            <FormHelperText>
              <span className="error-icon">
                <Error color={colors['--text-error']} />
              </span>
              {showHelperText}
            </FormHelperText>
          )}
        </StyledFormControl>
      </View>
    )
  }
)

export default Input
