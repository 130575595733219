import { useContext } from 'react'
import { Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Props from './shared'
import { ContentContext } from '../../../../providers/ContentProvider'

const PayBrightByAffirmFR = ({ width = '138.84', height = '52' }: Props) => {
  const isWeb = Platform.OS === 'web'
  const { localized } = useContext(ContentContext)
  const titleText = localized('entry.SVGTitle')
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 139 52"
      fill="none"
      aria-labelledby="web--svg_paybright_by_affirm_fr_title">
      {isWeb ? <title id="web--svg_paybright_by_affirm_fr_title">{titleText}</title> : null}
      <Path
        d="M26.1502 7.06372C28.3947 7.06372 30.2729 8.09038 31.0605 9.53325V8.22912C31.0572 8.13608 31.0729 8.04336 31.1068 7.95674C31.1407 7.87011 31.1919 7.79143 31.2573 7.7256C31.3227 7.65977 31.4008 7.60821 31.4869 7.57413C31.5729 7.54005 31.665 7.52417 31.7574 7.5275H34.6989C34.7907 7.52587 34.8818 7.54294 34.9669 7.57767C35.052 7.61241 35.1292 7.66409 35.1939 7.72962C35.2587 7.79516 35.3096 7.87318 35.3436 7.95902C35.3776 8.04485 35.3941 8.13673 35.3919 8.22912V23.0859C35.3941 23.1783 35.3776 23.2702 35.3436 23.356C35.3096 23.4419 35.2587 23.5199 35.1939 23.5854C35.1292 23.651 35.052 23.7026 34.9669 23.7374C34.8818 23.7721 34.7907 23.7892 34.6989 23.7875H31.7574C31.6655 23.7892 31.5741 23.7721 31.4888 23.7374C31.4036 23.7027 31.3261 23.6511 31.2611 23.5856C31.196 23.5202 31.1447 23.4422 31.1103 23.3563C31.0758 23.2705 31.0589 23.1785 31.0605 23.0859V21.8016C30.2021 23.2722 28.3947 24.2751 26.1029 24.2751C21.9093 24.2751 18.0386 20.9177 18.0386 15.6694C18.0386 10.4212 21.9093 7.06372 26.1502 7.06372ZM26.7527 20.216C29.2531 20.216 31.0605 18.1865 31.0605 15.6218C31.0605 13.0572 29.2531 11.0752 26.7527 11.0752C26.1686 11.0813 25.5916 11.2043 25.0552 11.4369C24.5188 11.6696 24.0336 12.0072 23.628 12.4303C23.2224 12.8534 22.9044 13.3534 22.6925 13.9013C22.4806 14.4492 22.379 15.0341 22.3936 15.6218C22.3936 18.1865 24.2955 20.216 26.7527 20.216Z"
        fill="white"
      />
      <Path
        d="M9.49115 7.06372C7.19548 7.06372 5.34477 8.09038 4.41548 9.53325V8.22912C4.41879 8.13661 4.40312 8.0444 4.36946 7.95825C4.33581 7.8721 4.28488 7.79386 4.21985 7.7284C4.15483 7.66294 4.0771 7.61167 3.99152 7.57779C3.90594 7.54391 3.81435 7.52814 3.72245 7.53146H0.82432C0.732531 7.52984 0.641363 7.5469 0.556291 7.58164C0.471218 7.61637 0.393999 7.66806 0.329268 7.73359C0.264538 7.79912 0.213632 7.87715 0.179611 7.96298C0.14559 8.04882 0.129154 8.14069 0.131291 8.23308V29.9673C0.129135 30.0595 0.145585 30.1512 0.17965 30.2368C0.213715 30.3225 0.264684 30.4002 0.329473 30.4655C0.394261 30.5307 0.471522 30.582 0.556579 30.6163C0.641636 30.6506 0.732721 30.6671 0.82432 30.665H3.7697C3.8613 30.6671 3.95239 30.6506 4.03744 30.6163C4.1225 30.582 4.19976 30.5307 4.26455 30.4655C4.32934 30.4002 4.38031 30.3225 4.41437 30.2368C4.44844 30.1512 4.46489 30.0595 4.46274 29.9673V29.7572L4.50999 21.9681C5.48259 23.4348 7.49868 24.2275 9.46752 24.2275C13.732 24.2275 17.4846 20.8701 17.4846 15.6218C17.4846 10.3736 13.7557 7.06372 9.49115 7.06372ZM8.79418 20.216C6.34101 20.216 4.43911 18.1865 4.43911 15.6218C4.43911 13.0572 6.29376 11.0752 8.79418 11.0752C11.2946 11.0752 13.1256 13.1048 13.1256 15.6218C13.1256 18.1389 11.2985 20.216 8.79418 20.216Z"
        fill="white"
      />
      <Path
        d="M51.9773 7.55115H48.8272C48.6254 7.54768 48.4289 7.61622 48.2725 7.74464C48.1161 7.87306 48.0099 8.05307 47.9727 8.25277L46.6024 13.1244L44.5706 19.4231L41.1841 8.25277C41.1638 8.07039 41.0813 7.90076 40.9506 7.77281C40.8199 7.64486 40.6492 7.56652 40.4675 7.55115H37.0378C36.2739 7.55115 36.1794 8.04268 36.3447 8.60159L41.2314 23.062C41.2768 23.2583 41.3858 23.4338 41.5412 23.5608C41.6967 23.6878 41.8897 23.7592 42.0898 23.7636H43.2475L40.9991 29.6184C40.7707 30.2684 40.9558 30.6648 41.6015 30.6648H44.7517C44.9531 30.6693 45.1496 30.6016 45.3061 30.4738C45.4626 30.346 45.5689 30.1665 45.6062 29.9672L45.8149 29.1982L47.7837 23.8072L52.627 8.67294C52.7924 7.94754 52.5837 7.55115 51.9773 7.55115Z"
        fill="white"
      />
      <Path
        d="M81.2697 7.27378C81.6398 7.27378 82.0336 7.4363 82.0336 8.13396V11.0276C82.0437 11.1396 82.0301 11.2525 81.9935 11.3587C81.957 11.465 81.8984 11.5622 81.8217 11.6439C81.745 11.7257 81.6519 11.79 81.5486 11.8328C81.4452 11.8755 81.3341 11.8956 81.2224 11.8918H80.4113C78.281 11.8918 77.0288 12.9858 77.0288 15.7843V23.0859C77.0227 23.265 76.9463 23.4344 76.8164 23.5569C76.6865 23.6795 76.5137 23.7453 76.3358 23.74H73.3904C73.2986 23.7416 73.2074 23.7245 73.1224 23.6898C73.0373 23.655 72.9601 23.6034 72.8954 23.5378C72.8306 23.4723 72.7797 23.3943 72.7457 23.3084C72.7117 23.2226 72.6952 23.1307 72.6974 23.0383V8.15774C72.6935 8.06508 72.7087 7.9726 72.7422 7.88619C72.7756 7.79977 72.8265 7.72128 72.8917 7.6557C72.9568 7.59012 73.0348 7.53887 73.1207 7.50519C73.2065 7.47152 73.2984 7.45616 73.3904 7.46009H76.1704C76.2587 7.45637 76.3468 7.47028 76.4296 7.50099C76.5125 7.53171 76.5885 7.57864 76.6533 7.63907C76.7181 7.69949 76.7704 7.77223 76.8071 7.85308C76.8439 7.93394 76.8643 8.02131 76.8674 8.11017V9.65214C77.2523 8.93037 77.8234 8.32636 78.5205 7.90365C79.2175 7.48093 80.0149 7.25513 80.8287 7.25C80.9748 7.26979 81.1223 7.27774 81.2697 7.27378V7.27378Z"
        fill="white"
      />
      <Path
        d="M82.4746 2.60837C82.4785 2.07462 82.639 1.55393 82.9361 1.11175C83.2331 0.669579 83.6533 0.325649 84.1439 0.123196C84.6345 -0.0792565 85.1736 -0.1312 85.6935 -0.0261045C86.2133 0.0789908 86.6907 0.336436 87.0657 0.713869C87.4406 1.0913 87.6964 1.57189 87.8008 2.09521C87.9052 2.61853 87.8536 3.16124 87.6525 3.65513C87.4513 4.14902 87.1097 4.57205 86.6704 4.87103C86.2312 5.17002 85.7139 5.33164 85.1837 5.33556C84.4655 5.33451 83.7771 5.04684 83.2692 4.53563C82.7614 4.02441 82.4757 3.33135 82.4746 2.60837ZM83.7268 23.7163C83.6353 23.7163 83.5446 23.6982 83.4601 23.6629C83.3755 23.6276 83.2987 23.576 83.234 23.5108C83.1692 23.4457 83.1179 23.3683 83.0829 23.2832C83.0478 23.1981 83.0298 23.1068 83.0298 23.0147V8.15788C83.0265 8.06502 83.0423 7.97247 83.0761 7.88604C83.11 7.79961 83.1613 7.72117 83.2268 7.65566C83.2922 7.59014 83.3704 7.53896 83.4565 7.50533C83.5425 7.47169 83.6346 7.45634 83.7268 7.46022H86.6289C86.7211 7.45634 86.8131 7.47169 86.8992 7.50533C86.9852 7.53896 87.0634 7.59014 87.1289 7.65566C87.1943 7.72117 87.2456 7.79961 87.2795 7.88604C87.3134 7.97247 87.3292 8.06502 87.3258 8.15788V22.9909C87.3208 23.175 87.2457 23.3502 87.1159 23.48C86.9862 23.6099 86.8118 23.6846 86.6289 23.6886H83.7347L83.7268 23.7163Z"
        fill="white"
      />
      <Path
        d="M139 21.3141L137.98 19.6572C137.705 19.2132 137.169 19.2132 136.685 19.5422C136.233 19.8495 135.702 20.0188 135.157 20.0298C134.389 20.0298 133.302 19.5422 133.302 17.9566V11.0753H137.98C138.164 11.0712 138.339 10.996 138.469 10.8653C138.598 10.7346 138.673 10.5584 138.677 10.3736V8.13402C138.677 8.04206 138.659 7.95102 138.624 7.86612C138.589 7.78122 138.538 7.70413 138.473 7.6393C138.408 7.57446 138.331 7.52315 138.247 7.48833C138.162 7.4535 138.072 7.43584 137.98 7.43637H133.287V4.07495C133.29 3.98209 133.274 3.88954 133.24 3.80311C133.206 3.71669 133.155 3.63825 133.09 3.57273C133.024 3.50722 132.946 3.45603 132.86 3.4224C132.774 3.38877 132.682 3.37341 132.59 3.3773H129.648C129.556 3.37341 129.464 3.38877 129.378 3.4224C129.292 3.45603 129.214 3.50722 129.148 3.57273C129.083 3.63825 129.031 3.71669 128.997 3.80311C128.964 3.88954 128.948 3.98209 128.951 4.07495V7.48393H126.73C126.638 7.48178 126.547 7.49833 126.462 7.53258C126.377 7.56682 126.299 7.61807 126.234 7.68323C126.169 7.74839 126.118 7.82613 126.083 7.91177C126.049 7.99741 126.032 8.08918 126.033 8.18158V10.4212C126.032 10.5136 126.049 10.6054 126.083 10.691C126.118 10.7767 126.169 10.8544 126.234 10.9196C126.299 10.9847 126.377 11.036 126.462 11.0702C126.547 11.1045 126.638 11.121 126.73 11.1189H128.951V18.9595C128.951 22.6182 131.664 24.0651 134.306 24.0651C136.157 24.0651 137.803 23.5061 138.87 22.4319C139.028 22.2963 139.129 22.1059 139.153 21.8986C139.177 21.6914 139.122 21.4826 139 21.3141V21.3141Z"
        fill="white"
      />
      <Path
        d="M102.147 23.6684V21.524C101.336 22.8281 99.5288 23.831 97.2567 23.831C92.8308 23.831 89.1018 20.6598 89.1018 15.4354C89.1018 10.2109 92.9017 6.99219 97.0953 6.99219C99.387 6.99219 101.336 8.04263 102.147 9.46568V8.15758C102.145 8.06537 102.162 7.97368 102.196 7.88806C102.23 7.80243 102.281 7.72466 102.346 7.65944C102.41 7.59422 102.488 7.54291 102.573 7.50862C102.658 7.47432 102.749 7.45776 102.84 7.45993H105.739C105.831 7.45601 105.922 7.47137 106.008 7.50504C106.094 7.53871 106.172 7.58996 106.237 7.65554C106.302 7.72112 106.353 7.79961 106.387 7.88603C106.42 7.97245 106.435 8.06492 106.432 8.15758V23.7834C106.432 28.2825 103.12 31.41 97.7686 31.41C93.4608 31.41 90.3107 28.6551 89.5468 25.5553C89.5181 25.4883 89.5039 25.4159 89.5049 25.343C89.5059 25.27 89.5222 25.1981 89.5526 25.1319C89.5831 25.0657 89.6271 25.0067 89.6817 24.9588C89.7363 24.9108 89.8004 24.875 89.8697 24.8537H93.0434C93.4372 24.8537 93.6931 24.9012 93.8073 25.1351C94.1775 26.3481 95.4297 27.6086 97.8395 27.6086C100.753 27.6799 102.147 26.0944 102.147 23.6684ZM97.8159 19.982C100.32 19.982 102.147 18.0714 102.147 15.5305C102.147 12.9896 100.34 11.075 97.8159 11.075C97.2387 11.0698 96.6664 11.1824 96.1337 11.406C95.6009 11.6296 95.1187 11.9596 94.7161 12.376C94.3135 12.7924 93.9989 13.2866 93.7912 13.8287C93.5836 14.3709 93.4873 14.9498 93.5081 15.5305C93.5041 18.0714 95.3352 19.982 97.812 19.982H97.8159Z"
        fill="white"
      />
      <Path
        d="M63.4755 6.94484C61.1798 6.94484 59.2819 7.99528 58.4707 9.34698V5.91818L58.4235 1.32398C58.4194 1.14022 58.3451 0.965111 58.216 0.835141C58.0869 0.705171 57.913 0.630373 57.7304 0.626333H54.8323C54.7402 0.622408 54.6484 0.637767 54.5625 0.671439C54.4767 0.705111 54.3987 0.756361 54.3336 0.821943C54.2684 0.887526 54.2175 0.96601 54.1841 1.05243C54.1506 1.13885 54.1354 1.23132 54.1393 1.32398L54.1826 5.91818V22.9631C54.181 23.0555 54.1979 23.1473 54.2324 23.2329C54.2669 23.3185 54.3183 23.3963 54.3834 23.4614C54.4485 23.5266 54.526 23.5778 54.6112 23.6121C54.6965 23.6463 54.7878 23.6629 54.8795 23.6607H57.7737C57.8655 23.6629 57.9568 23.6463 58.042 23.6121C58.1273 23.5778 58.2048 23.5266 58.2699 23.4614C58.335 23.3963 58.3864 23.3185 58.4209 23.2329C58.4554 23.1473 58.4723 23.0555 58.4707 22.9631V21.655C59.3961 23.1256 61.1365 24.1285 63.4282 24.1285C67.6691 24.1285 71.4926 20.7671 71.4926 15.5228C71.4926 10.2785 67.6455 6.94484 63.4755 6.94484ZM62.8022 20.1487C60.349 20.1487 58.4943 18.1192 58.4943 15.5505C58.4943 12.9819 60.349 11.0039 62.8022 11.0039C63.3862 11.01 63.9632 11.133 64.4996 11.3656C65.036 11.5982 65.5212 11.9359 65.9268 12.359C66.3324 12.782 66.6504 13.2821 66.8623 13.83C67.0742 14.3779 67.1758 14.9627 67.1612 15.5505C67.1139 18.0954 65.2593 20.1487 62.8022 20.1487V20.1487Z"
        fill="white"
      />
      <Path
        d="M118.618 6.94487C116.047 6.94487 114.193 8.04288 113.267 9.34702V1.30023C113.264 1.2112 113.244 1.12365 113.207 1.04258C113.17 0.961511 113.118 0.888519 113.053 0.827774C112.989 0.76703 112.913 0.719724 112.83 0.688565C112.747 0.657407 112.659 0.643005 112.57 0.646184H109.629C109.537 0.644556 109.446 0.661622 109.361 0.696356C109.276 0.73109 109.198 0.782776 109.134 0.848309C109.069 0.913841 109.018 0.991868 108.984 1.0777C108.95 1.16354 108.934 1.25541 108.936 1.3478V22.9909C108.934 23.0831 108.95 23.1748 108.984 23.2604C109.018 23.346 109.069 23.4238 109.134 23.489C109.199 23.5542 109.276 23.6055 109.361 23.6398C109.446 23.6741 109.537 23.6907 109.629 23.6885H112.57C112.659 23.6917 112.747 23.6773 112.83 23.6461C112.913 23.615 112.989 23.5677 113.053 23.5069C113.118 23.4462 113.17 23.3732 113.207 23.2921C113.244 23.2111 113.264 23.1235 113.267 23.0345V15.178C113.267 12.6609 114.89 10.9564 117.299 10.9564C119.8 10.9564 121.213 12.6133 121.213 15.178V23.0146C121.216 23.1037 121.237 23.1912 121.274 23.2723C121.31 23.3534 121.362 23.4264 121.427 23.4871C121.492 23.5479 121.568 23.5952 121.651 23.6263C121.734 23.6575 121.822 23.6719 121.91 23.6687H124.781C124.869 23.6719 124.958 23.6575 125.04 23.6263C125.123 23.5952 125.199 23.5479 125.264 23.4871C125.329 23.4264 125.381 23.3534 125.418 23.2723C125.454 23.1912 125.475 23.1037 125.478 23.0146V14.0126C125.545 9.97728 122.883 6.94487 118.618 6.94487Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.12 47.3451C101.631 47.3451 101.387 47.1033 101.387 46.703C101.387 45.9617 102.21 45.708 103.714 45.5495C103.73 45.7736 103.701 45.9986 103.629 46.2113C103.557 46.4239 103.443 46.6198 103.295 46.7873C103.146 46.9548 102.965 47.0905 102.763 47.1863C102.561 47.2821 102.343 47.3361 102.12 47.3451V47.3451ZM102.773 41.7639C101.697 41.8015 100.657 42.1679 99.7924 42.8144L100.403 44.1106C101.008 43.5597 101.785 43.2363 102.6 43.1949C103.344 43.1949 103.758 43.4446 103.758 43.948C103.758 44.2889 103.482 44.4634 102.97 44.5307C101.045 44.7805 99.5325 45.3235 99.5325 46.814C99.5325 48.0031 100.371 48.7166 101.679 48.7166C102.112 48.7179 102.539 48.607 102.919 48.3946C103.298 48.1823 103.616 47.8755 103.844 47.5037V48.5224H105.585V44.2493C105.585 42.4814 104.368 41.7639 102.777 41.7639"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.272 41.9583V48.5226H122.135V45.3514C122.135 43.8491 123.04 43.4091 123.67 43.4091C123.95 43.409 124.223 43.4902 124.458 43.643L124.797 41.9068C124.531 41.8012 124.247 41.75 123.962 41.7561C123.539 41.7301 123.119 41.8422 122.764 42.0757C122.409 42.3092 122.139 42.6517 121.993 43.0523V41.9504L120.272 41.9583Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.456 41.7639C133.038 41.7704 132.628 41.8788 132.261 42.0797C131.894 42.2806 131.581 42.568 131.349 42.9174C131.145 42.5639 130.851 42.271 130.498 42.0685C130.146 41.866 129.746 41.761 129.34 41.7643C128.933 41.7675 128.535 41.8788 128.186 42.0869C127.836 42.295 127.547 42.5924 127.348 42.9491V41.9582H125.549V48.5224H127.415V45.1452C127.415 43.9282 128.049 43.3495 128.636 43.3495C129.223 43.3495 129.66 43.6983 129.66 44.5902V48.5224H131.518V45.1452C131.518 43.9163 132.133 43.3495 132.751 43.3495C133.243 43.3495 133.763 43.7102 133.763 44.5783V48.5224H135.625V43.9996C135.647 43.7048 135.606 43.4087 135.505 43.1312C135.404 42.8538 135.245 42.6014 135.038 42.391C134.832 42.1807 134.583 42.0173 134.309 41.9119C134.034 41.8064 133.741 41.7614 133.448 41.7798"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.728 41.9583H114.039V41.2924C114.039 40.4203 114.531 40.1745 114.956 40.1745C115.247 40.1779 115.533 40.2498 115.791 40.3846L116.366 39.0607C115.864 38.7823 115.297 38.6453 114.724 38.6643C113.543 38.6643 112.176 39.3421 112.176 41.4628V41.9464H109.345V41.2805C109.345 40.4084 109.841 40.1626 110.263 40.1626C110.554 40.1614 110.841 40.2336 111.097 40.3727L111.676 39.0488C111.172 38.7731 110.604 38.6364 110.03 38.6524C108.849 38.6524 107.483 39.3302 107.483 41.4509V41.9345H106.4V43.3814H107.498V48.4988H109.361V43.4051H112.192V48.5226H114.055V43.4051H115.744L115.728 41.9583Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.843 48.5225H118.705V41.9583H116.843V48.5225Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.707 32.8015C122.67 32.8015 118.185 36.3175 116.909 40.8443H118.737C119.8 37.475 123.411 34.502 127.707 34.502C132.928 34.502 137.44 38.5016 137.44 44.7329C137.456 46.0099 137.281 47.282 136.921 48.5066H138.693V48.4471C138.991 47.2322 139.138 45.9845 139.13 44.7329C139.13 37.7842 134.101 32.7856 127.691 32.7856"
        fill="white"
      />
      <Path
        d="M80 42.1H80.9598V43.114H81.0787C81.2461 42.77 81.5133 42.4823 81.8459 42.287C82.1791 42.0917 82.5632 41.9976 82.9504 42.0162C84.5847 42.0162 85.7123 43.2965 85.7123 45.3066C85.7123 47.3167 84.5847 48.597 82.9777 48.597C82.5869 48.6134 82.2002 48.5168 81.8645 48.319C81.5293 48.1212 81.26 47.831 81.0906 47.4842H81.0056V50.8613H80.0118L80 42.1ZM82.8314 47.7265C84.0775 47.7265 84.7185 46.6467 84.7185 45.2857C84.7185 43.9246 84.0955 42.8927 82.8314 42.8927C81.6163 42.8927 80.9752 43.8558 80.9752 45.2857C80.9752 46.7155 81.6343 47.7265 82.8314 47.7265Z"
        fill="white"
      />
      <Path
        d="M86.3591 46.6645C86.3591 45.2017 87.6721 44.9415 88.8724 44.7889C90.0721 44.6364 90.5401 44.6813 90.5401 44.2087V44.1787C90.5401 43.3591 90.0906 42.8835 89.1762 42.8835C88.8296 42.8609 88.4841 42.9405 88.1834 43.112C87.8832 43.2836 87.6417 43.5393 87.4899 43.8467L86.5445 43.5147C87.0522 42.3511 88.1644 42.019 89.1427 42.019C89.966 42.019 91.5339 42.2524 91.5339 44.2774V48.4652H90.5401V47.6097H90.4917C90.2878 48.0255 89.6988 48.6207 88.5686 48.6207C87.338 48.6207 86.3591 47.9088 86.3591 46.6645ZM90.5401 46.1649V45.2675C90.3733 45.465 89.2431 45.5667 88.8204 45.6325C88.0459 45.7312 87.356 45.9645 87.356 46.7093C87.356 47.3913 87.927 47.7383 88.7205 47.7383C89.8991 47.7413 90.5401 46.9636 90.5401 46.1649Z"
        fill="white"
      />
      <Path
        d="M92.7643 42.0998H93.7246V43.063H93.7941C93.9316 42.7385 94.1669 42.463 94.4676 42.2732C94.7683 42.0835 95.1205 41.9886 95.4773 42.0011C95.6266 42.0011 95.8877 42.0011 95.9999 42.0011V43.0122C95.8002 42.971 95.5968 42.9489 95.3924 42.9463C94.4475 42.9463 93.7576 43.5775 93.7576 44.4419V48.4712H92.7612L92.7643 42.0998Z"
        fill="white"
      />
    </Svg>
  )
}
export default PayBrightByAffirmFR
