import { VFC, useContext } from 'react'
import { Pressable, StyleSheet, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext } from '../../../providers/ContractProvider'
import { ContractOverviewItem, ContractType } from '../../../types'
import { SuccessAlt, ChevronRight } from '../../atoms/Icons'
import Error from '../../atoms/Icons/svgs/Error'
import { ProgressBar } from '../../atoms/ProgressBar'
import colors from '../../tokens/colors'
import { coralJam75, strawberryJam } from '../../tokens/colors/colors'
import {
  FlexedBox,
  StyledCard,
  StyledView,
  SuccessContainer,
  FlexChild50,
  ConditionalMarginView,
  BoxMarginBottomSm,
  SharedTitle,
  SharedAmountLabel,
  SharedAmountCurrency,
} from './styled'

const PlanCard: VFC<ContractOverviewItem> = ({
  contractKey,
  contractTitle,
  contractSubtitle,
  contractType,
  financedAmount,
  nextPaymentAmount,
  nextPaymentDate,
  paidInFull: isPaidInFull,
  principalBalance,
  totalRepaymentAmount,
  paymentProblem,
  updateEFTStatus,
}) => {
  const { localized, getCurrency, getDate } = useContext(ContentContext)
  const navigation = useNavigation()
  const successColor = colors['--ui-alt-01']

  const handlePress = () => {
    navigation.navigate('PlanDetails', { id: contractKey })
  }

  const handleUpdatePaymentButtonPress = () => {
    enterUpdatePaymentFlow()
  }

  const enterUpdatePaymentFlow = () => {
    navigation.navigate('PaymentMethodSelector')
  }

  const dateText = getDate(nextPaymentDate, { short: true })

  const paymentProblemAndNotPending = paymentProblem && updateEFTStatus !== 'pending'

  const hideProgressBar =
    paymentProblemAndNotPending ||
    isPaidInFull ||
    contractType === ContractType.AURORA_ONE_TIME ||
    contractType === ContractType.AURORA_REVISED_TRADE_IN

  return (
    <Pressable onPress={handlePress} style={{ maxWidth: 550 }}>
      <StyledCard level="--elevation-regular-1">
        <StyledView>
          <FlexedBox>
            <BoxMarginBottomSm>
              <SharedTitle variant="--body-01-semi-emphasized">{contractTitle}</SharedTitle>
              {contractSubtitle ? (
                <SharedTitle variant="--body-02-regular">{contractSubtitle}</SharedTitle>
              ) : null}
            </BoxMarginBottomSm>
            <SuccessContainer>
              {isPaidInFull && <SuccessAlt color={successColor} size="24" />}
            </SuccessContainer>
          </FlexedBox>
          <ConditionalMarginView hasProgressBar={!hideProgressBar}>
            <FlexedBox>
              <FlexChild50>
                <SharedAmountLabel>{localized('plans.planTotal')}</SharedAmountLabel>
                <SharedAmountCurrency>{getCurrency(totalRepaymentAmount)}</SharedAmountCurrency>
              </FlexChild50>
              {dateText && nextPaymentAmount ? (
                <FlexChild50>
                  <SharedAmountLabel>{`${localized('plans.due')} ${dateText}`}</SharedAmountLabel>
                  <SharedAmountCurrency>{getCurrency(nextPaymentAmount)}</SharedAmountCurrency>
                </FlexChild50>
              ) : null}
            </FlexedBox>
          </ConditionalMarginView>
          {hideProgressBar ? null : (
            <ProgressBar financedAmount={financedAmount} principalBalance={principalBalance} />
          )}
          {paymentProblemAndNotPending ? (
            <Pressable
              accessibilityRole="button"
              onPress={handleUpdatePaymentButtonPress}
              style={styles.box}>
              <FlexedBox>
                <Error size="30" color={strawberryJam} />
                <Text style={styles.link}>{localized('plans.paymentUpdate')}</Text>
              </FlexedBox>
              <ChevronRight size="20" color={strawberryJam} />
            </Pressable>
          ) : null}
        </StyledView>
      </StyledCard>
    </Pressable>
  )
}

export default PlanCard

const styles = StyleSheet.create({
  box: {
    borderRadius: 4,
    padding: 8,
    color: strawberryJam,
    backgroundColor: '#FFFDF5',
    marginTop: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: coralJam75,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  link: {
    fontSize: 14,
    color: strawberryJam,
    marginLeft: 4,
    alignSelf: 'center',
  },
})
