import { useContext } from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { ThemeContext, DARK } from '../../../providers/ThemeProvider'
import colors from '../../tokens/colors'
import {
  CheckBoxChecked,
  CheckBoxCheckedDisabled,
  CheckBoxOutline,
  CheckBoxOutlineDisabled,
} from '../Icons'
import IconProps from '../Icons/svgs/shared'
import Body from '../Typography/Body'
import { whiteOpacity9 } from '../../tokens/colors/colors'

export type Status = 'checked' | 'unchecked' | 'indeterminate'

interface Props {
  checked: boolean
  onPress: () => void
  disabled?: boolean
  label: string
}

const mainUIColor: string = colors['--ui-alt-01']
const mainInactive: string = colors['--ui-inactive-04']
const secondaryInactive: string = colors['--ui-inactive-03']
const disabledBG: string = colors['--ui-disabled-background']

type Disabled = {
  disabled: object
  enabled: object
}

type FnComp = ({ color, colorSecondary, size }: IconProps) => JSX.Element

type Dark = [FnComp, string, string] | [FnComp, string]

interface DarkObj {
  dark: Dark
  light: Dark
}
interface DisabledObj {
  disabled: DarkObj
  enabled: DarkObj
}

interface CheckedObj {
  checked: DisabledObj
  unchecked: DisabledObj
}

const iconsObj: CheckedObj = {
  checked: {
    disabled: {
      dark: [CheckBoxCheckedDisabled, '#838383', mainInactive],
      light: [CheckBoxCheckedDisabled, secondaryInactive, '#d8d5d5'],
    },
    enabled: {
      dark: [CheckBoxChecked, mainUIColor],
      light: [CheckBoxChecked, mainUIColor],
    },
  },
  unchecked: {
    disabled: {
      dark: [CheckBoxOutlineDisabled, mainInactive],
      light: [CheckBoxOutlineDisabled, disabledBG],
    },
    enabled: {
      dark: [CheckBoxOutline, mainUIColor],
      light: [CheckBoxOutline, mainUIColor],
    },
  },
}

const Checkbox = ({ checked, onPress, label, disabled = false }: Props) => {
  const { theme } = useContext(ThemeContext)
  const checkedIndex = checked ? 'checked' : 'unchecked'
  const disabledIndex: keyof Disabled = disabled ? 'disabled' : 'enabled'
  const darkIndex: keyof DarkObj = theme === DARK ? 'dark' : 'light'
  const [Icon, color, colorSecondary] = iconsObj[checkedIndex][disabledIndex][darkIndex]

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={styles.checkBox}
      onPress={() => !disabled && onPress()}>
      {colorSecondary ? (
        <Icon size={'20'} color={color} colorSecondary={colorSecondary} />
      ) : (
        <Icon size={'20'} color={color} />
      )}
      <Body
        lightColor={colors['--ui-primary-default']}
        darkColor={whiteOpacity9}
        style={styles.label}
        variant={'--body-01-regular'}>
        {label}
      </Body>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  checkBox: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 3,
  },
  label: {
    marginLeft: 16,
    marginTop: -5,
  },
})

export default Checkbox
