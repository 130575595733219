import { StatusDescription, AccessLevel } from '../../../types'
import { AuthFlowType } from '../../../providers/AuthProvider'

interface ParseResponse {
  statusDescription: StatusDescription
  accessLevel: AccessLevel
  authFlowType: AuthFlowType
}

export const parseResponse = ({ statusDescription, accessLevel, authFlowType }: ParseResponse) => {
  const { SUCCESS, INVALID_PIN, TOO_MANY_INCORRECT_ATTEMPTS, NO_PORTAL_ACCOUNT, UNAUTHORIZED } =
    StatusDescription
  const { AUTHORIZED_USER, AUTH_STEP_2, AUTH_STEP_3 } = AccessLevel
  const isPhoneUpdateFlow = authFlowType === 'phoneUpdate'
  const isAuthSuccess = statusDescription === SUCCESS && !isPhoneUpdateFlow

  return {
    hasFullAccess: isAuthSuccess && accessLevel === AUTHORIZED_USER,
    isStep3: isAuthSuccess && accessLevel === AUTH_STEP_3,
    isStep2: isAuthSuccess && accessLevel === AUTH_STEP_2,
    invalidPin: statusDescription === INVALID_PIN,
    unAuthorized: statusDescription === UNAUTHORIZED,
    tooManyAttempts: statusDescription === TOO_MANY_INCORRECT_ATTEMPTS,
    noPortalAccount: statusDescription === NO_PORTAL_ACCOUNT && !isPhoneUpdateFlow,
    updatePhoneFlow: [NO_PORTAL_ACCOUNT, SUCCESS].includes(statusDescription) && isPhoneUpdateFlow,
  }
}
