import { useContext, useEffect, useCallback } from 'react'
import { InteractionManager } from 'react-native'
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RootStackParamList } from '../types'
import { AuthNavigator } from './AuthNavigator'
import { ThemeContext } from '../providers/ThemeProvider'
import { ContentContext } from '../providers/ContentProvider'
import { AuthContext } from '../providers'
import NotFoundScreen from '../screens/NotFoundScreen'

import { linkingConfig, baseLinkingConfig } from './linkingConfig'
import { navigationRef, checkDeepLinkResult } from '../services/NavigationService'
import { useDeepLinks } from '../hooks/useDeepLinks'
import { useUrl } from '../hooks/useUrl'

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>()

const RootNavigator = () => (
  <Stack.Navigator initialRouteName="Root" screenOptions={{ headerShown: false }}>
    <Stack.Screen name="Root" component={AuthNavigator} />
    <Stack.Screen name="NotFound" component={NotFoundScreen} />
  </Stack.Navigator>
)

export const navigate = (name, params) => {
  navigationRef.current?.navigate(name, params)
}

const Navigation = () => {
  const { theme, rootBackgroundColor } = useContext(ThemeContext)
  useContext(ContentContext)
  const { isLoggedIn } = useContext(AuthContext)

  const { addDeepLink } = useDeepLinks()
  const { link, setLink } = useUrl()

  const handleDeepLink = useCallback(
    (url: string) => {
      const task = InteractionManager.runAfterInteractions(() => {
        const { didDeepLinkLand, action, linkPath } = checkDeepLinkResult(url)
        if (!didDeepLinkLand && linkPath) {
          setLink(null)
          addDeepLink({
            id: linkPath,
            type: 'AUTH',
            action: () => navigationRef.current?.dispatch(action),
          })
        }
      })

      return () => task.cancel()
    },
    [navigationRef]
  )

  useEffect(() => {
    if (!link) {
      return
    }

    handleDeepLink(link)
  }, [link])

  return (
    <NavigationContainer
      linking={isLoggedIn ? linkingConfig : baseLinkingConfig}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? route?.name} | PayBright`,
      }}
      ref={navigationRef}
      theme={theme === 'dark' ? DarkTheme : DefaultTheme}>
      <SafeAreaView
        style={{
          paddingTop: 0,
          flex: 1,
          backgroundColor: rootBackgroundColor,
        }}>
        <RootNavigator />
      </SafeAreaView>
    </NavigationContainer>
  )
}

export default Navigation
