import { StyleSheet, TouchableOpacity } from 'react-native'
import Box from '../../atoms/Box'
import Body from '../../atoms/Typography/Body'
import { ink50, whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { Edit } from '../../atoms/Icons'
import { useNavigation } from '@react-navigation/native'

export type Item = {
  title: string
  description: string | string[]
  linksTo?: string
}

const SettingsItem = ({ item }: { item: Item }) => {
  const descriptionArray =
    typeof item.description === 'string' ? [item.description] : item.description

  const navigation = useNavigation()
  const handleOnPress = (link: string) => navigation.navigate(link)

  return (
    <>
      <Box style={styles.itemContainer}>
        <Box>
          <Body
            style={styles.titleContainer}
            lightColor={colors['--text-dark']}
            darkColor={whiteOpacity9}
            variant="--body-01-semi-emphasized">
            {item.title}
          </Body>
          {descriptionArray.map(text => (
            <Body key={text} lightColor={ink50} darkColor={ink50}>
              {text}
            </Body>
          ))}
        </Box>
        {item.linksTo ? (
          <TouchableOpacity onPress={() => handleOnPress(item.linksTo)}>
            <Edit size={'20'} />
          </TouchableOpacity>
        ) : null}
      </Box>
    </>
  )
}

export default SettingsItem

const styles = StyleSheet.create({
  itemContainer: {
    paddingVertical: 22,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    marginBottom: 8,
  },
  switch: {
    marginBottom: 26,
  },
})
