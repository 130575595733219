import { useContext, useState, useEffect } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { RequestStatus, useFetch } from '../../hooks/useFetch'
import { ApiResponse, StatusDescription } from '../../types'
import { AuthContext } from '../../providers'
import { localized } from '../../localization/Localized'
import { patchBiometricKey } from '../../endpoints'
import {
  setKeyLocallyAndReturnsPayload,
  deletePrivateKeyLocally,
  getBiometricGUID,
  getKeyFromSecureStore,
  Payload,
} from '../../helpers/rsa'
import { checkBiometricCompatability, setBiometricsSetupFlagValue, biometricsSetupFlagExists } from '../../helpers/biometrics'
import colors from '../../components/tokens/colors'
import { ink25, whiteOpacity9 } from '../../components/tokens/colors/colors'
import Hairline from '../../components/atoms/Hairline'
import { Check } from '../../components/atoms/Icons'
import Body from '../../components/atoms/Typography/Body'
import Box from '../../components/atoms/Box'
import Caption from '../../components/atoms/Typography/Caption'
import Loading from '../../components/molucules/Loading'
import GenericModal from '../../components/molucules/GenericModal'
import AccountInformationSettingsWrapper from '../../components/wrappers/AccountInformationSettingsWrapper'
import AccountInformationWrapper from '../../components/wrappers/AccountInformationWrapper'

type PayloadOptions = Payload | Pick<Payload, 'publicKey'> | null

const BiometricsScreen = () => {
  const { portalDeviceGuid } = useContext(AuthContext)
  const [isBiometricsAvailable, setBiometricsAvailable] = useState<boolean | null>(null)
  const [payload, setPayload] = useState<PayloadOptions | null>(null)
  const [hasPrivateKey, setHasPrivateKey] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [url, setUrl] = useState('')
  const darkText = whiteOpacity9
  const lightText = colors['--text-dark']

  const setConditionToNoBiometrics = async () => {
    await deletePrivateKeyLocally(portalDeviceGuid)
    setHasPrivateKey(false)
  }

  const checkUserHasUsedBiometrics = async () => {
    await setBiometricsSetupFlagValue(true)
  }

  useEffect(() => {
    checkUserHasUsedBiometrics()
  }, [])

  const updateSettings = async (turnBiometricsOnPressed: boolean) => {
    if (turnBiometricsOnPressed) {
      setLoading(true)
      const payloadObj = await setKeyLocallyAndReturnsPayload(portalDeviceGuid)
      if (payloadObj) {
        setHasPrivateKey(true)
        setPayload(payloadObj)
      } else {
        setConditionToNoBiometrics()
        setPayload({ publicKey: null })
      }
    } else {
      setConditionToNoBiometrics()
      setPayload({ publicKey: null })
    }
  }

  const verifyPrivateKeyInStorage = async () => {
    const privKeyPromise = getKeyFromSecureStore(portalDeviceGuid)
    const bioGUIDPromise = getBiometricGUID()
    try {
      const [privKey, bioGUID] = await Promise.all([privKeyPromise, bioGUIDPromise])
      if (privKey && bioGUID === portalDeviceGuid) {
        setHasPrivateKey(true)
      } else {
        setHasPrivateKey(false)
      }
    } catch (err) {
      console.warn('Error: ', err)
    }
  }

  useEffect(() => {
    checkBiometricCompatability().then(isAvail => {
      setBiometricsAvailable(isAvail)
      if (isAvail) {
        verifyPrivateKeyInStorage()
      }
    })
  }, [])

  useEffect(() => {
    if (payload) {
      setUrl(patchBiometricKey)
    }
    if (isBiometricsAvailable) {
      verifyPrivateKeyInStorage()
    }
  }, [payload, isBiometricsAvailable])

  const { status, data } = useFetch({
    url,
    method: 'patch',
    body: payload,
  })

  const resetFetch = () => {
    setPayload(null)
    setUrl('')
    setLoading(false)
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if (status === RequestStatus.FETCHED) {
      const { statusDescription } = data as ApiResponse
      if ([StatusDescription.SUCCESS, StatusDescription.DELETED].includes(statusDescription)) {
        resetFetch()
      }
    }
    if (status === RequestStatus.ERROR) {
      const isErrorWhileSettingKey = !!payload?.publicKey
      setError(isErrorWhileSettingKey)
      resetFetch()
      setConditionToNoBiometrics()
    }
  }, [status, data])

  return (
    <AccountInformationSettingsWrapper>
      <AccountInformationWrapper title={localized`biometrics.settings`}>
        <Hairline />
        <TouchableOpacity
          disabled={!isBiometricsAvailable || hasPrivateKey}
          style={styles.buttons}
          onPress={() => updateSettings(true)}>
          <Body lightColor={lightText} darkColor={darkText}>
            {localized`biometrics.on`}
          </Body>
          {hasPrivateKey ? <Check size="24" /> : null}
        </TouchableOpacity>
        <Hairline />
        <TouchableOpacity
          disabled={!isBiometricsAvailable || !hasPrivateKey}
          style={styles.buttons}
          onPress={() => updateSettings(false)}>
          <Body lightColor={lightText} darkColor={darkText}>
            {localized`biometrics.off`}
          </Body>
          {!hasPrivateKey ? <Check size="24" /> : null}
        </TouchableOpacity>
        <Hairline />
        {isBiometricsAvailable === false ? (
          <Caption style={styles.caption} lightColor={lightText} darkColor={ink25}>
            {localized`biometrics.notAvailable`}
          </Caption>
        ) : null}
      </AccountInformationWrapper>
      {isLoading ? (
        <Box
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            top: 0,
            right: 0,
          }}>
          <Loading variant="in-screen" isTransparent={true} />
        </Box>
      ) : null}
      <GenericModal
        header={localized`biometrics.errorHeader`}
        visible={isError}
        closeModal={() => setError(false)}
        message={localized`biometrics.errorBody`}
        buttonText="global.ok"
      />
    </AccountInformationSettingsWrapper>
  )
}
export default BiometricsScreen

const styles = StyleSheet.create({
  caption: {
    marginVertical: 24,
  },
  buttons: {
    paddingVertical: 24,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
