import { useContext, useEffect, useState, useRef } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { useNavigation, CommonActions } from '@react-navigation/native'
import jwt_decode from 'jwt-decode'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import GenericModal from '../../molucules/GenericModal'
import Loading from '../../molucules/Loading'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import Body, { BODY_TWO_REGULAR, BODY_TWO_EMPHASIZED } from '../../atoms/Typography/Body'
import { DimensionContext } from '../../../providers'
import { ContentContext } from '../../../providers/ContentProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { sendVerifcationEmail, validateEmailPin } from '../../../endpoints'
import {
  GENERIC_ERROR,
  StatusDescription,
  AccessLevel,
  ApiResponse,
  EMAIL_UPDATE,
} from '../../../types'
import { MASK_PIN } from '../../../helpers/maskTypes'

const EmailUpdateVerifyEmail = () => {
  const isPageMounted = useRef(false)
  const navigation = useNavigation()
  const { localized, getLocaleCodeShort } = useContext(ContentContext)
  const { accessLevel, token } = useContext(AuthContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [pin, setPin] = useState<string | null>(null)
  const [modalHeader, setModalHeader] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isPageLoading, setPageLoading] = useState(false)
  const [url, setUrl] = useState('')

  interface DecodedToken {
    NewCredential: string | null
  }
  const parseJWT = (tokenString: string): DecodedToken => jwt_decode(tokenString)
  const { NewCredential } = parseJWT(token)

  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs' || viewPort === 'sm'

  const sendVerifcationEmailBody = () => ({ culture: getLocaleCodeShort() })
  const validateEmailPinBody = () => ({ pin, culture: getLocaleCodeShort() })
  const axiosBody =
    url === sendVerifcationEmail
      ? sendVerifcationEmailBody()
      : url === validateEmailPin
      ? validateEmailPinBody()
      : {}

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: axiosBody,
  })

  const effectSendVerificationEmail = () => {
    if (status === RequestStatus.FETCHING) {
      isPageMounted.current ? setLoading(true) : setPageLoading(true)
      return
    }
    if (isPageLoading) {
      setPageLoading(false)
      isPageMounted.current = true
    }
    setUrl('')
    setLoading(false)
  }

  const effectVerifyEmailPin = () => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      return
    }
    setLoading(false)
    if ([RequestStatus.FETCHED, RequestStatus.ERROR].includes(status)) {
      const { statusDescription } = (error || data) as ApiResponse
      setPin('')
      setUrl('')
      routeAfterPinEntry(statusDescription)
    }
  }

  useEffect(() => {
    if (url === sendVerifcationEmail) {
      effectSendVerificationEmail()
    } else if (url === validateEmailPin) {
      effectVerifyEmailPin()
    }
  }, [status, url])

  const send = async () => {
    setUrl(validateEmailPin)
  }

  const handleResendPin = () => {
    setUrl(sendVerifcationEmail)
  }

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' && !disabled) {
      send()
    }
  }

  const handleSubmit = () => {
    if (!disabled) {
      send()
    }
  }

  const navToBottomNavWithParam = (params: { [s: string]: string }) => {
    const routes: { name: string; params: {} }[] = navigation.getState().routes.slice(0, 2)
    routes[1].params = params
    navigation.dispatch(
      CommonActions.reset({
        routes,
        index: 1,
      })
    )
  }

  const routeAfterPinEntry = (statusDescription: StatusDescription) => {
    const isAuthorized = accessLevel === AccessLevel.AUTHORIZED_USER
    switch (statusDescription) {
      case StatusDescription.INVALID_PIN:
        openErrorModal(StatusDescription.INVALID_PIN)
        break
      case StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS:
        openErrorModal(StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS)
        break
      case StatusDescription.EMAIL_UPDATED:
        if (isAuthorized) {
          navToBottomNavWithParam({ paramType: EMAIL_UPDATE })
          break
        }
      default:
        navigation.navigate(GENERIC_ERROR)
        break
    }
  }

  const errorModalContent = (statusDescription: StatusDescription) => {
    switch (statusDescription) {
      case StatusDescription.INVALID_PIN:
        setModalHeader('emailVerification.error.incorrectCodeHeader')
        setModalMessage('emailVerification.error.incorrectCodeMessage')
        break
      case StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS:
        setModalHeader('emailVerification.error.tooManyAttemptsHeader')
        setModalMessage('emailVerification.error.tooManyAttemptsMessage')
        break
      default:
        navigation.navigate(GENERIC_ERROR)
        break
    }
  }

  const openErrorModal = (statusDescription: StatusDescription) => {
    setLoading(false)
    setModalOpen(true)
    errorModalContent(statusDescription)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const disabled = isModalOpen || pin?.length !== 6

  const pinInputProps: PinInputProps = {
    style: styles.input,
    keyboardType: 'number-pad',
    onChangeText: setPin,
    value: pin,
    testID: 'VerificationCode',
    label: localized('emailVerification.label'),
    maskProps: MASK_PIN,
    maskChar: '',
    autoFocus: true,
    onKeyPress: handleKeyPress,
    onSubmitEditing: handleSubmit,
  }

  interface PinInputProps {
    testID: string
    maskProps: any
    maskChar: ''
    autoFocus: boolean
    style: any
    label: () => string
    value: any
    onChangeText: () => void
    keyboardType: string
    onKeyPress: (e: any) => void
    onSubmitEditing: () => void
    returnKeyType?: string
  }

  if (!disabled) {
    pinInputProps.returnKeyType = 'done'
  }

  return (
    <PlanDetailWrapper
      title={localized('emailVerification.updateEmailAddress.header')}
      headingAlignment="left"
      {...Platform.select({
        native: {
          scrollView: true,
        },
        web: { isModal: true },
        default: {},
      })}>
      <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
        <GenericModal
          header={modalHeader}
          visible={isModalOpen}
          closeModal={closeModal}
          message={modalMessage}
          buttonText={'global.ok'}
        />
        {isPageLoading ? (
          <Loading variant="in-screen" />
        ) : (
          <>
            <Body
              darkColor={whiteOpacity9}
              lightColor={colors['--text-dark']}
              style={styles.body}
              variant={BODY_TWO_REGULAR}>
              {localized('emailVerification.updateEmailAddress.message')}
              <Body
                darkColor={whiteOpacity9}
                lightColor={colors['--text-dark']}
                variant={BODY_TWO_EMPHASIZED}>
                {` ${NewCredential}`}
              </Body>
            </Body>
            <Input {...pinInputProps} />
            <Box style={styles.link}>
              <Link onPress={handleResendPin}>{localized('emailVerification.resendCode')}</Link>
            </Box>
            <Button
              testID="emailVerificationContinueBtn"
              onPress={send}
              isLoading={isLoading}
              disabled={disabled}>
              {localized('global.continue')}
            </Button>
          </>
        )}
      </PlanDetailWrapper.InnerWrapper>
    </PlanDetailWrapper>
  )
}

export default EmailUpdateVerifyEmail

const styles = StyleSheet.create({
  heading: {
    marginBottom: 24,
  },
  body: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 24,
  },
  link: {
    marginBottom: 40,
    marginTop: 16,
  },
})
