import { useContext, useState, useEffect } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import camelCase from 'lodash.camelcase'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { updatePaymentMethod } from '../../../endpoints'
import {
  validateCreditCardNumber,
  validateCCExpiryDate,
  validateProvince,
} from '../../../helpers'
import Box from '../../atoms/Box'
import Checkbox, { Status as CheckboxStatus } from '../../atoms/Checkbox'
import Button from '../../atoms/Button'
import ManualAddressInput from '../../molucules/ManualAddressInput'
import { LinkYourCardLogoRow } from '../../molucules/LinkYourCardLogoRow'
import { LinkYourCardTextBlock } from '../../molucules/LinkYourCardTextBlock'
import SharedInput from '../../molucules/SharedInput'
import { ContentContext, ContractContext, DimensionContext } from '../../../providers'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import GenericModal from '../../molucules/GenericModal'

import {
  CreditCard,
  CC,
  ContractPaymentMethod,
  StatusDescription,
  UpdateCCPayload,
  BillingAddress,
} from '../../../types'
import { MASK_CREDIT_CARD, MASK_EXPIRY_DATE, MASK_CVV } from '../../../helpers/maskTypes'

export const LinkYourCreditCard = () => {
  const { viewPort } = useContext(DimensionContext)
  const { contract } = useContext(ContractContext)
  const { $ } = useContext(ContentContext)
  const navigation = useNavigation()
  const [currentCreditCard, setCurrentCreditCard] = useState<CreditCard | null>(null)
  const [responseModal, setResponseModal] = useState<StatusDescription | null>(null)
  const [billingAddress, setBillingAddress] = useState<BillingAddress | null>(null)
  const [payload, setPayload] = useState<UpdateCCPayload | null>(null)
  const [isAddressFormValid, setAddressFormValid] = useState(false)
  const [isAddressVisible, setAddressVisibility] = useState(false)
  const [isCardNumberValid, setCardNumberValid] = useState(false)
  const [isExpiryValid, setExpiryValid] = useState(false)
  const [isCvvValid, setCvvValid] = useState(false)
  const [isCCFormValid, setCCFormValid] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [cardNumber, setCardNumber] = useState('')
  const [cardExpiry, setCardExpiry] = useState('')
  const [url, setUrl] = useState('')
  const [cvv, setCvv] = useState('')
  const { contractKey, interestRate, paymentMethod, isAppliedToAllOfContractGroup } = contract
  const isFormValid = isCCFormValid && (isAddressVisible ? isAddressFormValid : true)
  const isInterestBearing = Number(interestRate) > 0
  const isMobile = viewPort === 'xs'
  const localErrorCardNumber = $`linkYourCreditCard.errorCardNumber`
  const localErrorExpiry = $`linkYourCreditCard.errorExpiry`
  const localErrorCVV = $`linkYourCreditCard.errorCVV`
  const subHeading = isInterestBearing
    ? $`linkYourCreditCard.errorInterestBearing`
    : $`linkYourCreditCard.subHeading`

  useEffect(() => {
    const passedAllCreditFormChecks = isCardNumberValid && isExpiryValid && isCvvValid
    setCCFormValid(passedAllCreditFormChecks)
  }, [isCardNumberValid, isExpiryValid, isCvvValid])

  useEffect(() => setCvvValid(cvv.length === 3), [cvv])
  useEffect(() => setExpiryValid(validateCCExpiryDate(cardExpiry)), [cardExpiry])
  useEffect(() => setCardNumberValid(validateCreditCardNumber(cardNumber)), [cardNumber])

  const handleKeypress = e => {
    if (e.nativeEvent.key === 'Enter' && isFormValid) {
      e.target.blur()
      handleContinuePress()
    }
  }
  const handleSubmit = e => {
    if (isFormValid) {
      e.target.blur()
      handleContinuePress()
    }
  }

  const updateCreditCardURL = contractKey ? updatePaymentMethod(`${contractKey}`) : ''

  interface ApiResponse {
    statusDescription: StatusDescription
    statusCode: number
    paymentMethods: ContractPaymentMethod
  }

  const { status, data } = useFetch<ApiResponse>({
    url,
    method: payload ? 'patch' : 'get',
    ...(payload && { body: payload }),
  })

  useEffect(() => {
    if (paymentMethod === CC) {
      setUrl(updateCreditCardURL)
    }
  }, [])

  useEffect(() => {
    if (!payload) return
    setUrl(updateCreditCardURL)
  }, [payload])

  const handlePatch = ({ statusDescription }: ApiResponse) => {
    if (statusDescription === StatusDescription.SUCCESS) {
      navigation.navigate('PlanDetails', { paymentMethodUpdated: Date.now(), id: contractKey })
    } else {
      setResponseModal(statusDescription)
    }
  }

  const handleGet = ({ paymentMethods }: ApiResponse) => {
    if (paymentMethods?.currentPaymentMethod === CC && paymentMethods?.creditCard) {
      setCurrentCreditCard(paymentMethods.creditCard)
      const cleanPostalCode = paymentMethods?.creditCard?.addrPostalCode?.replace(/ /g, '')
      setBillingAddress({ ...paymentMethods.creditCard, addrPostalCode: cleanPostalCode })
      const province = paymentMethods?.creditCard?.addrProvince || ''
      const isValidProvince = validateProvince(province)
      setAddressVisibility(!isValidProvince)
    } else {
      setAddressVisibility(true)
    }
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      return
    }
    if (status === RequestStatus.FETCHED && data) {
      const callback = payload ? handlePatch : handleGet
      callback(data)
      setLoading(false)
      setUrl('')
      setPayload(null)
      return
    }
    if (status === RequestStatus.ERROR) {
      setLoading(false)
      setUrl('')
      setResponseModal(StatusDescription.FAILED)
    }
  }, [data, status])

  const handleContinuePress = () => {
    setPayload({
      cardNumber,
      cvv,
      cardExpiry,
      isAppliedToAllOfContractGroup,
      ...(isAddressVisible && billingAddress && { billingAddress }),
    })
  }

  const handleUpdateBillingAddressPress = (b: boolean) => {
    setAddressVisibility(b)
  }

  const getModalContent = (str: string): string =>
    responseModal ? `paymentMethodSelector.modal.${camelCase(responseModal)}.${str}` : ''

  return (
    <PlanDetailWrapper
      title={$`linkYourCreditCard.heading`}
      {...Platform.select({
        native: { scrollView: true },
        web: { isModal: !isMobile },
        default: null,
      })}>
      <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
        <LinkYourCardLogoRow heading={subHeading} isInterestBearing={isInterestBearing} />
        <SharedInput
          maskProps={MASK_CREDIT_CARD}
          maskChar=""
          textContentType="creditCardNumber"
          onChangeText={(text: string) => setCardNumber(text.replace(/ /g, ''))}
          label={$`linkYourCreditCard.labelCardNumber`}
          error={!isCardNumberValid}
          errorMessage={localErrorCardNumber}
          onKeyPress={handleKeypress}
          onSubmitEditing={handleSubmit}
        />
        <Box style={styles.box}>
          <Box style={styles.left}>
            <SharedInput
              maskProps={MASK_EXPIRY_DATE}
              maskChar=""
              inputProps={{ maxlength: 5 }}
              onChangeText={(text: string) => setCardExpiry(text)}
              label={$`linkYourCreditCard.labelExpiry`}
              error={!isExpiryValid}
              errorMessage={localErrorExpiry}
              onKeyPress={handleKeypress}
              onSubmitEditing={handleSubmit}
            />
          </Box>
          <Box style={styles.leftMargin}>
            <SharedInput
              maskProps={MASK_CVV}
              maskChar=""
              textContentType="creditCardNumber"
              onChangeText={(text: string) => setCvv(text)}
              label={$`linkYourCreditCard.labelCVV`}
              error={!isCvvValid}
              errorMessage={localErrorCVV}
              onKeyPress={handleKeypress}
              onSubmitEditing={handleSubmit}
            />
          </Box>
        </Box>
        <LinkYourCardTextBlock
          currentCreditCard={currentCreditCard}
          isAddressVisible={isAddressVisible}
          setAddressVisibility={handleUpdateBillingAddressPress}
        />
        {isAddressVisible && (
          <Box style={styles.topPaddingMd}>
            <ManualAddressInput
              hasLookupAddress={false}
              setBillingAddress={setBillingAddress}
              billingAddress={billingAddress}
              setFormValid={setAddressFormValid}
            />
          </Box>
        )}
        <Box style={styles.button}>
          <Button
            isLoading={Boolean(payload && isLoading)}
            testID="ContinueBtn"
            onPress={handleContinuePress}
            disabled={!isFormValid}>
            {$`global.continue`}
          </Button>
        </Box>
      </PlanDetailWrapper.InnerWrapper>
      <GenericModal
        header={getModalContent('heading')}
        visible={Boolean(responseModal)}
        closeModal={() => setResponseModal(null)}
        message={getModalContent('body')}
        buttonText={getModalContent('button')}
      />
    </PlanDetailWrapper>
  )
}

export const styles = StyleSheet.create({
  box: {
    flexDirection: 'row',
  },
  input: {
    marginBottom: 16,
  },
  body: {
    marginBottom: 8,
    paddingTop: 8,
  },
  button: {
    marginTop: 38,
    marginBottom: 32,
  },
  checkbox: { marginTop: 32, marginBottom: -16 },
  left: {
    flex: 1,
  },
  leftMargin: {
    flex: 1,
    marginLeft: 8,
  },
  topPaddingMd: {
    paddingTop: 16,
  },
})
