import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const ChevronLeft = ({ color = 'black', size = '40' }: Props) => (
  <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5122 31.5479C24.2096 31.8307 23.735 31.8148 23.4521 31.5122L13.6534 21.0308C13.6532 21.0307 13.6531 21.0305 13.653 21.0304C13.3898 20.7491 13.25 20.3776 13.25 20C13.25 19.6224 13.3898 19.2509 13.653 18.9696C13.6531 18.9695 13.6532 18.9693 13.6534 18.9692L23.4521 8.48781C23.735 8.18523 24.2096 8.16925 24.5122 8.45213C24.8148 8.735 24.8307 9.20961 24.5479 9.51219L14.7513 19.9913C14.7507 19.9931 14.75 19.9961 14.75 20C14.75 20.0039 14.7507 20.0069 14.7513 20.0087L24.5479 30.4878C24.8307 30.7904 24.8148 31.265 24.5122 31.5479ZM14.7484 20.0056C14.7485 20.0058 14.7486 20.0059 14.7487 20.006ZM14.7484 19.9944L14.7487 19.994Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </Svg>
)

export default ChevronLeft
