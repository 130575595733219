import Svg, { Path, G } from 'react-native-svg'
import Props from './shared'

const CheckBoxChecked = ({ color = 'black', size = '40' }: Props) => (
  <Svg fill={color} width={size} height={size} viewBox="0 0 512 512">
    <G>
      <Path d="M455.2,0H56.8C25.1,0,0,25.1,0,56.8v398.4C0,486.9,25.1,512,56.8,512h398.4c31.7,0,56.8-25.1,56.8-56.8V56.8   C512,25.1,486.9,0,455.2,0z M199.2,398.4L56.8,256l40-40l102.4,102.4l216-216l40,40L199.2,398.4z" />
    </G>
  </Svg>
)

export default CheckBoxChecked
