import { useState, useContext, useEffect, useRef } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { useNavigation, NavigationProp, CommonActions } from '@react-navigation/native'
import * as SecureStore from 'expo-secure-store'
import Login from '../Login'
import LoginForm from '../LoginForm'
import { verifyPin, getToken } from '../../../endpoints'
import { formatNumber } from '../../../helpers'
import { parseResponse } from './helpers'
import {
  LINK_YOUR_ACCOUNT,
  EMAIL_VERIFICATION_CODE_ENTRY,
  PHONE_NUMBER_ENTRY,
  GENERIC_ERROR,
  BOTTOM_NAVIGATOR,
  PHONE_NUMBER_UPDATE,
  PLANS,
  PURCHASES_TAB,
  StatusDescription,
  AccessLevel,
  ApiResponse,
} from '../../../types'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { useUUID } from '../../../hooks/useUUID'
import GenericModal from '../../molucules/GenericModal'
import Footer from '../../molucules/Footer'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import SharedBody from '../../molucules/SharedBody'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { LangOptions } from '../../../localization/types'

const PinEntry = () => {
  const navigation = useNavigation()
  const { portalDeviceGuid, phoneNumber, accessLevel, authFlowType } = useContext(AuthContext)
  const { localized, getLocaleCodeShort, loadNewCulture, currentLang } = useContext(ContentContext)
  const { getUUIDKey } = useUUID()
  const [isModalOpen, setModalOpen] = useState(false)
  const [pin, setPin] = useState<string | null>(null)
  const [modalHeader, setModalHeader] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [isNavigationModal, setNavigationModal] = useState(false)
  const pinRequestType = useRef<'text' | 'call'>('text');
  // used for button
  const [isLoading, setLoading] = useState(false)
  const [url, setUrl] = useState('')

  const isNative = Platform.OS === 'android' || Platform.OS === 'ios'
  
  const verifyPinBody = () => ({ pin })
  const getTokenBody = () => ({ portalDeviceGuid, phoneNumber, culture: getLocaleCodeShort(), pinRequestType: pinRequestType.current })
  const axiosBody = url === verifyPin ? verifyPinBody() : url === getToken ? getTokenBody() : {}

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: axiosBody,
  })

  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if ([RequestStatus.ERROR, RequestStatus.FETCHED].includes(status)) {
      setUrl('')
      setLoading(false)
      const { statusDescription } = (error || data) as ApiResponse
      if (url === verifyPin) {
        routeAfterPinEntry({ statusDescription, accessLevel, navigation })
      }
    }
  }, [status])

  useEffect(() => {
    getLanguageLocal().then(lang => {
      loadNewCulture(lang)
    })
  }, [])

  const getLanguageLocal = async (): Promise<LangOptions> => {
    let result: LangOptions
    const key = getUUIDKey(phoneNumber)
    if (isNative) {
      try {
        result = await SecureStore.getItemAsync('lang-' + key)
      } catch (e) {
        result = currentLang
      }
    } else {
      result = localStorage.getItem('lang-' + key)
    }
    return result || currentLang
  }

  const send = async () => {
    setUrl(verifyPin)
  }

  const handleResend = async (type) => {
    pinRequestType.current = type
    setUrl(getToken)
  }


  interface RouteAfterPinEntry {
    statusCode: number
    statusDescription: StatusDescription
    accessLevel: AccessLevel
    navigation: NavigationProp
  }

  const routeAfterPinEntry = ({
    statusDescription,
    accessLevel,
    navigation: nav,
  }: RouteAfterPinEntry) => {
    const { navigate, replace } = nav
    const {
      hasFullAccess,
      isStep2,
      isStep3,
      invalidPin,
      unAuthorized,
      tooManyAttempts,
      noPortalAccount,
      updatePhoneFlow,
    } = parseResponse({ statusDescription, accessLevel, authFlowType })

    switch (true) {
      case hasFullAccess:
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {
                name: BOTTOM_NAVIGATOR,
                params: {
                  screen: PURCHASES_TAB,
                  params: { screen: PLANS },
                },
              },
            ],
          })
        )
        break
      case isStep3:
        replace(EMAIL_VERIFICATION_CODE_ENTRY)
        break
      case updatePhoneFlow:
        replace(PHONE_NUMBER_UPDATE)
        break
      case isStep2:
        replace(LINK_YOUR_ACCOUNT)
        break
      case invalidPin:
        openErrorModal(StatusDescription.INVALID_PIN)
        break
      case tooManyAttempts:
        openErrorModal(StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS)
        break
      case noPortalAccount:
        openErrorModal(StatusDescription.NO_PORTAL_ACCOUNT)
        break
      case unAuthorized:
        navigate(PHONE_NUMBER_ENTRY)
        break
      default:
        navigate(GENERIC_ERROR)
        break
    }
  }

  const errorModalContent = (error: string) => {
    switch (error) {
      case StatusDescription.INVALID_PIN:
        setModalHeader('pinEntry.error.incorrectPINHeader')
        setModalMessage('pinEntry.error.incorrectPINMessage')
        break
      case StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS:
        setModalHeader('pinEntry.error.tooManyAttemptsHeader')
        setModalMessage('pinEntry.error.tooManyAttemptsMessage')
        break
      case StatusDescription.NO_PORTAL_ACCOUNT:
        setNavigationModal(true)
        setModalHeader('pinEntry.error.noPortalAccountHeader')
        setModalMessage('pinEntry.error.noPortalAccountMessage')
        break
      default:
        navigation.navigate(GENERIC_ERROR)
        break
    }
  }

  const openErrorModal = (error: string) => {
    setLoading(false)
    setModalOpen(true)
    errorModalContent(error)
  }

  const closeModal = () => {
    setModalOpen(false)
    if (isNavigationModal) {
      navigation.replace(PHONE_NUMBER_ENTRY)
    }
  }

  return (
    <>
      <GenericModal
        header={modalHeader}
        visible={isModalOpen}
        closeModal={closeModal}
        message={modalMessage}
        buttonText={'global.continue'}
      />
      <Login>
        <LoginForm
          isPinPage={true}
          title={localized('global.welcome')}
          instruction={localized('global.enterYourPin')}
          inputLabel={localized('global.PIN')}
          send={send}
          isLoading={isLoading}
          setValue={setPin}
          value={pin}>
          <SharedBody style={styles.hint} variant="--body-02-regular">
            {' '}
            {localized('global.weSentAPin')}{' '}
            <SharedBody style={styles.hint} variant="--body-02-semi-emphasized">
              {`${formatNumber(parseInt(phoneNumber, 10), '(***) ***-****')}.`}
            </SharedBody>
          </SharedBody>
          <Box style={styles.hint}>
            <Link onPress={() => handleResend('text')}>{localized('global.resendPin')}</Link>
          </Box>
          <Box style={styles.hint}>
            <Link onPress={() => handleResend('call')}>{localized('global.callWithPin')}</Link>
          </Box>
        </LoginForm>
      </Login>
      <Footer />
    </>
  )
}

const styles = StyleSheet.create({
  hint: {
    marginBottom: 45,
  },
})

export default PinEntry
