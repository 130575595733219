import { ReactNode } from 'react'
import { Text, TouchableOpacity, ViewStyle, Platform } from 'react-native'
// import styledComponents from 'styled-components'
import styled from 'styled-components/native'
import { ChevronRight, ChevronLeft } from '../Icons'
import colors from '../../tokens/colors'
import useColorByTheme from '../../../hooks/useColorByTheme'
import { whiteOpacity9, whiteOpacity7 } from '../../tokens/colors/colors'

export const PRIMARY_LEFT = 'primary-left'
export const PRIMARY_RIGHT = 'primary-right'
export const SMALL_PRINT = 'small-print'

type Variant = typeof PRIMARY_LEFT | typeof PRIMARY_RIGHT | typeof SMALL_PRINT

interface Props {
  children: ReactNode | string
  testID?: string
  variant?: Variant
  style?: ViewStyle
  onPress(): void
}

const ICON_OFFSET = '8px'

interface WebStyles {
  color: string
  colorHover: string
  translateX: string
}

const webStyles = ({ color, colorHover, translateX }: WebStyles) => `
svg {
     fill: ${color};
     stroke: ${color};
   }
   &:hover div {
     color: ${colorHover}!important;
   }
   &:hover svg {
     path {
       fill: ${colorHover};
       stroke: ${colorHover};
     }
     transition: transform 0.2s;
     transform: ${translateX};
   }
`

const StyledTouchable = styled(TouchableOpacity)(
  ({ variant, colorHover, color }: { variant: Variant; colorHover: string; color: string }) => {
    const translateX =
      variant === PRIMARY_LEFT ? `translateX(-${ICON_OFFSET})` : `translateX(${ICON_OFFSET})`
    return `
    display:flex;
    flex-direction: row;
    align-items: center;
    ${Platform.select({
      web: webStyles({ color, colorHover, translateX }),
      default: '',
    })}
  `
  }
)

const Link = ({ children, testID, style, variant, onPress }: Props) => {
  const color = useColorByTheme(colors['--ui-primary-default'], whiteOpacity9)
  const colorHover = useColorByTheme(colors['--ui-primary-hover'], whiteOpacity7)
  const fontSize = variant === SMALL_PRINT ? 14 : 16
  const fontFamily = variant === SMALL_PRINT ? 'Calibre' : 'Calibre-semi'
  return (
    <StyledTouchable
      accessibilityRole="button"
      testID={testID}
      accessibilityLabel={testID}
      onPress={onPress}
      variant={variant}
      colorHover={colorHover}
      color={color}>
      {variant === PRIMARY_LEFT ? <ChevronLeft size="18" color={color} /> : null}
      <Text style={{ fontFamily, color, fontSize, fontWeight: 'normal' }}>{children}</Text>
      {variant === PRIMARY_RIGHT ? <ChevronRight size="18" color={color} /> : null}
    </StyledTouchable>
  )
}

export default Link
