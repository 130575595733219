import { FC, ReactNode, useContext } from 'react'
import { TouchableOpacity, Text, Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import Box from '../../atoms/Box'
import { ChevronRight } from '../../atoms/Icons'
import Body from '../../atoms/Typography/Body'
import { purpleJelly, purpleJelly50, whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { useNavigation } from '@react-navigation/native'

export type MenuItem = {
  id: string
  label: string
  link: string
  icon: ReactNode
  type: MenuType
}

type MenuType = 'modal' | 'internal' | 'external' | 'logIn'

const handlePress = (type: MenuType, link: string, setModalOpen, navigation) => {
  switch (type) {
    case 'external':
      WebBrowser.openBrowserAsync(link)
      break
    case 'modal':
      setModalOpen(true)
      break
    case 'internal':
      navigation.navigate(link)
      break
    case 'logIn':
      navigation.replace(link)
      break
    default:
      const _handledAllCases: never = type
      break
  }
}

type Props = {
  item: MenuItem
  setModalOpen: () => boolean
}

const AccountMenuItem: FC<Props> = ({ item, setModalOpen }) => {
  const { theme } = useContext(ThemeContext)
  const navigation = useNavigation()
  const isDark = theme === 'dark'
  const Icon = item.icon
  const iconColor = isDark ? purpleJelly50 : purpleJelly

  const linkTextWithIcon = (
    <Box
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 24,
        display: 'flex',
      }}>
      <Icon color={iconColor} />
      <Box
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Body
          style={{ marginLeft: 16 }}
          darkColor={whiteOpacity9}
          lightColor={colors['--text-dark']}>
          {item.label}
        </Body>

        <ChevronRight size="20" color={colors['--ui-disabled']} />
      </Box>
    </Box>
  )

  if (Platform.OS === 'web' && item.type === 'external') {
    return (
      <Text accessibilityRole="link" target="_blank" href={item.link}>
        {linkTextWithIcon}
      </Text>
    )
  }
  return (
    <TouchableOpacity
      accessibilityRole="link"
      onPress={() => handlePress(item.type, item.link, setModalOpen, navigation)}>
      {linkTextWithIcon}
    </TouchableOpacity>
  )
}

export default AccountMenuItem
