import { injectable, inject } from "inversify";
import "reflect-metadata";
import { ILoggingService } from "./ILoggingService";
import { getEnvVars } from "../config/getEnvVars";
import { Platform } from 'react-native'
import { INJECTABLE_TYPES, LoggingParameters } from "../types";
import { ILoggingParameterService } from "./ILoggingParameterService";
import *  as Sentry from '@sentry/react-native';
import { Event, EventHint } from "@sentry/types";

const { 
    SENTRY_DSN, 
    SENTRY_ENABLE_IN_DEVELOPMENT, 
    SENTRY_ENABLE_DEBUG_CONSOLE_LOG, 
    SENTRY_ENVIRONMENT , 
    SENTRY_MAX_BREADCRUMBS, 
    SENTRY_ATTACH_STACKTRACE
} = getEnvVars()

@injectable()
class SentryLoggingService implements ILoggingService {
    
    private isInitailized: boolean = false;

    @inject(INJECTABLE_TYPES.ILoggingParameterService) 
    private _loggingParameterService: ILoggingParameterService;


    public LogError(message:string, error?: Error){        
        if(!this.isInitailized) {
            this.Init();
        }

        this.CaptureMessage(message, error);
    }

    public LogWarning(message:string) {
        if(!this.isInitailized) {
            this.Init();
        }

        this.CaptureMessage(message);
    }

    public LogInfo(message:string) {
        if(!this.isInitailized) {
            this.Init();
        }

        this.CaptureMessage(message);
    }

    private Init(){
        try {
            Sentry.init({
                dsn: SENTRY_DSN,
                debug: SENTRY_ENABLE_DEBUG_CONSOLE_LOG,
                environment: SENTRY_ENVIRONMENT,
                maxBreadcrumbs: SENTRY_MAX_BREADCRUMBS,
                attachStacktrace: SENTRY_ATTACH_STACKTRACE,
                beforeSend: this.beforeSend.bind(this)
            });

            console.log("Initialized Sentry Logging");

            this.isInitailized = true;
        }
        catch(error){
            console.error("Failed to initialze Sentry Logging", error);
        }
    }

    private beforeSend(event: Event, hint?: EventHint): PromiseLike<Event | null> | Event | null {

        this.EnrichEventWithLoggingParameters(event);

        return event;
    }

    private EnrichEventWithLoggingParameters(event: Event){

        const parameters: LoggingParameters = this._loggingParameterService.GetParameters();

        if(!event.tags){
            event.tags = {}
        }
        
        let k: keyof typeof parameters;

        for(k in parameters){
            event.tags[k] = parameters[k];
        }
    }

    private CaptureMessage(message: string, error?: Error){       

        const isNative = Platform.OS === 'android' || Platform.OS === 'ios';

        if(error)
        {
            if(isNative)
            {
                Sentry.captureException(error);
            }
            else
            {
                Sentry.captureException(error);
            }   
        }
        
        if(isNative)
        {
            Sentry.captureMessage(message);
        }
        else
        {
            Sentry.captureMessage(message);
        }
        

        
    }
}

export { SentryLoggingService };