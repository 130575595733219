import Svg, { Path } from 'react-native-svg'
import { strawberryJam } from '../../../tokens/colors/colors'
import Props from './shared'

const Failed = ({ color = strawberryJam, size = '20' }: Props) => {
  return (
    <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 88 88" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88Z"
        fill={color}
      />
      <Path
        d="M59.6391 30.3053C60.1761 29.7683 60.1761 28.8977 59.6391 28.3607C59.1021 27.8238 58.2315 27.8238 57.6946 28.3607L44.0002 42.0551L30.3058 28.3607C29.7688 27.8238 28.8982 27.8238 28.3612 28.3607C27.8243 28.8977 27.8243 29.7683 28.3612 30.3053L42.0556 43.9997L28.3612 57.6941C27.8243 58.231 27.8243 59.1016 28.3612 59.6386C28.8982 60.1756 29.7688 60.1756 30.3058 59.6386L44.0002 45.9442L57.6946 59.6386C58.2315 60.1756 59.1021 60.1756 59.6391 59.6386C60.1761 59.1016 60.1761 58.231 59.6391 57.6941L45.9447 43.9997L59.6391 30.3053Z"
        fill="white"
      />
    </Svg>
  )
}

export default Failed
