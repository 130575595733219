import { useEffect, useCallback, useState } from 'react'
import { Linking } from 'react-native'

export const useUrl = () => {
  const [link, setLink] = useState<string | null>(null)
  const handleUrlChange = useCallback(({ url }: { url: string }) => {
    setLink(url)
  }, [])

  useEffect(() => {
    ;(async () => {
      const initialUrl = await Linking.getInitialURL()
      if (!initialUrl) {
        return
      }
      handleUrlChange({ url: initialUrl })
    })()

    const subscription = Linking.addEventListener(
      'url',
      handleUrlChange
    )

    return () => subscription.remove()
  }, [])

  return { link, setLink }
}
