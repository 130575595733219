import Svg, { Path } from 'react-native-svg'
import { ink25 } from '../../../tokens/colors/colors'
import Props from './shared'

const CircleFilled = ({ color = ink25, size = '20' }: Props) => (
  <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 88 88" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88Z"
      fill={color}
    />
  </Svg>
)

export default CircleFilled
