import { FC } from 'react'
import styled from 'styled-components/native'
import BankCCLogo from '../BankCCLogo'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'

interface PlanCardRowProps {
  label: string
  dollarAmount?: string
  description?: string
  paymentAccountNumber?: string
  paymentIcon?: string
  error?: boolean
}

const PlanCardRowRoot = styled(Box)`
  margin: 8px 0 8px 0;
  padding: 0 8px;
`
const Spacer = styled.View`
  margin-top: 8px;
`
const BodyWithMargin = styled(Body)`
  margin-bottom: 2px;
`
const FlexContainerBetween = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const FlexContainerStart = styled(Box)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const textDark = colors['--text-dark']
const textError = colors['--text-error']

const PlanCardRow: FC<PlanCardRowProps> = ({
  label,
  dollarAmount,
  description,
  paymentAccountNumber,
  children,
  paymentIcon,
  error,
}) => (
  <PlanCardRowRoot>
    {!paymentAccountNumber && <Spacer />}
    <BodyWithMargin
      lightColor={error ? textError : textDark}
      darkColor={error ? textError : whiteOpacity9}
      variant="--body-02-regular">
      {label}
    </BodyWithMargin>
    {dollarAmount && (
      <FlexContainerStart>
        <Body lightColor={textDark} darkColor={whiteOpacity9} variant="--body-01-semi-emphasized">
          {dollarAmount}
        </Body>
        <Body
          lightColor={textDark}
          darkColor={whiteOpacity9}
          variant="--body-02-regular"
          style={{ paddingTop: 2 }}>
          {` ${description}`}
        </Body>
      </FlexContainerStart>
    )}
    {paymentAccountNumber && (
      <FlexContainerBetween>
        <FlexContainerStart>
          <BankCCLogo paymentIcon={paymentIcon} />
          <Body lightColor={textDark} darkColor={whiteOpacity9} variant="--body-02-semi-emphasized">
            {paymentAccountNumber}
          </Body>
        </FlexContainerStart>
        {children}
      </FlexContainerBetween>
    )}
  </PlanCardRowRoot>
)

export default PlanCardRow
