import { useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import {
  PLANS,
  PLAN_DETAILS,
  MAKE_PAYMENT,
  SCHEDULE,
  LINK_CREDIT_CARD,
  LINK_BANK_ACCOUNT,
  PAYMENT_METHOD_SELECTOR,
  PlansStackParamList,
} from '../types'
import { ContentContext } from '../providers/ContentProvider'
import PlansScreen from '../screens/PlansScreen'
import PlanDetailScreen from '../screens/PlanDetailScreen'
import MakeAPaymentScreen from '../screens/MakeAPaymentScreen'
import ScheduleScreen from '../screens/ScheduleScreen'
import { LinkCreditCardScreen } from '../screens/LinkCreditCardScreen'
import { LinkBankAccountScreen } from '../screens/LinkYourBankAccountScreen'
import PaymentMethodSelectorScreen from '../screens/PaymentMethodSelectorScreen'

const Stack = createStackNavigator<PlansStackParamList>()

const PlansNavigator = () => {
  const { localized } = useContext(ContentContext)
  const sharedTitle = localized('pageTitle.planDetail')
  return (
    <Stack.Navigator initialRouteName="PurchasePlans" screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={PLANS}
        component={PlansScreen}
        options={{ title: localized('pageTitle.plans') }}
      />
      <Stack.Screen
        name={PLAN_DETAILS}
        component={PlanDetailScreen}
        options={{ title: sharedTitle }}
      />
      <Stack.Screen
        name={MAKE_PAYMENT}
        component={MakeAPaymentScreen}
        options={{ title: localized('pageTitle.makeAPayment') }}
      />
      <Stack.Screen
        name={SCHEDULE}
        component={ScheduleScreen}
        options={{ title: localized('pageTitle.schedule') }}
      />
      <Stack.Screen
        name={LINK_CREDIT_CARD}
        component={LinkCreditCardScreen}
        options={{ title: sharedTitle }}
      />
      <Stack.Screen
        name={LINK_BANK_ACCOUNT}
        component={LinkBankAccountScreen}
        options={{ title: sharedTitle }}
      />
      <Stack.Screen
        name={PAYMENT_METHOD_SELECTOR}
        component={PaymentMethodSelectorScreen}
        options={{ title: sharedTitle }}
      />
    </Stack.Navigator>
  )
}

export default PlansNavigator
