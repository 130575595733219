import { FC } from 'react'
import styled from 'styled-components/native'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'

interface PlanCardRowProps {
  label: string
  dollarAmount?: string
  variant?: BodyVariants
}

const PlanCardRowRoot = styled(Box)`
  margin: 8px 0;
`

const FlexContainerBetween = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
`
const StyledBody = styled(Body)`
  padding-left: 5px;
  flex-shrink: 0;
  min-width: 68px;
  text-align: right;
`

const textDark = colors['--text-dark']

const PlanCardRowGridItem: FC<PlanCardRowProps> = ({
  label,
  dollarAmount,
  variant = '--body-02-regular',
}) => (
  <PlanCardRowRoot>
    <FlexContainerBetween>
      <Body
        lightColor={textDark}
        darkColor={whiteOpacity9}
        style={{ flexShrink: 1 }}
        variant={variant}>
        {label}
      </Body>
      <StyledBody
        lightColor={textDark}
        darkColor={whiteOpacity9}
        variant="--body-02-semi-emphasized">
        {dollarAmount}
      </StyledBody>
    </FlexContainerBetween>
  </PlanCardRowRoot>
)

export default PlanCardRowGridItem
