import Svg, { Path, G, Circle } from 'react-native-svg'
import Props from './shared'
import { strawberryJam } from '../../../tokens/colors/colors'

const PlansAlt = ({ color = 'black', size = '40', focused, modifier }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <G clipPath="url(#clip0)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 25.1C21.0258 25.1 25.1 21.0258 25.1 16C25.1 10.9742 21.0258 6.90002 16 6.90002C10.9742 6.90002 6.9 10.9742 6.9 16C6.9 21.0258 10.9742 25.1 16 25.1Z"
        stroke={color}
        strokeWidth={focused ? 2 : 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.6 12.1H14.9505C14.5299 12.0665 14.1157 12.219 13.8173 12.5174C13.519 12.8157 13.3664 13.2299 13.4 13.6505C13.4 15.35 18.6 16.65 18.6 18.3495C18.6077 18.763 18.4467 19.1619 18.1543 19.4543C17.8619 19.7467 17.463 19.9076 17.0496 19.9H13.4"
        stroke={color}
        strokeWidth={focused ? 2 : 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16 12.1V10.15"
        stroke={color}
        strokeWidth={focused ? 1.5 : 1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16 21.85V19.9"
        stroke={color}
        strokeWidth={focused ? 1.5 : 1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    {modifier ? <Circle cx="26" cy="6.00012" r="3" fill={strawberryJam} /> : null}
  </Svg>
)

export default PlansAlt
