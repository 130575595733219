import { useContext } from 'react'
import { Platform } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Props from './shared'
import { ContentContext } from '../../../../providers/ContentProvider'

const HorizontalLogoFR = ({ color, width = '206.808', height = '28' }: Props) => {
  const isWeb = Platform.OS === 'web'
  const { localized } = useContext(ContentContext)
  const titleText = localized('entry.SVGTitle')
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 325 44"
      fill="none"
      aria-labelledby="web--svg_horizontal_logo_fr_title">
      {isWeb ? <title id="web--svg_horizontal_logo_fr_title">{titleText}</title> : null}
      <Path
        d="M211.374 20.8787H213.238V22.8481H213.469C213.794 22.18 214.313 21.6213 214.959 21.242C215.606 20.8627 216.352 20.6798 217.104 20.7161C220.278 20.7161 222.468 23.2025 222.468 27.1064C222.468 31.0103 220.278 33.4967 217.157 33.4967C216.398 33.5286 215.647 33.3409 214.995 32.9568C214.344 32.5727 213.821 32.0091 213.492 31.3356H213.327V37.8943H211.397L211.374 20.8787ZM216.873 31.8061C219.293 31.8061 220.538 29.709 220.538 27.0657C220.538 24.4224 219.328 22.4182 216.873 22.4182C214.513 22.4182 213.268 24.2888 213.268 27.0657C213.268 29.8426 214.548 31.8061 216.873 31.8061Z"
        fill={color || 'black'}
      />
      <Path
        d="M223.724 29.7439C223.724 26.9031 226.274 26.3977 228.605 26.1014C230.935 25.8051 231.844 25.8923 231.844 24.9744V24.9163C231.844 23.3245 230.971 22.4008 229.195 22.4008C228.522 22.3569 227.851 22.5114 227.267 22.8446C226.684 23.1778 226.215 23.6745 225.92 24.2714L224.084 23.6266C225.07 21.3668 227.23 20.7219 229.13 20.7219C230.729 20.7219 233.774 21.1751 233.774 25.108V33.2411H231.844V31.5796H231.75C231.354 32.3871 230.21 33.5432 228.015 33.5432C225.625 33.5432 223.724 32.1606 223.724 29.7439ZM231.844 28.7737V27.0309C231.52 27.4143 229.325 27.6118 228.504 27.7396C227 27.9313 225.66 28.3845 225.66 29.831C225.66 31.1555 226.769 31.8294 228.31 31.8294C230.599 31.8352 231.844 30.3248 231.844 28.7737Z"
        fill={color || 'black'}
      />
      <Path
        d="M236.164 20.8787H238.029V22.7493H238.164C238.431 22.1191 238.888 21.584 239.472 21.2155C240.056 20.847 240.74 20.6627 241.433 20.687C241.723 20.687 242.23 20.687 242.448 20.687V22.6506C242.06 22.5706 241.665 22.5278 241.268 22.5228C239.433 22.5228 238.093 23.7485 238.093 25.4274V33.2527H236.158L236.164 20.8787Z"
        fill={color || 'black'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.079 30.987C253.147 30.987 252.681 30.5397 252.681 29.8252C252.681 28.4425 254.25 27.972 257.112 27.6757C257.112 29.5173 255.844 31.0161 254.079 31.0161V30.987ZM255.324 20.6057C253.277 20.6774 251.3 21.3579 249.653 22.5577L250.834 24.9743C251.984 23.9469 253.46 23.3434 255.012 23.2664C256.428 23.2664 257.213 23.7311 257.213 24.6723C257.213 25.3055 256.693 25.625 255.708 25.747C252.043 26.2176 249.17 27.2168 249.17 29.9994C249.17 32.207 250.763 33.5432 253.253 33.5432C254.081 33.5472 254.895 33.3414 255.619 32.9457C256.342 32.5499 256.95 31.9774 257.384 31.2833V33.183H260.635V25.2241C260.635 21.9419 258.316 20.5767 255.289 20.5767"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M288.606 20.9659V33.1656H292.146V27.2981C292.146 24.498 293.864 23.6789 295.097 23.6789C295.636 23.6749 296.163 23.8286 296.613 24.1204L297.221 20.8904C296.717 20.6908 296.177 20.594 295.634 20.6057C294.833 20.5631 294.039 20.7743 293.369 21.2081C292.699 21.642 292.188 22.2758 291.91 23.0166V20.9659H288.606Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.691 20.6057C312.896 20.6179 312.116 20.8199 311.418 21.1943C310.721 21.5687 310.125 22.1043 309.684 22.7552C309.297 22.0962 308.739 21.55 308.067 21.1722C307.396 20.7944 306.634 20.5985 305.861 20.6044C305.087 20.6103 304.329 20.8178 303.664 21.2058C302.998 21.5938 302.449 22.1484 302.072 22.8133V20.9659H298.655V33.1655H302.196V26.8914C302.196 24.6374 303.376 23.5569 304.521 23.5569C305.536 23.5569 306.468 24.2017 306.468 25.8806V33.183H310.009V26.8914C310.009 24.6083 311.189 23.5569 312.369 23.5569C313.307 23.5569 314.299 24.2249 314.299 25.8399V33.183H317.839V24.7362C317.839 21.9941 315.963 20.6057 313.709 20.6057"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.967 20.9659H276.757V19.7227C276.757 18.1077 277.689 17.6488 278.527 17.6488C279.08 17.6547 279.623 17.788 280.114 18.038L281.206 15.5748C280.247 15.0647 279.167 14.8179 278.078 14.8603C275.812 14.8603 273.234 16.1151 273.234 20.0887V20.9659H267.852V19.7227C267.852 18.1077 268.784 17.6488 269.593 17.6488C270.148 17.6482 270.695 17.7818 271.186 18.038L272.278 15.5748C271.316 15.072 270.238 14.8257 269.15 14.8603C266.884 14.8603 264.306 16.1151 264.306 20.0887V20.9659H262.246V23.6614H264.306V33.183H267.846V23.6614H273.228V33.183H276.768V23.6614H279.979L279.967 20.9659Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.085 33.183H285.632V20.9659H282.085V33.183Z"
        fill={color || '#060809'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M302.756 3.927C293.179 3.927 284.64 10.4742 282.215 18.8919H285.691C287.715 12.6236 294.584 7.12215 302.756 7.12215C312.688 7.12215 321.274 14.5698 321.274 26.1594C321.301 28.5362 320.967 30.9036 320.282 33.1829H323.652L323.687 33.0668C324.253 30.8069 324.532 28.4868 324.519 26.1594C324.519 13.2336 314.948 3.927 302.756 3.927Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M37.1764 9.97461C40.3925 9.97461 43.0774 11.4095 44.1986 13.4312V11.607C44.1937 11.4776 44.2159 11.3485 44.264 11.2278C44.312 11.1072 44.3849 10.9977 44.4779 10.906C44.571 10.8144 44.6823 10.7427 44.8048 10.6954C44.9273 10.6481 45.0585 10.6262 45.19 10.6311H49.3797C49.511 10.6279 49.6417 10.651 49.7637 10.6988C49.8858 10.7467 49.9967 10.8183 50.0899 10.9095C50.1831 11.0007 50.2565 11.1095 50.3059 11.2294C50.3552 11.3493 50.3794 11.4777 50.377 11.607V32.3639C50.3794 32.4935 50.3552 32.6222 50.3059 32.7424C50.2567 32.8626 50.1833 32.9717 50.0902 33.0634C49.9971 33.155 49.8862 33.2273 49.7642 33.2758C49.6421 33.3243 49.5113 33.348 49.3797 33.3457H45.19C45.0586 33.3481 44.9281 33.3243 44.8064 33.2757C44.6846 33.2271 44.5741 33.1548 44.4815 33.0631C44.3888 32.9713 44.316 32.8621 44.2674 32.7419C44.2188 32.6218 44.1954 32.4932 44.1986 32.3639V30.5746C42.9712 32.6253 40.3925 34.0254 37.1174 34.0254C31.1279 34.0254 25.5987 29.3372 25.5987 22C25.5987 14.6628 31.122 9.97461 37.1764 9.97461ZM38.038 28.3554C41.614 28.3554 44.1986 25.5205 44.1986 21.9651C44.1986 18.4098 41.614 15.6097 38.038 15.6097C37.2063 15.6187 36.3848 15.79 35.6208 16.1137C34.8569 16.4373 34.1657 16.9069 33.5873 17.4952C33.0088 18.0836 32.5547 18.779 32.2511 19.5413C31.9474 20.3035 31.8003 21.1175 31.8183 21.9361C31.8183 25.4972 34.5328 28.3554 38.038 28.3554Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M13.3836 9.97461C10.1086 9.97461 7.48262 11.4095 6.1372 13.4312V11.607C6.1415 11.4757 6.118 11.345 6.0682 11.2231C6.0184 11.1012 5.94338 10.9909 5.84792 10.8991C5.75247 10.8073 5.63865 10.7361 5.51371 10.6899C5.38878 10.6438 5.25546 10.6237 5.12222 10.6311H0.991531C0.860682 10.6287 0.730691 10.6523 0.609327 10.7005C0.487964 10.7488 0.377719 10.8206 0.285179 10.9117C0.19264 11.0028 0.119706 11.1113 0.0707355 11.2308C0.0217649 11.3503 -0.00223755 11.4782 0.000163953 11.607V41.9784C-0.00223755 42.1072 0.0217649 42.2352 0.0707355 42.3546C0.119706 42.4741 0.19264 42.5827 0.285179 42.6738C0.377719 42.7649 0.487964 42.8367 0.609327 42.8849C0.730691 42.9331 0.860682 42.9567 0.991531 42.9544H5.19894C5.32979 42.9567 5.45978 42.9331 5.58114 42.8849C5.7025 42.8367 5.81275 42.7649 5.90529 42.6738C5.99783 42.5827 6.07076 42.4741 6.11973 42.3546C6.1687 42.2352 6.19271 42.1072 6.1903 41.9784V41.6879L6.25521 30.8012C7.64785 32.8519 10.5275 33.9615 13.3364 33.9615C19.4262 33.9615 24.7843 29.2675 24.7843 21.9361C24.7843 14.6047 19.4675 9.97461 13.3836 9.97461ZM12.3863 28.3554C8.84575 28.3554 6.1608 25.5205 6.1608 21.9651C6.12911 21.1398 6.26653 20.3167 6.56488 19.5448C6.86323 18.7729 7.31639 18.0681 7.89735 17.4723C8.47831 16.8765 9.17518 16.4019 9.94641 16.077C10.7176 15.752 11.5474 15.5832 12.3863 15.5806C15.9623 15.5806 18.5411 18.4156 18.5411 21.9361C18.5411 25.4566 15.9623 28.3554 12.3863 28.3554Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M74.0576 10.6601H69.5552C69.2661 10.6562 68.9849 10.7524 68.7606 10.9318C68.5362 11.1112 68.383 11.3626 68.3277 11.6419L66.3745 18.4504L63.4948 27.24L58.6619 11.6303C58.6359 11.3758 58.5191 11.1386 58.3321 10.9609C58.1452 10.7833 57.9003 10.6767 57.641 10.6601H52.7432C51.6515 10.6601 51.5158 11.3456 51.7518 12.1299L58.7327 32.3348C58.7991 32.6081 58.9555 32.852 59.1776 33.0286C59.3996 33.2052 59.6748 33.3044 59.9601 33.3108H61.6124L58.4023 41.4904C58.0718 42.4024 58.3374 42.9543 59.2638 42.9543H63.7663C64.0548 42.9596 64.3359 42.8647 64.5604 42.6862C64.7848 42.5077 64.9382 42.2571 64.9937 41.9784L65.2887 40.9036L68.1035 33.3514L75.0195 12.2054C75.2496 11.2178 74.9487 10.6601 74.0576 10.6601Z"
        fill={color || '#4A4AF4'}
      />
      <Path
        d="M115.931 10.2708C116.462 10.2708 117.023 10.4974 117.023 11.4792V15.5457C117.036 15.7038 117.016 15.8629 116.962 16.0125C116.908 16.162 116.822 16.2985 116.71 16.4129C116.599 16.5273 116.464 16.6169 116.314 16.6757C116.164 16.7345 116.004 16.7612 115.843 16.7541H114.662C111.617 16.7541 109.829 18.2819 109.829 22.1916V32.3638C109.826 32.4879 109.797 32.6101 109.745 32.7232C109.693 32.8364 109.619 32.9383 109.527 33.023C109.434 33.1078 109.326 33.1738 109.208 33.2172C109.09 33.2606 108.964 33.2805 108.838 33.2759H104.637C104.508 33.2751 104.38 33.2492 104.262 33.1996C104.143 33.15 104.036 33.0777 103.946 32.9869C103.856 32.8962 103.785 32.7887 103.737 32.6707C103.69 32.5528 103.666 32.4268 103.669 32.2999V11.5082C103.665 11.3784 103.688 11.2492 103.736 11.1286C103.785 11.008 103.859 10.8985 103.952 10.8069C104.046 10.7154 104.157 10.6438 104.28 10.5965C104.403 10.5493 104.534 10.5274 104.666 10.5322H108.637C108.892 10.5244 109.139 10.6159 109.324 10.7869C109.51 10.9578 109.62 11.1942 109.629 11.4443V13.5938C110.18 12.5866 110.996 11.7436 111.992 11.153C112.987 10.5623 114.126 10.2456 115.288 10.236C115.501 10.2656 115.716 10.2773 115.931 10.2708Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M117.654 3.75274C117.66 3.00725 117.89 2.2801 118.314 1.66264C118.738 1.04518 119.338 0.564964 120.039 0.282329C120.74 -0.000305787 121.51 -0.0727517 122.252 0.0740982C122.995 0.220948 123.677 0.580543 124.212 1.10769C124.747 1.63485 125.113 2.30604 125.262 3.03694C125.411 3.76783 125.337 4.52583 125.05 5.21568C124.763 5.90553 124.275 6.49647 123.648 6.91422C123.021 7.33198 122.282 7.55793 121.525 7.56368C120.499 7.56368 119.514 7.16217 118.788 6.44748C118.062 5.73279 117.654 4.76346 117.654 3.75274ZM119.424 33.2469C119.293 33.2476 119.163 33.2228 119.041 33.1738C118.919 33.1248 118.809 33.0526 118.715 32.9614C118.622 32.8701 118.548 32.7616 118.498 32.6421C118.447 32.5226 118.421 32.3945 118.421 32.2651V11.5082C118.416 11.3788 118.439 11.2497 118.487 11.129C118.535 11.0084 118.608 10.8989 118.701 10.8072C118.794 10.7156 118.905 10.6439 119.027 10.5966C119.15 10.5493 119.281 10.5274 119.413 10.5323H123.543C123.675 10.5274 123.806 10.5493 123.929 10.5966C124.051 10.6439 124.162 10.7156 124.255 10.8072C124.348 10.8989 124.421 11.0084 124.469 11.129C124.517 11.2497 124.54 11.3788 124.535 11.5082V32.2012C124.529 32.4587 124.423 32.7042 124.238 32.8869C124.054 33.0696 123.805 33.1756 123.543 33.183H119.413L119.424 33.2469Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M198.392 29.889L196.934 27.5653C196.533 26.9437 195.754 26.9437 195.081 27.4026C194.432 27.8303 193.673 28.0662 192.892 28.0823C191.8 28.0823 190.248 27.4026 190.248 25.1777V15.5806H196.934C197.195 15.5747 197.444 15.47 197.628 15.2882C197.813 15.1064 197.919 14.8616 197.925 14.6046V11.4792C197.925 11.2198 197.821 10.971 197.635 10.787C197.45 10.603 197.197 10.4989 196.934 10.4974H190.248V5.80343C190.252 5.67213 190.229 5.54139 190.179 5.41952C190.129 5.29764 190.054 5.1873 189.959 5.09551C189.863 5.00372 189.75 4.93248 189.625 4.88634C189.5 4.84019 189.366 4.82014 189.233 4.82746H185.032C184.9 4.82169 184.768 4.84298 184.645 4.88999C184.523 4.93699 184.411 5.00868 184.318 5.10048C184.224 5.19229 184.152 5.3022 184.104 5.42322C184.056 5.54425 184.034 5.67373 184.04 5.80343V10.5613H180.86C180.729 10.5612 180.599 10.5871 180.479 10.6375C180.358 10.6878 180.249 10.7615 180.158 10.8543C180.067 10.947 179.997 11.0568 179.95 11.1772C179.903 11.2975 179.881 11.4259 179.886 11.5547V14.6801C179.883 14.8094 179.906 14.938 179.955 15.0582C180.003 15.1784 180.076 15.2876 180.169 15.3793C180.261 15.471 180.372 15.5434 180.494 15.5919C180.616 15.6405 180.746 15.6643 180.877 15.6619H184.058V26.5951C184.058 31.7132 187.929 33.7348 191.7 33.7348C194.349 33.7348 196.698 32.9506 198.22 31.4518C198.443 31.2597 198.583 30.9926 198.615 30.7032C198.647 30.4138 198.567 30.1233 198.392 29.889Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M145.755 33.183V30.1796C144.574 32.0095 142.013 33.4096 138.774 33.4096C132.448 33.4096 127.119 28.977 127.119 21.6747C127.119 14.3723 132.548 9.88165 138.538 9.88165C141.819 9.88165 144.598 11.3456 145.755 13.3324V11.5083C145.752 11.3795 145.776 11.2515 145.825 11.132C145.874 11.0125 145.947 10.904 146.04 10.8129C146.132 10.7218 146.242 10.65 146.364 10.6018C146.485 10.5536 146.615 10.5299 146.746 10.5323H150.877C151.008 10.5274 151.139 10.5493 151.262 10.5966C151.384 10.6439 151.496 10.7157 151.589 10.8073C151.682 10.8989 151.755 11.0085 151.803 11.1291C151.851 11.2497 151.873 11.3788 151.868 11.5083V33.3457C151.868 39.6314 147.147 44 139.476 44C133.321 44 128.819 40.1542 127.727 35.8204C127.685 35.7273 127.664 35.6264 127.665 35.5245C127.666 35.4226 127.689 35.322 127.732 35.2296C127.776 35.1371 127.839 35.055 127.917 34.9885C127.996 34.9221 128.088 34.873 128.187 34.8445H132.725C133.315 34.8445 133.652 34.9084 133.817 35.2337C134.342 36.93 136.13 38.6903 139.576 38.6903C143.796 38.7832 145.755 36.5699 145.755 33.183ZM139.565 28.0301C143.141 28.0301 145.725 25.3578 145.725 21.8083C145.725 18.2588 143.141 15.5806 139.565 15.5806C138.74 15.573 137.922 15.7302 137.16 16.0426C136.399 16.355 135.709 16.8163 135.134 17.3984C134.559 17.9805 134.109 18.6714 133.813 19.4294C133.517 20.1874 133.379 20.9966 133.41 21.8083C133.41 25.3578 136.024 28.0301 139.565 28.0301Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M90.5096 9.81192C87.2286 9.81192 84.5142 11.2817 83.3576 13.1697V8.38282L83.2927 1.95766C83.2852 1.70015 83.1775 1.45531 82.9919 1.27369C82.8064 1.09207 82.557 0.98754 82.2954 0.981689H78.1647C78.0332 0.976798 77.9021 0.998697 77.7795 1.04601C77.657 1.09333 77.5457 1.16504 77.4527 1.25666C77.3596 1.34829 77.2867 1.45784 77.2387 1.57846C77.1906 1.69909 77.1684 1.82818 77.1733 1.95766L77.2383 8.38282V32.2012C77.2351 32.3305 77.2585 32.4591 77.3071 32.5793C77.3557 32.6994 77.4285 32.8087 77.5211 32.9004C77.6137 32.9921 77.7243 33.0644 77.846 33.113C77.9678 33.1616 78.0983 33.1854 78.2296 33.183H82.3603C82.4915 33.1846 82.6216 33.1602 82.743 33.1113C82.8644 33.0625 82.9745 32.9901 83.067 32.8986C83.1595 32.807 83.2323 32.6981 83.2812 32.5783C83.3301 32.4585 83.3541 32.3303 83.3517 32.2012V30.3771C84.6735 32.4336 87.1578 33.8336 90.4329 33.8336C96.4932 33.8336 101.958 29.1397 101.958 21.8083C101.958 14.4768 96.4637 9.81192 90.5096 9.81192ZM89.5477 28.2566C86.0366 28.2566 83.393 25.4217 83.393 21.8663C83.393 18.311 86.0366 15.5109 89.5477 15.5109C90.3796 15.5199 91.2015 15.6912 91.9658 16.0148C92.7301 16.3384 93.4216 16.8078 94.0005 17.3961C94.5794 17.9844 95.0341 18.6797 95.3384 19.442C95.6427 20.2043 95.7905 21.0184 95.7733 21.8373C95.7025 25.3926 93.047 28.2566 89.5477 28.2566Z"
        fill={color || '#2ABC7A'}
      />
      <Path
        d="M169.294 9.81189C165.617 9.81189 162.968 11.3456 161.622 13.1697V1.92859C161.613 1.67843 161.504 1.44205 161.318 1.27113C161.132 1.10022 160.885 1.00867 160.631 1.01652H156.424C156.293 1.01415 156.163 1.03778 156.041 1.08599C155.92 1.1342 155.81 1.206 155.717 1.29711C155.625 1.38821 155.552 1.49674 155.503 1.61622C155.454 1.7357 155.43 1.86367 155.432 1.99249V32.2012C155.429 32.3305 155.452 32.4591 155.501 32.5792C155.55 32.6994 155.623 32.8086 155.715 32.9003C155.808 32.9921 155.918 33.0644 156.04 33.113C156.162 33.1615 156.292 33.1853 156.424 33.183H160.631C160.886 33.1892 161.133 33.0964 161.318 32.9245C161.504 32.7527 161.613 32.5157 161.622 32.2651V21.3202C161.622 17.7998 163.936 15.4179 167.382 15.4179C170.958 15.4179 172.976 17.7417 172.976 21.3202V32.2651C172.98 32.3894 173.008 32.5119 173.06 32.6253C173.112 32.7388 173.186 32.841 173.278 32.9263C173.37 33.0115 173.479 33.0781 173.597 33.1221C173.715 33.1661 173.841 33.1868 173.967 33.183H178.098C178.224 33.1868 178.35 33.1661 178.468 33.1221C178.587 33.0781 178.695 33.0115 178.787 32.9263C178.879 32.841 178.953 32.7388 179.005 32.6253C179.057 32.5119 179.086 32.3894 179.089 32.2651V19.6878C179.201 14.0527 175.384 9.81189 169.294 9.81189Z"
        fill={color || '#2ABC7A'}
      />
    </Svg>
  )
}
export default HorizontalLogoFR
