import { useContext } from 'react'
import { Image, StyleSheet, ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Button from '../../atoms/Button'
import Box from '../../atoms/Box'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Heading from '../../atoms/Typography/Heading'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { PHONE_NUMBER_ENTRY } from '../../../types'

const SharedBody = ({ variant, children }: { variant: BodyVariants; children: string }) => (
  <Body
    style={styles.body}
    variant={variant}
    lightColor={colors['--text-dark']}
    darkColor={whiteOpacity9}>
    {children}
  </Body>
)

export const NoPlansMessage = ({ showImage }: { showImage?: boolean }) => {
  const { localized } = useContext(ContentContext)
  const { viewPort } = useContext(DimensionContext)
  const { isLoggedIn } = useContext(AuthContext)
  const navigation = useNavigation()
  const { replace } = navigation

  const navigateToLinkAccount = () => {
    replace(PHONE_NUMBER_ENTRY)
  }

  return (
    <ScrollView contentContainerStyle={[styles.wrapper, styles[`wrapper-${viewPort}`]]}>
      <Box style={styles.container}>
        {showImage ? (
          <Image style={styles.image} source={require('../../../assets/images/shopping-bag.png')} />
        ) : null}
        <Box style={styles.titleWrapper}>
          <Heading style={styles.heading} variant="--heading-2">
            {localized('plans.errorNoPlans.header')}
          </Heading>
        </Box>
        {isLoggedIn ? null : (
          <>
            <SharedBody variant="--body-01-regular">
              {localized('plans.errorNoPlans.paragraph')}
            </SharedBody>
            <View style={styles.buttonContainer}>
              <Button isFullWidthOnMobile testID="CloseBtn" onPress={navigateToLinkAccount}>
                {localized('plans.errorNoPlans.button')}
              </Button>
            </View>
          </>
        )}
      </Box>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    maxWidth: 552,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  'wrapper-xs': {
    paddingHorizontal: 16,
    paddingVertical: 48,
  },
  'wrapper-sm': {
    paddingHorizontal: 24,
    paddingVertical: 48,
  },
  'wrapper-md': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'wrapper-lg': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  'wrapper-xl': {
    paddingHorizontal: 72,
    paddingVertical: 64,
  },
  image: {
    alignSelf: 'center',
    width: 129,
    height: 129,
    marginBottom: 24,
  },
  heading: {
    textAlign: 'center',
  },
  body: {
    textAlign: 'center',
    marginBottom: 40,
  },
  buttonContainer: {
    width: '100%',
  },
})
