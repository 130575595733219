import { createRef, ReactNode, useContext, useState, useEffect } from 'react'
import { StyleSheet, Platform, TouchableOpacity, Text } from 'react-native'
import { Modal } from 'react-native-paper'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  BottomTabParamList,
  StatusDescription,
  PURCHASES_TAB,
  PROFILE_TAB,
} from '../types'
import { Plans, Profile } from '../components/atoms/Icons'
import colors from '../components/tokens/colors'
import { darker4, ink0, whiteOpacity9 } from '../components/tokens/colors/colors'
import {
  DimensionContext,
  CustomerContext,
  ContractContext,
  AuthContext,
  ContentContext,
  ThemeContext,
} from '../providers'
import SideNav from './SideNav'
import Box from '../components/atoms/Box'
import Card from '../components/atoms/Card'
import Body from '../components/atoms/Typography/Body'
import Heading from '../components/atoms/Typography/Heading'
import Button from '../components/atoms/Button'
import { BlurredOverlay } from '../components/molucules/BlurredOverlay/index.ios'
import AccountNavigator from './AccountNavigator'
import PlansNavigator from './PlansNavigator'
import { navigateToLogin } from '../helpers'
import { useFetch, RequestStatus } from '../hooks/useFetch'
import { useNavigation } from '@react-navigation/native'
import { logout } from '../endpoints'

const BottomTab = createBottomTabNavigator<BottomTabParamList>()

const assignColor = (isDark: boolean, isActive: boolean) => {
  if (!isDark) {
    if (isActive) {
      return colors['--text-01']
    }
    return colors['--ui-inactive-02']
  }

  if (isActive) {
    return whiteOpacity9
  }
  return colors['--text-disabled']
}

interface DesktopWrapperProps {
  children: ReactNode
  isDesktop: boolean
  isLogoutModalOpen: boolean
  closeModal: () => void
}

const DesktopWrapper = ({
  children,
  isDesktop,
  isLogoutModalOpen,
  closeModal,
}: DesktopWrapperProps) => {
  const { localized } = useContext(ContentContext)
  const { resetCustomer } = useContext(CustomerContext)
  const { resetAuth } = useContext(AuthContext)
  const navigation = useNavigation()
  const [url, setUrl] = useState('')
  const { status, data } = useFetch({
    url,
    method: 'post',
  })

  useEffect(() => {
    if (status === RequestStatus.FETCHED && data) {
      const { statusDescription } = data
      if (statusDescription === StatusDescription.SUCCESS) {
        setUrl('')
        resetAuth()
        resetCustomer()
        navigateToLogin(navigation)
      }
    }
  }, [status, data])
  const doLogout = async () => {
    closeModal()
    setUrl(logout)
  }

  return isDesktop ? (
    <Box style={{ flexDirection: 'row' }}>
      <>
        {children}
        <Modal
          dismissable={true}
          contentContainerStyle={styles.modal}
          visible={isLogoutModalOpen}
          onDismiss={closeModal}>
          <Card style={styles.card}>
            <Heading style={styles.heading} variant="--heading-2">
              {localized('global.logOutConfirm')}
            </Heading>
            <Box style={styles.margin}>
              <Button onPress={doLogout}>{localized('global.logOut')}</Button>
            </Box>
            <TouchableOpacity onPress={closeModal}>
              <Body
                style={{textAlign: 'center'}}
                lightColor={colors['--text-01']}
                darkColor={whiteOpacity9}
                variant="--body-01-semi-emphasized">
                {localized('global.cancel')}
              </Body>
            </TouchableOpacity>
          </Card>
        </Modal>
      </>
    </Box>
  ) : (
    <>{children}</>
  )
}

const BottomNavigator = () => {
  const { theme } = useContext(ThemeContext)
  const { localized } = useContext(ContentContext)
  const isDark = theme === 'dark'
  const { viewPort } = useContext(DimensionContext)
  const { hasPaymentProblem } = useContext(ContractContext)
  const activeColor = assignColor(isDark, true)
  const inactiveColor = assignColor(isDark, false)
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)

  const backgroundColor = isDark ? darker4 : ink0

  const isDesktop = viewPort === 'xl'

  const isNative = Platform.OS !== 'web'
  const isIOS = Platform.OS === 'ios'

  const openLogoutModal = () => {
    setLogoutModalOpen(true)
  }

  const closeLogoutModal = () => {
    setLogoutModalOpen(false)
  }

  return (
    <>
      <DesktopWrapper
        isDesktop={isDesktop}
        isLogoutModalOpen={isLogoutModalOpen}
        closeModal={closeLogoutModal}>
        <SideNav openModal={openLogoutModal} />
        {isIOS && <BlurredOverlay />}
          <BottomTab.Navigator
            screenOptions={{ headerShown: false,
            tabBarStyle: isDesktop
              ? { display: 'none' }
              : { backgroundColor, height: 72, paddingTop: 8, paddingBottom: 12 },
            }}
            tabBarOptions={{
              keyboardHidesTabBar: true,
              activeTintColor: activeColor,
              inactiveTintColor: inactiveColor,
              labelStyle: { fontFamily: 'Calibre-semi', fontSize: 16 }
            }}>
            <BottomTab.Screen
              name={PURCHASES_TAB}
              component={PlansNavigator}
              options={{
                title: localized('pageTitle.plans'),
                tabBarVisible: !isDesktop,
                tabBarIcon: ({ color, focused }) => (
                  <Plans color={color} focused={focused} modifier={hasPaymentProblem} />
                ),
              }}
            />

            <BottomTab.Screen
              name={PROFILE_TAB}
              component={AccountNavigator}
              options={{
                title: localized('pageTitle.account'),
                tabBarVisible: !isDesktop,
                tabBarIcon: ({ color, focused }) => <Profile color={color} focused={focused} />,
              }}
            />
          </BottomTab.Navigator>
      </DesktopWrapper>
    </>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    maxWidth: 552,
    shadowColor: 'transparent',
    textAlign: 'center',
    marginHorizontal: 'auto',
    marginVertical: 0,
  },
  card: {
    paddingVertical: 64,
    paddingHorizontal: 72,
    justifyContent: 'center',
    alignContent: 'center',
  },
  heading: {
    marginBottom: 40,
  },
  margin: {
    marginBottom: 24,
  },
})

export default BottomNavigator
