import { FC, useContext } from 'react'
import { TouchableOpacity, StyleSheet, Linking } from 'react-native'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { darker4 } from '../../tokens/colors/colors'
import elevation from '../../tokens/elevation'
import { HorizontalLogoEN, HorizontalLogoFR } from '../../atoms/Icons'
import Box from '../../atoms/Box'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { ContentContext } from '../../../providers/ContentProvider'

interface NavBarProps {
  lightColor?: string
  hideOnMbile?: boolean
}

const NavBar: FC<NavBarProps> = ({ hideOnMbile }) => {
  const { theme } = useContext(ThemeContext)
  const { viewPort } = useContext(DimensionContext)

  const { getLocaleCode } = useContext(ContentContext)
  const isFrench = getLocaleCode() === 'fr-CA'

  if (viewPort === 'xs' && hideOnMbile) {
    return null
  }

  const Logo = isFrench ? HorizontalLogoFR : HorizontalLogoEN

  return (
    <Box
      paddingHorizontal={[16, 16, 16, 120, 120]}
      backgroundDark={darker4}
      backgroundLight="white"
      style={[styles.container, styles[viewPort]]}>
      <TouchableOpacity onPress={() => Linking.openURL('https://paybright.com')}>
        <Logo color={theme === 'dark' ? 'white' : ''} />
      </TouchableOpacity>
    </Box>
  )
}
export default NavBar

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    ...elevation['--elevation-regular-2'],
  },
  xs: {
    minHeight: 64,
  },
  sm: {
    minHeight: 64,
  },
  md: {
    minHeight: 88,
  },
  lg: {
    minHeight: 88,
  },
  xl: {
    minHeight: 88,
  },
  togglerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  toggler: {
    marginLeft: 20,
  },
})
