import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const Profile = ({ color = 'black', size = '40', focused }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <Path
      d="M16 14.5385C18.0817 14.5385 19.7692 12.8509 19.7692 10.7692C19.7692 8.68754 18.0817 7 16 7C13.9183 7 12.2308 8.68754 12.2308 10.7692C12.2308 12.8509 13.9183 14.5385 16 14.5385Z"
      stroke={color}
      strokeWidth={focused ? 2 : 1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9 24.1111C9 22.2546 9.7375 20.4741 11.0503 19.1613C12.363 17.8486 14.1435 17.1111 16 17.1111C17.8565 17.1111 19.637 17.8486 20.9497 19.1613C22.2625 20.4741 23 22.2546 23 24.1111"
      stroke={color}
      strokeWidth={focused ? 2 : 1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Profile
