import { useContext, useEffect, useState, useRef } from 'react'
import { StyleSheet, Platform, TouchableOpacity } from 'react-native'
import { Modal, Portal } from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native'
import Box from '../../atoms/Box'
import Heading from '../../atoms/Typography/Heading'
import { darkest, whiteOpacity9, ink0 } from '../../tokens/colors/colors'
import { DimensionContext, ViewPortType } from '../../../providers/DimensionProvider'
import AccountMenuItem, { MenuItem } from '../../molucules/AccountMenuItem'
import Card from '../../atoms/Card'
import Body from '../../atoms/Typography/Body'
import colors from '../../tokens/colors'
import Button from '../../atoms/Button'
import { navigateToLogin } from '../../../helpers'
import { useToggleRootBackgroundColor } from '../../../hooks/useToogleRootBackgroundColor'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { AuthContext } from '../../../providers/AuthProvider'
import { ContentContext, ThemeContext } from '../../../providers'
import { Localized } from '../../../localization/types'
import { logout } from '../../../endpoints'
import {
  HowItWorks,
  Logout,
  Help,
  AccountInformation,
  AppSettings,
  Moon,
  BiometricLogin,
} from '../../atoms/Icons'
import {
  ACCOUNT_INFORMATION,
  DARK_MODE,
  LANGUAGE_SETTINGS,
  StatusDescription,
  AccessLevel,
  PHONE_NUMBER_ENTRY,
  BIOMETRICS,
} from '../../../types'
import SuccessModal from '../../molucules/SuccessModal'
import { CustomerContext } from '../../../providers/CustomerProvider'
import { useCheckBiometricsCapable } from '../../../hooks/useCheckBiometricsCapable'
const ACCOUNT_INFORMATION_ID = 'account-information'
const BIOMETRICS_ID = 'biometrics'
const LANGUAGE_SETTINGS_ID = 'language-settings'
const DARK_MODE_ID = 'dark-mode'
const HOW_IT_WORKS_ID = 'how-it-works'
const HELP_ID = 'help'
const LOG_OUT_ID = 'log-out'
const LOG_IN_ID = 'log-in'

const menuItems = (localized: Localized, accessLevel: AccessLevel): MenuItem[] => {
  type MenuOrNull = MenuItem | null
  const isMenuItem = <T extends {}>(argument: T | null): argument is T => argument !== null
  const isAuthorized = accessLevel === AccessLevel.AUTHORIZED_USER
  const isBiometricsAvailable = useCheckBiometricsCapable()

  const biometricMenuItemOrNull: MenuOrNull =
    isAuthorized && isBiometricsAvailable
      ? {
          id: BIOMETRICS_ID,
          label: localized('biometrics.header'),
          link: BIOMETRICS,
          icon: BiometricLogin,
          type: 'internal',
        }
      : null
  const accountMenuItemOrNull: MenuOrNull = isAuthorized
    ? {
        id: ACCOUNT_INFORMATION_ID,
        label: localized('accountInformation.header'),
        link: ACCOUNT_INFORMATION,
        icon: AccountInformation,
        type: 'internal',
      }
    : null

  const items: MenuOrNull[] = [
    accountMenuItemOrNull,
    biometricMenuItemOrNull,
    {
      id: LANGUAGE_SETTINGS_ID,
      label: localized('languageSettings.header'),
      link: LANGUAGE_SETTINGS,
      icon: AppSettings,
      type: 'internal',
    },
    {
      id: DARK_MODE_ID,
      label: localized('darkMode.header'),
      link: DARK_MODE,
      icon: Moon,
      type: 'internal',
    },
    {
      id: HOW_IT_WORKS_ID,
      label: localized('howItWorks.header'),
      link: localized('howItWorks.link'),
      icon: HowItWorks,
      type: 'external',
    },
    {
      id: HELP_ID,
      label: localized('help.header'),
      link: localized('help.link'),
      icon: Help,
      type: 'external',
    },
    accessLevel === AccessLevel.AUTHORIZED_USER
      ? { id: LOG_OUT_ID, label: localized('global.logOut'), link: '', icon: Logout, type: 'modal' }
      : {
          id: LOG_IN_ID,
          label: localized('global.logIn'),
          link: PHONE_NUMBER_ENTRY,
          icon: Logout,
          type: 'logIn',
        },
  ]
  return items.filter(isMenuItem)
}
interface GetMenuItems {
  viewPort: ViewPortType
  localized: Localized
  accessLevel: AccessLevel
}

const getMenuItems = ({ viewPort, localized, accessLevel }: GetMenuItems): MenuItem[] => {
  if (Platform.OS === 'web') {
    if (viewPort === 'xl') {
      return menuItems(localized, accessLevel).filter(item => item.id !== LOG_OUT_ID)
    }
    return menuItems(localized, accessLevel)
  }
  return menuItems(localized, accessLevel)
}

const Account = () => {
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false)
  const [isGenericModalOpen, setGenericModalOpen] = useState(false)
  const { customer, resetCustomer } = useContext(CustomerContext)
  const { theme } = useContext(ThemeContext)
  const { localized } = useContext(ContentContext)
  const route = useRoute()
  const { accessLevel, resetAuth } = useContext(AuthContext)
  const { viewPort } = useContext(DimensionContext)
  const navigation = useNavigation()
  const items = getMenuItems({ viewPort, localized, accessLevel })
  const [url, setUrl] = useState('')
  const isMounted = useRef(false)
  const { status, data } = useFetch({
    url,
    method: 'post',
  })

  useToggleRootBackgroundColor(ink0, darkest)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (status === RequestStatus.FETCHED && data) {
      const { statusDescription } = data
      if (statusDescription === StatusDescription.SUCCESS) {
        setUrl('')
        resetAuth()
        resetCustomer()
        navigateToLogin(navigation)
      }
    }
  }, [status, data])

  const doLogout = async () => {
    onDismissHandler()
    setUrl(logout)
  }

  const type = route?.params?.type

  useEffect(() => {
    if (type) {
      setGenericModalOpen(true)
    }
  }, [type])

  const onDismissHandler = () => {
    isMounted.current && setLogoutModalOpen(false)
  }

  return (
    <>
      <Box scrollView={true} backgroundLight={ink0} backgroundDark={darkest}>
        <Box marginTop={[64, 64, 64, 80, 80]} marginHorizontal={[16, 16, 16, 80, 80]}>
          <Heading style={styles.margin}>{localized('profile.header')}</Heading>
          {items.map(item => (
            <AccountMenuItem key={item.label} item={item} setModalOpen={setLogoutModalOpen} />
          ))}
        </Box>
      </Box>
      <Portal>
        <SuccessModal
          customer={customer}
          type={type}
          setGenericModalOpen={setGenericModalOpen}
          isGenericModalOpen={isGenericModalOpen}
        />
        <Modal
          dismissable={true}
          contentContainerStyle={styles.modalContainer}
          visible={isLogoutModalOpen}
          onDismiss={onDismissHandler}>
          <Card style={styles.card}>
            <Body
              lightColor={colors['--text-dark']}
              darkColor={whiteOpacity9}
              style={[styles.body, styles.margin]}
              variant="--body-01-semi-emphasized">
              {localized('global.logOutConfirm')}
            </Body>
            <Box style={styles.margin}>
              <Button onPress={doLogout}>{localized('global.logOut')}</Button>
            </Box>
            <TouchableOpacity onPress={onDismissHandler}>
              <Body
                style={styles.body}
                lightColor={colors['--text-01']}
                darkColor={whiteOpacity9}
                variant="--body-01-semi-emphasized">
                {localized('global.cancel')}
              </Body>
            </TouchableOpacity>
          </Card>
        </Modal>
      </Portal>
    </>
  )
}

export default Account

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0,
    paddingHorizontal: 8,
  },
  card: {
    paddingHorizontal: 72,
    paddingVertical: 64,
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 552,
  },
  body: {
    textAlign: 'center',
  },
  margin: {
    marginBottom: 24,
    justifyContent: 'center',
    alignContent: 'center',
  },
})
