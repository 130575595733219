import { VFC, useContext, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { ContentContext } from '../../../providers/ContentProvider'
import { BillingAddress } from '../../../types'
import Box from '../../atoms/Box'
import { validatePostalCode, validateProvince } from '../../../helpers'
import SharedInput from '../SharedInput'
import {
  MASK_POSTAL_CODE,
  MASK_PROVINCE,
  MASK_ADDRESS,
  MASK_ADDRESS_SHORT,
  MASK_NAME,
} from '../../../helpers/maskTypes'

interface Props {
  setIsLookupAddressVisible?: () => void
  setFormValid: (bool: boolean) => void
  hasLookupAddress?: boolean
  setBillingAddress: (arg: any) => void
  billingAddress: BillingAddress | null
}

export const defaultBillingAddress: BillingAddress = {
  addrAptSuite: '',
  addrCity: '',
  addrHouseNo: '',
  addrStreetName: '',
  addrProvince: '',
  addrPostalCode: '',
}

const ManualAddressInput: VFC<Props> = ({
  setBillingAddress,
  billingAddress,
  hasLookupAddress = false,
  setIsLookupAddressVisible,
  setFormValid,
}) => {
  const { addrAptSuite, addrHouseNo, addrStreetName, addrCity, addrProvince, addrPostalCode } =
    billingAddress || defaultBillingAddress

  const { localized } = useContext(ContentContext)

  const setCustomerAddress = (key: string, value: string) => {
    const newValue = ['addrPostalCode', 'addrProvince'].includes(key)
      ? value.toUpperCase().trim()
      : value.replace(/\s+/g, ' ')
    setBillingAddress((prevState: BillingAddress) => ({
      ...prevState,
      ...{ [key]: newValue },
    }))
  }

  const deriveProvFromPostCode = (postCode: string = '') => {
    const normalizedPC = postCode.toUpperCase()
    const [firstChar] = normalizedPC
    const FSA = normalizedPC.substr(0, 3)
    switch (firstChar) {
      case 'A':
        return 'NL'
      case 'B':
        return 'NS'
      case 'C':
        return 'PE'
      case 'E':
        return 'NB'
      case 'G':
      case 'H':
      case 'J':
        return 'QC'
      case 'K':
      case 'L':
      case 'M':
      case 'N':
      case 'P':
        return 'ON'
      case 'R':
        return 'MB'
      case 'S':
        return 'SK'
      case 'T':
        return 'AB'
      case 'V':
        return 'BC'
      case 'Y':
        return 'YT'
      case 'X':
        return ['X0A', 'X0B', 'X0C'].includes(FSA)
          ? 'NU'
          : ['X1A', 'X0E', 'X0G'].includes(FSA)
          ? 'NT'
          : ''
      default:
        return ''
    }
  }

  useEffect(() => {
    if (!addrPostalCode) return
    const prov = deriveProvFromPostCode(addrPostalCode || '')
    setBillingAddress((prevState: BillingAddress) => ({
      ...prevState,
      addrProvince: prov,
    }))
  }, [addrPostalCode])

  useEffect(() => {
    const isValid = Boolean(
      addrHouseNo &&
        addrStreetName &&
        addrCity &&
        validateProvince(addrProvince || '') &&
        validatePostalCode(addrPostalCode || '')
    )
    setFormValid(isValid)
  }, [addrAptSuite, addrHouseNo, addrStreetName, addrCity, addrProvince, addrPostalCode])

  const sharedProps = { maskChar: '', inputProps: { autoComplete: 'new-password' } }

  const getErrorString = (key: string, value: string | undefined) =>
    localized(`address.error${value ? key : 'Empty'}`)

  return (
    <>
      <Box style={styles.box}>
        <SharedInput
          style={styles.inputLeft}
          textContentType="streetAddressLine1"
          onChangeText={text => setCustomerAddress('addrHouseNo', text)}
          label={localized('address.labelStreetNumber')}
          error={!Boolean(addrHouseNo)}
          errorMessage={getErrorString('StreetNumber', addrHouseNo)}
          maskProps={MASK_ADDRESS_SHORT}
          value={addrHouseNo}
          {...sharedProps}
        />
        <SharedInput
          style={styles.inputRight}
          textContentType="none"
          onChangeText={text => setCustomerAddress('addrAptSuite', text)}
          label={localized('address.labelUnit')}
          error={false}
          errorMessage={''}
          maskProps={MASK_ADDRESS_SHORT}
          value={addrAptSuite}
          {...sharedProps}
        />
      </Box>
      <SharedInput
        textContentType="fullStreetAddress"
        onChangeText={text => setCustomerAddress('addrStreetName', text)}
        label={localized('address.labelStreetName')}
        error={!Boolean(addrStreetName)}
        errorMessage={getErrorString('StreetName', addrStreetName)}
        maskProps={MASK_ADDRESS}
        value={addrStreetName}
        {...sharedProps}
      />
      <SharedInput
        textContentType="addressCity"
        onChangeText={text => setCustomerAddress('addrCity', text)}
        label={localized('address.labelCity')}
        error={!Boolean(addrCity)}
        errorMessage={getErrorString('City', addrCity)}
        maskProps={MASK_NAME}
        value={addrCity}
        {...sharedProps}
      />
      <Box style={styles.box}>
        <SharedInput
          style={styles.inputLeft}
          textContentType="addressState"
          disabled={true}
          onChangeText={() => null}
          label={localized('address.labelProvince')}
          value={addrProvince}
          maskProps={MASK_PROVINCE}
          {...sharedProps}
        />
        <SharedInput
          style={styles.inputRight}
          textContentType="postalCode"
          onChangeText={text => {
            setCustomerAddress('addrPostalCode', text.replace(/ /g, ''))
          }}
          label={localized('address.labelPostalCode')}
          error={!validatePostalCode(addrPostalCode || '')}
          errorMessage={getErrorString('PostalCode', addrPostalCode)}
          maskProps={MASK_POSTAL_CODE}
          value={addrPostalCode}
          {...sharedProps}
        />
      </Box>
      {/* {hasLookupAddress && (
      <Box style={styles.linkWrapper}>
        <Link onPress={() => setIsLookupAddressVisible(true)}>Look up your address</Link>
      </Box>
    )} */}
    </>
  )
}

export default ManualAddressInput

const styles = StyleSheet.create({
  box: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  inputLeft: {
    marginRight: 8,
    flex: 1,
  },
  inputRight: {
    marginLeft: 8,
    flex: 1,
  },
  linkWrapper: {
    marginTop: 8,
    marginBottom: 28,
  },
})
