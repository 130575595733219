import { ReactNode } from 'react'
import { StyleSheet } from 'react-native'
import Box from '../../atoms/Box'
import { darkest, beigeBread25 } from '../../tokens/colors/colors'

const ScreenWrapper = ({ children }: { children: ReactNode }) => (
  <Box
    scrollView={true}
    contentContainerStyle={styles.container}
    backgroundLight={beigeBread25}
    backgroundDark={darkest}>
    {children}
  </Box>
)

export default ScreenWrapper

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})
