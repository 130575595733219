import EmailVerificationCodeEntry from '../../components/organisms/EmailVerificationCodeEntry'
import AccountCreationWebWrapper from '../../components/wrappers/AccountCreationWebWrapper'

const EmailVerificationCodeEntryScreen = props => (
  <AccountCreationWebWrapper>
    <EmailVerificationCodeEntry {...props} />
  </AccountCreationWebWrapper>
)

export default EmailVerificationCodeEntryScreen
