import { VFC, useContext, useState } from 'react'
import Button from '../../atoms/Button/index'
import Link from '../../atoms/Link'
import { StyledCurrencyTextField, HelperText } from './styled'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext, ModalVariant } from '../../../providers/ContractProvider'
import { ThemeContext } from '../../../providers/ThemeProvider'
import Box from '../../atoms/Box'

type ErrorObject = {
  errorMsg: string
  isError: boolean
}

interface MakeAPaymentFormProps {
  getErrorMessage(n: number, b: boolean): ErrorObject
  dispatchRequestedPayment(n: number): void
}

export const MakeAPaymentForm: VFC<MakeAPaymentFormProps> = ({
  getErrorMessage,
  dispatchRequestedPayment,
}) => {
  const { localized, getCurrency } = useContext(ContentContext)
  const { contract, setContract } = useContext(ContractContext)
  const { theme } = useContext(ThemeContext)
  const { minExtraPaymentAmount, allowPayoff, payOffBalance } = contract
  const [localValue, setLocalValue] = useState(minExtraPaymentAmount || 10)
  const [hasBeenFocused, setHasBeenFocused] = useState(false)

  const updateAmount = (num: number) => {
    setLocalValue(num)
  }
  const { errorMsg, isError } = getErrorMessage(localValue, hasBeenFocused)

  const handleContinuePress = () => {
    dispatchRequestedPayment(localValue)
    setContract(prevContract => ({
      ...prevContract,
      modalVariant: ModalVariant.CONFIRM,
    }))
  }

  return (
    <>
      <Box style={{ marginBottom: 40 }}>
        <StyledCurrencyTextField
          theme={theme}
          label={localized('makeAPayment.label')}
          value={localValue}
          currencySymbol="$"
          textAlign="left"
          decimalPlaces={2}
          leadingZero="deny"
          outputFormat="number"
          decimalCharacter="."
          digitGroupSeparator=","
          minimumValue={0}
          onBlur={() => (hasBeenFocused ? null : setHasBeenFocused(true))}
          error={errorMsg}
          helperText={errorMsg && <HelperText theme={theme} error={errorMsg} />}
          variant="outlined"
          onChange={(_: {}, input: number) => updateAmount(input)}
        />
      </Box>
      <Box style={{ marginBottom: 24 }}>
        <Button testID="ContinueBtn" disabled={isError} onPress={handleContinuePress}>
          {localized('makeAPayment.modal.makePayment.button')}
        </Button>
      </Box>
      {allowPayoff ? (
        <Box
          style={{
            marginBottom: 24,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Link
            onPress={() => {
              setLocalValue(payOffBalance)
            }}>
            {`${localized('makeAPayment.modal.makePayment.buttonSecondary')} ${getCurrency(
              payOffBalance
            )}`}
          </Link>
        </Box>
      ) : null}
    </>
  )
}
