import { useContext } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { ThemeContext } from '../providers'

export const useToggleRootBackgroundColor = (lightColor: string, darkColor: string) => {
  const { setRootBackgroundColor, theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  useFocusEffect(function toggleRootBackgroundColor() {
    setRootBackgroundColor(isDark ? darkColor : lightColor)
  })
}
