import {
  Channel,
  Env,
  playgroundURL,
  sandboxURL,
  prodURL,
  sentryDSN,
  demoFlinksURLEn,
  demoFlinksURLFr,
  prodFlinksURLEn,
  prodFlinksURLFr,
  demoFlinksInstance,
  prodFlinksInstance,
} from './types'

export const ENV: Env = {
  [Channel.DEVELOPMENT]: {
    REACT_APP_SMS_PHONE_NUMBER: '200904',
    REACT_APP_BASE_URL: playgroundURL,
    SENTRY_DSN: sentryDSN,
    SENTRY_ENABLE_IN_DEVELOPMENT: true,
    SENTRY_ENABLE_DEBUG_CONSOLE_LOG: true,
    SENTRY_ENVIRONMENT: 'Playground',
    SENTRY_MAX_BREADCRUMBS: 5,
    SENTRY_ATTACH_STACKTRACE: false,
    GA_ENABLE_DEBUG_MODE: true,
    GA_ENVIRONMENT: 'Test',
    FLINKS_URL_EN: demoFlinksURLEn,
    FLINKS_URL_FR: demoFlinksURLFr,
    FLINKS_INSTANCE: demoFlinksInstance,
    INACTIVE_SESSION_MINUTES: 15,
    FEATURES: {},
  },
  [Channel.PREVIEW]: {
    REACT_APP_SMS_PHONE_NUMBER: '200904',
    REACT_APP_BASE_URL: sandboxURL,
    SENTRY_DSN: sentryDSN,
    SENTRY_ENABLE_IN_DEVELOPMENT: true,
    SENTRY_ENABLE_DEBUG_CONSOLE_LOG: true,
    SENTRY_ENVIRONMENT: 'Sandbox',
    SENTRY_MAX_BREADCRUMBS: 5,
    SENTRY_ATTACH_STACKTRACE: false,
    GA_ENABLE_DEBUG_MODE: false,
    GA_ENVIRONMENT: 'Test',
    FLINKS_URL_EN: demoFlinksURLEn,
    FLINKS_URL_FR: demoFlinksURLFr,
    FLINKS_INSTANCE: demoFlinksInstance,
    INACTIVE_SESSION_MINUTES: 60,
    FEATURES: {},
  },
  [Channel.PRODUCTION]: {
    REACT_APP_SMS_PHONE_NUMBER: '200904',
    REACT_APP_BASE_URL: prodURL,
    SENTRY_DSN: sentryDSN,
    SENTRY_ENABLE_IN_DEVELOPMENT: false,
    SENTRY_ENABLE_DEBUG_CONSOLE_LOG: false,
    SENTRY_ENVIRONMENT: 'Production',
    SENTRY_MAX_BREADCRUMBS: 5,
    SENTRY_ATTACH_STACKTRACE: false,
    GA_ENABLE_DEBUG_MODE: false,
    GA_ENVIRONMENT: 'Production',
    FLINKS_URL_EN: prodFlinksURLEn,
    FLINKS_URL_FR: prodFlinksURLFr,
    FLINKS_INSTANCE: prodFlinksInstance,
    INACTIVE_SESSION_MINUTES: 15,
    FEATURES: {},
  },
}
