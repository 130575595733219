import { useContext } from 'react'
import { Text, TextStyle, StyleSheet } from 'react-native'
import colors from '../../../tokens/colors'
import { DimensionContext } from '../../../../providers/DimensionProvider'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'

interface Props {
  children: string
  style?: TextStyle
}

const Numerical = ({ children, style, ...otherProps }: Props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Text accessibilityRole="text" style={[styles.baseStyle, style, styles[theme]]} {...otherProps}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  baseStyle: {
    fontFamily: 'MangueiraBold',
    fontWeight: '400',
    lineHeight: 20,
  },
  dark: {
    color: whiteOpacity9,
  },
  light: {
    color: colors['--text-dark'],
  },
  xs: {
    fontSize: 40,
    lineHeight: 40,
  },
  sm: {
    fontSize: 40,
    lineHeight: 40,
  },
  md: {
    fontSize: 56,
    lineHeight: 56,
  },
  lg: {
    fontSize: 56,
    lineHeight: 56,
  },
  xl: {
    fontSize: 64,
    lineHeight: 64,
  },
})

export default Numerical
