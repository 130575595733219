import { useContext, useEffect } from 'react'
import { useNavigation, useIsFocused, CommonActions } from '@react-navigation/native'
import { ScrollView, View } from 'react-native'
import * as WebBrowser from 'expo-web-browser'

import {
  StyledButtonContainer,
  StyledButtonContainerWrapper,
  StyledTextContainer,
  StyledHeading,
  StyledTaglineContainer,
  StyledRoot,
  StyledImg1,
  StyledImg2,
  StyledImg3,
  TopImageBlock,
  BottomImageBlock,
  BottomLinkBlock,
} from './styled'
import { purpleJelly10, darkest } from '../../tokens/colors/colors'
import { WithPayBrightByAffirm, WithPayBrightByAffirmFR } from '../../atoms/Icons'

import Button from '../../atoms/Button'
import Link, { SMALL_PRINT } from '../../atoms/Link'
import SharedBody from '../../molucules/SharedBody'
import { BODY_ONE_SEMI_EMPHASIZED } from '../../atoms/Typography/Body'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { ThemeContext, DARK } from '../../../providers/ThemeProvider'
import {
  BOTTOM_NAVIGATOR,
  PHONE_NUMBER_ENTRY,
  DEFAULT_PHONE_NUMBER,
} from '../../../types'
import { useUUID } from '../../../hooks/useUUID'
import { useSmartBanner } from '../../../hooks/useSmartBanner'
import { useCheckBiometricsCapable } from '../../../hooks/useCheckBiometricsCapable'
import { useToggleRootBackgroundColor } from '../../../hooks/useToogleRootBackgroundColor'

const AppUserTypeSelector = () => {
  const navigation = useNavigation()
  const { $, getLocaleCode } = useContext(ContentContext)
  const { viewPort, dimensions } = useContext(DimensionContext)
  const {
    phoneNumber,
    setPhoneNumber,
    hasPreviouslyLoggedIn,
    setHasPreviouslyLoggedIn,
    setAuthFlowType,
  } = useContext(AuthContext)
  const { theme } = useContext(ThemeContext)
  const isMobile = viewPort === 'xs'
  const isTallPhone = isMobile && dimensions.screen.height > 667
  const isDark = theme === DARK
  const isScreenVisible = useIsFocused()
  const isFrench = getLocaleCode() === 'fr-CA'

  useUUID(phoneNumber)
  useSmartBanner()
  useToggleRootBackgroundColor(purpleJelly10, darkest)

  useEffect(() => {
    if (isScreenVisible) {
      setAuthFlowType('auth')
      setPhoneNumber(DEFAULT_PHONE_NUMBER)
    }
  }, [isScreenVisible])

  const isBiometricsAvailable = useCheckBiometricsCapable()

  const handleReturningPress = () => {
    if (hasPreviouslyLoggedIn && isBiometricsAvailable) {
      setHasPreviouslyLoggedIn(false)
    } else {
      navigation.navigate(PHONE_NUMBER_ENTRY)
    }
  }

  const handleLinkPress = () => {
    WebBrowser.openBrowserAsync($`global.termsAndConditionsLink`)
  }

  const topImageBlock = () =>
    isTallPhone ? (
      <TopImageBlock>
        {!isDark ? (
          <>
            <StyledImg1 source={require('../../../assets/shop/sporting-goods.png')} />
            <StyledImg2 source={require('../../../assets/shop/electronics.png')} />
          </>
        ) : null}
      </TopImageBlock>
    ) : (
      <View style={{ flex: isMobile ? 3 : 0 }} />
    )

  const bottomImageBlock = () =>
    isTallPhone ? (
      <BottomImageBlock>
        {!isDark ? <StyledImg3 source={require('../../../assets/shop/bag.png')} /> : null}
      </BottomImageBlock>
    ) : (
      <View style={{ flex: isMobile ? 1 : 0 }} />
    )

  const Logo = isFrench ? WithPayBrightByAffirmFR : WithPayBrightByAffirm

  return (
    <ScrollView
      contentContainerStyle={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}>
      <StyledRoot isMobile={isMobile} isDark={isDark} background={purpleJelly10}>
        {topImageBlock()}
        <StyledHeading isMobile={isMobile} dimensions={dimensions} variant={'--heading-1'}>
          {$`entry.header`}
        </StyledHeading>
        <StyledTaglineContainer isMobile={isMobile}>
          <Logo color={theme === 'dark' ? 'white' : ''} />
        </StyledTaglineContainer>
        {bottomImageBlock()}
        <StyledTextContainer>
          <SharedBody variant={BODY_ONE_SEMI_EMPHASIZED}>{''}</SharedBody>
        </StyledTextContainer>
        <StyledButtonContainerWrapper>
          <StyledButtonContainer>
            <Button
              isFullWidthOnMobile={true}
              testID="returningCustomerBtn"
              onPress={handleReturningPress}>
              {$`entry.returning`}
            </Button>
          </StyledButtonContainer>
        </StyledButtonContainerWrapper>

        {isMobile ? (
          <>
            <View style={{ flex: 1 }} />
            <BottomLinkBlock>
              <Link variant={SMALL_PRINT} onPress={handleLinkPress}>
                {$`global.termsAndConditions`}
              </Link>
            </BottomLinkBlock>
          </>
        ) : null}
      </StyledRoot>
    </ScrollView>
  )
}

export default AppUserTypeSelector
