import { useEffect, useContext, useState } from 'react'
import { navigationRef } from '../services/NavigationService'
import { DeepLinkContext } from '../providers/DeepLinkProvider'
import { AuthContext } from '../providers'

export const useDeepLinks = () => {
  const [currentRoute, setCurrentRoute] = useState<string>()
  const [currentParams, setCurrentParams] = useState<{}>()
  const { deepLinksState, addDeepLink, removeDeepLink } = useContext(DeepLinkContext)
  const { isLoggedIn } = useContext(AuthContext)

  useEffect(() => {
    if (!navigationRef.current) return
    const handleNavigationStateChange = () => {
      setCurrentRoute(navigationRef.current?.getCurrentRoute()?.name)
      setCurrentParams(navigationRef.current?.getCurrentRoute()?.params)
    }
    navigationRef.current?.addListener('state', handleNavigationStateChange)
    return () => {
      navigationRef.current?.removeListener('state', handleNavigationStateChange)
    }
  }, [navigationRef.current])

  useEffect(() => {
    ;(async () => {
      if (!deepLinksState || !currentRoute) return
      const isAuthRoute = deepLinksState.type === 'AUTH'
      const isPurchasePlans = currentRoute === 'PurchasePlans'
      const isWelcomeScreen = currentParams?.paramType === 'welcome'
      const isPlansScreenWithoutModal = isPurchasePlans && !isWelcomeScreen
      if (isAuthRoute && isLoggedIn) {
        setTimeout(async () => {
          await deepLinksState?.action()
          removeDeepLink()
        }, 0)
      }
    })()
  }, [deepLinksState, currentRoute, isLoggedIn])

  return { addDeepLink }
}
