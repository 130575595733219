import { VFC } from 'react'
import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import BankCCLogo from '../BankCCLogo'
import { PaymentIcon } from '../../../types'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'

const LogoWrapper = styled(Box)`
  flex-direction: row;
  margin-bottom: 16px;
`
interface LinkYourCardLogoRowProps {
  heading?: string
  isInterestBearing: boolean
}

export const LinkYourCardLogoRow: VFC<LinkYourCardLogoRowProps> = ({
  isInterestBearing,
  heading,
}) => {
  const iconArray: PaymentIcon[] = [
    PaymentIcon.MC,
    ...(!isInterestBearing ? [PaymentIcon.VISA] : []),
    PaymentIcon.VISA_DEBIT,
  ]

  return (
    <>
      {heading ? (
        <Body
          style={styles.body}
          variant="--body-02-regular"
          darkColor={whiteOpacity9}
          lightColor={colors['--text-dark']}>
          {heading}
        </Body>
      ) : null}
      <LogoWrapper>
        {iconArray.map(icon => (
          <BankCCLogo key={icon} paymentIcon={icon} />
        ))}
      </LogoWrapper>
    </>
  )
}

export const styles = StyleSheet.create({
  body: {
    marginBottom: 8,
    paddingTop: 8,
  },
})
