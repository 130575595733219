import { FC, useContext } from 'react'
import { Platform } from 'react-native'
import Box from '../../components/atoms/Box'
import { DimensionContext } from '../../providers/DimensionProvider'
import { LinkYourBankAccount } from '../../components/organisms/LinkYourBankAccount'
import { ScreenParentWrapper } from '../../components/wrappers/ScreenParentWrapper'

interface WrapperProps {
  isNative: boolean
  isMobile: boolean
}

const Wrapper: FC<WrapperProps> = ({ isNative, isMobile, children }) =>
  isNative ? (
    <>{children}</>
  ) : isMobile ? (
    <ScreenParentWrapper>
      <Box style={{ height: '100%' }}>{children}</Box>
    </ScreenParentWrapper>
  ) : (
    <ScreenParentWrapper>{children}</ScreenParentWrapper>
  )

export const LinkBankAccountScreen = () => {
  const isNative = ['ios', 'android'].includes(Platform.OS)
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'

  return (
    <Wrapper isNative={isNative} isMobile={isMobile}>
      <LinkYourBankAccount />
    </Wrapper>
  )
}
