import {
  ink,
  ink0,
  ink5,
  ink10,
  ink25,
  ink50,
  ink75,
  mintSprig,
  mintSprig25,
  mintSprig75,
  purpleJelly,
  purpleJelly10,
  purpleJelly25,
  purpleJellyDark,
  purpleJellyDarker,
  strawberryJam,
  yellowDijon,
} from './colors'

const titleColors = {
  '--title-dark': purpleJellyDark,
  '--title-light': ink0,
}

const textColors = {
  '--text-dark': purpleJellyDark,
  '--text-light': ink0,
  '--text-01': purpleJelly,
  '--text-02': mintSprig,
  '--text-de-emphasized': ink50,
  '--text-error': strawberryJam,
  '--text-disabled': ink50,
}

const userInterfaceColors = {
  '--ui-primary-default': purpleJelly,
  '--ui-primary-hover': purpleJellyDark,
  '--ui-primary-active': purpleJellyDarker,
  '--ui-secondary-default': purpleJellyDark,
  '--ui-secondary-hover': purpleJelly,
  '--ui-inactive-01': ink10,
  '--ui-inactive-02': ink25,
  '--ui-inactive-03': ink50,
  '--ui-inactive-04': ink75,
  '--ui-disabled': ink25,
  '--ui-disabled-background': ink5,
  '--ui-background-hover': purpleJelly10,
  '--ui-background-active': purpleJelly25,
  '--ui-alert-background': purpleJelly10,
  '--ui-error-background': '#FFF5F5',
  '--ui-warning-background': '#FFFDF5',
  '--ui-confirmation-background': '#ECFAF8',
  '--ui-light': ink0,
  '--ui-alt-01': mintSprig75,
  '--ui-alt-02': mintSprig25,
  '--ui-overlay': ink,
}

const borderColors = {
  '--border-error': strawberryJam,
  '--border-warning': yellowDijon,
  '--border-confirmation': mintSprig,
  '--border-inactive-01': ink10,
  '--border-inactive-02': ink25,
  '--border-emphasized': purpleJelly,
  '--border-hover': purpleJelly,
  '--border-focus-dark': purpleJelly,
  '--border-focus-light': ink0,
  '--border-disabled': ink25,
}

const backgroundColors = {
  '--background-dark': purpleJelly,
  '--background-light': ink0,
  '--background-01': mintSprig25,
  '--background-02': purpleJelly10,
  '--background-03': purpleJellyDark,
  '--background-footer': purpleJellyDark,
}

const themeColors = {
  ...titleColors,
  ...textColors,
  ...userInterfaceColors,
  ...borderColors,
  ...backgroundColors,
}

export default themeColors
