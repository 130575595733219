import { ReactNode, useContext } from 'react'
import { useRoute, useNavigation, getFocusedRouteNameFromRoute } from '@react-navigation/native'
import styled from 'styled-components'
import { DimensionContext } from '../../providers/DimensionProvider'
import { ThemeContext } from '../../providers/ThemeProvider'
import { ContentContext } from '../../providers/ContentProvider'
import { ContractContext } from '../../providers'
import { Localized } from '../../localization/types'
import colors from '../../components/tokens/colors'
import {
  darker4,
  darker7,
  ink50,
  purpleJelly10,
  whiteOpacity9,
} from '../../components/tokens/colors/colors'
import Button from '../../components/atoms/Button'
import { AuthContext } from '../../providers/AuthProvider'
import {
  Home,
  Plans,
  Profile,
  PayBrightByAffirm,
  PayBrightByAffirmFR,
} from '../../components/atoms/Icons'
import { PHONE_NUMBER_ENTRY, PROFILE_TAB, PURCHASES_TAB } from '../../types'

const NavContainer = styled.nav(
  ({ theme }) => `
  background-color: ${theme === 'dark' ? darker7 : colors['--ui-secondary-default']};
  width: 200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10000;
  `
)

const ItemWrapper = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding-left: 8px;
  flex-grow: 1;
`

const NavItem = styled.li(({ isActive, theme, color }) => {
  const isDark = theme === 'dark'
  const backgroundColor = isDark ? darker4 : '#F2F2FA'

  const activeStyle = `background-color: ${backgroundColor};
  border-radius: 160px 0px 0px 160px;
  ${!isDark && 'border-right: 4px solid #D0CFEA'};`
  return `
    height: 66px;
    display: flex;
    padding-left: 24px;
    align-items: center;
    font-family: Calibre-semi;
    font-size: 16px;
    color: ${color};
    cursor: pointer;
    ${isActive && activeStyle}
    `
})

const StyledButton = styled(Button)`
  margin: 40px 16px;
`

const LogoWrapper = styled.div`
  margin: 40px 0 38px 24px;
`

interface IconType {
  account: ReactNode
  plans: ReactNode
  home: ReactNode
}

interface NavItemType {
  id: string
  label: string
  route: string
}

const icons: IconType = {
  account: Profile,
  plans: Plans,
  home: Home,
}

const navItems = (localized: Localized): NavItemType[] => [
  { id: 'plans', label: localized('plans.navLink'), route: PURCHASES_TAB },
  { id: 'account', label: localized('account.navLink'), route: PROFILE_TAB },
]

const SideNav = ({ openModal }: { openModal: () => void }) => {
  const { viewPort } = useContext(DimensionContext)
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  const { isLoggedIn } = useContext(AuthContext)
  const { localized, getLocaleCode } = useContext(ContentContext)
  const { hasPaymentProblem } = useContext(ContractContext)
  const navigation = useNavigation()
  const route = useRoute()
  const isFrench = getLocaleCode() === 'fr-CA'

  const setIconColor = (isActive: boolean) => {
    if (isDark) {
      if (isActive) {
        return whiteOpacity9
      }
      return ink50
    }
    if (isActive) {
      return colors['--text-dark']
    }
    return purpleJelly10
  }

  const isDesktop = viewPort === 'xl'

  const navigateToLinkAccount = () => {
    navigation.replace(PHONE_NUMBER_ENTRY)
  }

  const Logo = isFrench ? PayBrightByAffirmFR : PayBrightByAffirm

  return isDesktop ? (
    <NavContainer theme={theme}>
      <LogoWrapper>
        <a target="_blank" href="https://paybright.com">
          <Logo />
        </a>
      </LogoWrapper>
      <ItemWrapper>
        {navItems(localized).map(({ id, label, route: routeName }) => {
          const Icon = icons[id]
          const isActive = routeName === getFocusedRouteNameFromRoute(route)
          return (
            <NavItem
              theme={theme}
              isActive={isActive}
              color={setIconColor(isActive)}
              onClick={() => navigation.navigate(routeName)}
              key={id}>
              <Icon
                size={48}
                focused={isActive}
                color={setIconColor(isActive)}
                modifier={hasPaymentProblem}
              />{' '}
              {label}
            </NavItem>
          )
        })}
      </ItemWrapper>
      {isLoggedIn ? (
        <StyledButton inverse={true} onPress={openModal}>
          {localized('global.logOut')}
        </StyledButton>
      ) : (
        <StyledButton inverse={true} onPress={navigateToLinkAccount}>
          {localized('global.logIn')}
        </StyledButton>
      )}
    </NavContainer>
  ) : null
}

export default SideNav
