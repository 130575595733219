import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const Home = ({ color = 'black', size = '40' }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      d="M19.4828 10.9539C19.6152 10.8265 19.8007 10.75 20 10.75C20.1993 10.75 20.3848 10.8265 20.5172 10.9539L30.48 20.5404C30.7784 20.8276 31.2532 20.8185 31.5404 20.52C31.8276 20.2216 31.8185 19.7468 31.52 19.4596L21.5576 9.8734C21.1398 9.47115 20.579 9.25 20 9.25C19.421 9.25 18.8602 9.47115 18.4424 9.8734L8.47997 19.4596C8.1815 19.7468 8.17236 20.2216 8.45956 20.52C8.74677 20.8185 9.22155 20.8276 9.52003 20.5404L19.4828 10.9539Z" fill={color}
    />
    <Path 
    d="M12.75 22C12.75 21.5858 12.4142 21.25 12 21.25C11.5858 21.25 11.25 21.5858 11.25 22V30C11.25 30.4142 11.5858 30.75 12 30.75H17.8182C18.2324 30.75 18.5682 30.4142 18.5682 30V24.1818C18.5682 23.7927 18.8836 23.4773 19.2727 23.4773H20.7273C21.1164 23.4773 21.4318 23.7927 21.4318 24.1818V30C21.4318 30.4142 21.7676 30.75 22.1818 30.75H28C28.4142 30.75 28.75 30.4142 28.75 30V22C28.75 21.5858 28.4142 21.25 28 21.25C27.5858 21.25 27.25 21.5858 27.25 22V29.25H22.9318V24.1818C22.9318 22.9643 21.9448 21.9773 20.7273 21.9773H19.2727C18.0552 21.9773 17.0682 22.9643 17.0682 24.1818V29.25H12.75V22Z" fill={color}
    />
  </Svg>
)

export default Home
