import { ReactNode } from 'react'

export interface ProviderProps {
  children: ReactNode
}
export type Provider<T> = (props: T) => JSX.Element

export const combineProviders = (providers: Provider<ProviderProps>[]) =>
  providers.reduce((Combined, SingleProvider) => ({ children }) => (
    <Combined>
      <SingleProvider>{children}</SingleProvider>
    </Combined>
  ))
