import { useContext, ReactNode } from 'react'
import { StyleSheet, Image } from 'react-native'
import Box from '../../atoms/Box'
import Card from '../../atoms/Card'
import { DimensionContext } from '../../../providers/DimensionProvider'
import ScreenWrapper from '../ScreenWrapper'

const AccountCreationWebWrapper = ({
  hasPlant = true,
  children,
  scrollView = true,
}: {
  hasPlant?: boolean
  scrollView?: boolean
  children: ReactNode
}) => {
  const { viewPort } = useContext(DimensionContext)
  if (viewPort === 'xs' || viewPort === 'sm') {
    return (
      <Box style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }} scrollView={scrollView}>
        {children}
      </Box>
    )
  }
  return (
    <ScreenWrapper>
      <Card style={styles.card}>
        {hasPlant && (
          <Image style={styles.leftImage} source={require('../../../assets/images/plant.png')} />
        )}
        {children}
        {hasPlant && (
          <Image style={styles.rightImage} source={require('../../../assets/images/plant.png')} />
        )}
      </Card>
    </ScreenWrapper>
  )
}

export default AccountCreationWebWrapper

const styles = StyleSheet.create({
  card: {
    maxWidth: 552,
    marginTop: 72,
    paddingHorizontal: 72,
    paddingVertical: 64,
    marginBottom: 114,
  },
  leftImage: {
    width: 150,
    height: 200,
    position: 'absolute',
    transform: [{ translateX: -150 }],
    left: 0,
    top: 30,
  },
  rightImage: {
    width: 180,
    height: 360,
    position: 'absolute',
    right: 0,
    bottom: 30,
    transform: [{ translateX: 180 }, { rotateY: '180deg' }],
  },
})
