import memoize from 'lodash.memoize'
import { Platform, I18nManager } from 'react-native'
import * as SecureStore from 'expo-secure-store'
import i18n from 'i18n-js'
import * as Localization from 'expo-localization'
import { EN, FR, SYSTEM, LangOptions, Localized } from './types'

const requireEN = () => require('./en.json')
const requireFR = () => require('./fr.json')

const isNative = Platform.OS === 'android' || Platform.OS === 'ios'

const getLanguageLocal = async (): Promise<LangOptions> => {
  let result: LangOptions
  if (isNative) {
    try {
      result = await SecureStore.getItemAsync('lang')
    } catch (e) {
      result = SYSTEM
    }
  } else {
    result = localStorage.getItem('lang')
  }
  return result || SYSTEM
}

export const translationGetters = (langKey: string) =>
  ({
    en: requireEN,
    'en-ca': requireEN,
    'en-us': requireEN,
    'en-gb': requireEN,
    fr: requireFR,
    'fr-ca': requireFR,
    'fr-fr': requireFR,
    'fr-us': requireFR,
  }[langKey] || requireEN)

export const localized = memoize(
  (key: string | TemplateStringsArray, config?) =>
    i18n.t(key, config).includes('missing') ? key : i18n.t(key, config),
  (key: string | TemplateStringsArray, config) => (config ? key + JSON.stringify(config) : key)
)

export const $ = localized

export const initLocalization = async () => {
  const langValueFromDeviceStorage = await getLanguageLocal()
  setLocalization(langValueFromDeviceStorage)
}

export const getLocale = () => i18n.locale

export const setLocalization = async (lang: LangOptions) => {
  localized?.cache?.clear?.()
  const isRTL = Localization.isRTL
  I18nManager.forceRTL(isRTL)
  const newLang = lang === SYSTEM ? Localization.locale?.toLocaleLowerCase() : lang
  const json = await translationGetters(newLang)()
  i18n.translations = {
    [newLang]: json,
  }
  i18n.locale = newLang
}
