import { StyleSheet, Image } from 'react-native'

const ThumbsUp = () => (
  <Image style={styles.thumbsUp} source={require('../../../assets/images/thumbs-up.png')} />
)

export default ThumbsUp

const styles = StyleSheet.create({
  thumbsUp: {
    width: 25,
    height: 25,
    marginBottom: 5,
  },
})
