import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const Moon = ({ color = 'black', size = '40', ...otherProps }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill={color} {...otherProps}>
    <Path
      d="M26.4411 23.9932C24.6426 23.9928 22.8693 23.5602 21.2633 22.7302C19.6573 21.9002 18.2634 20.6958 17.1935 19.2137C16.1235 17.7316 15.4073 16.0131 15.1022 14.1959C14.7971 12.3787 14.9117 10.5136 15.4368 8.75C13.6939 9.57359 12.1798 10.8312 11.0324 12.4082C9.88495 13.9852 9.14065 15.8315 8.86729 17.7789C8.59393 19.7263 8.8002 21.7129 9.46732 23.5577C10.1344 25.4024 11.2412 27.0467 12.6868 28.3407C14.1323 29.6348 15.8707 30.5374 17.7436 30.9663C19.6165 31.3953 21.5643 31.337 23.4095 30.7967C25.2548 30.2564 26.9389 29.2513 28.3083 27.873C29.6777 26.4947 30.689 24.787 31.25 22.9055C29.7431 23.6219 28.1021 23.993 26.4411 23.9932Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Moon
