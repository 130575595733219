import { injectable } from "inversify";
import { IUserAuthInfoService } from "./IUserAuthInfoService"

/**
 * This class will store user auth information.
 * Note: It does not update the react context.
 */

@injectable()
class UserAuthInfoService implements IUserAuthInfoService {

    private _portalDeviceGuid: string | null;
    private _portalAccountId: string | null;
    private _portalSessionId: string | null;

    GetPortalDeviceGuid(): string | null {
      return this._portalDeviceGuid;
    }
    SetPortalDeviceGuid(portalDeviceGuid: string|null): void {
      this._portalDeviceGuid = portalDeviceGuid;
    }

    GetPortalAccountId(): string | null {
      return this._portalAccountId;
    }
    SetPortalAccountId(portalAccountId: string | null): void {
      this._portalAccountId = portalAccountId;
    }
    GetPortalSessionId(): string | null {
      return this._portalSessionId;

    }
    SetPortalSessionId(portalSessionId: string | null): void {
      this._portalSessionId = portalSessionId;
    }
  }

  export {UserAuthInfoService};
