import { useContext } from 'react'
import { Image, StyleSheet, Linking, Platform, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import Body from '../../atoms/Typography/Body'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Typography/Heading'
import AccountCreationWrapper from '../../wrappers/AccountCreationWrapper'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import { HOME_ENTRY } from '../../../types'
import { ContentContext } from '../../../providers/ContentProvider'

interface GenericError {
  title?: string
  body?: string
  buttonLabel?: string
}

const SharedAnchor = ({ href, children }: { href: string; children: ReactNode }) =>
  Platform.OS === 'web' ? (
    <a style={{ textDecoration: 'none' }} target="_blank" href={href}>
      {children}
    </a>
  ) : (
    <View style={{ paddingTop: 30 }}>
      <Link onPress={() => Linking.openURL(href)}>{children}</Link>
    </View>
  )

const GenericError = ({
  title = 'genericError.title',
  body = 'genericError.body',
  buttonLabel = 'genericError.buttonText',
}: GenericError) => {
  const navigation = useNavigation()
  const { localized } = useContext(ContentContext)

  const onPress = () => {
    navigation.navigate(HOME_ENTRY)
  }

  return (
    <AccountCreationWrapper paddingTop={32} hasPlant={false}>
      <Box>
        <Image style={styles.image} source={require('../../../assets/images/generic-error.png')} />
        <Heading style={styles.heading} variant="--heading-2">
          {localized(title)}
        </Heading>
        <Body style={styles.body} lightColor={colors['--text-01']} darkColor={whiteOpacity9}>
          {localized(body)}
          <SharedAnchor href={localized('genericError.contactUsLink')}>
            {localized('genericError.contactUs')}
          </SharedAnchor>
        </Body>
        <Button testID="ContinueBtn" style={styles.button} onPress={onPress}>
          {localized(buttonLabel)}
        </Button>
      </Box>
    </AccountCreationWrapper>
  )
}

export default GenericError

const styles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    marginBottom: 56,
    alignSelf: 'center',
  },
  heading: {
    marginBottom: 22,
    textAlign: 'center',
  },
  body: {
    flexDirection: 'row',
    textAlign: 'center',
    marginBottom: 56,
  },
  button: {
    alignSelf: 'center',
  },
})
