import * as SecureStore from 'expo-secure-store'
import { RSA } from 'react-native-rsa-native'

const BIOMETRIC_GUID_TAG = 'com.paybright.portal_GUID60'
const KEYTAG = 'com.paybright.portal_key60'
const KEYSIZE = 2048
export interface StoredBiometrics {
  biometricDeviceGuid: string
  privateKey: string
}

export interface MessagePayload {
  message: string
  signature: string
}

export interface Payload extends MessagePayload {
  publicKey: string
}

export const getKeyFromSecureStore = async (portalDeviceGuid: string): Promise<string> => {
  try {
    const maybePrivateKey = await SecureStore.getItemAsync(KEYTAG + portalDeviceGuid)
    if (maybePrivateKey) return maybePrivateKey
  } catch (err) {
    console.warn('Error in getting key: ', err)
  }

  return ''
}

export const setKeyToSecureStore = async (portalDeviceGuid: string, privateKey: string) => {
  await SecureStore.setItemAsync(KEYTAG + portalDeviceGuid, privateKey).catch(e =>
    console.warn('Error setting private Key', e)
  )
}

export const verify = async (messageSignature: string, message: string, guid: string) => {
  const privateKey = await getKeyFromSecureStore(guid)
  if (privateKey) {
    await RSA.verify(messageSignature, message, privateKey).catch(e =>
      console.warn('Verify Error: ', e)
    )
  }
}

export const getBiometricGUID = async (): Promise<string> => {
  const data = await SecureStore.getItemAsync(BIOMETRIC_GUID_TAG)
  if (!data) {
    throw new Error(`No Biometric GUID using tag ${BIOMETRIC_GUID_TAG}`)
  }
  return data
}

export const deletePrivateKeyLocally = async (portalDeviceGuid: string) => {
  try {
    await SecureStore.deleteItemAsync(KEYTAG + portalDeviceGuid)
    await SecureStore.deleteItemAsync(BIOMETRIC_GUID_TAG)
  } catch (err) {
    console.warn('Deleting Keys Error: ', err)
  }
}

export const setKeyLocallyAndReturnsPayload = async (
  portalDeviceGuid: string
): Promise<Payload | null> => {
  try {
    const { private: privateKey, public: publicKey } = await RSA.generateKeys(KEYSIZE)
    await setKeyToSecureStore(portalDeviceGuid, privateKey)
    await SecureStore.setItemAsync(BIOMETRIC_GUID_TAG, portalDeviceGuid)
    const payload = await composeRSAPayload(portalDeviceGuid)
    if (payload) {
      return { publicKey, ...payload }
    }
  } catch (e) {
    console.warn('Setting Key Error: ', e)
  }
  return null
}

export const composeRSAPayload = async (
  portalDeviceGuid: string
): Promise<MessagePayload | void> => {
  const message = Date.now().toString()
  try {
    const privateKey = await getKeyFromSecureStore(portalDeviceGuid)
    if (!privateKey) {
      throw new Error('no key found')
    }
    const signature = await RSA.signWithAlgorithm(message, privateKey, 'SHA512withRSA')
    if (!signature) {
      throw new Error('no signature generated')
    }
    return { message, signature }
  } catch (err) {
    await deletePrivateKeyLocally(portalDeviceGuid)
    console.warn('Compose Payload Error: ', err)
  }
}
