import { useState, FC, useContext } from 'react'
import { ContentContext } from '../../../providers/ContentProvider'
import { DimensionContext } from '../../../providers/DimensionProvider'
import Box from '../../atoms/Box'
import { ChevronDown, ChevronUp } from '../../atoms/Icons'
import {
  StyledListAccordion,
  ListItem,
  ListWrapper,
  ListItemBodyLeft,
  ListItemBodyRight,
  RightBox,
  LeftBoxOuter,
  LeftBoxInner,
  FrenchDateContainer,
} from './styled'
import colors from '../../tokens/colors'
import { whiteOpacity9, mintSprig75, ink50 } from '../../tokens/colors/colors'
import { getTitle, getIcon } from './helpers'
import {
  Description,
  Status,
  ScheduleItemProps,
  ListProperties,
  destructuredEntries,
  Next,
} from '../../../types'

export const ScheduleItem: FC<ScheduleItemProps> = ({ item }) => {
  const {
    principal,
    interest,
    processingFees,
    creditCardFees,
    otherFees,
    status,
    description,
    eventDate,
    totalPayment,
  } = item

  const listObject = { principal, interest, processingFees, creditCardFees, otherFees }
  const listArray = Object.entries(listObject) as Next[]
  const listReducer = (previousValue: ListProperties[] | [], next: Next) => {
    const [id, value] = next as destructuredEntries
    if (typeof value === 'number') {
      const itemObject: ListProperties = { id, value, label: id }
      return [...previousValue, itemObject]
    }
    return previousValue
  }
  const listNodes = listArray.reduce(listReducer, [])
  const { localized, getCurrency, getDate, getLocale } = useContext(ContentContext)
  const { viewPort, dimensions } = useContext(DimensionContext)
  const [expanded, setExpanded] = useState(false)
  const handlePress = () => setExpanded(!expanded)
  const isMobile = viewPort === 'xs'
  const isFrench = getLocale().slice(0, 2).toLowerCase() === 'fr' && isMobile
  const fontBold = isMobile ? '--body-02-semi-emphasized' : '--body-01-semi-emphasized'
  const fontRegular = isMobile ? '--body-02-regular' : '--body-01-regular'
  const iconSize = isMobile ? '16' : '20'
  const isActiveOrProcessing = [Status.Active, Status.Processing].includes(status)
  const isProcessing = status === Status.Processing
  const darkColor =
    description === Description.Refund ? mintSprig75 : isProcessing ? ink50 : whiteOpacity9
  const lightColor =
    description === Description.Refund ? mintSprig75 : isProcessing ? ink50 : colors['--text-dark']
  const darkColorAlt =
    description === Description.Refund ? mintSprig75 : isActiveOrProcessing ? ink50 : whiteOpacity9
  const lightColorAlt =
    description === Description.Refund
      ? mintSprig75
      : isActiveOrProcessing
      ? ink50
      : colors['--text-dark']
  const chevronColor = isActiveOrProcessing ? ink50 : ''
  const renderIcon = getIcon({ iconSize, key: status, color: mintSprig75 })

  const renderTitle = () => (
    <ListItemBodyLeft lightColor={lightColor} darkColor={darkColor} variant={fontRegular}>
      {getDate(eventDate)}
    </ListItemBodyLeft>
  )

  const renderLeft = () => (
    <LeftBoxOuter isFrench={isFrench}>
      <LeftBoxInner isFrench={isFrench}>
        {renderIcon()}
        <ListItemBodyLeft lightColor={lightColor} darkColor={darkColor} variant={fontBold}>
          {localized(getTitle(description))}
        </ListItemBodyLeft>
      </LeftBoxInner>
      {isFrench ? <FrenchDateContainer>{renderTitle()}</FrenchDateContainer> : null}
    </LeftBoxOuter>
  )

  const renderRight = () => (
    <RightBox isFrench={isFrench}>
      <ListItemBodyRight lightColor={lightColorAlt} darkColor={darkColorAlt} variant={fontBold}>
        {getCurrency(totalPayment)}
      </ListItemBodyRight>
      {expanded ? (
        <ChevronUp color={chevronColor} size="20" />
      ) : (
        <ChevronDown color={chevronColor} size="20" />
      )}
    </RightBox>
  )

  const renderListNode = ({ id, value, label }: ListProperties) => {
    const labelText = localized(`paymentSchedule.${label}`)
    const currencyText = getCurrency(value as number)
    return (
      <ListItem key={id}>
        <ListItemBodyLeft lightColor={lightColor} darkColor={darkColor} variant={fontRegular}>
          {labelText}
        </ListItemBodyLeft>
        <ListItemBodyLeft lightColor={lightColorAlt} darkColor={darkColorAlt} variant={fontRegular}>
          {currencyText}
        </ListItemBodyLeft>
      </ListItem>
    )
  }

  return (
    <StyledListAccordion
      titleNumberOfLines={3}
      style={{
        paddingHorizontal: dimensions.screen.width > 400 ? 8 : 0,
      }}
      title={!isFrench ? renderTitle() : null}
      right={renderRight}
      left={renderLeft}
      expanded={expanded}
      theme={{ colors: { background: 'transparent' } }}
      onPress={handlePress}>
      <ListWrapper isMobile={isMobile}>{listNodes.map(renderListNode)}</ListWrapper>
    </StyledListAccordion>
  )
}
