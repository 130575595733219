import { ReactNode } from 'react'
import { ViewStyle } from 'react-native'
import colors from '../../tokens/colors'
// @ts-ignore library doesn't support types
import { Chase } from 'react-native-animated-spinkit'
import { mintSprig75 } from '../../tokens/colors/colors'
import useColorByTheme from '../../../hooks/useColorByTheme'

interface Props {
  children?: ReactNode | string
  style?: ViewStyle
  size?: number
  thickness?: number
  darkModeOverride?: boolean
}

const Spinner = ({
  children,
  style,
  size = 16,
  thickness = 4,
  darkModeOverride = false,
}: Props) => {
  const color = darkModeOverride
    ? colors['--ui-primary-default']
    : useColorByTheme(colors['--ui-primary-default'], mintSprig75)
  return (
    <Chase style={style} size={size} color={color}>
      {children}
    </Chase>
  )
}

export default Spinner
