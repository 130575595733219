import { ModalVariant, Contract } from '../../../../providers/ContractProvider'

export const getPlanDetailsModalVariant = (contract: Contract) => {
  const { allowExtraPaymentMessage, allowPayoffMessage, allowExtraPayment, allowPayoff } = contract
  const isMessageInResults = (message: string) =>
    [allowExtraPaymentMessage, allowPayoffMessage].includes(message)
  const paymentAllowed = allowPayoff && allowExtraPayment
  const payoffOnlyAllowed = !allowExtraPayment && allowPayoff

  switch (true) {
    case isMessageInResults('ContractNotCapturedYet'):
      return ModalVariant.CONTRACT_NOT_CAPTURED
    case isMessageInResults('ContractNotFullyCaptured'):
      return ModalVariant.CONTRACT_NOT_FULLY_CAPTURED
    case isMessageInResults('AlreadyScheduled'):
      return ModalVariant.PAYMENT_ALREADY_SCHEDULED
    case isMessageInResults('CutoffDateExceeded'):
      return ModalVariant.CUTOFF_DATE_EXCEEDED
    case payoffOnlyAllowed:
      return ModalVariant.NOT_ALLOWED_UNTIL_FIRST_PAYMENT
    case isMessageInResults('BalanceRemainingLessThanMinimum'):
      return ModalVariant.BALANCE_REMAINING_LESS_THAN_MINIMUM
    case isMessageInResults('NotAllowedIfHasUnconfirmedSentPayments'):
      return ModalVariant.NOT_ALLOWED_IF_HAS_UNCONFIRMED_SENT_PAYMENTS  
    case paymentAllowed:
      return ModalVariant.MAKE_PAYMENT
    default:
      return ModalVariant.GENERIC_ERROR
  }
}
