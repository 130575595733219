import LinkYourAccount from '../../components/organisms/LinkYourAccount'
import AccountCreationWebWrapper from '../../components/wrappers/AccountCreationWebWrapper'

const LinkYourAccountScreen = props => (
  <AccountCreationWebWrapper>
    <LinkYourAccount {...props} />
  </AccountCreationWebWrapper>
)

export default LinkYourAccountScreen
