import { Platform } from 'react-native'
import { getEnvVars } from './getEnvVars'
import { playgroundURL } from '../types'
const isAndroid = Platform.OS === 'android'
const isLocalAndroid = __DEV__ && isAndroid

export const { REACT_APP_SMS_PHONE_NUMBER, REACT_APP_BASE_URL, INACTIVE_SESSION_MINUTES } =
  getEnvVars()
const baseURL = REACT_APP_BASE_URL || ''

export const PAYBRIGHT_SMS_FROM_PHONE_NUMBER = REACT_APP_SMS_PHONE_NUMBER || ''

export const DOTNET_API_ROOT = isLocalAndroid ? playgroundURL : baseURL

export const TOKEN_EXPIRES_MINUTES = 5
export const BUFFER_ADDED_SECONDS = 30
