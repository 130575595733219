import { useContext } from 'react'
import Svg, { Path } from 'react-native-svg'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'
import Props from './shared'
import colors from '../../../tokens/colors'

const ChevronUp = ({ size = '40' }: Props) => {
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  const iconColor = isDark ? whiteOpacity9 : colors['--text-01']
  return (
    <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 40 40" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45213 24.5122C8.16926 24.2096 8.18523 23.735 8.48781 23.4521L18.9692 13.6534C18.9693 13.6532 18.9695 13.6531 18.9696 13.653C19.2509 13.3898 19.6224 13.25 20 13.25C20.3776 13.25 20.7491 13.3898 21.0304 13.653C21.0305 13.6531 21.0307 13.6532 21.0308 13.6534L31.5122 23.4521C31.8148 23.735 31.8307 24.2096 31.5479 24.5122C31.265 24.8148 30.7904 24.8307 30.4878 24.5479L20.0087 14.7513C20.0069 14.7507 20.0039 14.75 20 14.75C19.9961 14.75 19.9931 14.7507 19.9913 14.7513L9.51219 24.5479C9.20961 24.8307 8.73501 24.8148 8.45213 24.5122ZM19.9944 14.7484C19.9942 14.7485 19.9941 14.7486 19.994 14.7487Z"
        fill={iconColor}
      />
    </Svg>
  )
}

export default ChevronUp
