import { PLANS, PLAN_DETAILS, PURCHASES_TAB, PROFILE_TAB, ACCOUNT } from '../types'
import { DeepLinkSchema } from '../services/NavigationService'

export const baseLinkingConfig = {
  prefixes: DeepLinkSchema,
  config: {
    screens: {
      Root: {
        screens: {
          AuthNavigator: {},
        },
      },
    },
  },
}

export const linkingConfig = {
  prefixes: DeepLinkSchema,
  config: {
    screens: {
      Root: {
        screens: {
          BottomNavigator: {
            screens: {
              [PURCHASES_TAB]: {
                initialRouteName: PLANS,
                screens: {
                  [PLANS]: {
                    path: PLANS,
                  },
                  [PLAN_DETAILS]: {
                    path: `${PLANS}/${PLAN_DETAILS}/:id`,
                    parse: {
                      id: (id: string) => `${id}`,
                    },
                  },
                },
              },
              [PROFILE_TAB]: {
                initialRouteName: PROFILE_TAB,
                screens: {
                  [ACCOUNT]: {
                    path: PROFILE_TAB,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}
