import Svg, { G, Rect, Path } from 'react-native-svg'
import Props from './shared'

const Edit = ({ color = '#007AFF', size = '40' }: Props) => (
  <Svg
    aria-hidden="false"
    fill={color}
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 512 512">
    <G>
      <G>
        <Rect
          x="17.469"
          y="222.239"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -121.8772 249.4323)"
          width="445.367"
          height="99.192"
        />
      </G>
    </G>
    <G>
      <G>
        <Path d="M507.203,51.778L460.222,4.796c-6.394-6.394-16.762-6.394-23.156,0l-39.769,39.769l70.138,70.138l39.769-39.769    C513.599,68.54,513.599,58.172,507.203,51.778z" />
      </G>
    </G>
    <G>
      <G>
        <Path d="M21.474,437.528l-20.315,52.11c-5.179,13.286,7.918,26.383,21.203,21.203l52.108-20.317L21.474,437.528z" />
      </G>
    </G>
  </Svg>
)

export default Edit
