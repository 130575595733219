import { VFC, useContext } from 'react'
import { Pressable } from 'react-native'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext, ModalVariant } from '../../../providers/ContractProvider'
import styled from 'styled-components/native'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import Hairline from '../../atoms/Hairline'
import BankCCLogo from '../../molucules/BankCCLogo'
import SharedBody from '../../molucules/SharedBody'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'

const FlexContainerBetween = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const FlexContainerStart = styled(Box)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

interface MakeAPaymentConfirmProps {
  paymentAccountNumber: string
  paymentIcon: string
  amount: number
  variant?: string
}

export const MakeAPaymentConfirm: VFC<MakeAPaymentConfirmProps> = ({
  paymentAccountNumber,
  paymentIcon,
  amount,
  variant,
}) => {
  const { localized, getCurrency } = useContext(ContentContext)
  const { setContract } = useContext(ContractContext)
  const handleAmountPress = () =>
    setContract(prevContract => ({ ...prevContract, modalVariant: ModalVariant.MAKE_PAYMENT }))

  const isForcedPayOff = variant === ModalVariant.CONFIRM_FORCE

  return (
    <Box>
      <Box style={{ marginBottom: 16 }}>
        <Hairline />
        <Box style={{ paddingVertical: 3 }}>
          <FlexContainerBetween>
            <SharedBody variant="--body-02-regular">
              {localized('makeAPayment.paymentMethod')}
            </SharedBody>
            <FlexContainerStart>
              <BankCCLogo paymentIcon={paymentIcon} />
              <Body
                lightColor={colors['--text-dark']}
                darkColor={whiteOpacity9}
                variant="--body-02-semi-emphasized">
                {`••••${paymentAccountNumber}`}
              </Body>
            </FlexContainerStart>
          </FlexContainerBetween>
        </Box>
        <Hairline />
        <Box style={{ paddingVertical: 16 }}>
          <FlexContainerBetween>
            <SharedBody variant="--body-02-regular">{localized('makeAPayment.amount')}</SharedBody>
            <FlexContainerStart>
              <Pressable
                accessible={!isForcedPayOff}
                accessibilityLabel={isForcedPayOff ? '' : localized('makeAPayment.amount')}
                accessibilityRole="button"
                importantForAccessibility="yes"
                testID={'01'}
                disabled={isForcedPayOff}
                onPress={handleAmountPress}>
                <Body
                  lightColor={colors['--text-dark']}
                  darkColor={whiteOpacity9}
                  variant="--body-02-semi-emphasized">
                  {getCurrency(amount)}
                </Body>
              </Pressable>
            </FlexContainerStart>
          </FlexContainerBetween>
        </Box>
        <Hairline />
      </Box>
      {isForcedPayOff ? (
        <Box style={{ marginBottom: 40 }}>
          <Body
            variant="--body-02-regular"
            lightColor={'black'}
            darkColor={'white'}
            style={{ textAlign: 'center' }}>
            {localized('makeAPayment.modal.confirmForce.footer')}
          </Body>
        </Box>
      ) : null}
    </Box>
  )
}
