// primary colours
export const purpleJellyDarker: string = '#141333'
export const purpleJellyDark: string = '#212189'
export const purpleJelly: string = '#4a4af4'
export const purpleJelly50: string = '#9795D7'
export const purpleJelly25: string = '#D0CFEA'
export const purpleJelly10: string = '#F2F2FA'

export const mintSpringDarkOpacity: string = 'rgba(42, 188, 122, .2)'
export const mintSprigDark: string = '#2abc7a'
export const mintSprig: string = '#00C1A1'
export const mintSprig75: string = '#40D0B8'
export const mintSprig50: string = '#80E0D0'
export const mintSprig25: string = '#BFEFE7'
export const mintSprig10: string = '#ECFAF8'

// secondary colours
export const beigeBread: string = '#F2ECE4'
export const beigeBread75: string = '#F5F1EB'
export const beigeBread50: string = '#F8F5F1'
export const beigeBread25: string = '#FCFAF8'

// tertiary colours
export const ink: string = '#0D0D0D'
export const ink75: string = '#404040'
export const ink50: string = '#808080'
export const ink25: string = '#BFBFBF'
export const ink10: string = '#E6E6E6'
export const ink5: string = '#F2F2F2'
export const ink0: string = '#FFFFFF'

// accent colours
export const coralJam: string = '#FF7575'
export const coralJam75: string = '#FF9797'
export const coralJam50: string = '#FFBABA'
export const coralJam25: string = '#FFDCDC'

export const yellowDijon: string = '#FAE980'
export const yellowDijon75: string = '#FBEEA0'
export const yellowDijon50: string = '#FCF4BF'
export const yellowDijon25: string = '#FEF9DF'

export const strawberryJam: string = '#C80606'

// dark colors
export const dark7: string = '#2A2A2A' // #202020 with 0.7 opacity
export const darker7: string = '#1D1d1D' // #0F0F0F with 0.7 opacity or overlay 1
export const darker4: string = '#272727' // #0F0F0F with 0.4 opacity or overlay 2
export const darkest: string = '#0F0F0F'
export const darker: string = '#111111'
export const dark: string = '#2F2F2F'

export const whiteOpacity1: string = 'rgba(255, 255, 255, 0.1)'
export const whiteOpacity4: string = 'rgba(255, 255, 255, 0.4)'
export const whiteOpacity6: string = 'rgba(255, 255, 255, 0.7)'
export const whiteOpacity7: string = 'rgba(255, 255, 255, 0.7)'
export const whiteOpacity9: string = 'rgba(255, 255, 255, 0.9)'

export const blackOpacity6: string = 'rgba(0, 0, 0, 0.7)'
