import { ReactNode, useContext } from 'react'
import { View, ScrollView, StyleProp, ViewStyle, RefreshControl } from 'react-native'
import { DimensionContext, ViewPortType } from '../../../providers/DimensionProvider'
import { ThemeContext, DARK, Theme } from '../../../providers/ThemeProvider'

type StyleKey = number[] | number
type ViewPort = ViewPortType

type K =
  | 'margin'
  | 'marginBottom'
  | 'marginTop'
  | 'marginRight'
  | 'marginLeft'
  | 'marginVertical'
  | 'marginHorizontal'
  | 'padding'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingRight'
  | 'paddingLeft'
  | 'paddingVertical'
  | 'paddingHorizontal'

type StyleProps = {
  [key in K]?: StyleKey
}

interface Children {
  children: ReactNode
}

type Style = {
  style?: StyleProp<ViewStyle>
}

type BackgroundColor = {
  backgroundLight?: string
  backgroundDark?: string
}

type ScrollViewType = {
  scrollView?: boolean
}

type RefreshControlType = {
  refreshing?: boolean
  onRefresh?: () => void
}

type ContentContainerStyleType = {
  contentContainerStyle?: ViewStyle
}

type Props = Children &
  StyleProps &
  Style &
  BackgroundColor &
  ScrollViewType &
  ContentContainerStyleType &
  RefreshControlType

const arrayToViewPortMap = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
}

const getStyleByType = (viewPort: ViewPort, styleProp: StyleKey) => {
  if (typeof styleProp === 'undefined') return null
  if (typeof styleProp === 'number') return styleProp
  return styleProp[arrayToViewPortMap[viewPort]]
}

const baseStyle = (viewPort: ViewPort, styleProps: StyleProps) => {
  const styleObject: { [key in K]?: number } = {}
  for (const key in styleProps) {
    if (styleProps.hasOwnProperty(key)) {
      styleObject[key] = getStyleByType(viewPort, styleProps[key])
    }
  }
  return styleObject
}

const backgroundStyle = (theme: Theme, backgroundLight: string, backgroundDark: string) => ({
  backgroundColor: theme === DARK ? backgroundDark : backgroundLight,
})

const setViewType = (scrollView: boolean) => (scrollView ? ScrollView : View)

const Box = ({
  children,
  margin,
  marginBottom,
  marginTop,
  marginRight,
  marginLeft,
  marginVertical,
  marginHorizontal,
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  paddingVertical,
  paddingHorizontal,
  backgroundLight = 'transparent',
  backgroundDark = 'transparent',
  scrollView = false,
  refreshing = false,
  onRefresh = () => {},
  contentContainerStyle,
  style,
  ...otherProps
}: Props) => {
  const { viewPort } = useContext(DimensionContext)
  const { theme } = useContext(ThemeContext)
  const styleProps: StyleProps = {
    margin,
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    marginVertical,
    marginHorizontal,
    padding,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    paddingVertical,
    paddingHorizontal,
  }
  const ViewComponent = setViewType(scrollView)

  const modifiedProps = Boolean(scrollView)
    ? {
        ...otherProps,
        keyboardShouldPersistTaps: 'handled',
        refreshControl: <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
      }
    : otherProps

  return (
    <ViewComponent
      scrollIndicatorInsets={{ right: 1 }}
      contentContainerStyle={contentContainerStyle}
      style={[
        baseStyle(viewPort, styleProps),
        backgroundStyle(theme, backgroundLight, backgroundDark),
        style,
      ]}
      {...modifiedProps}>
      {children}
    </ViewComponent>
  )
}

export default Box
