import { createContext, ReactNode, useState, Dispatch } from 'react'

interface ContextState {
  isLoading: boolean
  setLoading: Dispatch<boolean>
}

export const LoadingContext = createContext<ContextState>({
  isLoading: false,
  setLoading: (): void => {},
})

interface Props {
  children: ReactNode
}

export const LoadingProvider = ({ children }: Props) => {
  const [isLoading, setLoading] = useState<boolean>(true)

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>
  )
}
