import { useContext, useEffect, useState, useRef } from 'react'
import { StyleSheet } from 'react-native'
import { useNavigation, CommonActions, useRoute } from '@react-navigation/native'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useUUID } from '../../../hooks/useUUID'
import GenericModal from '../../molucules/GenericModal'
import Loading from '../../molucules/Loading'
import AccountCreationWrapper from '../../wrappers/AccountCreationWrapper'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import Body, { BODY_TWO_REGULAR, BODY_TWO_EMPHASIZED } from '../../atoms/Typography/Body'
import { CustomerContext } from '../../../providers/CustomerProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { sendVerifcationEmail, validateEmailPin } from '../../../endpoints'
import {
  GENERIC_ERROR,
  HOME_ENTRY,
  BOTTOM_NAVIGATOR,
  PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION,
  StatusDescription,
  AccessLevel,
  PLANS,
  ApiResponse,
  PURCHASES_TAB,
  PLANS_QUERY_PARAMS,
} from '../../../types'
import { MASK_PIN } from '../../../helpers/maskTypes'

interface Result {
  accessLevel?: AccessLevel
  token?: string
  expiration?: Date
  statusDescription?: StatusDescription
  statusCode?: number
}

const EmailVerificationCodeEntry = () => {
  const isPageMounted = useRef(false)
  const navigation = useNavigation()
  const { customer, setCustomer } = useContext(CustomerContext)
  const { localized, getLocaleCodeShort } = useContext(ContentContext)
  const { accessLevel, phoneNumber, setToken, setAccessLevel } = useContext(AuthContext)
  const [isModalOpen, setModalOpen] = useState(false)
  const [pin, setPin] = useState<string | null>(null)
  const [modalHeader, setModalHeader] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isPageLoading, setPageLoading] = useState(false)
  const [url, setUrl] = useState('')
  const { deleteUUID } = useUUID()
  const route = useRoute()
  const accountUpdateEmailSent = route?.params?.params === PHONE_NUMBER_UPDATE_EMAIL_VERIFICATION
  const sendVerifcationEmailBody = () => ({ culture: getLocaleCodeShort() })

  const validateEmailPinBody = () => ({ pin, culture: getLocaleCodeShort() })
  const axiosBody =
    url === sendVerifcationEmail
      ? sendVerifcationEmailBody()
      : url === validateEmailPin
      ? validateEmailPinBody()
      : {}

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: axiosBody,
  })

  useEffect(() => {
    if (!accountUpdateEmailSent) setUrl(sendVerifcationEmail)
  }, [])

  const effectSendVerificationEmail = () => {
    if (status === RequestStatus.FETCHING) {
      isPageMounted.current ? setLoading(true) : setPageLoading(true)
      return
    }
    if (isPageLoading) {
      setPageLoading(false)
      isPageMounted.current = true
    }
    setLoading(false)

    const maskedEmail = data?.emailAddress
    if (status === RequestStatus.FETCHED && maskedEmail) {
      setCustomer({ ...customer, maskedEmail })
      setUrl('')
    }
  }

  const effectVerifyEmailPin = () => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      return
    }
    setLoading(false)
    if ([RequestStatus.FETCHED, RequestStatus.ERROR].includes(status)) {
      const { statusDescription } = (error || data) as ApiResponse
      setPin('')
      setUrl('')
      routeAfterPinEntry(statusDescription)
    }
  }

  useEffect(() => {
    if (url === sendVerifcationEmail) {
      effectSendVerificationEmail()
    } else if (url === validateEmailPin) {
      effectVerifyEmailPin()
    }
  }, [status, url])

  const send = async () => {
    setUrl(validateEmailPin)
  }

  const handleResendPin = () => {
    setUrl(sendVerifcationEmail)
  }

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' && !disabled) {
      send()
    }
  }

  const handleSubmit = () => {
    if (!disabled) {
      send()
    }
  }

  const navToPlansWithParam = (params: { [s: string]: string }) => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: BOTTOM_NAVIGATOR,
            params: {
              screen: PURCHASES_TAB,
              params: { params, screen: PLANS },
            },
          },
        ],
      })
    )
  }

  const routeAfterPinEntry = (statusDescription: StatusDescription) => {
    const isAuthorized = accessLevel === AccessLevel.AUTHORIZED_USER
    switch (statusDescription) {
      case StatusDescription.INVALID_PIN:
        openErrorModal(StatusDescription.INVALID_PIN)
        break
      case StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS:
        openErrorModal(StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS)
        break
      case StatusDescription.DEVICE_LINKED_TO_DIFFERENT_ACCOUNT:
        handleDeviceLinkedToDifferentAccount()
        break
      case StatusDescription.SUCCESS:
        if (isAuthorized) {
          navToPlansWithParam({ paramType: PLANS_QUERY_PARAMS.WELCOME })
          break
        }
      case StatusDescription.PHONE_NUMBER_UPDATED:
        if (isAuthorized) {
          navToPlansWithParam({ paramType: PLANS_QUERY_PARAMS.PHONE_UPDATED })
          break
        }
      default:
        navigation.navigate(GENERIC_ERROR)
        break
    }
  }

  const handleDeviceLinkedToDifferentAccount = () => {
    deleteUUID(phoneNumber)
    setToken('')
    setAccessLevel(null)
    navigation.navigate(HOME_ENTRY)
  }

  const errorModalContent = (statusDescription: StatusDescription) => {
    switch (statusDescription) {
      case StatusDescription.INVALID_PIN:
        setModalHeader('emailVerification.error.incorrectCodeHeader')
        setModalMessage('emailVerification.error.incorrectCodeMessage')
        break
      case StatusDescription.TOO_MANY_INCORRECT_ATTEMPTS:
        setModalHeader('emailVerification.error.tooManyAttemptsHeader')
        setModalMessage('emailVerification.error.tooManyAttemptsMessage')
        break
      default:
        navigation.navigate(GENERIC_ERROR)
        break
    }
  }

  const openErrorModal = (statusDescription: StatusDescription) => {
    setLoading(false)
    setModalOpen(true)
    errorModalContent(statusDescription)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const displayEmail = customer.maskedEmail || customer.email

  const disabled = isModalOpen || pin?.length !== 6

  const pinInputProps: PinInputProps = {
    style: styles.input,
    keyboardType: 'number-pad',
    onChangeText: setPin,
    value: pin,
    testID: 'VerificationCode',
    label: localized('emailVerification.label'),
    maskProps: MASK_PIN,
    maskChar: '',
    autoFocus: true,
    onKeyPress: handleKeyPress,
    onSubmitEditing: handleSubmit,
  }

  interface PinInputProps {
    testID: string
    maskProps: any
    maskChar: ''
    autoFocus: boolean
    style: any
    label: () => string
    value: any
    onChangeText: () => void
    keyboardType: string
    onKeyPress: (e: any) => void
    onSubmitEditing: () => void
    returnKeyType?: string
  }

  if (!disabled) {
    pinInputProps.returnKeyType = 'done'
  }

  return (
    <AccountCreationWrapper
      hasPlant={true}
      paddingTop={[32, 32, 0, 0, 0]}
      title={localized('emailVerification.header')}>
      <GenericModal
        header={modalHeader}
        visible={isModalOpen}
        closeModal={closeModal}
        message={modalMessage}
        buttonText={'global.ok'}
      />
      {isPageLoading ? (
        <Loading variant="in-screen" />
      ) : (
        <>
          <Body
            darkColor={whiteOpacity9}
            lightColor={colors['--text-dark']}
            style={styles.body}
            variant={BODY_TWO_REGULAR}>
            {localized('emailVerification.message')}
            <Body
              darkColor={whiteOpacity9}
              lightColor={colors['--text-dark']}
              variant={BODY_TWO_EMPHASIZED}>
              {` ${displayEmail}`}
            </Body>
          </Body>
          <Input {...pinInputProps} />
          <Box style={styles.link}>
            <Link onPress={handleResendPin}>{localized('emailVerification.resendCode')}</Link>
          </Box>
          <Button
            testID="emailVerificationContinueBtn"
            onPress={send}
            isLoading={isLoading}
            disabled={disabled}>
            {localized('global.continue')}
          </Button>
        </>
      )}
    </AccountCreationWrapper>
  )
}

export default EmailVerificationCodeEntry

const styles = StyleSheet.create({
  heading: {
    marginBottom: 24,
  },
  body: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 24,
  },
  link: {
    marginBottom: 40,
    marginTop: 16,
  },
})
