import { useContext, Fragment, useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useRoute } from '@react-navigation/native'
import ThumbsUp from '../../molucules/ThumbsUp'
import { CustomerContext, CustomerType, defaultCustomer } from '../../../providers/CustomerProvider'
import SettingsItem from '../../molucules/SettingsItem'
import AccountInformationWrapper from '../../wrappers/AccountInformationWrapper'
import AccountInformationSettingsWrapper from '../../wrappers/AccountInformationSettingsWrapper'
import Hairline from '../../atoms/Hairline'
import { ContentContext } from '../../../providers/ContentProvider'
import { Localized } from '../../../localization/types'
import { formatAddress, formatNumber } from '../../../helpers'
import { getCustomers } from '../../../endpoints'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { useFlagSecure } from '../../../hooks/useFlagSecure'
import GenericModal from '../../molucules/GenericModal'
import Loading from '../../molucules/Loading'
import SharedBody from '../../molucules/SharedBody'
import {
  EMAIL_UPDATE,
  AUTHENICATED_PHONE_NUMBER_UPDATE,
  UPDATE_PHONE_NUMBER,
  MAILING_ADDRESS_UPDATE,
} from '../../../types'

type Item = {
  key: 'fullName' | 'formattedAddress' | 'formattedPhoneNumber' | 'email'
  title: string
  linksTo?: string
}

const getItems = (localized: Localized): Item[] => [
  { key: 'fullName', title: localized('accountInformation.name') },
  {
    key: 'formattedPhoneNumber',
    title: localized('accountInformation.phoneNumber'),
    linksTo: AUTHENICATED_PHONE_NUMBER_UPDATE,
  },
  {
    key: 'formattedAddress',
    title: localized('accountInformation.address'),
    linksTo: MAILING_ADDRESS_UPDATE,
  },
  { key: 'email', title: localized('global.emailAddress'), linksTo: EMAIL_UPDATE },
]

type Param = typeof UPDATE_PHONE_NUMBER | typeof EMAIL_UPDATE

const AccountInformation = () => {
  const { customer, setCustomer } = useContext(CustomerContext)
  const { localized } = useContext(ContentContext)
  const items = getItems(localized)
  const [url, setUrl] = useState('')
  const [isLoading, setLoading] = useState(false)
  const route = useRoute()
  const [updateModalType, setUpdateModalType] = useState<Param | undefined | null>(
    route?.params?.paramType
  )
  const [noContracts, setNoContracts] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const paramType = route?.params?.paramType
    if (paramType) {
      setShowModal(true)
    }
    setUpdateModalType(paramType)
  }, [route?.params?.paramType])

  const localCustomer = {
    ...customer,
    get fullName(): string {
      return `${this.firstName} ${this.lastName}`
    },
    get formattedAddress(): string[] {
      return formatAddress(this.address)
    },
    get formattedPhoneNumber(): string {
      const phoneAsInt = parseInt(this.phoneNumber, 10)
      return formatNumber(phoneAsInt, '(***) ***-****')
    },
  }

  useFlagSecure()

  const closeModal = () => {
    if (route?.params?.paramType) {
      route.params.paramType = null
    }
    setShowModal(false)
  }

  const { status, data } = useFetch({
    url,
    method: 'get',
  })

  useEffect(
    function initiateGetContracts() {
      setUrl(getCustomers)
    },
    [updateModalType]
  )

  function isCustomer(maybeCustomer: any): maybeCustomer is CustomerType {
    const keys = Object.keys(defaultCustomer)
    return keys.every(key => key in maybeCustomer)
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
      return
    }
    setLoading(false)
    if (status === RequestStatus.FETCHED && data && url) {
      if (Object.values(data).includes(null)) {
        setNoContracts(true)
        return
      }
      if (isCustomer(data)) {
        setCustomer(data)
      }
      setUrl('')
    }
  }, [data, status, url])

  const modalMessage = () => {
    if (updateModalType === EMAIL_UPDATE) {
      return `${localized('accountInformation.emailAddressUpdated')} ${localCustomer.email}`
    }
    return localized('authenticatedPhoneNumberUpdate.phoneNumberUpdated')
  }

  const modalProps = {
    closeModal,
    header: localized('accountInformation.allDone'),
    headerIcon: () => <ThumbsUp />,
    visible: showModal,
    message: modalMessage(),
    buttonText: 'global.continue',
  }

  return (
    <>
      <GenericModal {...modalProps} />
      {isLoading ? (
        <Loading variant="in-screen" isTransparent={true} />
      ) : (
        <AccountInformationSettingsWrapper>
          <AccountInformationWrapper title={localized('accountInformation.header')}>
            {noContracts ? (
              <SharedBody style={styles.body} variant="--body-01-regular">
                {localized('accountInformation.noContracts')}
              </SharedBody>
            ) : (
              <>
                {items.map(({ key, title, linksTo }) => (
                  <Fragment key={key}>
                    <SettingsItem
                      item={{
                        ...(linksTo && { linksTo }),
                        title,
                        description: localCustomer[key],
                      }}
                    />
                    <Hairline />
                  </Fragment>
                ))}
              </>
            )}
          </AccountInformationWrapper>
        </AccountInformationSettingsWrapper>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  body: {
    textAlign: 'left',
    marginBottom: 40,
  },
})

export default AccountInformation
