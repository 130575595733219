import { createRef } from 'react'
import {
  PartialState,
  NavigationAction,
  NavigationState,
  getStateFromPath,
  getPathFromState,
  getActionFromState,
  NavigationContainerRef,
} from '@react-navigation/native'

import { linkingConfig } from '../nav/linkingConfig'

export const DeepLinkSchema = [
  'https://portaltest.paybright.com',
  'https://sandbox.portal.paybright.com',
  'https://playground.portal.paybright.com',
  'https://portal.paybright.com',
  'http://localhost:19006',
]
export const navigationRef = createRef<NavigationContainerRef<any>>()

const cleanPathStr = (path: string) => {
  const queryVairablesIndex = path.indexOf('?')
  if (queryVairablesIndex === -1) {
    return path
  }
  return path.substr(0, queryVairablesIndex)
}

export const checkDeepLinkResult = (url: string) => {
  const extractedUrl = DeepLinkSchema.reduce((cleaned, schema) => cleaned.replace(schema, ''), url)

  const currentState = navigationRef.current?.getRootState() as NavigationState

  const linkState = getStateFromPath(
    extractedUrl,
    linkingConfig.config as any
  ) as PartialState<NavigationState>

  const currentPath = cleanPathStr(getPathFromState(currentState))
  const linkPath = linkState ? cleanPathStr(getPathFromState(linkState)) : null
  const action = linkState ? (getActionFromState(linkState) as NavigationAction) : {}

  return {
    action,
    linkPath,
    didDeepLinkLand: currentPath === linkPath,
  }
}
