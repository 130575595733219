import { inject, injectable } from "inversify";
import { ILoggingParameterService } from "./ILoggingParameterService";
import { INJECTABLE_TYPES, LoggingParameters } from "../types";
import { IUserAuthInfoService } from "./IUserAuthInfoService";


@injectable()
class LoggingParameterService implements ILoggingParameterService {

    @inject(INJECTABLE_TYPES.IUserAuthInfoService) 
    private _userAuthInfoService: IUserAuthInfoService;

    public GetParameters(): LoggingParameters {

        const portalDeviceGuid = this._userAuthInfoService.GetPortalDeviceGuid();
        const portalAccountId = this._userAuthInfoService.GetPortalAccountId();
        const portalSessionId = this._userAuthInfoService.GetPortalSessionId();

        return {
            PortalAccountId: portalAccountId ? portalAccountId : "N/A",
            PortalSessionId: portalSessionId ? portalSessionId : "N/A",
            PortalDeviceGuid: portalDeviceGuid ? portalDeviceGuid : "N/A"
        }
    }
}    


export {LoggingParameterService}