import { ReactNode } from 'react'
import Box from '../../atoms/Box'
import { darkest } from '../../tokens/colors/colors'

const AccountInformationSettingsWrapper = ({ children }: { children: ReactNode }) => (
  <Box
    style={{ flex: 1 }}
    backgroundLight="white"
    backgroundDark={darkest}
    paddingTop={[0, 16, 16, 16, 80]}
    paddingHorizontal={[0, 16, 16, 16, 80]}>
    <Box style={{ flex: 1, maxWidth: 550 }}>{children}</Box>
  </Box>
)

export default AccountInformationSettingsWrapper
