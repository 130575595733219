import { StyleSheet } from 'react-native'
import { TextInputProps } from 'react-native-paper/lib/typescript/src/components/TextInput/TextInput'
import Input from '../../atoms/Input'
import { MaskProps } from '../../atoms/Input/index.native'

const SharedInput = (props: TextInputProps & MaskProps) => <Input style={styles.input} {...props} />

export default SharedInput

const styles = StyleSheet.create({
  input: {
    marginBottom: 16,
  },
})
