import * as SecureStore from 'expo-secure-store'
import 'react-native-get-random-values'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import Hex from 'crypto-js/enc-hex'
import { v4 as uuidv4 } from 'uuid'
import { Platform } from 'react-native'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from './../providers/AuthProvider'
import { HASH_SALT } from '../types'
import { DEFAULT_PHONE_NUMBER } from '../types'
const isNative = Platform.OS === 'android' || Platform.OS === 'ios'

type Key = string

interface UUIDKeyValue {
  key: Key
  value: string
}

interface InitParams {
  contextSetter: (str: string) => void
  key: Key
  isPhoneUpdateFlow: boolean
}

const getHashedKey = (phoneString: string) => {
  const encrypted = sha256(HASH_SALT + phoneString).toString()
  return Base64.parse(encrypted).toString(Hex)
}

const getUUID = async (key: Key) => {
  if (isNative) {
    try {
      return await SecureStore.getItemAsync(key)
    } catch (e) {
      // err
    }
  } else {
    return localStorage.getItem(key)
  }
}

const setUUID = async ({ key, value }: UUIDKeyValue) => {
  if (isNative) {
    try {
      await SecureStore.setItemAsync(key, value)
    } catch (e) {
      // err
    }
  } else {
    localStorage.setItem(key, value)
  }
}

const deleteUUID = async (phoneString: string) => {
  const key = getHashedKey(phoneString)
  if (isNative) {
    try {
      await SecureStore.deleteItemAsync(key)
    } catch (e) {
      // err
    }
  } else {
    localStorage.removeItem(key)
  }
}

const init = async ({ contextSetter, key, isPhoneUpdateFlow }: InitParams) => {
  const result = await getUUID(key)

  if (result && !isPhoneUpdateFlow) {
    contextSetter(result)
  } else {
    const value = uuidv4()
    await setUUID({ key, value })
    contextSetter(value)
  }
}

export const useUUID = (phoneString?: string) => {
  const { setPortalDeviceGuid, phoneNumber, setPhoneNumber, authFlowType } = useContext(AuthContext)

  useEffect(() => {
    if (!phoneString) return
    setPhoneNumber(phoneString)
  }, [phoneString])

  useEffect(() => {
    const isDefaultPhoneNumber = phoneNumber === DEFAULT_PHONE_NUMBER
    if (!phoneString || isDefaultPhoneNumber) return
    const key = getHashedKey(phoneNumber)
    const isPhoneUpdateFlow = authFlowType === 'phoneUpdate'
    init({ isPhoneUpdateFlow, key, contextSetter: setPortalDeviceGuid })
  }, [init, setPortalDeviceGuid, phoneNumber, phoneString, authFlowType, DEFAULT_PHONE_NUMBER])

  return { deleteUUID, getUUIDKey: getHashedKey }
}
