import { useContext, useEffect, useState } from 'react'
import {
  StyleSheet,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  Platform,
} from 'react-native'
import SharedInput from '../SharedInput'
import { SharedInputProps } from '../SharedInput/helpers'
import { validateEmail } from '../../../helpers'
import { CustomerContext } from '../../../providers/CustomerProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import Body from '../../atoms/Typography/Body'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { MASK_NAME } from '../../../helpers/maskTypes'

const PersonalInformation = ({
  validFormHandler,
  send,
  isValidForm,
}: {
  validFormHandler(isFormValid: boolean): void
  send(): void
  isValidForm: boolean
}) => {
  const { customer, setCustomer } = useContext(CustomerContext)
  const { localized } = useContext(ContentContext)
  const { lastName, email } = customer
  const [isEmailValid, setEmailIsValid] = useState(true)
  const [isLastNameValid, setLastNameIsValid] = useState(true)
  const isAndroid = Platform.OS === 'android'

  useEffect(() => {
    setEmailIsValid(!email ? false : validateEmail(email))
  }, [email])

  useEffect(() => {
    setLastNameIsValid(Boolean(lastName?.length))
  }, [lastName])

  useEffect(() => {
    validFormHandler(isEmailValid && isLastNameValid)
  }, [isEmailValid, isLastNameValid])

  const handleKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Enter' && isValidForm) {
      sanitizeCustomerContext()
    }
  }

  const sanitizeCustomerContext = () => {
    setCustomer(prevState => ({
      ...prevState,
      email: prevState?.email?.trim(),
      lastName: prevState?.lastName?.trim(),
      isSanitized: true,
    }))
  }

  useEffect(() => {
    const { isSanitized } = customer || {}
    if (isSanitized) {
      send()
    }
  }, [customer])

  const handleSubmit = () => {
    if (isValidForm) {
      sanitizeCustomerContext()
    }
  }

  const personalInformationPropsName: SharedInputProps = {
    textContentType: 'familyName',
    maskProps: MASK_NAME,
    errorMessage: localized('global.errorName'),
    maskChar: '',
    autoFocus: true,
    testID: 'LastName',
    onChangeText: text => {
      setCustomer({ ...customer, lastName: text })
    },
    label: localized('global.lastName'),
    value: lastName,
    error: !isLastNameValid,
    onKeyPress: handleKeyPress,
    onSubmitEditing: handleSubmit,
  }

  const personalInformationPropsEmail: SharedInputProps = {
    error: !isEmailValid,
    errorMessage: localized('global.errorEmail'),
    disableMask: true,
    textContentType: 'emailAddress',
    keyboardType: isAndroid ? 'visible-password' : 'email-address',
    testID: 'Email',
    onChangeText: text => setCustomer({ ...customer, email: text.toLowerCase() }),
    label: localized('global.emailAddress'),
    value: email,
    onKeyPress: handleKeyPress,
    onSubmitEditing: handleSubmit,
    autoCapitalize: 'none',
    ...(isAndroid && { secureTextEntry: true }),
  }

  if (isValidForm) {
    personalInformationPropsEmail.returnKeyType = 'done'
    personalInformationPropsName.returnKeyType = 'done'
  }

  return (
    <>
      <Body
        variant="--body-02-semi-emphasized"
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        style={styles.marginSm}>
        {localized('linkYourAccount.subHeader')}
      </Body>
      <SharedInput {...personalInformationPropsName} />
      <SharedInput {...personalInformationPropsEmail} />
      <Body
        variant="--body-02-regular"
        darkColor={whiteOpacity9}
        lightColor={colors['--text-dark']}
        style={styles.marginLg}>
        {localized('linkYourAccount.paragraph')}
      </Body>
    </>
  )
}

const styles = StyleSheet.create({
  marginSm: {
    marginBottom: 24,
  },
  marginLg: {
    marginBottom: 36,
  },
})

export default PersonalInformation
