import { createContext, ReactNode, useState, Dispatch } from 'react'
import queryString from 'query-string'

interface ContextState {
  params: {}
  setParams: Dispatch<{}>
}

export const QueryParamsContext = createContext<ContextState>({
  params: {},
  setParams: (): void => {},
})

interface Props {
  children: ReactNode
}

export const QueryParamsProvider = ({ children }: Props) => {
  const [params, setParams] = useState<{}>(queryString.parse(location.search))

  return (
    <QueryParamsContext.Provider value={{ params, setParams }}>
      {children}
    </QueryParamsContext.Provider>
  )
}
