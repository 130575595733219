import { VFC, useContext } from 'react'
import Body from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import Link from '../../atoms/Link'
import { whiteOpacity9 } from '../../../components/tokens/colors/colors'
import colors from '../../../components/tokens/colors'
import { ContentContext } from '../../../providers/ContentProvider'
import { formatAddress } from '../../../helpers'
import { AddressType } from '../../../providers/CustomerProvider'

import { BillingAddress } from '../../../types'

type AddressProps = BillingAddress & {
  openAddressDropdown: (bool: boolean) => void
  isAddressVisible: boolean
  hasCreditCard: boolean
}

export const Address: VFC<AddressProps> = ({
  openAddressDropdown,
  isAddressVisible,
  hasCreditCard,
  ...addressProps
}) => {
  const { localized } = useContext(ContentContext)

  const AddressLines = ({
    addrAptSuite = '',
    addrCity = '',
    addrHouseNo = '',
    addrStreetName = '',
    addrProvince = '',
    addrPostalCode = '',
  }: BillingAddress) => {
    const addressLines = formatAddress({
      aptSuite: addrAptSuite,
      city: addrCity,
      houseNo: addrHouseNo,
      streetName: addrStreetName,
      province: addrProvince,
      postalCode: addrPostalCode,
    })
    return (
      <Box style={{ marginBottom: 16 }}>
        {addressLines.map(addressLine => (
          <Body key={addressLine} lightColor={colors['--text-dark']} darkColor={whiteOpacity9}>
            {`${addressLine}`}
          </Body>
        ))}
      </Box>
    )
  }

  return (
    <Box style={{ marginBottom: isAddressVisible ? 16 : 0 }}>
      {hasCreditCard ? <AddressLines {...addressProps} /> : null}
      {!isAddressVisible ? (
        <Link onPress={() => openAddressDropdown(true)}>
          {localized('linkYourCreditCard.updateAddress')}
        </Link>
      ) : null}
    </Box>
  )
}
