import { FC } from 'react'
import styled from 'styled-components/native'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Card from '../../atoms/Card'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import Box from '../../atoms/Box'
import colors from '../../tokens/colors'

interface SharedTitleProps {
  variant: BodyVariants
}
interface ConditionalMarginViewProps {
  hasProgressBar: boolean
}

const StyledCurrency = styled(Body)`
  font-size: 20px;
`

const StyledBody = styled(Body)`
  margin-bottom: 8px;
`

export const BoxMarginBottomSm = styled(Box)`
  max-width: 100%;
  flex: 1 0 auto;
  margin-bottom: 6px;
`

export const SuccessContainer = styled(Box)`
  flex-basis: 80px;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: flex-end;
`

export const StyledCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  max-width: 550px;
`

export const FlexedBox = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 100%;
`
export const StyledView = styled.View`
  flex: 1;
`

export const FlexChild50 = styled(Box)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  margin-bottom: 0;
`
export const ConditionalMarginView = styled.View<ConditionalMarginViewProps>`
  margin-bottom: ${({ hasProgressBar }) => (hasProgressBar ? '16px' : '0')};
`

export const SharedTitle: FC<SharedTitleProps> = ({ variant, children }) => (
  <StyledBody lightColor={colors['--text-dark']} darkColor={whiteOpacity9} variant={variant}>
    {children}
  </StyledBody>
)

export const SharedAmountLabel: FC = ({ children }) => (
  <BoxMarginBottomSm>
    <Body
      lightColor={colors['--text-de-emphasized']}
      darkColor={whiteOpacity9}
      variant="--body-02-regular">
      {children}
    </Body>
  </BoxMarginBottomSm>
)

export const SharedAmountCurrency: FC = ({ children }) => (
  <StyledCurrency
    lightColor={colors['--text-01']}
    darkColor={whiteOpacity9}
    variant="--body-01-semi-emphasized">
    {children}
  </StyledCurrency>
)
