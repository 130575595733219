import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const Error = ({ color = 'black', size = '40' }: Props) => (
  <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8833 25.375C18.8833 24.7537 19.387 24.25 20.0083 24.25C20.6296 24.25 21.1333 24.7537 21.1333 25.375C21.1333 25.9963 20.6296 26.5 20.0083 26.5C19.387 26.5 18.8833 25.9963 18.8833 25.375Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 13.65C20.4142 13.65 20.75 13.9858 20.75 14.4V22.4C20.75 22.8142 20.4142 23.15 20 23.15C19.5858 23.15 19.25 22.8142 19.25 22.4V14.4C19.25 13.9858 19.5858 13.65 20 13.65Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 20C8.25 13.5107 13.5107 8.25 20 8.25C26.4893 8.25 31.75 13.5107 31.75 20C31.75 26.4893 26.4893 31.75 20 31.75C13.5107 31.75 8.25 26.4893 8.25 20ZM20 9.75C14.3391 9.75 9.75 14.3391 9.75 20C9.75 25.6609 14.3391 30.25 20 30.25C25.6609 30.25 30.25 25.6609 30.25 20C30.25 14.3391 25.6609 9.75 20 9.75Z"
      fill={color}
    />
  </Svg>
)

export default Error
