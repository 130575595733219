import { ReactNode, useContext } from 'react'
import { View, StyleSheet, ViewStyle } from 'react-native'
import elevation from '../../tokens/elevation'
import { ThemeContext } from '../../../providers/ThemeProvider'
import { darker7, ink10 } from '../../tokens/colors/colors'

const ELEVATION_REGULAR_ONE = '--elevation-regular-1'
const ELEVATION_REGULAR_TWO = '--elevation-regular-2'
const ELEVATION_REGULAR_THREE = '--elevation-regular-3'
const ELEVATION_REGULAR_FOUR = '--elevation-regular-4'
const ELEVATION_EMPHASIZED_ONE = '--elevation-emphasized-1'
const ELEVATION_EMPHASIZED_TWO = '--elevation-emphasized-2'
const ELEVATION_EMPHASIZED_THREE = '--elevation-emphasized-3'
const ELEVATION_EMPHASIZED_FOUR = '--elevation-emphasized-4'

type Elevation =
  | typeof ELEVATION_REGULAR_ONE
  | typeof ELEVATION_REGULAR_TWO
  | typeof ELEVATION_REGULAR_THREE
  | typeof ELEVATION_REGULAR_FOUR
  | typeof ELEVATION_EMPHASIZED_ONE
  | typeof ELEVATION_EMPHASIZED_TWO
  | typeof ELEVATION_EMPHASIZED_THREE
  | typeof ELEVATION_EMPHASIZED_FOUR

interface Props {
  children: ReactNode
  level?: Elevation
  style?: ViewStyle
  isError?: boolean
}

const Card = ({ children, style, level = ELEVATION_REGULAR_ONE }: Props) => {
  const { theme } = useContext(ThemeContext)
  return <View style={[styles.baseStyle, styles[theme], styles[level], style]}>{children}</View>
}

export default Card

const styles = StyleSheet.create({
  baseStyle: {
    borderRadius: 8,
  },
  dark: {
    backgroundColor: darker7,
  },
  light: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: ink10,
  },
  '--elevation-regular-1': {
    ...elevation[ELEVATION_REGULAR_ONE],
  },
  '--elevation-regular-2': {
    ...elevation[ELEVATION_REGULAR_TWO],
  },
  '--elevation-regular-3': {
    ...elevation[ELEVATION_REGULAR_THREE],
  },
  '--elevation-regular-4': {
    ...elevation[ELEVATION_REGULAR_FOUR],
  },

  '--elevation-emphasized-1': {
    ...elevation[ELEVATION_EMPHASIZED_ONE],
  },
  '--elevation-emphasized-2': {
    ...elevation[ELEVATION_EMPHASIZED_TWO],
  },
  '--elevation-emphasized-3': {
    ...elevation[ELEVATION_EMPHASIZED_THREE],
  },
  '--elevation-emphasized-4': {
    ...elevation[ELEVATION_EMPHASIZED_FOUR],
  },
})
