import { useNavigation } from '@react-navigation/native'
import { useContext } from 'react'
import { TouchableOpacity, ViewStyle } from 'react-native'
import { LIGHT, ThemeContext } from '../../../providers/ThemeProvider'
import Box from '../../atoms/Box'
import { ArrowLeft } from '../../atoms/Icons'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'

const BackArrow = ({
  style,
  paddingTop = 32,
  paddingLeft,
  paddingBottom = 32,
  darkBackground = 'transparent',
  darkModeOverride = false,
}: {
  style?: ViewStyle
  paddingTop?: number[] | number
  paddingLeft?: number[] | number
  paddingBottom?: number[] | number
  darkBackground?: string
  darkModeOverride?: boolean
}) => {
  const { theme } = useContext(ThemeContext)
  const navigation = useNavigation()

  return (
    <Box
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingBottom={paddingBottom}
      backgroundLight="transparent"
      backgroundDark={darkBackground}
      style={style}>
      <TouchableOpacity onPress={navigation.goBack}>
        <ArrowLeft
          color={
            darkModeOverride || theme === LIGHT ? colors['--ui-primary-default'] : whiteOpacity9
          }
        />
      </TouchableOpacity>
    </Box>
  )
}

export default BackArrow
