import { useContext, ReactNode } from 'react'
import { Text, TextStyle, StyleSheet } from 'react-native'
import colors from '../../../tokens/colors'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'

export const BODY_ONE_REGULAR = '--body-01-regular'
export const BODY_ONE_SEMI_EMPHASIZED = '--body-01-semi-emphasized'
export const BODY_ONE_EMPHASIZED = '--body-01-emphasized'
export const BODY_TWO_REGULAR = '--body-02-regular'
export const BODY_TWO_SEMI_EMPHASIZED = '--body-02-semi-emphasized'
export const BODY_TWO_EMPHASIZED = '--body-02-emphasized'

export type BodyVariants =
  | typeof BODY_ONE_REGULAR
  | typeof BODY_ONE_SEMI_EMPHASIZED
  | typeof BODY_ONE_EMPHASIZED
  | typeof BODY_TWO_REGULAR
  | typeof BODY_TWO_SEMI_EMPHASIZED
  | typeof BODY_TWO_EMPHASIZED

export interface BodyProps {
  variant?: BodyVariants
  children: string | ReactNode
  lightColor: string
  darkColor: string
  style?: TextStyle
}

const Body = ({ variant = BODY_ONE_REGULAR, children, style, ...otherProps }: BodyProps) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Text
      accessibilityRole="text"
      style={[styles[variant], style, styles[theme], { color: otherProps[`${theme}Color`] }]}
      {...otherProps}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  '--body-01-regular': {
    fontSize: 18,
    fontFamily: 'Calibre',
    lineHeight: 24,
  },
  '--body-01-semi-emphasized': {
    fontSize: 18,
    fontFamily: 'Calibre-semi',
    lineHeight: 24,
  },
  '--body-01-emphasized': {
    fontSize: 18,
    fontFamily: 'Calibre-bold',
    lineHeight: 24,
  },
  '--body-02-regular': {
    fontSize: 16,
    fontFamily: 'Calibre',
    lineHeight: 18.2,
  },
  '--body-02-semi-emphasized': {
    fontSize: 16,
    fontFamily: 'Calibre-semi',
    lineHeight: 18.2,
  },
  '--body-02-emphasized': {
    fontSize: 16,
    fontFamily: 'Calibre-bold',
    lineHeight: 18.2,
  },
})

export default Body
