import { useContext } from 'react'
import { Text, TextStyle, StyleSheet } from 'react-native'
import colors from '../../../tokens/colors'
import { whiteOpacity9 } from '../../../tokens/colors/colors'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { DimensionContext } from '../../../../providers/DimensionProvider'

const HEADING_ONE = '--heading-1'
const HEADING_TWO = '--heading-2'
const HEADING_THREE = '--heading-3'
const HEADING_FOUR = '--heading-4'

export type HeadingVariants =
  | typeof HEADING_ONE
  | typeof HEADING_TWO
  | typeof HEADING_THREE
  | typeof HEADING_FOUR

interface Props {
  variant?: HeadingVariants
  lightColor?: string
  darkColor?: string
  children: string | string[]
  style?: TextStyle
}

const Heading = ({
  variant = HEADING_ONE,
  lightColor = colors['--text-dark'],
  darkColor = whiteOpacity9,
  children,
  style,
  ...otherProps
}: Props) => {
  const { theme } = useContext(ThemeContext)
  const { viewPort } = useContext(DimensionContext)
  const isDark = theme === 'dark'
  return (
    <Text
      accessibilityRole="header"
      aria-level={variant.slice(-1)}
      style={[
        styles.baseStyle,
        styles[`${variant}-${viewPort}`],
        style,
        styles[theme],
        { color: isDark ? darkColor : lightColor },
      ]}
      {...otherProps}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  baseStyle: {
    fontFamily: 'MangueiraBold',
    fontWeight: '500',
    // lineHeight: 1,
  },
  '--heading-1-xs': {
    fontSize: 32,
    lineHeight: 38.4,
  },
  '--heading-1-sm': {
    fontSize: 36,
    lineHeight: 43.2,
  },
  '--heading-1-md': {
    fontSize: 40,
    lineHeight: 48,
  },
  '--heading-1-lg': {
    fontSize: 44,
    lineHeight: 52.8,
  },
  '--heading-1-xl': {
    fontSize: 48,
    lineHeight: 57.6,
  },
  '--heading-2-xs': {
    fontSize: 28,
    lineHeight: 33.6,
  },
  '--heading-2-sm': {
    fontSize: 28,
    lineHeight: 33.6,
  },
  '--heading-2-md': {
    fontSize: 32,
    lineHeight: 38.4,
  },
  '--heading-2-lg': {
    fontSize: 32,
    lineHeight: 38.4,
  },
  '--heading-2-xl': {
    fontSize: 36,
    lineHeight: 43.2,
  },
  '--heading-3-xs': {
    fontSize: 24,
    lineHeight: 28.8,
  },
  '--heading-3-sm': {
    fontSize: 24,
    lineHeight: 28.8,
  },
  '--heading-3-md': {
    fontSize: 24,
    lineHeight: 28.8,
  },
  '--heading-3-lg': {
    fontSize: 24,
    lineHeight: 28.8,
  },
  '--heading-3-xl': {
    fontSize: 24,
    lineHeight: 28.8,
  },
  '--heading-4-xs': {
    fontSize: 20,
    lineHeight: 24,
  },
  '--heading-4-sm': {
    fontSize: 20,
    lineHeight: 24,
  },
  '--heading-4-md': {
    fontSize: 20,
    lineHeight: 24,
  },
  '--heading-4-lg': {
    fontSize: 20,
    lineHeight: 24,
  },
  '--heading-4-xl': {
    fontSize: 20,
    lineHeight: 24,
  },
})

export default Heading
