import { VFC, useContext } from 'react'
import { patchFlatListProps } from 'react-native-web-refresh-control'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { FlatList, ListRenderItem } from 'react-native'

interface FlatListWithRefreshProps {
  data: any[]
  renderItem: ListRenderItem<any>
  refreshing: boolean
  onRefresh?: () => void
  keyExtractor?: (item: any, index: number) => string
}

export const FlatListWithRefresh: VFC<FlatListWithRefreshProps> = ({
  data,
  renderItem,
  refreshing,
  onRefresh,
  keyExtractor,
}) => {
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'
  if (isMobile) {
    patchFlatListProps()
  }

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      refreshing={refreshing}
      onRefresh={onRefresh}
      keyExtractor={keyExtractor}
    />
  )
}
