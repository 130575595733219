import { BlurView } from 'expo-blur'
import { useAppState } from '../../../hooks/useAppState'
import { Image, StyleSheet } from 'react-native'

export const BlurredOverlay = () => {
  const { appState } = useAppState({})

  const isBackgroundOrInactive = appState.match(/inactive|background/)

  return isBackgroundOrInactive ? (
    <BlurView intensity={100} style={styles.root}>
      <Image style={styles.image} source={require('../../../assets/images/pb-splash.png')} />
    </BlurView>
  ) : null
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 360,
    height: 360,
  },
})
