import { createContext, ReactNode, useState, useEffect } from 'react'
import { Dimensions, ScaledSize } from 'react-native'
export const breakpoints = [576, 768, 992, 1200]
interface Dimension {
  window: ScaledSize
  screen: ScaledSize
}

const XS = 'xs'
const SM = 'sm'
const MD = 'md'
const LG = 'lg'
const XL = 'xl'

export type ViewPortType = typeof XS | typeof SM | typeof MD | typeof LG | typeof XL

interface ContextState {
  dimensions: Dimension
  // tslint:disable-next-line: max-union-size
  viewPort: ViewPortType
}

export const DimensionContext = createContext<ContextState>({
  dimensions: {
    window: { height: 0, width: 0, scale: 0, fontScale: 0 },
    screen: { height: 0, width: 0, scale: 0, fontScale: 0 },
  },
  viewPort: 'xs',
})

interface Props {
  children: ReactNode
}

const findViewPort = (width: number) => {
  if (0 <= width && width < breakpoints[0]) {
    return XS
  }
  if (breakpoints[0] <= width && width < breakpoints[1]) {
    return SM
  }
  if (breakpoints[1] <= width && width < breakpoints[2]) {
    return MD
  }
  if (breakpoints[2] <= width && width < breakpoints[3]) {
    return LG
  }
  if (breakpoints[3] <= width) {
    return XL
  }
  return XS
}

const window = Dimensions.get('window')
const screen = Dimensions.get('screen')

export const DimensionProvider = ({ children }: Props) => {
  const [dimensions, setDimensions] = useState({ window, screen })

  const viewPort = findViewPort(dimensions.window.width)

  // tslint:disable-next-line: no-shadowed-variable
  const onChange = ({ window, screen }: Dimension) => {
    setDimensions({ window, screen })
  }

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      onChange
    )
    return () => {
      subscription.remove()
    }
  })
  return (
    <DimensionContext.Provider value={{ dimensions, viewPort }}>
      {children}
    </DimensionContext.Provider>
  )
}
