import Svg, { Path } from 'react-native-svg'
import { ink25 } from '../../../tokens/colors/colors'
import Props from './shared'

const Circle = ({ color = ink25, size = '20' }: Props) => {
  const strokeWidth = parseInt(size) * 0.55
  return (
    <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 88 88" fill="none">
      <Path
        d="M82.5 44C82.5 65.263 65.263 82.5 44 82.5C22.737 82.5 5.5 65.263 5.5 44C5.5 22.737 22.737 5.5 44 5.5C65.263 5.5 82.5 22.737 82.5 44Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Circle
