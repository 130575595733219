import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const AccountInformation = ({ color = 'black', size = '40' }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      d="M15.1822 13.6849C15.1822 15.1284 15.7556 16.5128 16.7763 17.5335C17.797 18.5542 19.1814 19.1276 20.6249 19.1276C22.0684 19.1276 23.4528 18.5542 24.4735 17.5335C25.4942 16.5128 26.0676 15.1284 26.0676 13.6849C26.0676 12.2414 25.4942 10.857 24.4735 9.83632C23.4528 8.81561 22.0684 8.24219 20.6249 8.24219C19.1814 8.24219 17.797 8.81561 16.7763 9.83632C15.7556 10.857 15.1822 12.2414 15.1822 13.6849V13.6849Z"
      fill={color}
    />
    <Path
      d="M20.6251 20.6121C18.1327 20.6149 15.7432 21.6063 13.9807 23.3687C12.2183 25.1312 11.2269 27.5207 11.2241 30.0131C11.2241 30.1443 11.2762 30.2702 11.369 30.363C11.4618 30.4558 11.5876 30.5079 11.7189 30.5079H29.5314C29.6626 30.5079 29.7884 30.4558 29.8812 30.363C29.974 30.2702 30.0261 30.1443 30.0261 30.0131C30.0233 27.5207 29.0319 25.1312 27.2695 23.3687C25.507 21.6063 23.1175 20.6149 20.6251 20.6121Z"
      fill={color}
    />
  </Svg>
)

export default AccountInformation
