import styled, { keyframes, css } from 'styled-components'
interface StyledProps {
  isMobile?: boolean
  isModal?: boolean
  modalBackground: string
}

const animation = keyframes`
0% {
  opacity:0;
}
50% {
  opacity:.2
}
100% {
  opacity: 1;
}
`

const animationRule = css`
  ${animation}
`

export const StyledModal = styled.div<StyledProps>(
  ({ isMobile, isModal, modalBackground }) => css`
    min-width: 320px;
    max-width: 552px;
    width: 100%;
    display: flex;
    flex: 1 1 0;
    align-items: stretch;
    flex-direction: column;
    overflow: hidden;
    animation: ${animationRule} 90ms ease-in forwards;
    background-color: ${modalBackground};
    ${!isMobile ? 'margin: 16px 0; border-radius: 8px;' : 'max-width:100vw; width:100vw;'}
    ${isModal ? 'flex:0 0 auto; margin:16px 0; padding-bottom: 23px;' : 'flex:1; padding-bottom:0;'}
  `
)
