import { useContext, useCallback } from 'react'
import AppUserTypeSelector from '../AppUserTypeSelector'
import Footer from '../../molucules/Footer'
import Login from '../Login'
import { DimensionContext } from '../../../providers/DimensionProvider'
import GenericModal from '../../molucules/GenericModal'
import { AuthContext } from '../../../providers/AuthProvider'
import { localized } from '../../../localization/Localized'
import { INACTIVE_SESSION_MINUTES } from '../../../config/api'

const HomeEntry = () => {
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'
  const { sessionExpired, setSessionExpired } = useContext(AuthContext)

  const closeModal = useCallback(() => {
    setSessionExpired(false)
  }, [setSessionExpired])

  const body = localized('sessionExpired.body').replace('_', INACTIVE_SESSION_MINUTES)

  return (
    <>
      <Login center={true}>
        <AppUserTypeSelector />
      </Login>
      <GenericModal
        header="sessionExpired.title"
        visible={sessionExpired}
        closeModal={closeModal}
        message={body}
        buttonText="global.ok"
      />
      {!isMobile ? <Footer /> : null}
    </>
  )
}
export default HomeEntry
