import { useCallback, useContext, useEffect, useState } from 'react'
import { StyleSheet, Image, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import Box from '../../atoms/Box'
import Body from '../../atoms/Typography/Body'
import Button from '../../atoms/Button'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import colors from '../../tokens/colors'
import { ContentContext, ContractContext, DimensionContext } from '../../../providers'
import { LINK_CREDIT_CARD, LINK_BANK_ACCOUNT, EFT, CC } from '../../../types'
import GenericModal from '../../molucules/GenericModal'
import Checkbox from '../../atoms/Checkbox'

const IS_APPLE_PAYMENT_UPDATE_APPLIED_TO_ALL_CONTRACTS_IN_GROUP_BY_DEFAULT = true

const PaymentMethodSelector = () => {
  const { navigate } = useNavigation()
  const { $ } = useContext(ContentContext)
  const { viewPort } = useContext(DimensionContext)
  const { contract, setContract } = useContext(ContractContext)
  const isMobile = viewPort === 'xs'
  const [paymentSelected, setPaymentSelected] = useState<typeof EFT | typeof CC>(EFT)
  const [updatePaymentModalVisible, setUpdatePaymentModalVisible] = useState(false)
  const { isApple } = contract
  const [isAppliedToAllOfContractGroup, setIsAppliedToAllOfContractGroup] = useState(
    IS_APPLE_PAYMENT_UPDATE_APPLIED_TO_ALL_CONTRACTS_IN_GROUP_BY_DEFAULT && isApple
  )

  const closeModal = () => {
    setUpdatePaymentModalVisible(false)
  }

  const enterUpdateEftFlow = () => {
    setUpdatePaymentModalVisible(false)
    navigate(LINK_BANK_ACCOUNT)
  }

  useEffect(() => {
    setContract(prevContract => ({
      ...prevContract,
      isAppliedToAllOfContractGroup,
    }))
  }, [isApple, isAppliedToAllOfContractGroup])

  const handleLinkBankAccount = () => {
    setPaymentSelected(EFT)
    setUpdatePaymentModalVisible(true)
  }

  const handleLinkCreditCard = () => {
    if (isAppliedToAllOfContractGroup) {
      setPaymentSelected(CC)
      setUpdatePaymentModalVisible(true)
    } else {
      navigate(LINK_CREDIT_CARD)
    }
  }

  const handleModalButtonPress = useCallback(() => {
    if (paymentSelected === CC) {
      closeModal()
      navigate(LINK_CREDIT_CARD)
    } else {
      enterUpdateEftFlow()
    }
  }, [paymentSelected])

  const paymentModalProps =
    paymentSelected === EFT
      ? {
          message: 'updatePaymentModalMessage.body',
          ...(isAppliedToAllOfContractGroup && {
            additionalMessage: 'updatePaymentModalMessage.appleMessage',
          }),
        }
      : {
          message: 'updatePaymentModalMessage.appleMessage',
        }

  return (
    <PlanDetailWrapper
      title={$`paymentMethodSelector.heading`}
      {...Platform.select({
        native: { scrollView: true },
        web: { isModal: !isMobile },
        default: null,
      })}>
      <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
        <Body lightColor={colors['--text-dark']} darkColor={whiteOpacity9} style={styles.body}>
          {$`paymentMethodSelector.paragraph`}
        </Body>
        <Image style={styles.piggy} source={require('../../../assets/images/piggy.png')} />
        <Box style={styles.buttonWrapper}>
          {isApple ? (
            <Box style={styles.checkbox}>
              <Checkbox
                checked={isAppliedToAllOfContractGroup}
                onPress={() => setIsAppliedToAllOfContractGroup(currentState => !currentState)}
                label={$`global.applyToAllAppleContractsCheckboxLabel`}
              />
            </Box>
          ) : null}
          <Button testID="LinkCreditCard" onPress={handleLinkCreditCard}>
            {$`paymentMethodSelector.linkCreditCard`}
          </Button>
        </Box>
        <Box style={styles.buttonWrapper}>
          <Button testID="LinkBankAccount" secondary={true} onPress={handleLinkBankAccount}>
            {$`paymentMethodSelector.linkBankAccount`}
          </Button>
        </Box>
      </PlanDetailWrapper.InnerWrapper>
      <GenericModal
        header={'updatePaymentModalMessage.header'}
        visible={updatePaymentModalVisible}
        closeModal={closeModal}
        onButtonPress={handleModalButtonPress}
        buttonText={'updatePaymentModalMessage.button'}
        secondaryButtonText={'global.goBack'}
        onSecondaryButtonPress={closeModal}
        {...paymentModalProps}
      />
    </PlanDetailWrapper>
  )
}

export default PaymentMethodSelector

const styles = StyleSheet.create({
  body: {
    marginTop: 8,
    marginBottom: 60,
  },
  buttonWrapper: {
    marginBottom: 24,
  },
  checkbox: {
    marginBottom: 24,
  },
  piggy: {
    alignSelf: 'center',
    width: 160,
    height: 160,
    marginBottom: 60,
  },
})
