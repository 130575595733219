import { useContext } from 'react'
import Svg, { Path } from 'react-native-svg'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'
import Props from './shared'
import colors from '../../../tokens/colors'

const ChevronDown = ({ color, size = '40' }: Props) => {
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'
  const iconColor = color ? color : isDark ? whiteOpacity9 : colors['--text-01']
  return (
    <Svg aria-hidden="true" width={size} height={size} viewBox="0 0 40 40" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5479 15.4878C31.8307 15.7904 31.8148 16.265 31.5122 16.5479L21.0308 26.3466C21.0307 26.3468 21.0305 26.3469 21.0304 26.347C20.7491 26.6102 20.3776 26.75 20 26.75C19.6224 26.75 19.2509 26.6102 18.9696 26.347C18.9695 26.3469 18.9693 26.3468 18.9692 26.3466L8.48781 16.5479C8.18523 16.265 8.16925 15.7904 8.45213 15.4878C8.735 15.1852 9.20961 15.1693 9.51219 15.4521L19.9913 25.2487C19.9931 25.2493 19.9961 25.25 20 25.25C20.0039 25.25 20.0069 25.2493 20.0087 25.2487L30.4878 15.4521C30.7904 15.1693 31.265 15.1852 31.5479 15.4878ZM20.0056 25.2516C20.0058 25.2515 20.0059 25.2514 20.006 25.2513Z"
        fill={iconColor}
      />
    </Svg>
  )
}

export default ChevronDown
