import {
  MASK_NAME,
  MASK_PHONE_NUMBER,
  MASK_POSTAL_CODE,
  MASK_ADDRESS,
  MASK_ADDRESS_SHORT,
  MASK_EMAIL,
  MASK_LAST_DIGITS_OF_PAYMENT_ACCOUNT_NUMBER,
} from '../../../helpers/maskTypes'

export const personalInformationPropsLastName = {
  textContentType: 'familyName',
  maskProps: MASK_NAME,
  maskChar: '',
  autoFocus: false,
  testID: 'LastName',
  returnKeyType: 'default',
}

export const personalInformationPropsFirstName = {
  textContentType: 'firstName',
  maskProps: MASK_NAME,
  maskChar: '',
  autoFocus: true,
  testID: 'FirstName',
  returnKeyType: 'default',
}

export const personalInformationOldPhoneNumber = {
  textContentType: 'phoneNumber',
  testID: 'OldPhoneNumber',
  maskChar: '',
  maskProps: MASK_PHONE_NUMBER,
  keyboardType: 'number-pad',
  returnKeyType: 'default',
}

export const personalInformationPropsEmail = {
  maskProps: MASK_EMAIL,
  disableMask: true,
  textContentType: 'emailAddress',
  testID: 'Email',
  returnKeyType: 'default',
  autoCapitalize: 'none',
}

export const personalInformationPropsStreetNumber = {
  textContentType: 'streetAddressLine1',
  maskProps: MASK_ADDRESS_SHORT,
  maskChar: '',
  testID: 'StreetNumber',
  returnKeyType: 'default',
}

export const personalInformationPostalCode = {
  textContentType: 'postalCode',
  maskProps: MASK_POSTAL_CODE,
  maskChar: '',
  testID: 'PostalCode',
  returnKeyType: 'default',
}

export const personalInformationLastDigitsOfPaymentMethod = {
  textContentType: 'lastDigitsOfPaymentMethod',
  testID: 'lastDigitsOfPaymentMethod',
  maskChar: '',
  maskProps: MASK_LAST_DIGITS_OF_PAYMENT_ACCOUNT_NUMBER,
  keyboardType: 'number-pad',
  returnKeyType: 'default',
}
