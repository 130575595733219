import { useContext } from 'react'
import Svg, { Path } from 'react-native-svg'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'
import colors from '../../../tokens/colors'

const QuestionMark = () => {
  const { theme } = useContext(ThemeContext)
  const isDark = theme === 'dark'

  const color = isDark ? whiteOpacity9 : colors['--ui-primary-default']

  return (
    <Svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7546 7.14362C11.5537 6.29796 10.7197 5.70949 9.79458 5.81144C8.87149 5.91318 8.20017 6.66403 8.2 7.52722C8.19993 7.8586 7.93125 8.12717 7.59988 8.12711C7.26851 8.12704 6.99993 7.85836 7 7.52699C7.0003 6.02313 8.1611 4.7842 9.66313 4.61867C11.1631 4.45336 12.5749 5.40526 12.9221 6.8662C13.2704 8.33218 12.4251 9.79882 10.9944 10.2894C10.7482 10.3738 10.5997 10.5919 10.5998 10.8192V11.6C10.5998 11.9314 10.3312 12.2 9.99982 12.2C9.66845 12.2 9.39982 11.9314 9.39982 11.6L9.39982 10.8199C9.39982 10.8198 9.39982 10.82 9.39982 10.8199C9.39955 10.0606 9.89214 9.39874 10.6052 9.15426C11.4756 8.85581 11.9543 7.98423 11.7546 7.14362Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10669 14.3C9.10669 13.803 9.50963 13.4 10.0067 13.4C10.5037 13.4 10.9067 13.803 10.9067 14.3C10.9067 14.7971 10.5037 15.2 10.0067 15.2C9.50963 15.2 9.10669 14.7971 9.10669 14.3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599854 10C0.599854 4.80853 4.80838 0.600006 9.99985 0.600006C15.1913 0.600006 19.3999 4.80853 19.3999 10C19.3999 15.1915 15.1913 19.4 9.99985 19.4C4.80838 19.4 0.599854 15.1915 0.599854 10ZM9.99985 1.80001C5.47112 1.80001 1.79985 5.47127 1.79985 10C1.79985 14.5287 5.47112 18.2 9.99985 18.2C14.5286 18.2 18.1999 14.5287 18.1999 10C18.1999 5.47127 14.5286 1.80001 9.99985 1.80001Z"
        fill={color}
      />
    </Svg>
  )
}

export default QuestionMark
