import { useContext } from 'react'
import { Text, TextStyle, StyleSheet } from 'react-native'
import colors from '../../../tokens/colors'
import { ThemeContext } from '../../../../providers/ThemeProvider'
import { whiteOpacity9 } from '../../../tokens/colors/colors'

const CAPTION_ONE_REGULAR = '--caption-regular'
const CAPTION_SEMI_EMPHASIZED = '--caption-semi-emphasized'
const CAPTION_EMPHASIZED = '--caption-emphasized'

type BodyVariants =
  | typeof CAPTION_ONE_REGULAR
  | typeof CAPTION_SEMI_EMPHASIZED
  | typeof CAPTION_EMPHASIZED

interface Props {
  variant?: BodyVariants
  children: any
  lightColor: string
  darkColor: string
  style?: TextStyle
}

const Caption = ({ variant = CAPTION_ONE_REGULAR, children, style, ...otherProps }: Props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Text
      accessibilityRole="text"
      style={[
        styles.baseStyle,
        styles[variant],
        style,
        styles[theme],
        { color: otherProps[`${theme}Color`] },
      ]}
      {...otherProps}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  baseStyle: {
    fontSize: 14,
    lineHeight: 15.84,
  },
  '--caption-regular': {
    fontFamily: 'Calibre',
  },
  '--caption-semi-emphasized': {
    fontFamily: 'Calibre-semi',
  },
  '--caption-emphasized': {
    fontFamily: 'Calibre-bold',
  },
})

export default Caption
