export const fonts = {
  MangueiraBold: 'MangueiraBold',
  Inter: 'Calibre',
  InterSemi: 'Calibre-semi',
}

export const fontSizes = {
  '--font-size-01': '0.750rem',
  '--font-size-02': '0.875rem',
  '--font-size-03': '1.000rem',
  '--font-size-04': '1.125rem',
  '--font-size-05': '1.250rem',
  '--font-size-06': '1.500rem',
  '--font-size-07': '1.750rem',
  '--font-size-08': '2.000rem',
  '--font-size-09': '2.250rem',
  '--font-size-10': '2.500rem',
  '--font-size-11': '2.750rem',
  '--font-size-12': '3.000rem',
  '--font-size-13': '3.500rem',
  '--font-size-14': '4.000rem',
}

export const lineHeights = {
  '--line-height-01': 1.0,
  '--line-height-02': 1.32,
  '--line-height-03': 1.5,
}
