import { ReactNode, createContext, useState, useEffect, Dispatch } from 'react'
import { Platform } from 'react-native'
import jwt_decode from 'jwt-decode'
import { AccessLevel, DEFAULT_PHONE_NUMBER, INJECTABLE_TYPES } from '../types'
import { IUserAuthInfoService } from '../services/IUserAuthInfoService'
import { useInjection } from 'inversify-react'
import { useSessionStorage } from '../hooks/useSessionStorage'

export type AuthFlowType = 'auth' | 'phoneUpdate' | 'emailUpdate'
interface ContextState {
  token: string | null
  setToken: Dispatch<string | null>
  sessionId: string | null
  portalAccountId: string | null
  accessLevel: AccessLevel | null
  setAccessLevel: Dispatch<AccessLevel | null>
  portalDeviceGuid: string
  setPortalDeviceGuid: Dispatch<string>
  phoneNumber: string
  setPhoneNumber: Dispatch<string>
  isLoggedIn: boolean
  resetAuth: () => void
  sessionExpired: boolean
  setSessionExpired: Dispatch<boolean>
  hasPreviouslyLoggedIn: boolean
  setHasPreviouslyLoggedIn: Dispatch<boolean>
  authFlowType: AuthFlowType
  setAuthFlowType: Dispatch<AuthFlowType>
  tokenAppliedFromSessionStorage: boolean
}

export const AuthContext = createContext<ContextState>({
  token: null,
  setToken: () => null,
  sessionId: null,
  portalAccountId: null,
  accessLevel: null,
  setAccessLevel: () => null,
  portalDeviceGuid: '',
  setPortalDeviceGuid: () => null,
  phoneNumber: '',
  setPhoneNumber: () => null,
  isLoggedIn: false,
  resetAuth: () => null,
  sessionExpired: false,
  setSessionExpired: () => null,
  hasPreviouslyLoggedIn: false,
  setHasPreviouslyLoggedIn: () => null,
  authFlowType: 'auth',
  setAuthFlowType: () => null,
})

interface Props {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [portalAccountId, setPortalAccountId] = useState<string | null>(null)
  const [portalDeviceGuid, setPortalDeviceGuid] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [accessLevel, setAccessLevel] = useState<AccessLevel | null>(null)
  const [hasPreviouslyLoggedIn, setHasPreviouslyLoggedIn] = useState(false)
  const isLoggedIn = accessLevel === AccessLevel.AUTHORIZED_USER
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [authFlowType, setAuthFlowType] = useState<AuthFlowType>('auth')
  const _userAuthInfoService = useInjection<IUserAuthInfoService>(
    INJECTABLE_TYPES.IUserAuthInfoService
  )
  const isWeb = Platform.OS === 'web'

  interface DecodedToken {
    jti: string | null
    PortalAccountId: string | null
  }

  const [sessionStorageValue, setSessionStorageValue] = useSessionStorage('pb_token', '')
  const [token, setToken] = useState<string | null>(sessionStorageValue || null)

  const parseJWT = (tokenString: string): DecodedToken => jwt_decode(tokenString)

  useEffect(() => {
    if (!token) {
      setSessionId(null)
      setPortalAccountId(null)
      return
    }

    const { jti, PortalAccountId } = parseJWT(token)

    if (jti && jti !== sessionId) {
      setSessionId(jti)
    }

    if (PortalAccountId && PortalAccountId !== portalAccountId) {
      setPortalAccountId(PortalAccountId)
    }
  }, [token])

  useEffect(() => {
    if (token && isLoggedIn && isWeb) {
      setSessionStorageValue(token)
    }
  }, [token, isLoggedIn, isWeb])

  const resetAuth = () => {
    if (isWeb) {
      setSessionStorageValue('')
    }
    setToken('')
    setAccessLevel(null)
    setPhoneNumber(DEFAULT_PHONE_NUMBER)
  }

  useEffect(() => {
    _userAuthInfoService.SetPortalSessionId(sessionId)
  }, [sessionId])

  useEffect(() => {
    _userAuthInfoService.SetPortalDeviceGuid(portalDeviceGuid)
  }, [portalDeviceGuid])

  useEffect(() => {
    _userAuthInfoService.SetPortalAccountId(portalAccountId)
  }, [portalAccountId])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        sessionId,
        portalAccountId,
        accessLevel,
        setAccessLevel,
        portalDeviceGuid,
        setPortalDeviceGuid,
        phoneNumber,
        setPhoneNumber,
        isLoggedIn,
        resetAuth,
        sessionExpired,
        setSessionExpired,
        hasPreviouslyLoggedIn,
        setHasPreviouslyLoggedIn,
        authFlowType,
        setAuthFlowType,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
