/**
 * Ioc Container file.
 * Allows for dependancy injection using inversify module. 
 * see : https://github.com/inversify/InversifyJS
 */
import { Container } from 'inversify';
import 'reflect-metadata';
import { INJECTABLE_TYPES } from './types'
import { ILoggingService } from "./services/ILoggingService"
import { SentryLoggingService } from "./services/SentryLoggingService"
import { LoggingParameterService } from "./services/LoggingParameterService"
import { ILoggingParameterService } from './services/ILoggingParameterService';
import { IUserAuthInfoService } from './services/IUserAuthInfoService';
import { UserAuthInfoService } from './services/UserAuthInfoService';


const iocContainer = new Container();
            
iocContainer.bind<IUserAuthInfoService>(INJECTABLE_TYPES.IUserAuthInfoService).to(UserAuthInfoService).inSingletonScope();
iocContainer.bind<ILoggingParameterService>(INJECTABLE_TYPES.ILoggingParameterService).to(LoggingParameterService).inSingletonScope();
iocContainer.bind<ILoggingService>(INJECTABLE_TYPES.ILoggingService).to(SentryLoggingService).inSingletonScope();

export { iocContainer };
