import { createContext, ReactNode, useState, Dispatch, useEffect } from 'react'
import { Platform } from 'react-native'
import * as SecureStore from 'expo-secure-store'
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/fr'
import 'date-time-format-timezone'
import { getLocale, setLocalization, localized, $ } from '../localization/Localized'
import { EN, FR, SYSTEM, LangOptions, Localized } from '../localization/types'

interface ContextState {
  localized: Localized
  $: Localized
  currentLang: string
  setCurrentLang: Dispatch<string>
  loadNewCulture: (string: LangOptions) => void
  getCurrency: (num: number) => string
  getDate: (date?: Date, opts?: {}) => string
  getApr: (num: number) => string
  getLocale: () => string
  getLocaleCode: () => 'en-CA' | 'fr-CA'
  getLocaleCodeShort: () => typeof EN | typeof FR
}

const isNative = Platform.OS === 'android' || Platform.OS === 'ios'

const storeLanguageLocal = async (lang: LangOptions) => {
  if (isNative) {
    try {
      await SecureStore.setItemAsync('lang', lang)
    } catch (e) {
      // err
    }
  } else {
    localStorage.setItem('lang', lang)
  }
}

const getLanguageLocal = async (): Promise<LangOptions> => {
  let result: LangOptions
  if (isNative) {
    try {
      result = await SecureStore.getItemAsync('lang')
    } catch (e) {
      result = SYSTEM
    }
  } else {
    result = localStorage.getItem('lang')
  }
  return result || SYSTEM
}

export const ContentContext = createContext<ContextState>({
  localized,
  $,
  getLocale,
  currentLang: '',
  setCurrentLang: (): void => {},
  loadNewCulture: (): void => {},
  getCurrency: num => '$',
  getDate: date => '',
  getApr: num => '%',
  getLocaleCode: () => 'fr-CA',
  getLocaleCodeShort: () => 'fr',
})

interface Props {
  children: ReactNode
}

export const ContentProvider = ({ children }: Props) => {
  const [currentLang, setCurrentLang] = useState<string>(SYSTEM)

  useEffect(() => {
    getLanguageLocal().then(lang => {
      setCurrentLang(lang)
    })
  }, [])

  useEffect(() => {
    if (isNative) return
    document.documentElement.lang = currentLang === SYSTEM ? navigator.language : currentLang
  }, [currentLang])

  const getLocaleCode = () => (getLocale().slice(0, 2).toLowerCase() === 'fr' ? 'fr-CA' : 'en-CA')
  const getLocaleCodeShort = () => getLocaleCode().slice(0, 2) as typeof EN | typeof FR

  const getCurrency = (number: number): string => {
    const trimmer = (str: string) => str.toLowerCase().replace('us', '').trim()
    const options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: 'symbol',
    }
    return trimmer(new Intl.NumberFormat(getLocaleCode(), options).format(number))
  }

  interface GetDateOptions {
    short?: boolean
  }

  type Month = 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
  interface DateTimeFormatOptions {
    year?: 'numeric' | '2-digit'
    month?: Month
    day?: 'numeric' | '2-digit'
    timeZone?: string
  }

  const isDate = (date: Date | string | null) => {
    if (!date) return false
    const maybeDate = Date.parse(date as string)
    return !isNaN(maybeDate)
  }

  const getDate = (date: Date | string, opts: GetDateOptions = {}) => {
    if (!isDate(date)) return ''
    const normalizedDate = new Date(date)
    const options: DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      timeZone: 'America/Toronto',
      ...(opts.short ? null : { year: 'numeric' }),
    }
    return normalizedDate.toLocaleDateString(getLocaleCode(), options).replace('.', '')
  }

  const getApr = (rate: number) =>
    getLocaleCode() === 'fr-CA' ? `${rate.toFixed(2).replace('.', ',')} %` : `${rate.toFixed(2)}%`

  const loadNewCulture = async (lang: LangOptions) => {
    try {
      await setLocalization(lang)
      setCurrentLang(lang)
      storeLanguageLocal(lang)
    } catch (err) {}
  }

  return (
    <ContentContext.Provider
      value={{
        currentLang,
        setCurrentLang,
        loadNewCulture,
        localized,
        $,
        getLocale,
        getCurrency,
        getDate,
        getApr,
        getLocaleCode,
        getLocaleCodeShort,
      }}>
      {children}
    </ContentContext.Provider>
  )
}
