import { useContext, useState, useEffect, useRef } from 'react'
import { Platform, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { DimensionContext } from '../../../providers'
import { ContentContext } from '../../../providers/ContentProvider'
import { ContractContext } from '../../../providers/ContractProvider'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import { getEnvVars } from '../../../config/getEnvVars'
import Loading from '../../molucules/Loading'
import Box from '../../atoms/Box'
import { ILoggingService } from '../../../services/ILoggingService'
import { useInjection } from 'inversify-react'
import { INJECTABLE_TYPES } from '../../../types'

export const LinkYourBankAccount = () => {
  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs'
  const { FLINKS_URL_EN, FLINKS_URL_FR, FLINKS_INSTANCE } = getEnvVars()
  const { getLocaleCode } = useContext(ContentContext)
  const { setEFTPayload, contract } = useContext(ContractContext)
  const isFrench = getLocaleCode() === 'fr-CA'
  const flinksURL = isFrench ? FLINKS_URL_FR : FLINKS_URL_EN
  const [step, setStep] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [disableBackButton, setDisableBackButton] = useState(false)
  const navigation = useNavigation()
  const redirectTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const t0 = useRef(performance.now())

  const _loggingService = useInjection<ILoggingService>(INJECTABLE_TYPES.ILoggingService)

  window.addEventListener('message', e => {
    if (e.origin === FLINKS_INSTANCE && e.data.step) {
      setLoading(false)

      e.data.step === 'COMPONENT_LOAD_INSTITUTION_SELECTOR'
        ? setDisableBackButton(false)
        : setDisableBackButton(true)

      if (e.data.step === 'REDIRECT') {
        const { loginId: flinksLoginID, accountId } = e.data
        const { isAppliedToAllOfContractGroup } = contract
        setEFTPayload({
          flinksLoginID,
          accountId,
          isAppliedToAllOfContractGroup,
        })
        setStep(e.data.step)
      }
    }
  })

  useEffect(() => {
    if (!isLoading) {
      _loggingService.LogInfo(`[Flinks Widget][Load Time]: ${performance.now() - t0.current}`)
    }

    return () => {
      if (isLoading) {
        _loggingService.LogInfo(
          `[Flinks Widget][Abandonded After]: ${performance.now() - t0.current}`
        )
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (step === 'REDIRECT') {
      redirectTimer.current = setTimeout(() => navigation.pop(2), 3000)
    }

    return () => {
      if (redirectTimer.current) {
        clearTimeout(redirectTimer.current)
      }
    }
  }, [step])

  const [height, setHeight] = useState('0')
  const topBarHeightMobile = 84
  const topBarHeightDesktop = 175

  return (
    <View
      style={{ flex: 1 }}
      onLayout={event => {
        const { height: renderedHeight } = event.nativeEvent.layout
        const computedHeightAsString = `${
          renderedHeight - (isMobile ? topBarHeightMobile : topBarHeightDesktop)
        }`
        setHeight(computedHeightAsString)
      }}>
      <PlanDetailWrapper
        {...Platform.select({
          native: { scrollView: true },
          web: { disableBackButton, isModal: !isMobile, hasIframe: true, darkModeOverride: true },
          default: null,
        })}>
        <iframe style={{ border: 'none' }} height={height} src={flinksURL} />
        {isLoading ? (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              top: 0,
              right: 0,
            }}>
            <Loading variant="in-screen" isTransparent={true} darkModeOverride={true} />
          </Box>
        ) : null}
      </PlanDetailWrapper>
    </View>
  )
}
