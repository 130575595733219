import { ReactNode } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { useToggleRootBackgroundColor } from '../../../hooks/useToogleRootBackgroundColor'
import Box from '../../atoms/Box'
import Heading from '../../atoms/Typography/Heading'
import BackArrow from '../../molucules/BackArrow'
import { darkest, ink0 } from '../../tokens/colors/colors'

const AccountInformationWrapper = ({ title, children }: { children: ReactNode; title: string }) => {
  const isWeb = Platform.OS === 'web'

  useToggleRootBackgroundColor(ink0, darkest)
  const darkBackground = isWeb ? 'transparent' : darkest
  return (
    <Box backgroundLight={ink0} backgroundDark={darkBackground} scrollView={true}>
      <BackArrow darkBackground={darkBackground} paddingLeft={[16, 0, 0, 0, 0]} />
      <Box
        paddingHorizontal={[16, 0, 0, 0, 0]}
        backgroundLight="#fff"
        backgroundDark={darkBackground}
        paddingBottom={[32, 0, 0, 0, 0]}>
        <Heading style={styles.heading} variant="--heading-2">
          {title}
        </Heading>
        {children}
      </Box>
    </Box>
  )
}

export default AccountInformationWrapper

const styles = StyleSheet.create({
  heading: {
    marginBottom: 24,
  },
})
