import { NavigationProp } from '@react-navigation/native'
import { CustomerType } from './../providers/CustomerProvider'
import {
  LINK_YOUR_ACCOUNT,
  EMAIL_VERIFICATION_CODE_ENTRY,
  PHONE_NUMBER_ENTRY,
  HOME_ENTRY,
  PAYMENT_METHOD_SETUP,
  PAYMENT_METHOD_CREDIT_CARD_ENTRY,
  PAYMENT_METHOD_CREDIT_CARD_ENTRY_SUCCESS,
  GENERIC_ERROR,
  BOTTOM_NAVIGATOR,
} from '../types'
import { customerTypes } from '../constants'

const { TYPE_ONE, TYPE_TWO, TYPE_THREE, TYPE_FOUR, TYPE_FIVE, TYPE_SIX } = customerTypes

export const navigateToLogin = (navigation: NavigationProp) => {
  navigation.navigate(HOME_ENTRY)
}
