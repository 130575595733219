import GenericError from '../../components/organisms/GenericError'
import AccountCreationWebWrapper from '../../components/wrappers/AccountCreationWebWrapper'

const GenericErrorScreen = () => (
  <AccountCreationWebWrapper hasPlant={false} scrollView={false}>
    <GenericError />
  </AccountCreationWebWrapper>
)

export default GenericErrorScreen
