import styled from 'styled-components/native'
import Box from '../../atoms/Box'

export enum Sizes {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

interface StyledProps {
  center?: boolean
  viewPort?: Sizes
}

export const StyledCard = styled(Box)<StyledProps>`
  flex-grow: 0;
  flex-basis: 50%;
  ${props =>
    !props.center ? 'justify-content:center; align-items:center;' : 'justify-content:center;'}
`

export const StyledInnerCardMD = styled(Box)<StyledProps>`
  ${props => (!props.center ? 'padding-bottom:170px;' : 'justify-content:center;')}
  align-self: center;
  width: 410px;
  justify-content: center;
`

export const StyledInnerCard = styled(Box)<StyledProps>`
  ${props =>
    !props.center
      ? 'justify-content:center; align-items:center; flex-direction: row;'
      : 'justify-content:center; align-items:center;'}

  ${props => (!props.center && props.viewPort === Sizes.LG ? 'width:330px;' : null)}
    ${props => (!props.center && props.viewPort === Sizes.XL ? ' width:378px;' : null)}
`
