import { useContext, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { ContentContext } from '../../../providers'
import { Address } from '../Address'
import Body from '../../atoms/Typography/Body'
import { ink75, whiteOpacity9, purpleJellyDark } from '../../tokens/colors/colors'
import { CreditCard } from '../../../types'
interface Props {
  currentCreditCard: CreditCard | null
  isAddressVisible: boolean
  setAddressVisibility: (b: boolean) => void
}

export const LinkYourCardTextBlock = ({
  currentCreditCard,
  isAddressVisible,
  setAddressVisibility,
}: Props) => {
  const { localized } = useContext(ContentContext)

  useEffect(() => {
    if (isAddressVisible) return
    if (!currentCreditCard) {
      setAddressVisibility(true)
    }
  }, [isAddressVisible, currentCreditCard])

  return (
    <>
      {currentCreditCard || isAddressVisible ? (
        <Body
          variant="--body-01-semi-emphasized"
          style={styles.body}
          lightColor={ink75}
          darkColor={whiteOpacity9}>
          {localized('linkYourCreditCard.billingAddress')}
        </Body>
      ) : null}
      {!isAddressVisible ? (
        <Address
          openAddressDropdown={setAddressVisibility}
          isAddressVisible={isAddressVisible}
          hasCreditCard={!!currentCreditCard}
          {...currentCreditCard}
        />
      ) : null}
    </>
  )
}

export const styles = StyleSheet.create({
  body: {
    marginBottom: 8,
    paddingTop: 8,
  },
  addressDisclaimer: {
    marginBottom: 8,
  },
})
