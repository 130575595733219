import { useContext, Fragment } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'
import * as SecureStore from 'expo-secure-store'
import Body from '../../components/atoms/Typography/Body'
import AccountInformationSettingsWrapper from '../../components/wrappers/AccountInformationSettingsWrapper'
import AccountInformationWrapper from '../../components/wrappers/AccountInformationWrapper'
import { ContentContext } from '../../providers/ContentProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { useUUID } from '../../hooks/useUUID'
import { EN, FR, SYSTEM, LangOptions, Localized } from '../../localization/types'
import colors from '../../components/tokens/colors'
import { ink25, whiteOpacity9 } from '../../components/tokens/colors/colors'
import Hairline from '../../components/atoms/Hairline'
import { Check } from '../../components/atoms/Icons'
import Caption from '../../components/atoms/Typography/Caption'

type MenuItem = {
  label: string
  value: LangOptions
  web: boolean
}

const menuItems = (localized: Localized): MenuItem[] => [
  {
    label: localized('languageSettings.english'),
    value: EN,
    web: true,
  },
  {
    label: localized('languageSettings.french'),
    value: FR,
    web: true,
  },
  {
    label: localized('languageSettings.system'),
    value: SYSTEM,
    web: true,
  },
]

const LanguageSettingsScreen = () => {
  const { currentLang, loadNewCulture, localized } = useContext(ContentContext)
  const { isLoggedIn, phoneNumber } = useContext(AuthContext)
  const { getUUIDKey } = useUUID()

  const isNative = Platform.OS === 'android' || Platform.OS === 'ios'

  const handleOnPress = async (value: LangOptions) => {
    loadNewCulture(value)
    const key = getUUIDKey(phoneNumber)

    if (isLoggedIn) {
      if (isNative) {
        try {
          await SecureStore.setItemAsync('lang-' + key, value)
        } catch (e) {
          // err
        }
      }
      localStorage.setItem('lang-' + key, value)
    }
  }

  return (
    <AccountInformationSettingsWrapper>
      <AccountInformationWrapper title={localized('languageSettings.header')}>
        <Hairline />
        {menuItems(localized).map(({ label, value }) => (
          <Fragment key={label}>
            <TouchableOpacity
              style={{ paddingVertical: 24, flexDirection: 'row', justifyContent: 'space-between' }}
              onPress={() => handleOnPress(value)}>
              <Body lightColor={colors['--text-dark']} darkColor={whiteOpacity9}>
                {label}
              </Body>
              {value === currentLang && <Check size="24" />}
            </TouchableOpacity>
            <Hairline />
          </Fragment>
        ))}
        <Caption style={styles.caption} lightColor={colors['--text-dark']} darkColor={ink25}>
          {localized('languageSettings.paragraph')}
        </Caption>
      </AccountInformationWrapper>
    </AccountInformationSettingsWrapper>
  )
}
export default LanguageSettingsScreen

const styles = StyleSheet.create({
  caption: {
    marginVertical: 24,
  },
})
