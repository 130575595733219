import { useContext, useState, useRef, useEffect } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import { CustomerContext, ContentContext, DimensionContext } from '../../../providers'
import PlanDetailWrapper from '../../wrappers/PlanDetailWrapper'
import Body, { BodyVariants } from '../../atoms/Typography/Body'
import Box from '../../atoms/Box'
import SharedInput from '../../molucules/SharedInput'
import Button from '../../atoms/Button'
import colors from '../../tokens/colors'
import { whiteOpacity9 } from '../../tokens/colors/colors'
import { isTenDigits } from '../../../helpers'
import { MASK_PHONE_NUMBER } from '../../../helpers/maskTypes'
import { useNavigation } from '@react-navigation/native'
import { initiatePhoneNumberUpdate } from '../../../endpoints'
import {
  AUTHENICATED_PHONE_NUMBER_UPDATE_PIN,
  isApiResponse,
  StatusDescription,
} from '../../../types'
import GenericModal from '../../molucules/GenericModal'

const SharedBody = ({ children, variant }: { children: string; variant?: BodyVariants }) => (
  <Body
    variant={variant}
    style={styles.marginBottomMd}
    darkColor={whiteOpacity9}
    lightColor={colors['--text-dark']}>
    {children}
  </Body>
)

const AuthenticatedPhoneNumberUpdate = () => {
  const { customer } = useContext(CustomerContext)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [url, setUrl] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<'valid' | 'short' | 'duplicate'>(
    'valid'
  )
  const isAndroid = Platform.OS === 'android'

  const initialErrorVisibility = useRef(true)

  const { viewPort } = useContext(DimensionContext)
  const isMobile = viewPort === 'xs' || viewPort === 'sm'
  const navigation = useNavigation()
  const [loading, setLoading] = useState(false)

  const { $, getLocaleCodeShort } = useContext(ContentContext)

  const { status, data } = useFetch({
    url,
    method: 'post',
    body: {
      newCredential: phoneNumber,
      culture: getLocaleCodeShort(),
    },
  })

  const handleError = () => {
    setUrl('')
    setLoading(false)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setPhoneNumber('')
  }

  const respondToAPIResponse = () => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if (status === RequestStatus.FETCHED) {
      setUrl('')
      setLoading(false)
      if (isApiResponse(data)) {
        const { statusCode, statusDescription } = data
        if (statusCode === 200 && statusDescription === StatusDescription.SUCCESS) {
          navigation.navigate(AUTHENICATED_PHONE_NUMBER_UPDATE_PIN)
          return
        }
      }
      handleError()
    }
    if (status === RequestStatus.ERROR) {
      handleError()
    }
  }

  useEffect(respondToAPIResponse, [status])

  useEffect(() => {
    if (!isTenDigits(phoneNumber || '')) {
      setPhoneNumberIsValid('short')
    } else if (customer.phoneNumber?.replace(/\D+/g, '') === phoneNumber?.replace(/\D+/g, '')) {
      setPhoneNumberIsValid('duplicate')
    } else {
      setPhoneNumberIsValid('valid')
    }
  }, [customer.phoneNumber, phoneNumber])

  const phoneNumberInValid = phoneNumberIsValid !== 'valid'

  const getPhoneErrorText = (key: string) =>
    ({
      valid: '',
      short: $(`global.errorPhone`),
      duplicate: $('updatePhoneNumber.errorDuplicatePhone'),
    }[key] || '')

  const handleSubmit = () => {
    if (initialErrorVisibility.current) {
      initialErrorVisibility.current = false
    }
    if (phoneNumberInValid) return
    setUrl(initiatePhoneNumberUpdate)
  }

  const handleKeyPress = e => {
    if (e.nativeEvent.key === 'Enter' && !phoneNumberInValid) {
      handleSubmit()
    }
  }

  return (
    <>
      <GenericModal
        header={$`genericError.title`}
        visible={isModalOpen}
        closeModal={closeModal}
        message={$`authenticatedPhoneNumberUpdate.errorModalBody`}
        buttonText={'global.ok'}
      />
      <PlanDetailWrapper
        title={$`accountInformation.header`}
        headingAlignment="left"
        {...Platform.select({
          native: {
            scrollView: true,
          },
          web: { isModal: true },
          default: {},
        })}>
        <PlanDetailWrapper.InnerWrapper isMobile={isMobile}>
          <SharedInput
            textContentType="phoneNumber"
            value={phoneNumber}
            testID="PhoneNumber"
            maskProps={MASK_PHONE_NUMBER}
            maskChar=""
            style={styles.marginBottomMd}
            label={$`authenticatedPhoneNumberUpdate.label`}
            error={phoneNumberInValid}
            errorMessage={getPhoneErrorText(phoneNumberIsValid)}
            onChangeText={(text: string) => {
              setPhoneNumber(text.replace(/[^0-9]/g, ''))
            }}
            onKeyPress={handleKeyPress}
            onSubmitEditing={handleSubmit}
            {...(isAndroid && { secureTextEntry: true })}
          />
          <SharedBody variant="--body-02-regular">{$`authenticatedPhoneNumberUpdate.body`}</SharedBody>
          <Box style={styles.marginBottomLg}>
            <Button
              testID="ContinueBtn"
              isLoading={loading}
              disabled={phoneNumberInValid}
              onPress={handleSubmit}>
              {$`global.continue`}
            </Button>
          </Box>
        </PlanDetailWrapper.InnerWrapper>
      </PlanDetailWrapper>
    </>
  )
}

export default AuthenticatedPhoneNumberUpdate

const styles = StyleSheet.create({
  marginBottomMd: {
    marginBottom: 16,
    marginTop: 8,
  },
  marginBottomLg: {
    marginBottom: 24,
    marginTop: 8,
  },
})
