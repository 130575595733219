import { ReactNode, useContext } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { DimensionContext } from '../../../providers/DimensionProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import Box from '../../atoms/Box'
import Body from '../../atoms/Typography/Body'
import colors from '../../tokens/colors'
import { darker4 } from '../../tokens/colors/colors'

const SharedBody = ({ children, style }: { children: string; style?: ViewStyle }) => (
  <Body style={style} lightColor="white" darkColor="white" variant="--body-02-semi-emphasized">
    {children}
  </Body>
)

const SharedAnchor = ({ href, children }: { href: string; children: ReactNode }) => (
  <a style={{ textDecoration: 'none' }} target="_blank" href={href}>
    {children}
  </a>
)

const Footer = () => {
  const { viewPort } = useContext(DimensionContext)
  const { localized } = useContext(ContentContext)
  return (
    <Box
      style={[styles.container, styles[`container-${viewPort}`]]}
      paddingHorizontal={[24, 24, 16, 120, 120]}
      paddingVertical={[32, 32, 32, 32, 32]}
      backgroundLight={colors['--background-footer']}
      backgroundDark={darker4}>
      <Box>
        <Body lightColor="white" darkColor="white" variant="--body-02-regular">
          {`©${new Date().getFullYear()} ${localized('footer.copyRight')}`}
        </Body>
      </Box>
      <Box style={[styles.rightColumn, styles[`rightColumn-${viewPort}`]]}>
        <Box style={[styles.linksContainer, styles[`linksContainer-${viewPort}`]]}>
          <Box paddingBottom={[16, 16, 0]}>
            <SharedAnchor href={localized('global.termsAndConditionsLink')}>
              <SharedBody>{localized('global.termsAndConditions')}</SharedBody>
            </SharedAnchor>
          </Box>
          <Box marginLeft={[0, 0, 32, 32, 32]} marginBottom={[16, 16, 0, 0, 0]}>
            <SharedAnchor href={localized('footer.privacyLink')}>
              <SharedBody>{localized('footer.privacy')}</SharedBody>
            </SharedAnchor>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer

const flexOnSmall = {
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  'container-xl': {
    flexDirection: 'row',
  },
  'container-lg': {
    flexDirection: 'row',
  },
  'container-md': flexOnSmall,
  'container-sm': flexOnSmall,
  'container-xs': flexOnSmall,
  rightColumn: {
    alignItems: 'center',
  },
  'rightColumn-xs': {
    flexDirection: 'column-reverse',
  },
  'rightColumn-sm': {
    flexDirection: 'column-reverse',
  },
  'rightColumn-md': {
    width: '100%',
    flexDirection: 'row',
  },
  'rightColumn-lg': {
    marginLeft: 'auto',
    flexDirection: 'row',
  },
  'rightColumn-xl': {
    marginLeft: 'auto',
    flexDirection: 'row',
  },

  linksContainer: {
    flexDirection: 'row',
  },
  'linksContainer-md': {
    marginBottom: 16,
  },
  'linksContainer-sm': {
    flexDirection: 'column',
  },
  'linksContainer-xs': {
    flexDirection: 'column',
  },
  socialIconsWrapper: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  'socialIconsWrapper-lg': {
    marginLeft: 'auto',
  },
  'socialIconsWrapper-md': {
    marginLeft: 'auto',
  },
  'socialIconsWrapper-sm': {
    marginLeft: 0,
    marginBottom: 16,
  },
  'socialIconsWrapper-xs': {
    marginLeft: 0,
    marginBottom: 16,
  },
})
