import styled from 'styled-components/native'
import Typography from '../../atoms/Typography'
import { List } from 'react-native-paper'

const { Body } = Typography

interface Props {
  isMobile?: boolean
  isFrench?: boolean
}

export const StyledListAccordion = styled(List.Accordion)`
  background-color: transparent;
`

export const ListItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const ListWrapper = styled.View<Props>`
  margin-left: ${({ isMobile }) => (isMobile ? '-40px' : '-37px')};
  padding-right: 41px;
`

export const ListItemBodyLeft = styled(Body)`
  padding-left: 4px;
  display: flex;
  flex-direction: row;
`

export const ListItemBodyRight = styled(Body)`
  padding-right: 4px;
  display: flex;
  flex-direction: row;
`

export const RightBox = styled.View<Props>`
  flex-direction: row;
  align-items: center;
  ${({ isFrench }) => (isFrench ? 'margin-top: -2px;' : null)}
  ${({ isFrench }) => (isFrench ? 'padding-bottom: 20px;' : null)}
`
export const LeftBoxOuter = styled.View<Props>`
  ${({ isFrench }) => (isFrench ? 'margin: 8px;' : null)}
`

export const LeftBoxInner = styled.View<Props>`
  flex-direction: row;
  align-items: center;
  ${({ isFrench }) => (isFrench ? 'margin-bottom: 4px;' : null)}
`
export const FrenchDateContainer = styled.View`
  margin-left: 16px;
`
