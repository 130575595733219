import Svg, { Path } from 'react-native-svg'
import Props from './shared'

const ArrowLeft = ({ color = 'black', size = '24', ...otherProps }: Props) => (
  <Svg
    aria-hidden="true"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    {...otherProps}>
    <Path
      d="M10.7305 0.975662C11.0201 0.679496 11.4949 0.674162 11.7911 0.963745C12.0872 1.25333 12.0926 1.72817 11.803 2.02434L2.78233 11.25H23C23.4142 11.25 23.75 11.5858 23.75 12C23.75 12.4142 23.4142 12.75 23 12.75H2.78233L11.803 21.9757C12.0926 22.2718 12.0872 22.7467 11.7911 23.0363C11.4949 23.3258 11.0201 23.3205 10.7305 23.0243L0.469236 12.5299C0.333767 12.3942 0.25 12.2069 0.25 12C0.25 11.8498 0.294128 11.71 0.370119 11.5927C0.398827 11.5484 0.432076 11.5073 0.469217 11.4701L10.7305 0.975662Z"
      fill={color}
    />
  </Svg>
)

export default ArrowLeft
