import { VFC, useContext, useState, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { formatNumber } from '../../../helpers'
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect'
import Link from '../../atoms/Link'
import LoginForm from '../LoginForm'
import SharedBody from '../../molucules/SharedBody'
import { getToken } from '../../../endpoints'
import { normalizeMaskedValue } from '../../../helpers/maskTypes'
import { AuthContext } from '../../../providers/AuthProvider'
import { CustomerContext } from '../../../providers/CustomerProvider'
import { ContentContext } from '../../../providers/ContentProvider'
import { QueryParamsContext } from '../../../providers/QueryParamsProvider'
import { PIN_ENTRY_LOGIN, StatusDescription } from '../../../types'
import Footer from '../../molucules/Footer'
import Login from '../Login'
import { useFetch, RequestStatus } from '../../../hooks/useFetch'
import GenericModal from '../../molucules/GenericModal'
import { PAYBRIGHT_SMS_FROM_PHONE_NUMBER } from '../../../config/api'
import Box from '../../atoms/Box'

const PhoneNumberEntry: VFC = () => {
  const navigation = useNavigation()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalHeader, setModalHeader] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  let pageStatus = ''
  if (Platform.OS === 'web') {
    let { params } = useContext(QueryParamsContext)
    pageStatus = params.status ? params.status : ''
  }

  const { setCustomer } = useContext(CustomerContext)
  const { $, getLocaleCodeShort } = useContext(ContentContext)
  const { portalDeviceGuid, phoneNumber, setPhoneNumber, authFlowType } = useContext(AuthContext)
  const [localPhoneNumber, setLocalPhoneNumber] = useState<number>()
  // used for button
  const [isLoading, setLoading] = useState(false)
  const isScreenVisible = useIsFocused()
  const [url, setUrl] = useState('')

  const { status, data, error } = useFetch({
    url,
    method: 'post',
    body: {
      portalDeviceGuid,
      phoneNumber: normalizeMaskedValue(localPhoneNumber || 0),
      culture: getLocaleCodeShort(),
    },
  })

  const openErrorModal = () => {
    const msg = $`phoneNumberEntry.errorModal.genericError`
    setModalOpen(true)
    setModalHeader('global.oops')
    setModalMessage(`${msg} ${PAYBRIGHT_SMS_FROM_PHONE_NUMBER}.`)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (status === RequestStatus.FETCHING) {
      setLoading(true)
    }
    if (status === RequestStatus.FETCHED) {
      setUrl('')
      setLoading(false)
      const { statusCode, statusDescription } = data
      setCustomer(prev => ({
        ...prev,
        phoneNumber: formatNumber(
          parseInt(normalizeMaskedValue(localPhoneNumber), 10),
          '(***) ***-****'
        ),
      }))
      if (statusCode === 200 && statusDescription === StatusDescription.SUCCESS) {
        navigation.navigate(PIN_ENTRY_LOGIN)
      }
    }
    if (status === RequestStatus.ERROR) {
      setUrl('')
      setLoading(false)
      const { status } = error
      if (status === 500) {
        // Temporary hack for now. Should be addressed in the future.
        openErrorModal()
      }
    }
  }, [status])

  const send = async () => {
    setUrl(getToken)
  }

  const handleSubmit = () => {
    const localPhoneAsString = normalizeMaskedValue(localPhoneNumber)
    localPhoneAsString === phoneNumber ? send() : setPhoneNumber(localPhoneAsString)
  }

  useDidUpdateEffect(() => (isScreenVisible ? send() : null), [portalDeviceGuid])

  const instruction =
    authFlowType === 'phoneUpdate' ? $`entry.updatePhoneParagraph` : $`entry.paragraph`
  return (
    <>
      <GenericModal
        header={modalHeader}
        visible={isModalOpen}
        closeModal={closeModal}
        message={modalMessage}
        buttonText={'global.ok'}
      />
      <Login>
        <LoginForm
          title={$`global.welcome`}
          instruction={instruction}
          inputLabel={$`entry.label`}
          send={handleSubmit}
          isLoading={isLoading}
          setValue={setLocalPhoneNumber}
          value={localPhoneNumber}>
          <SharedBody style={styles.hint} variant="--body-02-regular">
            {$`entry.footer`}
          </SharedBody>
        </LoginForm>
      </Login>
      <Footer />
    </>
  )
}

const styles = StyleSheet.create({
  hint: {
    marginBottom: 45,
  },
})

export default PhoneNumberEntry
