import { HTMLProps, useRef, useEffect } from 'react'
import styledComponents from 'styled-components'
import colors from '../../tokens/colors'
import {
  mintSprig75,
  mintSprigDark,
  mintSpringDarkOpacity,
  whiteOpacity4,
  dark7,
  whiteOpacity7,
  purpleJelly25,
  darker4,
  ink0,
} from '../../tokens/colors/colors'
import radii from '../../tokens/radii'
import space from '../../tokens/space'
import { fonts, fontSizes, lineHeights } from '../../tokens/fonts'
import { breakpoints } from '../../../providers/DimensionProvider'
import { Props } from './index.native'
import Spinner from '../Spinner'
import useColorByTheme from '../../../hooks/useColorByTheme'
import { View } from 'react-native'

const TextWrapper = styledComponents.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styledComponents.div`
  margin-right: 8px;
  height: 24px;
`

const StyledButton = styledComponents('button')(
  ({
    isFullWidthOnMobile,
    isLoading,
    inverse,
    secondary,
  }: Props & HTMLProps<HTMLButtonElement>) => {
    const textDarkColor = colors['--text-dark']
    const primaryDefaultColor = useColorByTheme(colors['--ui-primary-default'], mintSprig75)
    const primaryDefault = inverse ? 'transparent' : primaryDefaultColor
    const disabledBackground = useColorByTheme(colors['--ui-disabled-background'], dark7)
    const enabledBackground = useColorByTheme(
      colors['--ui-primary-hover'],
      inverse ? 'transparent' : mintSprigDark
    )
    const textDisabled = useColorByTheme(colors['--text-disabled'], whiteOpacity4)
    const secondaryBorderColor = useColorByTheme(textDarkColor, whiteOpacity7)
    const border = `1px solid ${inverse ? ink0 : secondary ? secondaryBorderColor : 'transparent'}`
    const disabledBorder = useColorByTheme(colors['--border-disabled'], whiteOpacity4)
    const backgroundColor = isLoading
      ? disabledBackground
      : secondary
      ? 'transparent'
      : primaryDefault
    const hoverBackground = isLoading ? disabledBackground : enabledBackground
    const secondaryHoverBackground = useColorByTheme(purpleJelly25, mintSpringDarkOpacity)
    const textColor = useColorByTheme(
      secondary ? textDarkColor : ink0,
      secondary ? 'white ' : textDarkColor
    )
    const inverseHoverColor = useColorByTheme(textDarkColor, darker4)
    return `
    @media only screen and (max-width: ${breakpoints[0]}px) {
      width: ${isFullWidthOnMobile ? '100%' : 'auto'};
    }
    padding: ${space['--spacing-03'] + 2}px ${space['--spacing-05']}px;
    border-radius: ${radii['--corner-radius-32']};
    font-family: ${fonts.Inter};

    font-size: ${fontSizes['--font-size-03']};

    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: ${isLoading ? 'auto' : 'pointer'};
    background-color: ${backgroundColor};
    color: ${inverse ? ink0 : textColor};
    border: ${border};
    &:focus,
    &:hover {
      border-color:transparent;
      background: ${
        inverse ? whiteOpacity7 : secondary ? secondaryHoverBackground : hoverBackground
      };
      color: ${inverse ? inverseHoverColor : textColor};
    }
    &:active {
      background: ${secondary ? purpleJelly25 : hoverBackground};
    }
    &:focus::after {
      background: ${colors['--ui-light']};
      border: 2px solid ${primaryDefault};
    }
    &:disabled {
      cursor: not-allowed;
      background: ${disabledBackground};
      border: 1px solid ${disabledBorder};
      color: ${textDisabled};
      &:hover {
        background: ${disabledBackground};
        border: 1px solid ${disabledBorder};
        color: ${textDisabled};
      }
    }
    &:focus {
      outline: 0;
      position: relative;
      &::after {
        background-color: transparent;
        content: '';
        z-index: -1;
        position: absolute;
        left: -5px;
        right: -5px;
        top: -5px;
        bottom: -5px;
        border-radius: 32px;
      }
    }
  }`
  }
)

const Button = ({
  children,
  icon,
  style,
  isFullWidthOnMobile,
  onPress,
  disabled,
  isLoading,
  testID,
  inverse = false,
  ...otherProps
}: Props) => {
  const buttonElement = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    buttonElement?.current?.blur()
  }, [onPress])
  return (
    <StyledButton
      inverse={inverse}
      onClick={onPress}
      ref={buttonElement}
      id={testID}
      isLoading={isLoading}
      disabled={isLoading || disabled}
      isFullWidthOnMobile={isFullWidthOnMobile}
      {...otherProps}>
      {isLoading ? (
        <View style={{ alignItems: 'center' }}>
          <Spinner size={20}>{children}</Spinner>
        </View>
      ) : (
        <TextWrapper>
          {icon ? <IconWrapper>{icon}</IconWrapper> : null}
          {children}
        </TextWrapper>
      )}
    </StyledButton>
  )
}

export default Button
