import { FormControl } from '@material-ui/core'
import styled from 'styled-components'
import useColorByTheme from '../../../hooks/useColorByTheme'
import colors from '../../tokens/colors'
import { dark7, whiteOpacity9 } from '../../tokens/colors/colors'
import radii from '../../tokens/radii'

const StyledFormControl = styled(FormControl)(() => {
  const background = useColorByTheme(colors['--background-light'], dark7)
  const backgroundError = useColorByTheme(colors['--ui-error-background'], dark7)
  const borderColor = useColorByTheme(colors['--border-focus-dark'], whiteOpacity9)
  const textColor = useColorByTheme(colors['--text-dark'], whiteOpacity9)
  const labelColor = useColorByTheme(colors['--text-01'], whiteOpacity9)
  return `
    & legend  span{
      font-size: 1.1em;
    }
    & .MuiFormControl-marginNormal {
      margin: 0;
    }
    & .MuiInputBase-root {
      font-family: Calibre;
      background-color: ${background};
      color: ${textColor};
      -webkit-text-fill-color: ${textColor};
      border-radius: ${radii['--corner-radius-4']};
      overflow: hidden;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors['--ui-inactive-01']};
      }
    }

    & .MuiInputLabel-root {
      color: ${labelColor};
    }

    & .MuiOutlinedInput-root {
      &:hover fieldset,
      &.Mui-focused fieldset {
        border-color: ${borderColor};
        border-width: 1px;
      }
    }

    & label.Mui-focused {
      color: ${labelColor};
    }

    & .MuiOutlinedInput-root.Mui-error {
      background-color: ${colors['--ui-error-background']};
      fieldset {
        border-color: ${colors['--border-error']};
      }

      input {
        background-color: ${backgroundError};
      }
    }

    & .MuiOutlinedInput-root.Mui-disabled,
    .MuiInputBase-input.Mui-disabled {
      color: ${colors['--text-disabled']};
      background-color: ${colors['--ui-disabled-background']};
    }

    & .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-input.Mui-disabled {
      color: ${colors['--text-disabled']};
    }

    & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: ${colors['--border-disabled']};
    }

    & .MuiFormLabel-root.Mui-error {
      color: ${labelColor};
    }

    & .MuiFormHelperText-root {
      font-family: Calibre;
      letter-spacing: 0;
    }

    & .MuiFormHelperText-root.Mui-error {
      color: ${colors['--text-error']};
    }

    & .MuiOutlinedInput-root {
      svg {
        background-color: transparent;
        color: ${colors['--ui-inactive-02']};
      }

      &.Mui-focused .MuiInputAdornment-root svg {
        color: ${colors['--border-focus-dark']};
      }

      &.Mui-focused .MuiInputAdornment-root p {
        color: ${colors['--border-focus-dark']};
      }
    }

    & .MuiFormLabel-filled:not(.Mui-disabled) + .MuiOutlinedInput-root .MuiInputAdornment-root svg {
      color: ${colors['--border-focus-dark']};
    }

    & .MuiFormLabel-filled:not(.Mui-disabled) + .MuiOutlinedInput-root .MuiInputAdornment-root p {
      color: ${colors['--border-focus-dark']};
    }

    .MuiOutlinedInput-adornedStart div:first-of-type {
      margin-right: 8px;

      svg {
        margin-right: -4px;
        margin-left: -8px;
      }
    }

    .MuiOutlinedInput-adornedEnd div:last-of-type {
      margin-left: 8px;

      svg {
        margin-right: -8px;
        margin-left: -4px;
      }
    }

    // Error Icon

    & .MuiFormHelperText-root {
      margin-top: 0;
      }
    &.Mui-error  .MuiFormHelperText-root {
      display: flex;
      line-height: 24px;
      margin-top: 4px;
      margin-left: 0;
    }

    & .error-icon {
      display: none;
    }


    &.Mui-error .MuiFormHelperText-root {
      color: ${colors['--text-error']}
    }
    &.Mui-error .MuiFormHelperText-root .error-icon {
      display: inline-block;

      svg {
        margin-left: -4px;
        margin-right: 4px;
        height: 24px;
        width: 24px;
        vertical-align: text-bottom;
      }
    }
  `
})

export default StyledFormControl
